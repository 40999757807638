import {Component, OnInit} from '@angular/core';
import {InstanceDaoService} from '../../support/data-center/instance/instance-dao.service';
import {ProjectDaoService} from '../../project/project-dao.service';
import {DataCenterDAOService} from '../../data-center/data-center-dao';
import {FormGroup} from '@angular/forms';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {InstanceSnapshotWrapper} from './instance-snapshot-wrapper';
import {SnapshotPolicyWrapper} from './snapshot-policy-wrapper';
import {DataCenter, Instance, Project, SnapshotPolicy, SnapshotPolicyType} from '../../../models';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-instance-snapshot-dashboard',
  templateUrl: './instance-snapshot-dashboard.component.html',
  styleUrls: ['./instance-snapshot-dashboard.component.scss'],
  animations: fuseAnimations,
})
export class InstanceSnapshotDashboardComponent implements OnInit {
  instances: Instance[];
  snapshotPolicies: SnapshotPolicy[];
  projects: Project[];
  dataCenters: DataCenter[];
  myForm: FormGroup;
  rows: InstanceSnapshotWrapper[];
  loadingIndicator = true;

  constructor(private instanceDao: InstanceDaoService,
              private dataCenterDao: DataCenterDAOService,
              private projectDao: ProjectDaoService) {
  }

  ngOnInit(): void {
    forkJoin([
      this.instanceDao.filter({hasGoogleInstanceName: true}),
      this.instanceDao.getAllSnapshotPolicy(),
      this.dataCenterDao.get(),
      this.projectDao.get()
    ]).subscribe((result) => {
      this.instances = result[0];
      this.snapshotPolicies = result[1];
      this.dataCenters = result[2];
      this.projects = result[3];
      this.rows = [];
      for (const instance of this.instances) {
        const snapshotPolicy = this.getSnapshotPolicy(instance.id);
        const instanceHelper: InstanceSnapshotWrapper = {
          instanceId: instance.id,
          instanceName: instance.name,
          projectName: this.getInstanceProject(instance).name,
          snapshotPolicyDaily: snapshotPolicy.snapshotPolicyTypes['DAILY'],
          snapshotPolicyThreeHour: snapshotPolicy.snapshotPolicyTypes['THREE_HOUR_BUSINESS'],
          snapshotPolicyWeekly: snapshotPolicy.snapshotPolicyTypes['WEEKLY'],
          snapshotPolicyMonthly: snapshotPolicy.snapshotPolicyTypes['MONTHLY']
        };
        this.rows.push(instanceHelper);
      }
      this.loadingIndicator = false;
    });
  }

  getInstanceProject(instance: Instance): Project {
    return this.projects.find((project) => {
      const datacenter = this.dataCenters.find((dataCenter) => {
        return instance.dataCenterId === dataCenter.id;
      });
      if (datacenter && datacenter.id) {
        return project.id === datacenter.projectId;
      }
    });
  }

  getSnapshotPolicy(instanceId: number): SnapshotPolicyWrapper {
    let find = this.snapshotPolicies.find((value) => {
      return value.instanceId === instanceId;
    });
    if (!find) {
      find = {
        instanceId: instanceId,
        snapshotPolicyTypes: []
      };
    }
    const snapshotPolicyHelper: SnapshotPolicyWrapper = {
      instanceId: find.instanceId,
      snapshotPolicyTypes: []
    };
    snapshotPolicyHelper.snapshotPolicyTypes['DAILY'] = false;
    snapshotPolicyHelper.snapshotPolicyTypes['THREE_HOUR_BUSINESS'] = false;
    snapshotPolicyHelper.snapshotPolicyTypes['WEEKLY'] = false;
    snapshotPolicyHelper.snapshotPolicyTypes['MONTHLY'] = false;
    for (const snapshotPolicyType of find.snapshotPolicyTypes) {
      snapshotPolicyHelper.snapshotPolicyTypes[snapshotPolicyType] = true;
    }
    return snapshotPolicyHelper;
  }

  onSelectAllDaily(): void {
    const find = this.getAllDailySelected();
    if (!find) {
      for (const row of this.rows) {
        row.snapshotPolicyDaily = true;
      }
    } else {
      for (const row of this.rows) {
        row.snapshotPolicyDaily = false;
      }
    }
  }

  onSelectAllThreeHour(): void {
    const find = this.getAllThreeHourSelected();
    if (!find) {
      for (const row of this.rows) {
        row.snapshotPolicyThreeHour = true;
      }
    } else {
      for (const row of this.rows) {
        row.snapshotPolicyThreeHour = false;
      }
    }
  }

  onSelectAllWeekly(): void {
    const find = this.getAllWeeklySelected();
    if (!find) {
      for (const row of this.rows) {
        row.snapshotPolicyWeekly = true;
      }
    } else {
      for (const row of this.rows) {
        row.snapshotPolicyWeekly = false;
      }
    }
  }

  onSelectAllMonthly(): void {
    const find = this.getAllMonthlySelected();
    if (!find) {
      for (const row of this.rows) {
        row.snapshotPolicyMonthly = true;
      }
    } else {
      for (const row of this.rows) {
        row.snapshotPolicyMonthly = false;
      }
    }
  }

  getAllDailySelected(): boolean {
    let find = null;
    if (this.rows) {
      find = this.rows.find((row) => {
        return row.snapshotPolicyDaily === false;
      });
    }
    return find === undefined;
  }

  getAllThreeHourSelected(): boolean {
    let find = null;
    if (this.rows) {
      find = this.rows.find((row) => {
        return row.snapshotPolicyThreeHour === false;
      });
    }
    return find === undefined;
  }

  getAllWeeklySelected(): boolean {
    let find = null;
    if (this.rows) {
      find = this.rows.find((row) => {
        return row.snapshotPolicyWeekly === false;
      });
    }
    return find === undefined;
  }

  getAllMonthlySelected(): boolean {
    let find = null;
    if (this.rows) {
      find = this.rows.find((row) => {
        return row.snapshotPolicyMonthly === false;
      });
    }
    return find === undefined;
  }

  onSubmit(): void {
    const rowsChanged: InstanceSnapshotWrapper[] = [];
    for (const row of this.rows) {
      const originalSnapshotPolicy = this.getSnapshotPolicy(row.instanceId);
      const daily = originalSnapshotPolicy.snapshotPolicyTypes['DAILY'];
      const three_hour = originalSnapshotPolicy.snapshotPolicyTypes['THREE_HOUR_BUSINESS'];
      const weekly = originalSnapshotPolicy.snapshotPolicyTypes['WEEKLY'];
      const monthly = originalSnapshotPolicy.snapshotPolicyTypes['MONTHLY'];
      let shouldAdd = false;
      if ((daily && !row.snapshotPolicyDaily) || (row.snapshotPolicyDaily && !daily)) {
        shouldAdd = true;
      }
      if ((three_hour && !row.snapshotPolicyThreeHour) || (row.snapshotPolicyThreeHour && !three_hour)) {
        shouldAdd = true;
      }
      if ((weekly && !row.snapshotPolicyWeekly) || (row.snapshotPolicyWeekly && !weekly)) {
        shouldAdd = true;
      }
      if ((monthly && !row.snapshotPolicyMonthly) || (row.snapshotPolicyMonthly && !monthly)) {
        shouldAdd = true;
      }
      if (shouldAdd) {
        rowsChanged.push(row);
      }
    }
    const snapshotPolicies = [];
    for (const row of rowsChanged) {
      const snapshotPolicy: SnapshotPolicy = {
        instanceId: row.instanceId,
        snapshotPolicyTypes: []
      };
      if (row.snapshotPolicyDaily) {
        snapshotPolicy.snapshotPolicyTypes.push(SnapshotPolicyType.DAILY);
      }
      if (row.snapshotPolicyThreeHour) {
        snapshotPolicy.snapshotPolicyTypes.push(SnapshotPolicyType.THREE_HOUR_BUSINESS);
      }
      if (row.snapshotPolicyWeekly) {
        snapshotPolicy.snapshotPolicyTypes.push(SnapshotPolicyType.WEEKLY);
      }
      if (row.snapshotPolicyMonthly) {
        snapshotPolicy.snapshotPolicyTypes.push(SnapshotPolicyType.MONTHLY);
      }
      snapshotPolicies.push(snapshotPolicy);
    }
    this.instanceDao.saveBatchSnapshotPolicy(snapshotPolicies).subscribe();
  }
}
