<div class="dialog-content-wrapper">
    <form autocomplete="off">

        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row>
                <span class="title dialog-title">Lista de empresas</span>
            </mat-toolbar-row>
            <mat-toolbar-row class="no-padding" style="height: auto;">
                <div class="search-fixed">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <input class="search-customer-group"
                               id="customer-group-search-search"
                               autocomplete="off" placeholder="busca" matInput [formControl]="search">

                        <mat-form-field appearance="outline" style="height: 66px;width: 120px; margin-top:16px">
                            <mat-label>Tipo</mat-label>
                            <mat-select placeholder="Tipo" [formControl]="selectSearchCustomerGroupTypeFormControl">
                                <mat-option [value]="searchCustomerGroupType.ALL">Todos</mat-option>
                                <mat-option [value]="searchCustomerGroupType.COMPANY_NAME">Nome</mat-option>
                                <mat-option [value]="searchCustomerGroupType.PARTNER" *appIsAdmin>Parceiro</mat-option>
                                <mat-option [value]="searchCustomerGroupType.SOFTWARE_COMPANY">Softwares</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg " fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>

            <table class="customer-group-table mb-30 mt-24" border="0" cellspacing="0">
                <tr *ngFor="let customerGroup of customerGroupsFiltered">
                    <td class="w-40" (click)="setCustomerGroupOnChange(customerGroup)" style="padding: 0 0 0 10px;">
                        <span *ngIf="customerGroup.id;">
                            <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroup.imageUUID, 30)}}"
                                 width="30" height="30">
                        </span>
                    </td>
                    <td class="customer-group-search-customer-group w-430"
                        (click)="setCustomerGroupOnChange(customerGroup)">
                        <div class="username mr-12 ml-8">{{ customerGroup.name }}</div>
                        <div *ngIf="customerGroup.partnerCustomerGroupId"
                             class="sub-title" style="padding-left: 9px;">
                            {{customerGroupService.getCustomerGroup(customerGroup.partnerCustomerGroupId)?.name}}
                        </div>

                    </td>
                    <td (click)="setCustomerGroupOnChange(customerGroup)">
                        <div *ngFor="let softwareCompanyId of customerGroup.softwareCompanyIds" class="sub-title">
                            {{adminService.getSoftwareCompany(softwareCompanyId)?.name}}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent" aria-label="Criar Empresa"
                    id="customer-group-search-add-customer-group" (click)="onAddCustomerGroup()">Criar Empresa
            </button>
            <button type="button" mat-raised-button color="primary"
                    id="customer-group-search-cancel"
                    (click)="onCancel()">Cancelar
            </button>
        </div>
    </form>
</div>
