import {Component} from '@angular/core';
import {InstanceScheduleAuditDaoService} from '../../support/data-center/instance/instance-schedule-audit/instance-schedule-audit-dao.service';
import {InstanceScheduleAudit} from '../../../models';

@Component({
    selector: 'app-instance-schedule-audits',
    templateUrl: './instance-schedule-audits.component.html',
    styleUrls: ['./instance-schedule-audits.component.scss', '../status.component.scss']
})
export class InstanceScheduleAuditsComponent {

    public instanceScheduleAudits: InstanceScheduleAudit[];
    public instanceScheduleAuditStatus = true;

    constructor(private instanceScheduleAuditDao: InstanceScheduleAuditDaoService,) {
        this.instanceScheduleAuditDao.getStatus().subscribe((instanceScheduleAudits) => {
            this.instanceScheduleAudits = instanceScheduleAudits;
            this.getStatus(this.instanceScheduleAudits);

        });
    }

    getStatus(instanceScheduleAudits: InstanceScheduleAudit[]): void {
        if (instanceScheduleAudits && instanceScheduleAudits[0] && instanceScheduleAudits[1]) {
            const dt1: Date = new Date(instanceScheduleAudits[0].createdAt);
            const dt2: Date = new Date(instanceScheduleAudits[1].createdAt);
            const now: Date = new Date(Date.now());
            const ms1: number = now.getTime() - dt1.getTime();
            const ms2: number = now.getTime() - dt2.getTime();
            const minFix1 = parseInt((parseInt((ms1 / 1000).toString(), 10) / 60).toString(), 10);
            const minFix2 = parseInt((parseInt((ms2 / 1000).toString(), 10) / 60).toString(), 10);
            if (minFix1 > 40) {
                this.instanceScheduleAuditStatus = false;
            } else {
                this.instanceScheduleAuditStatus = minFix2 <= 720;
            }
        }
    }


}
