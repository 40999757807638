import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Wan} from '../../../../models';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class DataCenterRouterWanDaoService extends BaseDao<Wan>{

  constructor(http: R2CloudHttpApiService, validatorDao: KluhManagerValidator) {
    super(http, validatorDao, 'wan');
  }

}
