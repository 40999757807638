<form (submit)="onSubmit()">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Agendamento da instancia:</span>
            <span>{{instance.name}}</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-caption primary-200-fg" style="padding: 10px; text-align: center;">
        <div> Para deixar desligado o dia inteiro, coloque <b class="primary-500-fg">00:00</b> em ligar e delisgar </div>
        <div> Para deixar ligada o dia inteiro, coloque <b class="primary-500-fg">delisgar em um horário anterior</b> ao <b class="primary-500-fg">ligar</b>  </div>
    </div>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent [formGroup]="myForm">

        <div style="margin: 10px;">
            <ng-container formGroupName="instanceForm">
                <mat-slide-toggle name="hasSchedule" formControlName="hasInstanceSchedule"> Ativar Agendamento
                </mat-slide-toggle>
            </ng-container>
        </div>

        <mat-divider class="divider"></mat-divider>

        <table>
            <thead>
            <tr style="text-align: center">
                <th class="tit col-start">Ligar</th>
                <th class="tit col-stop">Desligar</th>
            </tr>
            </thead>
            <tr style="text-align: center">
                <td>
                    <div style="height: 55px;">
                    <mat-form-field class="hour-field">
                        <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                               style="text-align: right" type="number"
                               [formControl]="startHoursAll">
                    </mat-form-field>
                    <span style="padding: 0px 3px 7px 3px;"> : </span>
                    <mat-form-field class="hour-field">
                        <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                               [formControl]="startMinutesAll">
                    </mat-form-field>
                </div>
                    <button type="button" (click)="onInsertInAllStart(startHoursAll.value, startMinutesAll.value)" mat-raised-button color="accent" aria-label="Copiar em todos">
                        Copiar em todos
                    </button>
                </td>
                <td>
                    <div style="height: 55px;">
                    <mat-form-field class="hour-field">
                        <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                               style="text-align: right" type="number" name="startHours"
                               [formControl]="stopHoursAll">
                    </mat-form-field>
                    <span style="padding: 0px 3px 7px 3px;"> : </span>
                    <mat-form-field class="hour-field">
                        <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                               [formControl]="stopMinutesAll">
                    </mat-form-field>
                </div>
                    <button type="button" (click)="onInsertInAllStop(stopHoursAll.value, stopMinutesAll.value)"  mat-raised-button color="accent" aria-label="Copiar em todos">
                        Copiar em todos
                    </button>
                </td>
            </tr>
        </table>


        <mat-divider class="divider mt-30"></mat-divider>

        <table>
            <thead>
            <tr>
                <th class="day-of-week" style="width: 40%"></th>
                <th class="tit col-start">Ligar</th>
                <th class="tit col-stop">Desligar</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let instanceScheduleForm of myForm.get('list')['controls']; let i = index;"
                [formGroup]="instanceScheduleForm">


                <td class="day-of-week">
                    {{getDayOfWeek(instanceScheduleForm.value.dayOfWeek)}}
                </td>

                <!--<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" style="width: 186px">-->
                <td align="center" class="col-start">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                   style="text-align: right" type="number" name="startHours"
                                   formControlName="startHours">

                            <mat-error>
                                {{instanceScheduleForm.get('startHours').errors | error}}
                            </mat-error>
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                   name="startMinutes" formControlName="startMinutes">

                            <mat-error>
                                {{instanceScheduleForm.get('startMinutes').errors | error}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </td>
                <td align="center" class="col-stop">
                    <div>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="23" min="0" style="text-align: right" type="number"
                                   name="stopHours" formControlName="stopHours">
                            <mat-error>
                                {{instanceScheduleForm.get('stopHours').errors | error}}
                            </mat-error>
                        </mat-form-field>
                        <span style="padding: 0px 3px 7px 3px;"> : </span>
                        <mat-form-field class="hour-field">
                            <input matInput placeholder="" max="59" min="0" style="text-align: left" type="number"
                                   name="stopMinutes" formControlName="stopMinutes">
                            <mat-error>
                                {{instanceScheduleForm.get('stopMinutes').errors | error}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </td>
                <!--</div>-->

            </tr>
            </tbody>
        </table>


    </div>

    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" aria-label="Salvar"
                [disabled]="!myForm.valid">
            Salvar
        </button>
        <button type="button" mat-raised-button color="warn" aria-label="Cancelar" (click)="onCancel()">
            Cancelar
        </button>
    </div>
</form>


