import {Component, OnDestroy} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {ADUser, ADUserServiceExternal} from '../../../models';
import {CrudOperationWrapper, theDateIsGreaterThanNowPlusMinutes} from '../../../helpers/kluh';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {AdUserServiceExternalDaoService} from '../../ad-user-service-external/ad-user-service-external-dao.service';
import {AdUserServiceService} from '../r2-cloud-admin-aduser/ad-user-service.service';
import {R2CloudAdminUserServiceTypeChoiceComponent} from './r2-cloud-admin-user-service-type-choice/r2-cloud-admin-user-service-type-choice.component';

@Component({
    selector: 'app-r2-cloud-admin-aduser-service',
    templateUrl: './r2-cloud-admin-aduser-service.component.html',
    styleUrls: ['./r2-cloud-admin-aduser-service.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminADUserServiceComponent extends ComponentCleaner implements OnDestroy {

    timerPendingChangesLoad = null;
    readonly appCanCreateList: PermissionWrapper[];
    canAccess = false;

    constructor(public adminService: R2CloudAdminService,
                public customerGroupService: CustomerGroupService,
                private adUserServiceExternalDaoService: AdUserServiceExternalDaoService,
                private userAuthorityDao: UserAuthorityDaoService,
                public adUserServiceService: AdUserServiceService,
                private dialog: MatDialog) {
        super();
        this.appCanCreateList = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];


    }

    ngOnDestroy(): void {
        clearInterval(this.timerPendingChangesLoad);
    }

    openServiceUserCreateModal(): void {
        this.openServiceUserModal(null, null);
    }

    openServiceUserEditModal(adUser: ADUser, adUserServiceExternal: ADUserServiceExternal): void {
        this.adUserServiceService.openServiceUserModal(adUser, adUserServiceExternal);
    }

    theDateIsGreaterThanNowPlusMinutes(updatedAt: Date, minutes: number): boolean {
        return theDateIsGreaterThanNowPlusMinutes(updatedAt, minutes);
    }

    private openServiceUserModal(adUser: ADUser, adUserServiceExternal: ADUserServiceExternal): void {
        const dialogRef = this.dialog.open(R2CloudAdminUserServiceTypeChoiceComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                managerUsers: this.adminService.managerUsers,
                adUser: adUser,
                adUserServiceExternal: adUserServiceExternal,
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((crudOperation: CrudOperationWrapper) => {

        });
        this.addSubscription(subscription);
    }
}
