<div fusePerfectScrollbar class="page-layout simple fullwidth">
    <div class="content p-24">
        <div fxLayout="column" class="admin-box">

            <div class="arrow_box" *ngIf="!(adminService.subProject?.id) && !customerGroup?.id">
                <div class="inner_box">Selecione uma empresa</div>
            </div>

            <app-r2-cloud-admin-sub-project></app-r2-cloud-admin-sub-project>

            <ng-container *ngIf="adminService.subProject?.id">
                <!--Dominios-->
                <app-r2-cloud-admin-addomain></app-r2-cloud-admin-addomain>

                <ng-container *ngIf="adminService.adDomains && adminService.adDomains.length > 0">
                    <!--Servidores-->
                    <app-r2-cloud-admin-desktop-server></app-r2-cloud-admin-desktop-server>

                    <!--AdUses-->
                    <app-r2-cloud-admin-aduser></app-r2-cloud-admin-aduser>

                    <!--Grupos-->
                    <app-r2-cloud-admin-adgroup></app-r2-cloud-admin-adgroup>

                    <!--AdUsers Service-->
                    <app-r2-cloud-admin-aduser-service></app-r2-cloud-admin-aduser-service>

                    <!--Remote App-->
                    <app-r2-cloud-admin-remote-app></app-r2-cloud-admin-remote-app>
                </ng-container>

            </ng-container>
        </div>
    </div>
</div>