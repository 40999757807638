<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>Status últimos agendamentos
                <span *ngIf="instanceScheduleAuditStatus" _ngcontent-c30=""
                      class="nav-link-badge ng-star-inserted"
                      style="background-color: rgb(3,155,229); color: rgb(255, 255, 255);">
                                                                OK
                                                  </span>
                <span *ngIf="!instanceScheduleAuditStatus" _ngcontent-c30=""
                      class="nav-link-badge ng-star-inserted"
                      style="background-color: rgb(244, 67, 54); color: rgb(255, 255, 255);">
                                                      ERRO
                                                  </span>
            </div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th style="width: 260px"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let instanceScheduleAudit of instanceScheduleAudits">
                <td>
                    <div *ngIf="instanceScheduleAudit">
                        <div>
                            <b *ngIf="instanceScheduleAudit.status == 'RUNNING' || instanceScheduleAudit.status == 'TERMINATED'">CHECOU
                                INSTÂNCIAS</b>
                            <b *ngIf="instanceScheduleAudit.status == 'CHANGING TO RUNNING' || instanceScheduleAudit.status == 'CHANGING TO TERMINATED'">EDITOU
                                ESTADO</b>
                        </div>
                        <div class="font-size-12">{{instanceScheduleAudit.status}}
                            : {{instanceScheduleAudit.instanceScheduleId}}</div>
                    </div>
                </td>
                <td>
                    <div *ngIf="instanceScheduleAudit">
                        <div *ngIf="instanceScheduleAuditStatus">
                            <b>
                                {{instanceScheduleAudit.createdAt | timeDiff: 'large'}}
                            </b>
                        </div>
                        <div *ngIf="!instanceScheduleAuditStatus">
                            <b style="color: red">
                                {{instanceScheduleAudit.createdAt | timeDiff: 'large'}}
                            </b>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
