import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TestComponent} from './test/test.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseHighlightModule, FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {TesteAuthDaoService} from './test/teste-auth.dao.service';


const routes = [
    {
        path: 'test',
        component: TestComponent,
        canActivate: [AuthService],
        data: {permissions: ['DataCenter.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        FuseHighlightModule
    ],
    declarations: [TestComponent],
    providers: [
        TesteAuthDaoService
    ]
})
export class TestModule {
}
