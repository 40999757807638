import {Component, Input, OnInit} from '@angular/core';
import {InstanceEditComponent} from '../instance-edit/instance-edit.component';

import {ComponentCleaner} from '../../../../../component-cleaner';
import {InstanceDaoService} from '../instance-dao.service';
import {GoogleInstanceStatusWrapper} from '../google-instance-status-wrapper';
import {InstanceScheduleComponent} from '../instance-schedule/instance-schedule.component';
import {InstanceScheduleDaoService} from '../instance-schedule/instance-schedule-dao.service';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {GoogleInstanceStatus, Instance, SnapshotPolicyType} from '../../../../../models';
import {timer} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-instance-list',
    templateUrl: './instance-list.component.html',
    styleUrls: ['./instance-list.component.scss']
})
export class InstanceListComponent extends ComponentCleaner implements OnInit {
    instances: Instance[];
    instanceStatusList: GoogleInstanceStatusWrapper[] = [];
    @Input()
    snapshotPolicyTypes: SnapshotPolicyType[];
    @Input()
    editing: boolean;


    constructor(private dialog: MatDialog,
                private instanceDao: InstanceDaoService,
                private instanceScheduleDao: InstanceScheduleDaoService) {
        super();
        const subscription = timer(0, 8000).subscribe(() => {
            if (this.instances) {
                for (const instance of this.instances) {
                    this.onInstanceStatus(instance);
                }
            }
        });
        this.addSubscription(subscription);
    }

    ngOnInit(): void {
    }

    @Input()
    set modelInstances(instances: any[]) {
        this.instances = instances;
        if (this.instances) {
            for (const instance of this.instances) {
                this.onInstanceStatus(instance);
            }
        }
    }

    onEditSnapshot(instance: Instance): void {
        this.instanceDao.getSnapshotPolicy(instance.id).subscribe((snapshotPolicy) => {
            this.dialog.open(InstanceEditComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog',
                data: {
                    snapshotPolicyTypes: this.snapshotPolicyTypes,
                    instance: instance,
                    snapshotPolicy: snapshotPolicy
                }
            });
            // dialogRef
            //   .afterClosed()
            //   .takeUntil(this.destroy$)
            //   .filter(x => !!x)
            //   .map((data) => data.instances)
            //   .subscribe((newInstances) => {
            //
            //   });
        });
    }


    onEditInstanceSchedule(instance: Instance): void {
        this.instanceScheduleDao.filter({instanceId: instance.id}).subscribe((instanceScheduleList) => {
            const dialogRef = this.dialog.open(InstanceScheduleComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog',
                data: {
                    instance: instance,
                    instanceScheduleList: instanceScheduleList
                }
            });
            const subscription = dialogRef
                .afterClosed()
                .subscribe(() => {
                    this.instanceDao.getOne(instance.id).subscribe((result) => {
                        const index = this.instances.findIndex((item) => {
                            return item.id === result.id;
                        });
                        this.instances[index] = result;
                    });
                });
            this.addSubscription(subscription);
        });
    }

    onStopInstance(instance: Instance): void {
        const index = this.instanceStatusList.findIndex((instanceStatus) => {
            return instanceStatus.instanceId === instance.id;
        });
        this.instanceStatusList[index].actionPending = true;
        this.instanceDao.stopInstance(instance).subscribe(() => {
            // console.debug('parado instancia ' + instance.name);
        });
    }

    onStartInstance(instance: Instance): void {
        const index = this.instanceStatusList.findIndex((instanceStatus) => {
            return instanceStatus.instanceId === instance.id;
        });
        this.instanceStatusList[index].actionPending = true;
        this.instanceDao.startInstance(instance).subscribe(() => {
            // console.debug('iniciado instancia ' + instance.name);
        });
    }

    onInstanceStatus(instance: Instance): void {
        this.instanceDao.getInstanceStatus(instance).subscribe((result) => {
            const index = this.instanceStatusList.findIndex((instanceStatus) => {
                return instanceStatus.instanceId === instance.id;
            });
            const newStatus = result.value as GoogleInstanceStatus;
            const newInstanceStatus = {
                instanceId: instance.id,
                status: newStatus,
                actionPending: false
            };
            if (index !== -1) {
                const existingInstanceStatus = this.instanceStatusList[index];
                if (existingInstanceStatus.actionPending && existingInstanceStatus.status === newStatus) {
                    newInstanceStatus.actionPending = true;
                }
                this.instanceStatusList[index] = newInstanceStatus;
            } else {
                this.instanceStatusList.push(newInstanceStatus);
            }
            // console.debug('instance: ' + instance.name + ', status: ' + result.value);
        });
    }

    getInstanceStatus(instance: Instance): GoogleInstanceStatus {
        if (this.instanceStatusList) {

            const find = this.instanceStatusList.find((instanceStatus) => {
                return instanceStatus.instanceId === instance.id;
            });
            if (find) {
                return find.status;
            }
        }
        return null;
    }

    getInstancePending(instance: Instance): boolean {
        if (this.instanceStatusList) {
            const find = this.instanceStatusList.find((instanceStatus) => {
                return instanceStatus.instanceId === instance.id;
            });
            if (find) {
                return find.actionPending;
            }
        }
        return true;
    }

    onRemoveInstance(instance: Instance): void {

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo deletar essa Instancia?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.instanceDao.remove(instance.id).subscribe(() => {
                        const indexRemove = this.instances.findIndex((y) => y.id === instance.id);
                        if (indexRemove > -1) {
                            this.instances.splice(indexRemove, 1);
                        }
                    });
                }
            });
        this.addSubscription(subscription);
    }
}
