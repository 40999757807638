import {Component, EventEmitter, Input, Output} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {ADDomain, ADUser, CustomerGroup, ManagerUser, SubProject} from '../../../models';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {StatusService} from '../status-service';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';

@Component({
    selector: 'app-ad-user-snapshots',
    templateUrl: './ad-user-snapshots.component.html',
    styleUrls: ['./ad-user-snapshots.component.scss', '../status.component.scss']
})
export class AdUserSnapshotsComponent {

    public adUsersSnapshots: JaversSnapshot<ADUser>[];

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    managerUsers: ManagerUser[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    @Input()
    adUsers: ADUser[];


    @Output()
    adUsersRead: EventEmitter<ADUser[]> = new EventEmitter();

    loading = false;
    alreadyRead = false;


    constructor(private adUserDaoService: AdUserDaoService, public statusServer: StatusService) {
    }

    getData(): void {
        this.loading = true;
        this.getAdUsers().subscribe(adUsers => {
            this.alreadyRead = true;
            this.adUsersRead.emit(adUsers);
            this.adUsers = adUsers;
            this.adUserDaoService.snapshotsLimit(5).subscribe((adUsersSnapshots) => {
                this.loading = false;
                this.adUsersSnapshots = adUsersSnapshots;
            });
        });
    }

    getAdUsers(): Observable<ADUser[]> {
        // console.log('!adUsers: ' + !this.adUsers, 'adUsers?.length < 1: ' + (this.adUsers?.length < 1), 'alreadyRead: ' + (this.alreadyRead));
        if (!this.adUsers || this.adUsers?.length < 1 || this.alreadyRead) {
            console.log('getAdUsers()');
            return this.adUserDaoService.get();
        }
        return of(this.adUsers);
    }
}
