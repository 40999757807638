import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerDaoService} from './customer-dao.service';
import {CustomerListComponent} from './customer-list/customer-list.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {KluhSharedModule} from '../../kluh-shared.module';
import {CustomerCreateEditComponent} from './customer-create-edit/customer-create-edit.component';
import {NgxMaskModule} from 'ngx-mask';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path: 'customer',
        component: CustomerListComponent,
        canActivate: [AuthService],
        data: {permissions: ['Customer.READ']}
    }
];



@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        NgxMaskModule.forRoot()
    ],
    entryComponents: [CustomerCreateEditComponent],
    declarations: [CustomerListComponent, CustomerCreateEditComponent],
    providers: [CustomerDaoService]
})
export class CustomerModule {
}
