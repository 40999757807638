import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {AdGroupListComponent} from './ad-group-list/ad-group-list.component';
import {AdGroupDaoService} from './ad-group-dao.service';
import {AdGroupCreateEditComponent} from './ad-group-create-edit/ad-group-create-edit.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path: 'ad-group',
        component: AdGroupListComponent,
        canActivate: [AuthService],
        data: {permissions: ['ADGroup.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatToolbarModule,
        MatSelectModule,
        KluhSharedModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMatSelectSearchModule
    ],
    providers: [
        AdGroupDaoService
    ],
    declarations: [AdGroupListComponent, AdGroupCreateEditComponent],
    entryComponents: [AdGroupCreateEditComponent]
})
export class AdGroupModule {
}
