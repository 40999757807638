import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PermissionWrapper} from '../../../../../directives/if-permission.directive';
import {MatTableDataSource} from '@angular/material/table';
import {ImageFileService} from '../../../../image-file/image-file-service';
import {FormControl} from '@angular/forms';
import {startWith} from 'rxjs/operators';
import {faWindows} from '@fortawesome/free-brands-svg-icons';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {combineLatest} from 'rxjs/';
import {ConfirmDialogComponent} from '../../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {
    CustomerGroup,
    HipcomBulkUpdateCurrentSoftwareVersion,
    HipcomBulkUpdateDetail,
    HipcomBulkUpdateLatestSoftwareVersion,
    HipcomBulkUpdatePath,
    ServerStatus,
    ServerStatusType,
    StatusType,
    SubProject
} from '../../../../../models';
import {SubProjectDaoService} from '../../../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {CustomerGroupService} from '../../../../customer-group/customer-group.service';
import {HipcomBulkUpdateDetailDaoService} from './hipcom-bulk-update-detail-dao.service';
import {HipcomBulkUpdateDetailLogDaoService} from '../hipcom-bulk-update-detail-log/hipcom-bulk-update-detail-log-dao.service';
import {HipcomBulkUpdateCurrentSoftwareVersionDaoService} from '../hipcom-bulk-update-current-software-version/hipcom-bulk-update-current-software-version-dao.service';
import {HipcomBulkUpdateScheduleCreateEditComponent} from '../hipcom-bulk-update-schedule-create-edit/hipcom-bulk-update-schedule-create-edit.component';
import {CrudOperationWrapper} from '../../../../../helpers/kluh';
import {HipcomBulkUpdateDetailLogComponent} from '../hipcom-bulk-update-detail-log/hipcom-bulk-update-detail-log.component';
import {DesktopServerStatusService} from '../../../../../ws/desktop-server-status.service';
import {HipcomBulkUpdateMessageSendMessageComponent} from '../hipcom-bulk-update-message-send-message/hipcom-bulk-update-message-send-message.component';
import {HipcomBulkUpdateService} from '../hipcom-bulk-update.service';
import {DomainPathService} from '../../../../../domain-path/domain-path.service';
import {HipcomBulkUpdatePathDaoService} from '../hipcom-bulk-update-path/hipcom-bulk-update-path-dao.service';

@Component({
    selector: 'app-hipcom-bulk-update-detail',
    templateUrl: './hipcom-bulk-update-detail.component.html',
    styleUrls: ['./hipcom-bulk-update-detail.component.scss', '../hipcom-bulk-update.component.scss']
})
export class HipcomBulkUpdateDetailComponent extends ComponentCleaner {

    faWindows = faWindows;
    faCircle = faCircle;
    bulkUpdateDetailFormControl: FormControl = new FormControl('');
    subProjects: SubProject[] = [];
    appCanCreateList: PermissionWrapper[];

    hipcomBulkUpdateDetails: HipcomBulkUpdateDetail[] = [];

    desktopServerOnlineList: ServerStatus[] = [];
    hipcomBulkUpdatePathIds: number[] = [];
    hipcomBulkUpdatePaths: HipcomBulkUpdatePath[] = [];

    @Input()
    hipcomBulkUpdateLatestSoftwareVersions: HipcomBulkUpdateLatestSoftwareVersion[];

    hipcomBulkUpdateCurrentSoftwareVersions: HipcomBulkUpdateCurrentSoftwareVersion[] = [];

    @Output()
    eventHipcomBulkUpdateDetailDataList = new EventEmitter();

    @Output()
    eventHipcomBulkUpdateCurrentSoftwareVersions = new EventEmitter();


    displayedColumns: string[] = [
        'customerGroup', 'schedule', 'updateModules',
        'modules', 'checkedAt', 'finishedAt', 'online', 'status', 'action'
    ];
    dataSource = new MatTableDataSource<HipcomBulkUpdateDetail>();
    statusType = StatusType;
    maxUpdateTimeInMinutes = 60;


    constructor(private route: ActivatedRoute,
                private imageFileService: ImageFileService,
                private hipcomBulkUpdateService: HipcomBulkUpdateService,
                private desktopServerStatusService: DesktopServerStatusService,
                private customerGroupService: CustomerGroupService,
                private subProjectDaoService: SubProjectDaoService,
                private router: Router,
                private hipcomBulkUpdateDetailDaoService: HipcomBulkUpdateDetailDaoService,
                private hipcomBulkUpdateCurrentSoftwareVersionDaoService: HipcomBulkUpdateCurrentSoftwareVersionDaoService,
                private hipcomBulkUpdateDetailLogDaoService: HipcomBulkUpdateDetailLogDaoService,
                private hipcomBulkUpdatePathDaoService: HipcomBulkUpdatePathDaoService,
                public domainPathService: DomainPathService,
                private dialog: MatDialog) {
        super();
        this.loadHipcomBulkUpdateDetails();

        combineLatest(
            [this.bulkUpdateDetailFormControl.valueChanges.pipe(startWith(''))]
        ).subscribe(values => {
            const fieldValue = values[0].toLowerCase();
            if (fieldValue) {
                this.dataSource.data = this.hipcomBulkUpdateDetails.filter(bulkUpdateDetail => {

                    let startAt = '';
                    let finishedAt = '';

                    if (bulkUpdateDetail.startAt) {
                        const stAt = new Date('' + bulkUpdateDetail.startAt);
                        startAt = stAt.getDate() + '-' + (stAt.getMonth() + 1) + '-' + stAt.getFullYear() + ' ' + stAt.getHours() + ':' + stAt.getMinutes();
                    }
                    if (bulkUpdateDetail.finishedAt && bulkUpdateDetail.finishedAt instanceof Date) {
                        const fiAt = new Date('' + bulkUpdateDetail.finishedAt);
                        finishedAt = fiAt.getDate() + '-' + (fiAt.getMonth() + 1) + '-' + fiAt.getFullYear() + ' ' + fiAt.getHours() + ':' + fiAt.getMinutes();
                    }
                    return (
                        this.getCustomerGroupByDesktopServerId(bulkUpdateDetail.desktopServerId).findIndex(
                            (value) => value.name.toLowerCase().indexOf(fieldValue.toLowerCase()) > -1) > -1 ||
                        (this.checkDesktopServerIsOnline(bulkUpdateDetail.desktopServerId) && 'online'.indexOf(fieldValue.toLowerCase()) > -1) ||
                        (!this.checkDesktopServerIsOnline(bulkUpdateDetail.desktopServerId) && 'offline'.indexOf(fieldValue.toLowerCase()) > -1) ||
                        (bulkUpdateDetail.hipcomBulkUpdateCurrentSoftwareVersionIds &&
                            this.findCurrentVersionByVersion(fieldValue, bulkUpdateDetail.hipcomBulkUpdateCurrentSoftwareVersionIds)) ||
                        this.findBySchedule(fieldValue, bulkUpdateDetail) ||
                        this.findCurrentVersionByName(fieldValue, bulkUpdateDetail.hipcomBulkUpdateCurrentSoftwareVersionIds) ||
                        startAt.indexOf(fieldValue) > -1 ||
                        finishedAt.indexOf(fieldValue) > -1 ||
                        bulkUpdateDetail.status.toLowerCase().indexOf(fieldValue.toLowerCase()) > -1 ||
                        (bulkUpdateDetail.status === StatusType.STARTED && 'novo'.indexOf(fieldValue.toLowerCase()) > -1) ||
                        (bulkUpdateDetail.status === StatusType.UPDATING && 'atualizando'.indexOf(fieldValue.toLowerCase()) > -1)
                    );
                });
            } else {
                this.dataSource.data = this.hipcomBulkUpdateDetails;
            }
        });
    }

    findDesktopServersOnline(): void {
        this.hipcomBulkUpdateService.findDesktopServersOnline(this.desktopServerOnlineList);
    }

    checkDesktopServerIsOnline(desktopServerId: number): boolean {
        const index = this.desktopServerOnlineList.findIndex((o) => o.desktopServerId === desktopServerId);
        if (index > -1) {
            if (this.desktopServerOnlineList[index].status === ServerStatusType.CONNECTED) {
                return true;
            }
        }
        return false;
    }

    findHipcomBulkUpdateLatestSoftwareVersionById(id: number): HipcomBulkUpdateLatestSoftwareVersion {
        return this.hipcomBulkUpdateService.findHipcomBulkUpdateLatestSoftwareVersionById(id, this.hipcomBulkUpdateLatestSoftwareVersions);
    }

    findHipcomBulkUpdateCurrentSoftwareVersionById(id: number): HipcomBulkUpdateCurrentSoftwareVersion {
        return this.hipcomBulkUpdateService.findHipcomBulkUpdateCurrentSoftwareVersionById(id, this.hipcomBulkUpdateCurrentSoftwareVersions);
    }

    checkCurrentVersionExist(currentVersionId: number): boolean {
        const currentVersion = this.findHipcomBulkUpdateCurrentSoftwareVersionById(currentVersionId);
        if (currentVersion) {
            return this.checkLastVersionExist(currentVersion.version, currentVersion.hipcomBulkUpdateLatestSoftwareVersionId);
        } else {
            return false;
        }
    }

    checkLastVersionExist(version: string, hipcomBulkUpdateLatestSoftwareVersionId: number): boolean {
        const index = this.hipcomBulkUpdateLatestSoftwareVersions.findIndex(value => (value.version === version && value.id === hipcomBulkUpdateLatestSoftwareVersionId));
        return index > -1;
    }

    findCurrentVersionByVersion(version: string, ids: number[]): boolean {
        for (const id of ids) {
            const index = this.hipcomBulkUpdateCurrentSoftwareVersions.findIndex(value =>
                (value.version.toLowerCase().indexOf(version.toLowerCase()) > -1) && value.id === id);
            if (index > -1) {
                return true;
            }
        }
        return false;
    }

    findCurrentVersionByName(name: string, ids: number[]): boolean {
        for (const id of ids) {
            const index = this.hipcomBulkUpdateCurrentSoftwareVersions.findIndex(value =>
                (this.findHipcomBulkUpdateLatestSoftwareVersionById(
                    value.hipcomBulkUpdateLatestSoftwareVersionId).name.toLowerCase().indexOf(name.toLowerCase()) > -1) && value.id === id && !this.checkCurrentVersionExist(id));
            if (index > -1) {
                return true;
            }
        }
        return false;
    }

    findBySchedule(text: string, bulkUpdateDetail: HipcomBulkUpdateDetail): boolean {
        return (this.twoZeros(bulkUpdateDetail.sundayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.sundayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.mondayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.mondayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.tuesdayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.tuesdayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.wednesdayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.wednesdayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.thursdayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.thursdayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.fridayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.fridayStartMinutes)).indexOf(text) > -1 ||
            (this.twoZeros(bulkUpdateDetail.saturdayStartHours) + ':' + this.twoZeros(bulkUpdateDetail.saturdayStartMinutes)).indexOf(text) > -1;
    }

    twoZeros(num: number): string {
        if (num < 10) {
            return '0' + num;
        }
        return '' + num;
    }

    loadHipcomBulkUpdateDetails(): void {
        this.hipcomBulkUpdateDetailDaoService.get().subscribe((hipcomBulkUpdateDetails) => {
            if (hipcomBulkUpdateDetails) {
                this.hipcomBulkUpdateDetails = hipcomBulkUpdateDetails;
                this.eventHipcomBulkUpdateDetailDataList.emit(this.hipcomBulkUpdateDetails);

                const desktopServerIds: number[] = [];
                for (const hipcomBulkUpdateDetail of this.hipcomBulkUpdateDetails) {
                    desktopServerIds.push(hipcomBulkUpdateDetail.desktopServerId);
                    this.desktopServerOnlineList.push(
                        {
                            desktopServerId: hipcomBulkUpdateDetail.desktopServerId,
                            status: null,
                            clientVersion: null,
                            username: null
                        }
                    );
                }
                this.subProjectDaoService.getSubProjectsByDesktopServerIds(desktopServerIds).subscribe((subProjects) => {
                    this.subProjects = subProjects;
                    this.hipcomBulkUpdateCurrentSoftwareVersionDaoService.get().subscribe((currents) => {
                        if (currents) {
                            this.hipcomBulkUpdateCurrentSoftwareVersions = currents;
                            this.eventHipcomBulkUpdateCurrentSoftwareVersions.emit(this.hipcomBulkUpdateCurrentSoftwareVersions);
                            this.getAllPathFromUpdateDetails(hipcomBulkUpdateDetails);
                        }
                        this.dataSource = new MatTableDataSource<HipcomBulkUpdateDetail>(this.hipcomBulkUpdateDetails);

                    });
                });
                this.findDesktopServersOnline();
            }
        });
    }

    private getAllPathFromUpdateDetails(hipcomBulkUpdateDetails: HipcomBulkUpdateDetail[]): void {
        this.hipcomBulkUpdatePathIds = [];
        const localHipcomBulkUpdatePathIds = [];
        hipcomBulkUpdateDetails.forEach((detail) => {
            this.hipcomBulkUpdateCurrentSoftwareVersions.forEach((current) => {
                if (current.hipcomBulkUpdateDetailId === detail.id) {
                    localHipcomBulkUpdatePathIds.push(current.hipcomBulkUpdatePathId);
                }
            });
        });
        this.hipcomBulkUpdatePathIds = Array.from(new Set(localHipcomBulkUpdatePathIds));
        this.hipcomBulkUpdatePathDaoService.findByIdList(this.hipcomBulkUpdatePathIds).subscribe((hipcomBulkUpdatePaths) => {
            this.hipcomBulkUpdatePaths = hipcomBulkUpdatePaths;
        });

    }

    getNameOfTheLastFolder(path: string): string {
        if (!path) {
            return '';
        }
        const folders = path?.split('\\');
        return folders[folders.length - 1];
    }

    findPathByid(id: number): HipcomBulkUpdatePath {
        return this.hipcomBulkUpdatePaths?.find(x => x.id === id);
    }

    getClass(element: HipcomBulkUpdateDetail): any {
        if (element && element.status && element.status === StatusType.ERROR) {
            return {'red-900-bg red-900': true};
        } else if (element && element.active && element.status === StatusType.STARTED) {
            return {'accent-50 accent-50-bg': true};
        } else if (element && element.active && element.status === StatusType.UPDATING) {
            if (this.checkIfDateIsGreaterThanNowMoreMinutes(element.startAt, this.maxUpdateTimeInMinutes)) {
                return {'red-900-bg red-900': true};
            } else {
                return {'yellow-100 yellow-100-bg': true};
            }
        } else if (element && !element.active) {
            return {'primary-100 primary-100-bg': true};
        }
    }

    checkIfDateIsGreaterThanNowMoreMinutes(dateIn: Date, minutes: number): Boolean {
        const createdAt: Date = new Date(dateIn);
        const today: Date = new Date(Date.now());
        const d = Math.abs(today.getTime() - createdAt.getTime());
        const totalMinutes = Math.floor((d / 1000) / 60);
        return totalMinutes > minutes;
    }


    goToProjectPage(customerGroupId: number): void {
        this.router.navigate(['/admin'], {queryParams: {id: customerGroupId}});
    }

    onCreateEditSchedule(item: HipcomBulkUpdateDetail): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateScheduleCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                hipcomBulkUpdateDetail: item
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {
            if (bulkUpdateOperation && bulkUpdateOperation.operation === 'SAVE') {
                const index = this.hipcomBulkUpdateDetails.findIndex(value => value.id === bulkUpdateOperation.data.id);
                this.hipcomBulkUpdateDetails[index] = bulkUpdateOperation.data;
                this.dataSource = new MatTableDataSource<HipcomBulkUpdateDetail>(this.hipcomBulkUpdateDetails);
            }
        });
        this.addSubscription(subscription);
    }

    onExecuteTask(item: HipcomBulkUpdateDetail): void {
        const customerGroups = this.getCustomerGroupByDesktopServerId(item.desktopServerId);
        let customerGroupNames = '';
        for (let i = 0; i < customerGroups.length; i++) {
            if (i === 0) {
                customerGroupNames = customerGroups[i].name;
            } else {
                customerGroupNames = customerGroupNames + '<br>' + customerGroups[i].name;
            }
        }
        let message = 'Você tem certeza que deseja executar esse atualização?<br><br>';
        if (customerGroups.length > 1) {
            message = message + 'nas empresas: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        } else {
            message = message + 'na empresa: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        }

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                if (this.hasModuleToUpdate(item)) {
                    item.startAt = new Date(Date.now());
                    item.status = StatusType.UPDATING;
                }
                this.hipcomBulkUpdateDetailDaoService.executeTask(item).subscribe((executeTask) => {
                    const timer = setTimeout(() => {
                        this.loadHipcomBulkUpdateDetails();
                    }, 10000);
                });
            }
        });
        this.addSubscription(subscription);
    }

    showExecuteButton(element: HipcomBulkUpdateDetail): boolean {
        if (element.status === this.statusType.UPDATING &&
            this.checkDesktopServerIsOnline(element.desktopServerId) &&
            this.hasModuleToUpdate(element) &&
            this.checkIfDateIsGreaterThanNowMoreMinutes(element.startAt, this.maxUpdateTimeInMinutes)) {
            return true;
        } else if (element.status !== this.statusType.UPDATING &&
            this.checkDesktopServerIsOnline(element.desktopServerId) &&
            this.hasModuleToUpdate(element)) {
            return true;
        }
        return false;
    }

    findLatestUpdatedModule(element: HipcomBulkUpdateDetail): HipcomBulkUpdateCurrentSoftwareVersion {
        let lastCurrent: HipcomBulkUpdateCurrentSoftwareVersion = null;
        for (const currentId of element.hipcomBulkUpdateCurrentSoftwareVersionIds) {
            const current = this.findHipcomBulkUpdateCurrentSoftwareVersionById(currentId);
            const currentCheckedAt: Date = new Date(current.checkedAt);
            if (!lastCurrent) {
                lastCurrent = current;
            } else {
                const lastCheckedAt: Date = new Date(lastCurrent.checkedAt);
                if (currentCheckedAt.getTime() > lastCheckedAt.getTime()) {
                    lastCurrent = current;
                }
            }
        }
        return lastCurrent;
    }


    hasModuleToUpdate(item: HipcomBulkUpdateDetail): boolean {
        let has = false;
        for (const currentId of item.hipcomBulkUpdateCurrentSoftwareVersionIds) {
            if (!this.checkCurrentVersionExist(currentId)) {
                has = true;
            }
        }
        return has;
    }

    onExecuteAllTasks(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja atualizar todos os servidores que <u>estão online</u> agora?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.setStatusUpdatingInAllServersOnlineIfHaveAnyModuleToUpdate();
                this.hipcomBulkUpdateDetailDaoService.executeAllTasks().subscribe((v) => {
                    setTimeout(() => {
                        // console.log('LoadHipcomBulkUpdateDetails');
                        this.loadHipcomBulkUpdateDetails();
                    }, 10000);
                });
            }
        });
        this.addSubscription(subscription);
    }

    setStatusUpdatingInAllServersOnlineIfHaveAnyModuleToUpdate(): void {
        for (const hipcomBulkUpdateDetail of this.hipcomBulkUpdateDetails) {
            if (this.checkDesktopServerIsOnline(hipcomBulkUpdateDetail.desktopServerId) && this.hasModuleToUpdate(hipcomBulkUpdateDetail)) {
                hipcomBulkUpdateDetail.status = StatusType.UPDATING;
                hipcomBulkUpdateDetail.startAt = new Date(Date.now());
            }
        }
    }

    onDisableAllTasks(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja <b>DESATIVAR</b> a atualização em todos os servidores?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.hipcomBulkUpdateDetailDaoService.disableAllTasks().subscribe((e) => {
                    this.loadHipcomBulkUpdateDetails();
                });
            }
        });
        this.addSubscription(subscription);
    }

    onEnableAllTasks(): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja <b>ATIVAR</b> a atualização em todos os servidores?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.hipcomBulkUpdateDetailDaoService.enableAllTasks().subscribe((e) => {
                    this.loadHipcomBulkUpdateDetails();
                });
            }
        });
        this.addSubscription(subscription);
    }

    onToggleTask(item: HipcomBulkUpdateDetail, active: boolean): void {
        const customerGroups = this.getCustomerGroupByDesktopServerId(item.desktopServerId);
        let customerGroupNames = '';
        for (let i = 0; i < customerGroups.length; i++) {
            if (i === 0) {
                customerGroupNames = customerGroups[i].name;
            } else {
                customerGroupNames = customerGroupNames + '<br>' + customerGroups[i].name;
            }
        }
        let message = 'Você tem certeza que quer desativar esse atualização?<br><br>';
        if (active) {
            message = 'Você tem certeza que quer ativar esse atualização?<br><br>';
        }
        if (customerGroups.length > 1) {
            message = message + 'nas empresas: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        } else {
            message = message + 'na empresa: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        }

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                item.active = active;
                this.hipcomBulkUpdateDetailDaoService.save(item).subscribe((executeTask) => {

                });
            }
        });
        this.addSubscription(subscription);
    }

    onViewLog(element: HipcomBulkUpdateDetail): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateDetailLogComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                hipcomBulkUpdateDetail: element,
                customerGroups: this.getCustomerGroupByDesktopServerId(element.desktopServerId)
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((empty) => {

        });
        this.addSubscription(subscription);
    }

    getCustomerGroupByDesktopServerId(desktopServerId: number): CustomerGroup[] {
        const customerGroups: CustomerGroup[] = [];
        for (const subProject of this.subProjects) {
            const index = subProject.desktopServerIds.findIndex(value => value === desktopServerId);
            if (index > -1) {
                const customerGroup = this.customerGroupService.getCustomerGroup(subProject.customerGroupId);
                if (customerGroup) {
                    customerGroups.push(customerGroup);
                }
            }
        }
        return customerGroups;
    }

    onSendMessage(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): void {
        const customerGroups: CustomerGroup[] = this.getCustomerGroupByDesktopServerId(hipcomBulkUpdateDetail.desktopServerId);
        const dialogRef = this.dialog.open(HipcomBulkUpdateMessageSendMessageComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                customerGroups: customerGroups,
                desktopServerId: hipcomBulkUpdateDetail.desktopServerId,
                hipcomBulkUpdateDetailId: hipcomBulkUpdateDetail.id
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {
        });
        this.addSubscription(subscription);
    }

    onSendMessageToAll(): void {
        const dialogRef = this.dialog.open(HipcomBulkUpdateMessageSendMessageComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                everyone: true
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((bulkUpdateOperation: CrudOperationWrapper) => {
        });
        this.addSubscription(subscription);
    }

    onForcedUpdateOnModule(currentSoftwareId: number, hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): void {
        const currentSoftware = this.findHipcomBulkUpdateCurrentSoftwareVersionById(currentSoftwareId);
        const latestSoftware = this.findHipcomBulkUpdateLatestSoftwareVersionById(currentSoftware?.hipcomBulkUpdateLatestSoftwareVersionId);
        const customerGroups = this.getCustomerGroupByDesktopServerId(hipcomBulkUpdateDetail.desktopServerId);

        let customerGroupNames = '';
        for (let i = 0; i < customerGroups.length; i++) {
            if (i === 0) {
                customerGroupNames = customerGroups[i].name;
            } else {
                customerGroupNames = customerGroupNames + '<br>' + customerGroups[i].name;
            }
        }
        let message = 'Você tem certeza que deseja forçar a atualização do módulo <b>(' + latestSoftware.name + ' )</b>?<br><br>';
        if (customerGroups.length > 1) {
            message = message + 'nas empresas: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        } else {
            message = message + 'na empresa: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        }

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                hipcomBulkUpdateDetail.status = StatusType.UPDATING;
                hipcomBulkUpdateDetail.startAt = new Date(Date.now());
                this.hipcomBulkUpdateDetailDaoService.forcedUpdateOnModule(currentSoftwareId, hipcomBulkUpdateDetail).subscribe((executeTask) => {
                    const timer = setTimeout(() => {
                        this.loadHipcomBulkUpdateDetails();
                    }, 10000);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onForcedUpdateAllModules(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): void {
        const customerGroups = this.getCustomerGroupByDesktopServerId(hipcomBulkUpdateDetail.desktopServerId);

        let customerGroupNames = '';
        for (let i = 0; i < customerGroups.length; i++) {
            if (i === 0) {
                customerGroupNames = customerGroups[i].name;
            } else {
                customerGroupNames = customerGroupNames + '<br>' + customerGroups[i].name;
            }
        }
        let message = 'Você tem certeza que deseja forçar a atualização em <b><u>TODOS OS MÓDULOS</u></b>?<br><br>';
        if (customerGroups.length > 1) {
            message = message + 'nas empresas: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        } else {
            message = message + 'na empresa: <br><b class="accent-600-fg">' + customerGroupNames + '</b> <br>';
        }

        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                hipcomBulkUpdateDetail.status = StatusType.UPDATING;
                hipcomBulkUpdateDetail.startAt = new Date(Date.now());
                this.hipcomBulkUpdateDetailDaoService.forcedUpdateAllModules(hipcomBulkUpdateDetail).subscribe((executeTask) => {
                    const timer = setTimeout(() => {
                        this.loadHipcomBulkUpdateDetails();
                    }, 10000);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onScanModulesAgain(hipcomBulkUpdateDetail: HipcomBulkUpdateDetail): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja <u>escanear os múdulos novamente</u> desse servidor?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                hipcomBulkUpdateDetail.status = StatusType.UPDATING;
                hipcomBulkUpdateDetail.startAt = new Date(Date.now());
                this.hipcomBulkUpdateDetailDaoService.scanModulesAgain(hipcomBulkUpdateDetail).subscribe((v) => {
                    setTimeout(() => {
                        this.loadHipcomBulkUpdateDetails();
                    }, 10000);
                });
            }
        });
        this.addSubscription(subscription);
    }
}

