<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>Últimos AdGroups</div>
            <button *ngIf="!loading" mat-raised-button type="button" color="primary" class="md-small" (click)="getData()">
                <span *ngIf="!alreadyRead">Show</span>
                <span *ngIf="alreadyRead">Update</span>
            </button>
            <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th style="width: 300px"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let obj of adGroupsSnapshots">
                <td>
                    <div *ngIf="statusServer.getAdGroup(obj.state.id, adGroups) as adGroup">
                        <div *ngIf="statusServer.getSubProject(adGroup?.subProjectId, subProjects) as subProject">
                            <div><b>{{obj.state.name}} - {{obj.state.prefix}}</b></div>
                            <div class="font-size-12">
                                domínio: {{statusServer.getAdDomain(adGroup?.adDomainId, adDomains)?.name}}</div>
                            <div class="font-size-12"><b>por:</b> {{obj.commitMetadata?.author}}</div>
                            <div class="font-size-12">{{obj.commitMetadata?.commitDate}}</div>
                            <ul *ngIf="statusServer.getAdGroup(obj.state.id,adGroups)">
                                <li>
                                    ID:<b> {{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.id}} </b>
                                </li>
                                <li>Empresa:
                                    <b>{{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.name}} </b>
                                </li>
                                <li>subProject:<b> {{subProject?.name}} </b></li>
                            </ul>
                        </div>
                    </div>
                </td>
                <td>
                    {{obj.commitMetadata.commitDate | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>