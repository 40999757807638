<form [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Horário de acesso</span>
        </div>
    </mat-toolbar>


    <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch"
         style="padding: 10px;">
        <div [formGroup]="myForm.get('managerUserAccessPeriodProfileForm')" style="padding: 10px;">
            <mat-form-field class="full-width">
                <input matInput placeholder="Nome para esse perfil"
                       type="text" name="name"
                       formControlName="name" required >

                <mat-error>
                    {{myForm.get('managerUserAccessPeriodProfileForm').get('name').errors | error}}
                </mat-error>
            </mat-form-field>
        </div>

            <table>
                <thead>
                <tr>
                    <th class="day-of-week" style="width: 40%"></th>
                    <th class="tit col-start">Das</th>
                    <th class="tit col-stop">Às</th>
                    <th class="tit col-stop"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let managerUserAccessPeriodTimeForm of myForm.get('managerUserAccessPeriodTimesForm')['controls']; let i = index;"
                    [formGroup]="managerUserAccessPeriodTimeForm">


                    <td class="day-of-week">
                        {{getDayOfWeek(managerUserAccessPeriodTimeForm.value.dayOfWeek)}}
                    </td>

                    <td align="center" class="col-start">
                        <div>
                            <mat-form-field class="hour-field">
                                <input matInput placeholder="" pattern="^$|^([0]?[0-9]|[1][0-9]|[2][0-3]?)$"
                                       style="text-align: right;" type="number" name="startHours"
                                       formControlName="startHours">

                                <mat-error>
                                    {{managerUserAccessPeriodTimeForm.get('startHours').errors | error}}
                                </mat-error>
                            </mat-form-field>
                            <span style="padding: 0px 3px 7px 3px;"> : </span>
                            <mat-form-field class="hour-field">
                                <input matInput placeholder="" max="59" min="0" style="text-align: left;" type="number"
                                       name="startMinutes" formControlName="startMinutes">

                                <mat-error>
                                    {{managerUserAccessPeriodTimeForm.get('startMinutes').errors | error}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </td>
                    <td align="center" class="col-stop">
                        <div>
                            <mat-form-field class="hour-field">
                                <input matInput placeholder="" max="23" min="0" style="text-align: right;" type="number"
                                       name="stopHours" formControlName="stopHours">
                                <mat-error>
                                    {{managerUserAccessPeriodTimeForm.get('stopHours').errors | error}}
                                </mat-error>
                            </mat-form-field>
                            <span style="padding: 0px 3px 7px 3px;"> : </span>
                            <mat-form-field class="hour-field">
                                <input matInput placeholder="" max="59" min="0" style="text-align: left;" type="number"
                                       name="stopMinutes" formControlName="stopMinutes">
                                <mat-error>
                                    {{managerUserAccessPeriodTimeForm.get('stopMinutes').errors | error}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </td>
                    <!--</div>-->

                </tr>
                </tbody>
            </table>
        <div class="font-size-12 pt-20 pb-20">
            *Para deixar bloqueado o dia inteiro, coloque Das 00:00 Às 00:01
        </div>

    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                aria-label="Salvar">
            <span *ngIf="myForm.get('managerUserAccessPeriodProfileForm').get('id').value;else newUser">Salvar</span>
            <ng-template #newUser>Criar</ng-template>
        </button>
        <div *ngIf="myForm.get('managerUserAccessPeriodProfileForm').get('id').value">
            <button type="button" mat-raised-button color="warn" (click)="onRemove(myForm.get('managerUserAccessPeriodProfileForm').value)">Excluir</button>
        </div>
        <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
    </div>

</form>

