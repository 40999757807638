<mat-card>
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Filiais</div>

        <button  *appIfPermission="appCanCreateList" type="button" mat-raised-button color="accent" (click)="onCreateCustomer()" id="register-customer-create-customer" >Criar</button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div fxLayout="column mt-4">
            <table class="app-table" border="0" cellspacing="0">
                <tr *ngFor="let customer of adminService.customers; let i = index;">
                    <td class="h-150 w-150">
                        <img src="{{imageFileService.getCustomerPictureUrl(customer.imageUUID, 150)}}" width="150"
                             height="150">
                    </td>
                    <td>
                        <div class="clear-both">
                            <div class="text-max-550 font-size-18 mb-8">{{customer?.name}}</div>
                        </div>
                        <div class="clear-both" >
                            <div class="text-max-550 mb-8">{{adminService.getNumberOfManagerUserInCustomer(customer.id)}} usuários</div>
                        </div>
                        <div class="clear-both" *ngIf="customer.province || customer.city">
                            <div class="text-max-550 mb-4">
                                {{customer?.province}} / {{customer?.city}}
                            </div>
                        </div>
                        <div class="clear-both" *ngIf="customer.address || customer.addressNumber">
                            <div class="text-max-550 mb-4">
                                {{customer?.address}}, {{customer?.addressNumber}}
                            </div>
                        </div>
                        <div class="clear-both" *ngIf="customer.addressAddOn">
                            <div class="text-max-550 mb-4">
                                {{customer?.addressAddOn}}
                            </div>
                        </div>
                        <div class="clear-both" *ngIf="customer.phone">
                            <div class="text-max-550 mb-8">{{customer?.phone}}</div>
                        </div>

                        <div class="clear-both">
                            <button *appIfPermission="appCanWriteList" mat-raised-button
                                    id="register-customer-edit-customer-{{i+1}}"
                                    class="register-customer-edit-customer"
                                    (click)="onEditCustomer(customer)">Editar</button>
                        </div>

                    </td>
                </tr>
            </table>
            <div fxLayout="row" fxLayoutAlign="end center">
                <div class="arrow_box" *ngIf="adminService.customers.length < 1 && !waiting">
                    <div class="inner_box">É necessário criar uma filial</div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>