import {Component, Input, OnDestroy} from '@angular/core';
import {CustomerGroup} from '../../../models';
import {CustomerGroupDaoService} from '../customer-group-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';

import {CustomerGroupService} from '../customer-group.service';
import {ActivatedRoute} from '@angular/router';
import {CrudOperationWrapper, orderArrayAnyBy} from '../../../helpers/kluh';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {ManagerUserPermissionsDaoService} from '../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroupSearchComponent} from '../customer-group-search/customer-group-search.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'app-customer-group-tool-bar',
    templateUrl: './customer-group-tool-bar.component.html',
    styleUrls: ['./customer-group-tool-bar.component.scss']
})
export class CustomerGroupToolBarComponent extends ComponentCleaner implements OnDestroy {
    customerGroupCreator = false;
    public customerGroups: CustomerGroup[];

    private _customStyle: any;

    @Input()
    set customStyle(value: any) {
        this._customStyle = value;
    }

    get customStyle(): any {
        return this._customStyle;
    }

    constructor(private customerGroupDao: CustomerGroupDaoService,
                public customerGroupService: CustomerGroupService,
                public imageFileService: ImageFileService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private managerUserPermissionDaoService: ManagerUserPermissionsDaoService,
                private route: ActivatedRoute,
                private managerUserPermissionDao: ManagerUserPermissionsDaoService,
                private dialog: MatDialog) {
        super();

        this.getAllCustomerGroups();
        this.managerUserPermissionDaoService.getMyUserPermissions().subscribe((managerUserPermissions) => {
            if (managerUserPermissions.admin || managerUserPermissions.customerGroupCreator) {
                this.customerGroupCreator = true;
            }
        });
    }

    getAllCustomerGroups(): void {
        if (!this.customerGroups) {
            this.customerGroupDao.getAllCache().subscribe((customerGroups) => {
            // this.customerGroupDao.get().subscribe((customerGroups) => {
                if (customerGroups) {
                    this.customerGroups = customerGroups;
                } else {
                    this.customerGroups = [];
                }
                this.customerGroups = orderArrayAnyBy(this.customerGroups, 'name');
                this.customerGroupService.customerGroups = this.customerGroups;
                if (this.customerGroups && this.customerGroups.length > 0 && !(this.customerGroupService.customerGroup)) {
                    const lastSelectedCustomerGroup: CustomerGroup = this.customerGroupService.getLastSelectedCustomerGroupByCookie();
                    if (lastSelectedCustomerGroup) {
                        this.customerGroupService.set(lastSelectedCustomerGroup);
                    } else {
                        this.customerGroupService.set(this.customerGroups[0]);
                    }
                }
            });
        }
    }

    searchModalCustomerGroup(): void {
        const metricTypeDialogRef = this.dialog.open(CustomerGroupSearchComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                customerGroups: this.customerGroups
            }
        });
        this.addSubscription(metricTypeDialogRef.afterClosed().subscribe((choice) => {
            this.dialogRefCallback(choice);
        }));
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result) {
            if (result.operation === 'CREATE') {
                this.customerGroups.push(result.data);
                this.customerGroupService.customerGroup = result.data;
            } else if (result.operation === 'SAVE') {
                const index = this.customerGroups.findIndex(value => value.id === result.data.id);
                this.customerGroups[index] = result.data;
            } else if (result.operation === 'DELETE') {
                this.customerGroups = this.customerGroups.filter(value => value.id === result.data);
            }
        }
    }

    ngOnDestroy(): void {
       this.customerGroups = [];
    }

}
