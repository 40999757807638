import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../auth.service';
import {switchMap} from 'rxjs/operators';
import {GrantedAuthority, Permission} from '../models';
import {isMacOS} from '../helpers/kluh';

@Injectable()
export class HomeService implements CanActivate {
    private history = [];

    constructor(private auth: AuthService, private router: Router) {
        // router.events.pipe(filter(value => value instanceof NavigationEnd)).subscribe(({urlAfterRedirects}: NavigationEnd) => {
        //     this.history = [...this.history, urlAfterRedirects];
        // });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.auth.isLoggedIn().pipe(switchMap((result) => {
            if (result) {
                return this.auth.getMyPermissions().pipe(switchMap((permissions) => {
                    if (permissions) {
                        this.redirectByPermission(permissions);
                    }
                    return of(false);
                }));
            } else {
                this.auth.redirectToLogin(null);
            }
        }));

        // return this.auth.getMe().pipe(switchMap((user: FirebaseUserDetails) => {
        //     if (user) {
        //       const roles = user.authorities;
        //
        //       if (roles.length > 0) {
        //         this.redirectByRole(roles);
        //       } else {
        //         throw new Error('user has no role');
        //       }
        //     } else {
        //       this.auth.redirectToLogin(null);
        //     }
        //     return of(false);
        //   }
        // ));
    }

    /**
     * @deprecated
     *
     * @param roles
     */
    private redirectByRole(roles: GrantedAuthority[]): void {
        // console.warn('redirectByRole is deprecated');
        // const desktopAdminIndex = roles.findIndex((role) => {
        //     return role.authority === 'ROLE_ADMIN' || role.authority === 'ROLE_DESKTOP_ADMIN' || role.authority === 'ROLE_DESKTOP_SUPPORT';
        // });
        // if (desktopAdminIndex > -1) {
        //     this.router.navigate(['admin']);
        //     return;
        // }
        // const supportIndex = roles.findIndex((role) => {
        //     return role.authority === 'ROLE_SUPPORT';
        // });
        // if (supportIndex > -1) {
        //     this.router.navigate(['infra']);
        //     return;
        // }
        // const desktopIndex = roles.findIndex((role) => {
        //     return role.authority === 'ROLE_DESKTOP';
        // });
        // if (desktopIndex > -1) {
        //     this.router.navigate(['desktop']);
        //     return;
        // }
    }

    private redirectByPermission(permissions: Permission[]): void {
        // const desktopAdminIndex = permissions.findIndex((role) => {
        //     return role.name === 'SubProject.READ';
        // });
        // if (desktopAdminIndex > -1) {
        //     // this.router.navigate(['admin']);
        //     this.router.navigate(['desktop']);
        //     return;
        // }
        // const supportIndex = permissions.findIndex((role) => {
        //     return role.name === 'DesktopServer.READ';
        // });
        // if (supportIndex > -1) {
        //     // this.router.navigate(['infra']);
        //     this.router.navigate(['desktop']);
        //     return;
        // }
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        if (!params.get('id')) {
            if (isMacOS()) {
                this.router.navigate(['webclient-html5']);
            } else {
                this.router.navigate(['desktop']);
            }
        } else {
            this.router.navigate(['desktop']);
        }
        return;
    }
}
