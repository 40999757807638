<table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef class="w-50"></th>
        <td mat-cell *matCellDef="let element">
            <img
                src="{{imageFileService.getManagerUserPicture(adminService.getManagerUser(element.managerUserId))}}?w=40&square=true"
                width="40" height="40" style="max-width:40px;margin-right: 15px;">
        </td>
    </ng-container>

    <ng-container matColumnDef="managerUserId">
        <th mat-header-cell *matHeaderCellDef class="w-300">Usuário</th>
        <td mat-cell *matCellDef="let element">
            {{adminService.getManagerUser(element.managerUserId)?.email}}
        </td>
    </ng-container>

    <ng-container matColumnDef="connectAt">
        <th mat-header-cell *matHeaderCellDef>Conectou</th>
        <td mat-cell *matCellDef="let element" class="w-150">
            <div
                *ngIf="period === '' || period === 'today'">{{element.connectAt | date: 'HH:mm'}}</div>
            <div
                *ngIf="!(period === '' || period === 'today')"> {{element.connectAt | date: 'dd/MM/yyyy HH:mm'}}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="disconnectAt">
        <th mat-header-cell *matHeaderCellDef>Desconectou</th>
        <td mat-cell *matCellDef="let element" class="w-150">
            <div
                *ngIf="period === '' || period === 'today'">{{element.disconnectAt | date: 'HH:mm'}}</div>
            <div
                *ngIf="!(period === '' || period === 'today')"> {{element.disconnectAt | date: 'dd/MM/yyyy HH:mm'}}</div>
        </td>
    </ng-container>

    <ng-container matColumnDef="connectedTime">
        <th mat-header-cell *matHeaderCellDef>Duração</th>
        <td mat-cell *matCellDef="let element" class="w-150">
            {{element.disconnectAt | timeDiff: 'small': element.connectAt}}
        </td>
    </ng-container>

    <ng-container matColumnDef="ip">
        <th mat-header-cell *matHeaderCellDef>IP</th>
        <td mat-cell *matCellDef="let element" class="w-150">
            {{element.ip}}
        </td>
    </ng-container>

    <ng-container matColumnDef="userAgent">
        <th mat-header-cell *matHeaderCellDef>User Agent</th>
        <td mat-cell *matCellDef="let element" class="icon w-400">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="w-100">{{osDetectByUserAgent(element.userAgent)}}</div>
                <div>{{detectBrowserVersion(element.userAgent)}}</div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[50, 100]" [length]="totalDocs"></mat-paginator>
