<div class="fuse-card blue-color">
    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between start">

        <div class="pr-16">
            <div class="h4 secondary-text">Projeto</div>
            <div class="h1">{{subProject.name}}</div>
        </div>

        <div class="w-80 h-80">
            <div class="close-button">
                <button type="button" mat-mini-fab
                        id="sub-project-support-card-close"
                        class="fuse-white-bg my-fab" (click)="onClose()">
                    <mat-icon aria-label="Fechar" color="warn">close</mat-icon>
                </button>
            </div>
            <div class="edit-button">
                <button type="button" mat-mini-fab
                        class="sub-project-support-card-edit fuse-white-bg my-fab" (click)="onEdit()">
                    <mat-icon aria-label="Fechar" class="fuse-black-fg">edit</mat-icon>
                </button>
            </div>
            <img src="assets/kluh/images/project-small-1.jpg">
        </div>

    </div>

    <div class="p-16 pt-0 line-height-1.75" *ngIf="subProject.comment">
        {{subProject.comment}}
    </div>

    <div class="card-divider"></div>

    <div class="p-16 pt-4">
        <div class="mb-12" fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let desktopServer of desktopServers">
            <a [routerLink]="" (click)="onDesktopServerSelect(desktopServer)">{{desktopServer.hostname}}</a>
            <div fxLayout="row" fxLayoutAlign="start center">
                <fa-icon class="s-16 mr-8 windows-icon" [icon]="faWindows"></fa-icon>
                <mat-icon class="s-16 mr-8 online" title="onLine" *ngIf="isConnected(desktopServer); else offline">check_circle</mat-icon>
                <ng-template #offline>
                    <mat-icon class="s-16 mr-8 offline" title="offLine">error</mat-icon>
                </ng-template>
                <button type="button" mat-button color="primary" (click)="isConnected(desktopServer) && isClientConnected() && onConnect(desktopServer)" [disabled]="!isConnected(desktopServer) || !isClientConnected()">
                    <fa-icon [icon]="faDesktop"></fa-icon>
                </button>
            </div>
        </div>
        <div class="mb-12" fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let linuxServer of linuxServers">
            <a [routerLink]="" (click)="onLinuxServerSelect(linuxServer)">{{linuxServer.hostname}}</a>
            <div fxLayout="row" fxLayoutAlign="start center">
                <fa-icon class="s-16 mr-8 linux-icon" [icon]="faLinux"></fa-icon>
                <mat-icon class="s-16 mr-8 online" title="onLine" *ngIf="isLinuxServerConnected(linuxServer); else offline">check_circle</mat-icon>
                <ng-template #offline>
                    <mat-icon class="s-16 mr-8 offline" title="offLine">error</mat-icon>
                </ng-template>
                <button type="button" mat-button color="primary" (click)="isLinuxServerConnected(linuxServer) && isClientConnected() && onLinuxServerConnect(linuxServer)" [disabled]="!isLinuxServerConnected(linuxServer) || !isClientConnected()">
                    <fa-icon [icon]="faDesktop"></fa-icon>
                </button>
            </div>
        </div>
    </div>

</div>