import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched} from '../../../../helpers/kluh';
import {BackgroundImageTemplate, ImageFileTemplate} from '../../../../models-custom-layout';
import {ImageFileTemplateDaoService} from '../../image-file-template/image-file-template-dao.service';
import {Observable, of} from 'rxjs';
import {BackgroundImageTemplateDaoService} from '../background-image-template-dao.service';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {ManagerUserPermissionsDaoService} from '../../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {CustomerGroupDaoService} from '../../../customer-group/customer-group-dao.service';
import {CustomerGroup} from '../../../../models';

@Component({
    selector: 'app-background-image-template-edit',
    templateUrl: './background-image-template-edit.component.html',
    styleUrls: ['./background-image-template-edit.component.scss']
})
export class BackgroundImageTemplateEditComponent {

    backgroundImageTemplate: BackgroundImageTemplate;
    myForm: FormGroup;
    isAdmin = false;
    imageFileElement: any[] = [];
    customerGroup: CustomerGroup;
    sizeImageError = '';
    maxHeight = 1080;
    maxWidth = 1920;

    constructor(private dialogRef: MatDialogRef<BackgroundImageTemplateEditComponent>,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private customerGroupDaoService: CustomerGroupDaoService,
                public imageFileService: ImageFileService,
                private imageFileTemplateDaoService: ImageFileTemplateDaoService,
                private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
                private backgroundImageTemplateDaoService: BackgroundImageTemplateDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.customerGroup) {
            this.customerGroup = data.customerGroup;
        }
        if (!data.backgroundImageTemplate) {
            this.backgroundImageTemplate = {
                id: null,
                name: null,
                creatorCustomerGroupId: null,
                public: null,
                imageFileTemplateIds: null,
                createdAt: null,
                updatedAt: null
            };
        } else {
            this.backgroundImageTemplate = JSON.parse(JSON.stringify(data.backgroundImageTemplate));
        }
        this.myForm = this.fb.group(convertToFormGroup(this.backgroundImageTemplate));
        this.myForm.get('name').setValidators([Validators.required]);

        this.checkAdmin();

    }

    private checkAdmin(): void {
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            if (user) {
                this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id).subscribe((isAdmin) => {
                    this.isAdmin = isAdmin;
                });
            }
        });
    }


    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        this.sizeImageError = '';
        if (currentTarget.files.length > 0) {
            for (let i = 0; i < currentTarget.files.length; i++) {
                const file = currentTarget.files.item(i);
                const reader = new FileReader();
                reader.onload = (event: Event) => {
                    const target = <FileReader>event.target;
                    const binaryString = <string>target.result;
                    const base64 = window.btoa(binaryString);
                    const image = new Image();
                    image.src = 'data:image/jpg;base64, ' + base64;
                    image.onload = rs => {
                        const imgHeight = rs.currentTarget['height'];
                        const imgWidth = rs.currentTarget['width'];
                        if (imgHeight > this.maxHeight || imgWidth > this.maxWidth) {
                            this.sizeImageError = 'Tamanho máximo permitido da imagem é:';
                        } else {
                            this.imageFileElement.push(base64);
                        }
                    };
                };
                reader.readAsBinaryString(file);
            }
            markAsTouched(this.myForm);
        }
    }

    onDelete(backgroundImageTemplate: BackgroundImageTemplate): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse imagem do tema? ' +
                    '<br><h2>' + backgroundImageTemplate.name + '</h2>',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.backgroundImageTemplateDaoService.delete(backgroundImageTemplate.id).subscribe((removed) => {
                    const crudOperation: CrudOperationWrapper = {
                        data: backgroundImageTemplate,
                        operation: 'DELETE'
                    };
                    this.dialogRef.close(crudOperation);
                });
            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    onRemoveImageFromFileElement(index: number): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse imagem do tema? ',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.imageFileElement.splice(index, 1);
            }
        });

    }

    onRemoveImage(imageFileTemplateId: number): void {
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja remover esse imagem do tema? ' +
                    '<br><img src="' + this.imageFileService.getPictureUrlFromImageFileTemplateId(imageFileTemplateId, 80) + '" class="p-10" width="80">',
                disableCancel: false,
                confirmButtonValue: 'Remover',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.removeImageFileTemplateIdFromList(imageFileTemplateId);
            }
        });
    }

    removeImageFileTemplateIdFromList(id: number): void {
        const index = this.backgroundImageTemplate.imageFileTemplateIds.findIndex((ad) => {
            return ad === id;
        });
        if (index > -1) {
            this.backgroundImageTemplate.imageFileTemplateIds.splice(index, 1);
        }
        markAsTouched(this.myForm);
    }

    onSubmit(): void {
        const backgroundImageTemplateValue: BackgroundImageTemplate = this.myForm.value;
        if (!backgroundImageTemplateValue.imageFileTemplateIds) {
            backgroundImageTemplateValue.imageFileTemplateIds = [];
        }
        let imageFileTemplateList$: Observable<number[]>;
        if (this.imageFileElement) {
            const imageFileTemplateList: ImageFileTemplate[] = [];
            this.imageFileElement.map((imageFile) => {
                const imageFileTemplate: ImageFileTemplate = {
                    id: null,
                    image: imageFile,
                    createdAt: null,
                    updatedAt: null
                };
                imageFileTemplateList.push(imageFileTemplate);
            });
            imageFileTemplateList$ = this.imageFileTemplateDaoService.createAllAndReturnOnlyIds(imageFileTemplateList);
        } else {
            imageFileTemplateList$ = of([]);
        }
        imageFileTemplateList$.subscribe((imageFileTemplateIds) => {
            if (imageFileTemplateIds && imageFileTemplateIds.length > 0 || backgroundImageTemplateValue.imageFileTemplateIds.length > 0) {
                imageFileTemplateIds.map((ids) => {
                    backgroundImageTemplateValue.imageFileTemplateIds.push(ids);
                });
                let operation: CrudOperation;
                let saveOrCreate$;
                if (backgroundImageTemplateValue.id) {
                    operation = 'SAVE';
                    saveOrCreate$ = this.backgroundImageTemplateDaoService.save(backgroundImageTemplateValue);
                } else {
                    backgroundImageTemplateValue.creatorCustomerGroupId = this.customerGroup.id;
                    operation = 'CREATE';
                    saveOrCreate$ = this.backgroundImageTemplateDaoService.create(backgroundImageTemplateValue);
                }
                saveOrCreate$.subscribe((backgroundImageTemplate) => {
                    if (backgroundImageTemplate) {
                        const crudOperation: CrudOperationWrapper = {
                            data: backgroundImageTemplate,
                            operation: operation
                        };
                        this.dialogRef.close(crudOperation);
                    }
                });
            }
        });
    }
}
