<form *ngIf="myForm" [formGroup]="myForm"
      (submit)="myForm.valid && myForm.dirty && onSubmit()">

        <div mat-dialog-title>
            <mat-toolbar class="mat-accent m-0">
                <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="title dialog-title">Cadastro de Plugin</span>
                </mat-toolbar-row>
            </mat-toolbar>
        </div>
        <mat-dialog-content fxLayout="column" fxLayoutAlign="space-between stretch">
            <mat-form-field>
                <input matInput formControlName="name" placeholder="Nome (identificador)">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <button type="submit" mat-raised-button color="accent" *ngIf="myForm"
                    [disabled]="(!myForm.valid || myForm.pristine)"
                    aria-label="Salvar">
                <span *ngIf="myForm.get('id')?.value; else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

        </mat-dialog-actions>

</form>
