import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataCenterCreateComponent} from './data-center-create/data-center-create.component';
import {DataCenterComponent} from './data-center.component';
import {DataCenterDAOService} from './data-center-dao';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';

const routes = [
    {
        path: 'data-center',
        component: DataCenterComponent,
        canActivate: [AuthService],
        data: {permissions: ['DataCenter.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatRadioModule
    ],
    entryComponents: [DataCenterCreateComponent],
    providers: [DataCenterDAOService],
    declarations: [DataCenterComponent, DataCenterCreateComponent]
})
export class DataCenterModule {
}
