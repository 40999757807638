<div class="page-layout simple fullwidth" fusePerfectScrollbar>
  <div class="header mat-accent-bg p-24" fxlayout="row" fxlayoutalign="start center">
    <h2>Agendamento de instancias</h2>
  </div>

  <!-- CENTER -->
  <div class="center">

    <!-- / CONTENT BODY -->
    <div class="content">
      <div class="widget-group fuse-white-bg m-20" fxLayout="column" fxFlex="100" fxLayoutWrap *fuseIfOnDom
           [@animateStagger]="{value:'50'}">

        <form name="searchForm" [formGroup]="searchForm">

          <div style="padding: 20px">

              <input matInput class="search-schedule"  [formControl]="searchFieldControl" autocomplete="off"
                     placeholder="Busca">


            <div class="full-width" style="margin-top: 10px;">

              <table>
                <tr>
                  <td style="width: 235px;font-size: 16px;text-align: left;">
                    <mat-select class="select-schedule" placeholder="Agendados e Não agendados"
                                [formControl]="itsSchedulesControl" >
                      <mat-option *ngFor="let itsSchedule of itsSchedules" [value]="itsSchedule">
                        {{itsSchedule}}
                      </mat-option>
                    </mat-select>
                  </td>
                  <td style="width: 50px">
                     
                  </td>
                  <td style="width: 235px;font-size: 16px;text-align: left;">
                    <mat-select class="select-schedule"  placeholder="Ativos e Não ativos"
                                 [formControl]="onlyActiveControl" >
                      <mat-option *ngFor="let obj of onlyActive" [value]="obj">
                        {{obj}}
                      </mat-option>
                    </mat-select>
                  </td>
                </tr>

              </table>


            </div>

          </div>

          <mat-divider class="divider"></mat-divider>

        </form>
        <table cellspacing="0" cellpadding="0" style="margin-bottom: 400px">
          <thead>
          <tr>
            <th class="tit text-align-left p-14"> Projeto</th>
            <th class="tit text-align-left"> Instancia</th>
            <th class="tit">
              Segunda
            </th>
            <th class="tit">
              Terça
            </th>
            <th class="tit">
              Quarta
            </th>
            <th class="tit">
              Quinta
            </th>
            <th class="tit">
              Sexta
            </th>
            <th class="tit">
              Sábado
            </th>
            <th class="tit">
              Domingo
            </th>
            <th></th>
          </tr>
          </thead>
          <tr *ngFor="let instanceScheduleWrapper of instanceScheduleWrapperListFilter"
              [ngClass]="{ 'disabled': !instanceScheduleWrapper.hasInstanceSchedule ,
              'active': instanceScheduleWrapper.hasInstanceSchedule }">
            <td class="text-align-left"> {{instanceScheduleWrapper.projectName}}</td>
            <td class="text-align-left"> {{instanceScheduleWrapper.instanceName}}</td>
            <td>
              <div>{{instanceScheduleWrapper.mondayStart}}</div>
              <div>{{instanceScheduleWrapper.mondayStop}}</div>
            </td>
            <td>
              <div>{{instanceScheduleWrapper.tuesdayStart}}</div>
              <div>{{instanceScheduleWrapper.tuesdayStop}}</div>
            </td>
            <td>
              <div>{{instanceScheduleWrapper.wednesdayStart}}</div>
              <div>{{instanceScheduleWrapper.wednesdayStop}}</div>
            </td>
            <td>
              <div>{{instanceScheduleWrapper.thursdayStart}}</div>
              <div>{{instanceScheduleWrapper.thursdayStop}}</div>
            </td>
            <td>
              <div>{{instanceScheduleWrapper.fridayStart}}</div>
              <div>{{instanceScheduleWrapper.fridayStop}}</div>
            </td>
            <td>
              <div>{{instanceScheduleWrapper.saturdayStart}}</div>
              <div>{{instanceScheduleWrapper.saturdayStop}}</div>
            </td>
            <td>
              <div
                *ngIf="instanceScheduleWrapper.sundayStart != instanceScheduleWrapper.sundayStop || instanceScheduleWrapper.sundayStart == 'N/A' ">
                <div>{{instanceScheduleWrapper.sundayStart}}</div>
                <div>{{instanceScheduleWrapper.sundayStop}}</div>
              </div>
              <div
                *ngIf="instanceScheduleWrapper.sundayStart == instanceScheduleWrapper.sundayStop && instanceScheduleWrapper.sundayStart != 'N/A'">
                Desligado
              </div>
            </td>
            <td>

              <button *ngIf="instanceScheduleWrapper.hasInstanceSchedule"
                  type="button" mat-raised-button color="accent" aria-label="Editar"
                      (click)="onEditInstanceSchedule(instanceScheduleWrapper.instance)">
                  Editar
              </button>
              <button *ngIf="!instanceScheduleWrapper.hasInstanceSchedule"
                  type="button" mat-raised-button aria-label="Editar"
                      (click)="onEditInstanceSchedule(instanceScheduleWrapper.instance)">
                  Ativar
              </button>
            </td>
          </tr>

        </table>

      </div>
    </div>
  </div>
</div>


