import {Component} from '@angular/core';
import {InstanceDaoService} from '../../support/data-center/instance/instance-dao.service';
import {InstanceScheduleDaoService} from '../../support/data-center/instance/instance-schedule/instance-schedule-dao.service';
import {ProjectDaoService} from '../../project/project-dao.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DataCenterDAOService} from '../../data-center/data-center-dao';
import {InstanceScheduleWrapper} from './instance-schedule-wrapper';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {InstanceScheduleComponent} from '../../support/data-center/instance/instance-schedule/instance-schedule.component';

import {ComponentCleaner} from '../../../component-cleaner';
import {DataCenter, Instance, InstanceSchedule, Project} from '../../../models';
import {forkJoin} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';


@Component({
    selector: 'app-instance-schedule-dashboard',
    templateUrl: './instance-schedule-dashboard.component.html',
    styleUrls: ['./instance-schedule-dashboard.component.scss'],
    animations: fuseAnimations,
})
export class InstanceScheduleDashboardComponent extends ComponentCleaner {
    public instanceScheduleWrapperList: InstanceScheduleWrapper[] = [];
    public instanceScheduleWrapperListFilter: InstanceScheduleWrapper[] = [];
    public itsSchedulesControl: FormControl = new FormControl();
    public onlyActiveControl: FormControl = new FormControl();
    public searchFieldControl: FormControl = new FormControl();
    public itsSchedules: string[] = ['Agendados e não agendados', 'Apenas Agendados', 'Apenas Não agendados'];
    public onlyActive: string[] = ['Ativos e não ativos', 'Apenas Ativos', 'Apenas Não Ativos'];
    searchForm: FormGroup;

    constructor(private dialog: MatDialog,
                private instanceDao: InstanceDaoService,
                private instanceScheduleDao: InstanceScheduleDaoService,
                private projectDao: ProjectDaoService,
                private dataCenterDao: DataCenterDAOService,
                private formBuilder: FormBuilder) {
        super();


        const subscription = this.itsSchedulesControl.valueChanges.subscribe(() => {
            this.onFilterList();
        });
        this.addSubscription(subscription);
        const subscription1 = this.onlyActiveControl.valueChanges.subscribe(() => {
            this.onFilterList();
        });
        this.addSubscription(subscription1);
        const subscription2 = this.searchFieldControl.valueChanges.subscribe(() => {
            this.onFilterList();
        });
        this.addSubscription(subscription2);

        this.searchForm = this.formBuilder.group({
            searchField: [''],
            itsSchedules: [''],
            onlyActive: ['']
        });

        forkJoin([
            this.instanceDao.get(),
            this.instanceScheduleDao.get(),
            this.dataCenterDao.get(),
            this.projectDao.get()
        ]).subscribe((results) => {
            const instances: Instance[] = results[0];
            const instanceSchedules: InstanceSchedule[] = results[1] as InstanceSchedule[];
            const dataCenters: DataCenter[] = results[2] as DataCenter[];
            const projects: Project[] = results[3] as Project[];
            instances.forEach((instance) => {
                const dataCenter = dataCenters.find((item) => {
                    return instance.dataCenterId === item.id;
                });
                const project = projects.find((item) => {
                    return dataCenter.projectId === item.id;
                });
                const sundaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 1, instanceSchedules);
                if (sundaySchedule) {

                }
                const mondaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 2, instanceSchedules);
                const tuesdaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 3, instanceSchedules);
                const wednesdaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 4, instanceSchedules);
                const thursdaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 5, instanceSchedules);
                const fridaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 6, instanceSchedules);
                const saturdaySchedule = InstanceScheduleDashboardComponent.findSchedule(instance.id, 7, instanceSchedules);
                const instanceScheduleWrapper: InstanceScheduleWrapper = {
                    instance: instance,
                    instanceId: instance.id,
                    instanceName: instance.name,
                    hasInstanceSchedule: instance.hasInstanceSchedule,
                    dataCenter: dataCenter,
                    dataCenterName: dataCenter.name,
                    project: project,
                    projectName: project.name,
                    sundaySchedule: sundaySchedule,
                    sundayStart: InstanceScheduleDashboardComponent.formatTimeField(sundaySchedule, 'start'),
                    sundayStop: InstanceScheduleDashboardComponent.formatTimeField(sundaySchedule, 'stop'),
                    mondaySchedule: mondaySchedule,
                    mondayStart: InstanceScheduleDashboardComponent.formatTimeField(mondaySchedule, 'start'),
                    mondayStop: InstanceScheduleDashboardComponent.formatTimeField(mondaySchedule, 'stop'),
                    tuesdaySchedule: tuesdaySchedule,
                    tuesdayStart: InstanceScheduleDashboardComponent.formatTimeField(tuesdaySchedule, 'start'),
                    tuesdayStop: InstanceScheduleDashboardComponent.formatTimeField(tuesdaySchedule, 'stop'),
                    wednesdaySchedule: wednesdaySchedule,
                    wednesdayStart: InstanceScheduleDashboardComponent.formatTimeField(wednesdaySchedule, 'start'),
                    wednesdayStop: InstanceScheduleDashboardComponent.formatTimeField(wednesdaySchedule, 'stop'),
                    thursdaySchedule: thursdaySchedule,
                    thursdayStart: InstanceScheduleDashboardComponent.formatTimeField(thursdaySchedule, 'start'),
                    thursdayStop: InstanceScheduleDashboardComponent.formatTimeField(thursdaySchedule, 'stop'),
                    fridaySchedule: fridaySchedule,
                    fridayStart: InstanceScheduleDashboardComponent.formatTimeField(fridaySchedule, 'start'),
                    fridayStop: InstanceScheduleDashboardComponent.formatTimeField(fridaySchedule, 'stop'),
                    saturdaySchedule: saturdaySchedule,
                    saturdayStart: InstanceScheduleDashboardComponent.formatTimeField(saturdaySchedule, 'start'),
                    saturdayStop: InstanceScheduleDashboardComponent.formatTimeField(saturdaySchedule, 'stop'),
                };
                this.instanceScheduleWrapperList.push(instanceScheduleWrapper);
            });
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperList;
        });
    }

    static findSchedule(instanceId: number, dayOfWeek: number, instanceSchedules: InstanceSchedule[]): InstanceSchedule {
        return instanceSchedules?.find((instanceSchedule) => {
            return instanceSchedule.dayOfWeek === dayOfWeek && instanceSchedule.instanceId === instanceId;
        });
    }

    static formatTimeField(instanceSchedule: InstanceSchedule, field: string): string {
        if (instanceSchedule) {
            let hours: number;
            let minutes: number;
            if (field === 'start') {
                hours = instanceSchedule.startHours;
                minutes = instanceSchedule.startMinutes;
            } else if (field === 'stop') {
                hours = instanceSchedule.stopHours;
                minutes = instanceSchedule.stopMinutes;
            }
            let hoursString = hours.toString();
            let minutesString = minutes.toString();
            if (hoursString.length < 2) {
                hoursString = '0' + hoursString;
            }
            if (minutesString.length < 2) {
                minutesString = '0' + minutesString;
            }
            return hoursString + ':' + minutesString;
        } else {
            return 'N/A';
        }
    }

    onEditInstanceSchedule(instance: Instance): void {
        this.instanceScheduleDao.filter({instanceId: instance.id}).subscribe((resultList) => {
            const dialogRef = this.dialog.open(InstanceScheduleComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog',
                data: {
                    instance: instance,
                    instanceScheduleList: resultList
                }
            });
            const subscription = dialogRef
                .afterClosed()
                .subscribe(() => {
                    this.instanceDao.getOne(instance.id).subscribe((result) => {
                        const index = this.instanceScheduleWrapperList.findIndex((item) => {
                            return item.instanceId === result.id;
                        });
                        this.instanceScheduleWrapperList[index].instance = result;
                        this.instanceScheduleWrapperList[index].hasInstanceSchedule = result.hasInstanceSchedule;
                        // tem que pegar a lsita de instanceSchedule
                        this.instanceScheduleDao.filter({instanceId: result.id}).subscribe((instanceScheduleList) => {
                            if (instanceScheduleList) {
                                instanceScheduleList.sort((a, b) => {
                                    if (a.dayOfWeek === 1) {
                                        return 8 - b.dayOfWeek;
                                    }
                                    return a.dayOfWeek - b.dayOfWeek;
                                });

                                const mondaySchedule = instanceScheduleList[0];
                                this.instanceScheduleWrapperList[index].mondayStart = InstanceScheduleDashboardComponent.formatTimeField(mondaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].mondayStop = InstanceScheduleDashboardComponent.formatTimeField(mondaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].mondaySchedule = mondaySchedule;


                                const tuesdaySchedule = instanceScheduleList[1];
                                this.instanceScheduleWrapperList[index].tuesdayStart = InstanceScheduleDashboardComponent.formatTimeField(tuesdaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].tuesdayStop = InstanceScheduleDashboardComponent.formatTimeField(tuesdaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].tuesdaySchedule = tuesdaySchedule;

                                const wednesdaySchedule = instanceScheduleList[2];
                                this.instanceScheduleWrapperList[index].wednesdayStart = InstanceScheduleDashboardComponent.formatTimeField(wednesdaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].wednesdayStop = InstanceScheduleDashboardComponent.formatTimeField(wednesdaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].wednesdaySchedule = wednesdaySchedule;

                                const thursdaySchedule = instanceScheduleList[3];
                                this.instanceScheduleWrapperList[index].thursdayStart = InstanceScheduleDashboardComponent.formatTimeField(thursdaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].thursdayStop = InstanceScheduleDashboardComponent.formatTimeField(thursdaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].thursdaySchedule = thursdaySchedule;

                                const fridaySchedule = instanceScheduleList[4];
                                this.instanceScheduleWrapperList[index].fridayStart = InstanceScheduleDashboardComponent.formatTimeField(fridaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].fridayStop = InstanceScheduleDashboardComponent.formatTimeField(fridaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].fridaySchedule = fridaySchedule;

                                const saturdaySchedule = instanceScheduleList[5];
                                this.instanceScheduleWrapperList[index].saturdayStart = InstanceScheduleDashboardComponent.formatTimeField(saturdaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].saturdayStop = InstanceScheduleDashboardComponent.formatTimeField(saturdaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].saturdaySchedule = saturdaySchedule;

                                const sundaySchedule = instanceScheduleList[6];
                                this.instanceScheduleWrapperList[index].sundayStart = InstanceScheduleDashboardComponent.formatTimeField(sundaySchedule, 'start');
                                this.instanceScheduleWrapperList[index].sundayStop = InstanceScheduleDashboardComponent.formatTimeField(sundaySchedule, 'stop');
                                this.instanceScheduleWrapperList[index].sundaySchedule = sundaySchedule;



                                this.onFilterList();
                            }
                        });

                    });
                });
            this.addSubscription(subscription);
        });
    }


    public onFilterList(): void {
        const itsSchedules = this.itsSchedulesControl.value;
        const onlyActive = this.onlyActiveControl.value;
        const searchField = this.searchFieldControl.value;

        this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperList;
        if (itsSchedules === 'Apenas Agendados') {
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperListFilter.filter(
                instance => instance.fridayStart !== 'N/A');
        }
        if (itsSchedules === 'Apenas Não agendados') {
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperListFilter.filter(
                instance => instance.fridayStart === 'N/A');
        }
        if (onlyActive === 'Apenas Ativos') {
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperListFilter.filter(
                instance => instance.hasInstanceSchedule === true);
        }
        if (onlyActive === 'Apenas Não Ativos') {
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperListFilter.filter(
                instance => instance.hasInstanceSchedule === false);
        }
        if (searchField && searchField.length > 0) {
            this.instanceScheduleWrapperListFilter = this.instanceScheduleWrapperListFilter.filter(
                instance => {
                    const search = searchField.toString().toLowerCase();
                    if (instance.projectName.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.instance.name.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Segunda
                    if (instance.mondayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.mondayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Terça
                    if (instance.tuesdayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.tuesdayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Quarta
                    if (instance.wednesdayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.wednesdayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Quinta
                    if (instance.thursdayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.thursdayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Sexta
                    if (instance.fridayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.fridayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Sábado
                    if (instance.saturdayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.saturdayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    // Domingo
                    if (instance.sundayStart.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                    if (instance.sundayStop.toString().toLowerCase().indexOf(search) > -1) {
                        return instance;
                    }
                }
            );
        }
    }
}
