import {Component, Input} from '@angular/core';
import {InstanceDaoService} from '../instance/instance-dao.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {CloudConfigInsertTokenDialogComponent, GoogleInstanceDialogComponent} from '../cloud-config/cloud-config.component';

import {ComponentCleaner} from '../../../../component-cleaner';
import {CloudConfigDaoService} from '../cloud-config/cloud-config-dao.service';
import {filter, map, mergeMap} from 'rxjs/operators';
import {CloudConfig, DataCenter, Instance, Project, SnapshotPolicyType, VpnConfig} from '../../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-data-center-config',
    templateUrl: './data-center-config.component.html',
    styleUrls: ['./data-center-config.component.scss'],
    animations: fuseAnimations
})
export class DataCenterConfigComponent extends ComponentCleaner {
    activeTab = 0;
    editing1 = false;
    editing2 = false;
    editing3 = false;
    dataCenter: DataCenter;
    @Input()
    vpnConfig: VpnConfig;
    @Input()
    project: Project;
    instances: Instance[];
    snapshotPolicyTypes: SnapshotPolicyType[];
    cloudConfig: CloudConfig;

    constructor(private instanceDao: InstanceDaoService, private dialog: MatDialog, private cloudConfigDao: CloudConfigDaoService) {
        super();
    }

    @Input()
    set modelDataCenter(dataCenter: any) {
        this.dataCenter = dataCenter;
        // Se for Google já cria o CloudConfig automaticamente.
        this.instanceDao.getSnapshotPolicyTypes().subscribe((snapshotPolicyTypes) => {
            this.snapshotPolicyTypes = snapshotPolicyTypes;
            this.onGetInstances();
        });
    }

    onTabChange(input: number): void {
        this.activeTab = input;
    }

    onEdit(): void {
        this.setTabEditing(true);
    }

    isEditing(): boolean {
        return this.getTabEditing();
    }

    onCancel(): void {
        this.setTabEditing(false);
    }

    private setTabEditing(condition: boolean): void {
        switch (this.activeTab) {
            case 0:
                this.editing1 = condition;
                break;
            case 1:
                this.editing2 = condition;
                break;
            case 2:
                this.editing3 = condition;
                break;
        }
    }

    private getTabEditing(): boolean {
        switch (this.activeTab) {
            case 0:
                return this.editing1;
            case 1:
                return this.editing2;
            case 2:
                return this.editing3;
        }
    }

    onCreateCloudConfig(dataCenter: DataCenter): void {
        if (dataCenter && dataCenter.id) {
            const newCloudConfig: CloudConfig = {
                id: null,
                active: true,
                comment: null,
                googleCloudJson: null,
                dataCenterId: dataCenter.id,
                zone: 'southamerica-east1-a',
                modified: null,
                optlock: null
                // googleCloudJson: null,
            };
            this.cloudConfigDao.create(newCloudConfig).subscribe((cloudConfig) => {
                this.cloudConfig = cloudConfig;
                this.onInsertToken();
            });
        }
    }

    onInsertToken(): void {
        const dialogRef = this.dialog.open(CloudConfigInsertTokenDialogComponent, {
            disableClose: true,
            panelClass: 'insert-token-dialog'
        });
        const subscription = dialogRef
            .afterClosed().pipe(
                filter(x => !!x),
                mergeMap((data) => {
                    this.cloudConfig.googleCloudJson = JSON.parse(data.token);
                    if (this.cloudConfig.id) {
                        return this.cloudConfigDao.save(this.cloudConfig);
                    } else {
                        return this.cloudConfigDao.create(this.cloudConfig);
                    }

                }))
            .subscribe(() => {
                // abre a lista de instancias do projeto para poder inserir
                this.onGetInstances();
            });
        this.addSubscription(subscription);
    }

    onGetInstances(): void {
        this.instanceDao.filter({dataCenterId: this.dataCenter.id}).subscribe((instancesFromDAO) => {
            this.instances = instancesFromDAO;
            if (this.dataCenter.type === 'Google') {
                this.cloudConfigDao.filterOne({dataCenterId: this.dataCenter.id}).subscribe((cloudConfig) => {
                    this.cloudConfig = cloudConfig;
                    if (!cloudConfig) {
                        this.onCreateCloudConfig(this.dataCenter);
                    } else {
                        this.getGoogleInstances(instancesFromDAO);
                    }
                });

            }
        });
    }

    getGoogleInstances(instancesFromDAO: Instance[]): void {
        this.instanceDao.getFromGoogle(this.dataCenter.id).subscribe((instancesFromGoogle) => {
            if (this.compareInstances(instancesFromDAO, instancesFromGoogle)) {
                const dialogRef = this.dialog.open(GoogleInstanceDialogComponent, {
                    disableClose: true,
                    panelClass: 'google-instance-dialog',
                    data: {
                        instancesFromGoogle: instancesFromGoogle,
                        instances: instancesFromDAO
                    }
                });
                const subscription = dialogRef
                    .afterClosed().pipe(
                        filter(x => !!x),
                        map((data) => data.instances))
                    .subscribe((newInstances) => {
                        this.instances = newInstances;
                        // this.modelInstancesChange.emit(newInstances);
                        // todo ver um jeito de atualizar apenas as instancias que estão dentro do componente (InstanceListComponent)
                        // window.location.reload();
                    });
                this.addSubscription(subscription);
            }
        });
    }

    private compareInstances(instancesFromDAO: Instance[], instancesFromGoogle: Instance[]): boolean {
        if ((!instancesFromDAO || !instancesFromGoogle) || (instancesFromGoogle && instancesFromDAO && instancesFromGoogle.length !== instancesFromDAO.length)) {
            return true;
        }
        const checkedInstancesFromDAO = instancesFromDAO.filter((o1) => {
            const index = instancesFromGoogle.findIndex((o2) => {
                return o1.googleInstanceName === o2.googleInstanceName;
            });
            return index > -1;
        });
        const checkedInstancesFromGoogle = instancesFromGoogle.filter((o1) => {
            const index = instancesFromDAO.findIndex((o2) => {
                return o1.googleInstanceName === o2.googleInstanceName;
            });
            return index > -1;
        });
        return checkedInstancesFromDAO.length !== instancesFromDAO.length || checkedInstancesFromGoogle.length !== instancesFromGoogle.length;
    }
}
