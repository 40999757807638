import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {KluhSharedModule} from '../../kluh-shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {ScriptExecutionComponent} from './script-execution.component';
import {ScriptExecutionDaoService} from './script-execution-dao.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';

const routes = [
    {
        path: 'script-execution',
        component: ScriptExecutionComponent,
        canActivate: [AuthService],
        data: {permissions: ['SubProject.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatSelectModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatCardModule,
        MatIconModule,
        MatCardModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatToolbarModule,
        NgxMaskModule.forRoot()
    ],
    entryComponents: [ScriptExecutionComponent],
    declarations: [ScriptExecutionComponent],
    providers: [ScriptExecutionDaoService]
})
export class ScriptExecutionModule {
}
