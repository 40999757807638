import {Component, OnDestroy} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {ADUser, ADUserPoolRelationship, RoleIdSubProjectIdManagerUserId} from '../../../models';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {ManagerUserAndAdUserCreateEditComponent} from '../../manager-user-and-ad-user/manager-user-and-ad-user-create-edit/manager-user-and-ad-user-create-edit.component';
import {filter} from 'rxjs/operators';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CrudOperationWrapper, theDateIsGreaterThanNowPlusMinutes} from '../../../helpers/kluh';
import {ManagerUserAdministratorComponent} from '../../iam/manager-user-administrator/manager-user-administrator.component';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AdUserPoolRelationshipEditComponent} from '../../ad-user-pool-relationship/ad-user-pool-relationship-edit/ad-user-pool-relationship-edit.component';
import {AdUserPoolRelationshipDaoService} from '../../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';
import {R2CloudAdminUserTypeChoiceComponent} from './r2-cloud-admin-user-type-choice/r2-cloud-admin-user-type-choice.component';
import {combineLatest} from 'rxjs';

@Component({
    selector: 'app-r2-cloud-admin-aduser',
    templateUrl: './r2-cloud-admin-aduser.component.html',
    styleUrls: ['./r2-cloud-admin-aduser.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminADUserComponent extends ComponentCleaner implements OnDestroy {

    public tabIndex = 0;
    // canAccess = false;

    public timerPendingChangesLoad = null;
    public readonly appCanCreateAdUser: PermissionWrapper[];
    public readonly appCanCreateAdministrator: PermissionWrapper[];

    constructor(private adUserDao: AdUserDaoService,
                public dialogRef: MatDialogRef<R2CloudAdminADUserComponent>,
                public adminService: R2CloudAdminService,
                public adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
                public customerGroupService: CustomerGroupService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                private dialog: MatDialog,
                private managerUserDao: ManagerUserDaoService) {
        super();
        this.appCanCreateAdUser = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
        this.appCanCreateAdministrator = [
            {type: 'ADUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUser', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }


    ngOnDestroy(): void {
        clearInterval(this.timerPendingChangesLoad);
    }

    openUserTypeChoiceModal(): void {
        const dialogRef = this.dialog.open(R2CloudAdminUserTypeChoiceComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
        const subscription = dialogRef.afterClosed().subscribe(result => {
        });
        this.addSubscription(subscription);
    }

    openUserEditModal(adUser: ADUser): void {
        let managerUser = null;
        if (adUser) {
            managerUser = this.adminService.getManagerUser(adUser.managerUserId);
        }
        const dialogRef = this.dialog.open(ManagerUserAndAdUserCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adUser: adUser,
                managerUser: managerUser,
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adUserSaved) => {
            this.adUserDao.filter({
                'subProjectId': this.adminService.subProject.id,
                'serviceUser': adUserSaved.serviceUser
            }).subscribe((adUsers) => {
                if (adUserSaved.serviceUser) {
                    throw new Error('serviceUser inside ADUser component');
                } else {
                    this.adminService.adUsers = adUsers;
                }
            });

            this.managerUserDao.filter({'subProjectId': this.adminService.subProject.id}).subscribe((managerUsers) => {
                this.adminService.managerUsers = managerUsers;
            });
        });
        this.addSubscription(subscription);
    }

    openUserAdministratorCreateModal(): void {
        this.dialog.open(ManagerUserAdministratorComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
    }

    openUserAdministratorEditModal(roleIdSubProjectIdManagerUserId: RoleIdSubProjectIdManagerUserId): void {
        const dialogRef = this.dialog.open(ManagerUserAdministratorComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers,
                roleIdSubProjectIdManagerUserId: roleIdSubProjectIdManagerUserId
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            if (result && result.constructor === Array) {
                const managerUserOperation = result[0];
                const adUserOperation = result[1];

                if (managerUserOperation.operation === 'CREATE' || adUserOperation.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === managerUserOperation.data.id);
                    if (!managerUserCheck) {
                        this.adminService.addManagerUser(managerUserOperation.data);
                    }
                    const adUserCheck = this.adminService.adUsers.find(x => x.id === adUserOperation.data.id);
                    if (!adUserCheck) {
                        this.adminService.addADUser(adUserOperation.data);
                    }

                }

            }
        });
        this.addSubscription(subscription);
    }

    onLinkClick(event: MatTabChangeEvent): void {
        this.tabIndex = event.index;
    }

    theDateIsGreaterThanNowPlusMinutes(updatedAt: Date, minutes: number): boolean {
        return theDateIsGreaterThanNowPlusMinutes(updatedAt, minutes);
    }

    openUserPoolEditModal(adUserPoolRelationship: ADUserPoolRelationship): void {
        let managerUser = null;
        if (adUserPoolRelationship) {
            managerUser = this.adminService.getManagerUser(adUserPoolRelationship.managerUserId);
        }
        const dialogRef = this.dialog.open(AdUserPoolRelationshipEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adUserPoolRelationship: adUserPoolRelationship,
                managerUser: managerUser,
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((adUserPoolRelationshipEdit) => {
            const managerUserOfADUserPoolBySubProjectIds$ = this.managerUserDao.findAllManagerUserOfADUserPoolBySubProjectId(this.adminService.subProject.id);
            const adUsersPoolRelationship$ = this.adUserPoolRelationshipDaoService.filter({
                subProjectId: this.adminService.subProject.id,
                serviceUser: adUserPoolRelationshipEdit.serviceUser
            });
            const managerUsers$ = this.managerUserDao.filter({'subProjectId': this.adminService.subProject.id});

            combineLatest([adUsersPoolRelationship$, managerUserOfADUserPoolBySubProjectIds$, managerUsers$]).subscribe((results) => {
                const adUsersPoolRelationship = results[0];
                const managerUsersPool = results[1];
                const managerUsers = results[2];
                if (adUserPoolRelationshipEdit.serviceUser) {
                    throw new Error('serviceUser inside ADUser component');
                } else {
                    this.adminService.adUsersPoolRelationship = adUsersPoolRelationship;
                }
                this.adminService.managerUsers = managerUsers;
                managerUsersPool.forEach((it) => {
                    this.adminService.addOrReplaceManagerUser(it);
                });
            });

            /*

                        this.adUserPoolRelationshipDaoService.filter({
                            'subProjectId': this.adminService.subProject.id,
                            'serviceUser': adUserPoolRelationshipEdit.serviceUser
                        }).subscribe((adUsersPoolRelationship) => {

                            if (adUserPoolRelationshipEdit.serviceUser) {
                                throw new Error('serviceUser inside ADUser component');
                            } else {
                                this.adminService.adUsersPoolRelationship = adUsersPoolRelationship;
                            }
                        });


                        this.managerUserDao.filter({'subProjectId': this.adminService.subProject.id}).subscribe((managerUsers) => {
                            this.adminService.managerUsers = managerUsers;
                        });
            */

        });
        this.addSubscription(subscription);
    }
}
