import {Injectable} from '@angular/core';
import {MarketplaceOrder} from '../../../models';
import {MarketplaceOrderDaoService} from './marketplace-order.dao.service';
import {FuseNavigationService} from '../../../../../@fuse/components/navigation/navigation.service';

@Injectable({
    providedIn: 'root'
})
export class MarketplaceOrderService {
    constructor(
        private marketplaceOrderDaoService: MarketplaceOrderDaoService,
        private _fuseNavigationService: FuseNavigationService
    ) {
    }

    initMarketplaceOrder(): MarketplaceOrder {
        return {
            id: null,
            active: true,
            comment: null,
            marketplaceProductId: null,
            managerUserId: null,
            customerGroupId: null,
            readOrder: null,
            readOrderLastDate: null,
            producerReadOrder: null,
            producerReadOrderLastDate: null,
            cancel: null,
            cancelDate: null,
            concluded: null,
            concludedDate: null,
            description: null,
            phone: null,
            email: null,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
    }

    findAllUnread(marketplaceOrderList: MarketplaceOrder[], customerGroupId: number): MarketplaceOrder[] {
        return marketplaceOrderList.filter((o) => {
                if (o.customerGroupId === customerGroupId) {
                    return (!o.readOrder) && !o.cancel && !o.concluded;
                } else {
                    return (!o.producerReadOrder) && !o.cancel && !o.concluded;
                }
            }
        );
    }

    findAllRead(marketplaceOrderList: MarketplaceOrder[], customerGroupId: number): MarketplaceOrder[] {
        return marketplaceOrderList.filter(
            (o) => {
                if (o.customerGroupId === customerGroupId) {
                    return o.readOrder && !o.cancel && !o.concluded;
                } else {
                    return o.producerReadOrder && !o.cancel && !o.concluded;
                }
            }
        );
    }

    findAllCancel(marketplaceOrderList: MarketplaceOrder[]): MarketplaceOrder[] {
        return marketplaceOrderList.filter(
            (o) => o.cancel
        );
    }

    findAllConcluded(marketplaceOrderList: MarketplaceOrder[]): MarketplaceOrder[] {
        return marketplaceOrderList.filter(
            (o) => o.concluded
        );
    }

    findById(marketplaceOrderList: MarketplaceOrder[], marketplaceOrderId: number): boolean {
        const list = marketplaceOrderList.filter(
            (o) => {
                return o.id === marketplaceOrderId;
            }
        );
        return list.length > 0;
    }

    markAsRead(marketplaceOrderList: MarketplaceOrder[], marketplaceOrderId: number): MarketplaceOrder[] {
        const index = marketplaceOrderList.findIndex((o) => o.id === marketplaceOrderId);
        if (index > -1) {
            marketplaceOrderList[index].readOrder = true;
            marketplaceOrderList[index].producerReadOrder = true;
        }
        return marketplaceOrderList;
    }

    updateOrderMenuBadge(amountOfUnreadOrders: number): void {
        if (amountOfUnreadOrders > 0) {
            this._fuseNavigationService.updateNavigationItem('orders', {
                badge: {
                    title: '' + amountOfUnreadOrders,
                    translate: '' + amountOfUnreadOrders,
                    bg: '#4CAF4F',
                    fg: '#000000'
                }
            });
            this._fuseNavigationService.updateNavigationItem('marketplace', {
                badge: {
                    title: '' + amountOfUnreadOrders,
                    translate: '' + amountOfUnreadOrders,
                    bg: '#4CAF4F',
                    fg: '#000000'
                }
            });
        } else {
            this.removeOrderMenuBadge();
        }
    }

    removeOrderMenuBadge(): void {
        // console.log('removeOrderMenuBadge()');
        this._fuseNavigationService.updateNavigationItem('orders', {
                badge: null
            }
        );
        this._fuseNavigationService.updateNavigationItem('marketplace', {
            badge: null
        });
    }

    updateOrderMenuBadgeByMarketplaceOrderList(marketplaceOrderList: MarketplaceOrder[], customerGroupId: number): void {
        let amountOfUnreadOrders = 0;
        this.findAllUnread(marketplaceOrderList, customerGroupId).filter((order) => {
            amountOfUnreadOrders++;
        });
        this.updateOrderMenuBadge(amountOfUnreadOrders);
    }

    getAmountOfUnreadOrdersAndPutOnMenuBadge(): void {
        this.marketplaceOrderDaoService.getAmountOfUnreadOrders().subscribe(amountOfUnreadOrders => {
            if (amountOfUnreadOrders) {
                this.updateOrderMenuBadge(amountOfUnreadOrders);
            }
        });
    }
}
