import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import {ProjectDaoService} from '../project/project-dao.service';
import {VpnConfigDaoService} from './project-vpn-config/vpn-config-dao.service';
import {ComponentCleaner} from '../../component-cleaner';

import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {fuseAnimations} from '../../../../@fuse/animations';
import {ProjectCreateComponent} from '../project/project-create/project-create.component';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {CustomerGroup, Project, VpnConfig} from '../../models';
import {MatDialog} from '@angular/material/dialog';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class SupportComponent extends ComponentCleaner implements AfterViewInit {
    @ViewChild('selectProjectElement', {static: false})
    private selectProjectElement: ElementRef;
    @ViewChild(MatAutocomplete, {static: false})
    private matAutocomplete: MatAutocomplete;
    selectProjectForm = new FormControl();
    filteredOptions: Observable<Project[]>;
    projects: Project[];
    vpnConfig: VpnConfig;
    private projectIdParam = 'projectId';

    constructor(private projectDAO: ProjectDaoService,
                private vpnConfigDao: VpnConfigDaoService,
                private customerGroupDao: CustomerGroupDaoService,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private router: Router,
                private location: Location) {
        super();
        projectDAO.getActive().subscribe((projects) => {
            this.projects = projects;
            this.filteredOptions = this.selectProjectForm.valueChanges.pipe(
                startWith(<CustomerGroup>null),
                map((customerGroup) => {
                    if (customerGroup && typeof customerGroup === 'object') {
                        return customerGroup.name;
                    } else {
                        return customerGroup;
                    }
                }),
                map((name) => {
                    if (name) {
                        return this.filterProjects(name);
                    } else {
                        return this.projects;
                    }
                }),
            );
            const subscription = this.selectProjectForm.valueChanges.pipe(filter((x) => !!(x) && x.id)).subscribe((project) => {
                this.setUrlQueryParams(project);
                const subscription1 = this.vpnConfigDao.filterOne({projectId: project.id}).subscribe((vpnConfig) => {
                    this.vpnConfig = vpnConfig;
                });
                this.addSubscription(subscription1);
            });
            this.addSubscription(subscription);
            const queryParamMap = this.route.snapshot.queryParamMap;
            if (queryParamMap.has(this.projectIdParam)) {
                const projectId: number = Number(queryParamMap.get(this.projectIdParam));
                const project = this.projects.find((x) => x.id === projectId);
                if (project) {
                    this.selectProjectForm.setValue(project);
                }
            }
        });
    }

    private setUrlQueryParams(project: Project): void {
        let queryParams;
        if (project) {
            queryParams = {projectId: project.id};
        } else {
            queryParams = {};
        }
        const urlTree = this.router.createUrlTree([], {relativeTo: this.route, queryParams: queryParams});
        this.location.go(urlTree.toString());
    }

    ngAfterViewInit(): void {
        if (!this.route.snapshot.queryParamMap.has(this.projectIdParam)) {
            this.focusSelectProject();
        }
    }

    filterProjects(name: string): Project[] {
        return this.projects.filter((option) => option.name.toLowerCase().indexOf(name.toLowerCase()) >= 0);
    }

    // noinspection JSMethodCanBeStatic
    displayFn(customerGroup: Project): string {
        if (customerGroup) {
            return customerGroup.name;
        }
    }

    clear(): void {
        this.selectProjectForm.reset();
        this.setUrlQueryParams(null);
        this.focusSelectProject();
    }

    selectFirst(): void {
        // const search = this.selectProjectForm.value;
        // if (search && typeof search === 'string') {
        //   const found = this.projects.find((project) => project.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
        //   if (found) {
        //     this.selectProjectForm.setValue(found);
        //   }
        // }
        this.matAutocomplete.options.first.select();
    }

    focusSelectProject(): void {
        setTimeout(() => {
            this.selectProjectElement.nativeElement.focus();
        }, 100);
    }

    onHideSelectProject(): boolean {
        return this.selectProjectForm && this.selectProjectForm.value && this.selectProjectForm.value.id;
    }


    onCreateProject(): void {
        this.customerGroupDao.getAllCache().subscribe((customerGroups) => {
        // this.customerGroupDao.get().subscribe((customerGroups) => {
            const dialogRef = this.dialog.open(ProjectCreateComponent, {
                disableClose: true,
                panelClass: 'generic-edit-dialog',
                data: {
                    customerGroups: customerGroups
                }
            });
            const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((project) => {
                this.projects.push(project);
                this.selectProjectForm.setValue(project);
                this.selectProjectForm.updateValueAndValidity();
                // todo ver um jeito de atualizar apenas o conteúdo do projeto para chacar novamente se existe ou não DataCenter no projeto
                // window.location.reload();

            });
            this.addSubscription(subscription);
        });
    }

}
