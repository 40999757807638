<div id="r2cloud-support" class="page-layout simple fullwidth">
    <app-background type="dynamic"></app-background>

    <div class="header mat-accent-bg" fxLayout="row" fxLayoutAlign="space-between center">
        <ng-form
            id="r2cloud-support-form"
            (ngSubmit)="onSearchSubmit()">
            <div fxLayout="row" class="mat-display-1 mb-0" [@animate]="{value:'*',params:{x:'50px'}}">
                <mat-form-field class="pr-4" style="margin-left: 6px; width: 400px;">
                    <mat-label>Pesquisar</mat-label>
                    <input matInput
                           id="r2cloud-support-search"
                           [formControl]="search">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Nos tipos</mat-label>
                    <mat-select
                        id="r2cloud-support-type"
                        [formControl]="searchType">
                        <mat-option *ngFor="let type of searchTypes" [value]="type">
                            {{type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button type="button" mat-mini-fab class="clear-button" (click)="onClearCards()">
                    <mat-icon>delete_sweep</mat-icon>
                </button>
            </div>
        </ng-form>
<!--        <div fxLayout="row" class="mr-8 s-16">-->
<!--            <div class="mr-4">-->
<!--                Agente:-->
<!--            </div>-->
<!--            <div *ngIf="clientStatus" class="client-status-icon">-->
<!--                &lt;!&ndash;fadeInOut&ndash;&gt;-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.CONNECTED"-->
<!--                     matTooltip="Agente conectado">-->
<!--                    <fa-icon class="client-connected" [icon]="faCircle"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.CLIENT_UPDATE"-->
<!--                     matTooltip="Agente sendo atualizado">-->
<!--                    <fa-icon class="client-update" [icon]="faLaptopCode"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.NOT_CONNECTED"-->
<!--                     matTooltip="Agente não conectado">-->
<!--                    <fa-icon class="client-not-connected" [icon]="faCircle"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.INSTALLED"-->
<!--                     matTooltip="Agente instalado">-->
<!--                    <fa-icon class="client-installed icon-click" [icon]="faCircle" (click)="startClient()"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.NOT_INSTALLED"-->
<!--                     matTooltip="Agente não instalado">-->
<!--                    <fa-icon class="client-not-installed icon-click" [icon]="faLaptopCode"-->
<!--                             (click)="startClient()"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.STOMP_NOT_CONNECTED"-->
<!--                     matTooltip="Servidor desconectado">-->
<!--                    <fa-icon class="client-stomp-not-connected" [icon]="faTimesCircle"></fa-icon>-->
<!--                </div>-->
<!--                <div [@fadeIn] *ngIf="clientStatus.status === R2CloudClientStatus.OPENING" matTooltip="Agente abrindo">-->
<!--                    <fa-icon class="client-opening icon-click" [icon]="faLaptopCode" (click)="startClient()"></fa-icon>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <!--<div class="header-content" fxLayout="column" fxLayoutAlign="space-between stretch">-->
        <!--<div class="header-top" fxLayout="row" fxLayoutAlign="space-between center">-->

        <!--</div>-->
        <!--</div>-->
    </div>

    <div class="content flex-content" fxLayout="row wrap" fxLayoutGap="40px" fxLayoutAlign="start start" @list>
        <div *ngFor="let card of cards" class="support-card" @items>
            <app-sub-project-support-card *ngIf="card.cardType === SupportCardType.SUB_PROJECT"
                                          (connect)="onDesktopServerConnect($event)"
                                          (linuxConnect)="onLinuxServerConnect($event)"
                                          (close)="onClose(card)"
                                          (desktopServerSelect)="onDesktopServerSelect($event, card)"
                                          (linuxServerSelect)="onLinuxServerSelect($event, card)"
                                          [subProject]="card.value"></app-sub-project-support-card>

            <app-desktop-server-support-card *ngIf="card.cardType === SupportCardType.DESKTOP_SERVER"
                                             [desktopServer]="card.value"
                                             (subProjectSelect)="onSubProjectSelect($event, card)"
                                             (connect)="onDesktopServerConnect(card.value)"
                                             (close)="onClose(card)"></app-desktop-server-support-card>

            <app-linux-server-support-card *ngIf="card.cardType === SupportCardType.LINUX_SERVER"
                                           [linuxServer]="card.value"
                                           (subProjectSelect)="onSubProjectSelect($event, card)"
                                           (connect)="onLinuxServerConnect(card.value)"
                                           (close)="onClose(card)"
            ></app-linux-server-support-card>
        </div>
    </div>
</div>