import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupportComponent} from './support.component';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {ProjectVpnConfigComponent} from './project-vpn-config/project-vpn-config.component';
import {VpnConfigDaoService} from './project-vpn-config/vpn-config-dao.service';
import {InstanceDaoService} from './data-center/instance/instance-dao.service';
import {DataCenterRouterDAOService} from './data-center/data-center-router/data-center-router-dao.service';
import {DataCenterRouterWanDaoService} from './data-center/data-center-router/data-center-router-wan-dao.service';
import {DataCenterRouterWanProviderDaoServiceService} from './data-center/data-center-router/data-center-router-wan-provider-dao-service.service';
import {DataCenterRouterWanTechnologyDaoService} from './data-center/data-center-router/data-center-router-wan-technology-dao.service';
import {CloudConfigDaoService} from './data-center/cloud-config/cloud-config-dao.service';
import {DataCenterRouterComponent} from './data-center/data-center-router/data-center-router.component';
import {DataCenterConfigComponent} from './data-center/config/data-center-config.component';
import {DataCenterListComponent} from './data-center/data-center-list/data-center-list.component';
import {CloudConfigComponent, CloudConfigInsertTokenDialogComponent, GoogleInstanceDialogComponent} from './data-center/cloud-config/cloud-config.component';
import {InstanceListComponent} from './data-center/instance/instance-list/instance-list.component';
import {InstanceEditComponent} from './data-center/instance/instance-edit/instance-edit.component';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {KluhSharedModule} from '../../kluh-shared.module';
import {InstanceScheduleComponent} from './data-center/instance/instance-schedule/instance-schedule.component';
import {InstanceScheduleDaoService} from './data-center/instance/instance-schedule/instance-schedule-dao.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';


const routes = [
    {
        path: 'infra',
        component: SupportComponent,
        canActivate: [AuthService],
        data: {permissions: ['Instance.WRITE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTabsModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatCardModule,
        MatExpansionModule,
        MatSelectModule,
        MatToolbarModule,
        MatDialogModule,
        KluhSharedModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatListModule,
    ],
    providers: [
        DataCenterRouterDAOService,
        VpnConfigDaoService,
        DataCenterRouterWanDaoService,
        DataCenterRouterWanProviderDaoServiceService,
        DataCenterRouterWanTechnologyDaoService,
        CloudConfigDaoService,
        InstanceDaoService,
        InstanceScheduleDaoService
    ],
    declarations: [
        SupportComponent,
        ProjectVpnConfigComponent,
        DataCenterRouterComponent,
        DataCenterConfigComponent,
        CloudConfigComponent,
        CloudConfigInsertTokenDialogComponent,
        GoogleInstanceDialogComponent,
        DataCenterListComponent,
        InstanceListComponent,
        InstanceEditComponent,
        InstanceScheduleComponent
    ],
    entryComponents: [
        CloudConfigInsertTokenDialogComponent,
        GoogleInstanceDialogComponent,
        InstanceEditComponent,
        InstanceScheduleComponent
    ]
})
export class SupportModule {
}
