import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MonitoringDetailsService} from '../monitoring-details.service';
import {MatTableDataSource} from '@angular/material/table';
import {ServiceState, WindowsService} from '../../../../models-monitoring';
import {Subject} from 'rxjs/internal/Subject';
import {BaseServer, ScriptExecutionType, ServerType} from '../../../../models';
import {ServicesDetailsTableDaoService} from './services-details-table-dao.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder, FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ServicesDetailsTableService} from './services-details-table.service';
import {searchIndexOf} from '../helper/helper';
import {ScriptExecutionDaoService} from '../../../script-execution/script-execution-dao.service';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';
import {DomainPathService} from '../../../../domain-path/domain-path.service';


@Component({
    selector: 'app-services-details-table',
    templateUrl: './services-details-table.component.html',
    styleUrls: ['../monitoring-details.component.scss', './services-details-table.component.scss']
})
export class ServicesDetailsTableComponent implements AfterViewInit, OnDestroy {

    private subscription: Subscription;

    serviceTable: string[] = ['displayName', 'name', 'pathName', 'startName', 'startMode', 'firstSeenAt', 'state'];
    serviceDataSource = new MatTableDataSource<WindowsService>();
    protected _onDestroy = new Subject<void>();
    serviceList: WindowsService[];
    serviceListFilter: WindowsService[];
    searchMultiFilterCtrl: FormControl;

    @Input()
    baseServer: BaseServer;

    @Input()
    serverType: ServerType;

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    ServiceState = ServiceState;


    constructor(private dialog: MatDialog,
                private monitoringDetailsService: MonitoringDetailsService,
                private scriptExecutionDaoService: ScriptExecutionDaoService,
                private fb: FormBuilder,
                private webStompService: MonitoringStompService,
                private servicesDetailsTableService: ServicesDetailsTableService,
                private servicesDetailsTableDaoService: ServicesDetailsTableDaoService,
                private domainPathService: DomainPathService,
    ) {
        this.searchMultiFilterCtrl = fb.control(['']);
        this.monitoringDetailsService.changePath('monitoring-details', 'services');
    }


    ngAfterViewInit(): void {
        if (this.baseServer) {
            this.subscription = this.webStompService.stompSubscribe<WindowsService[]>('/topic/windows-service.' + this.serverType + '.' + this.baseServer.id)
                .subscribe((windowsServiceList) => {
                    console.log('windowsServiceList: ' + windowsServiceList.length);
                    this.servicesDetailsTableService.listHandler(windowsServiceList, this.serviceList);
                    this.filterBanksMulti();
                });
            this.findAll();
        }
    }

    findAll(): void {
        if (this.baseServer) {
            this.servicesDetailsTableDaoService.findAllByClientId(this.baseServer.clientId).subscribe((serviceList) => {
                if (serviceList) {
                    console.log('serviceList: ' + serviceList.length);
                    this.serviceList = serviceList;
                    this.filterBanksMulti();
                    this.searchMultiFilterCtrl.valueChanges
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe(() => {
                            this.filterBanksMulti();
                        });
                }
            });
        }
    }

    protected filterBanksMulti(): void {
        let search = this.searchMultiFilterCtrl.value;
        if (!search) {
            this.serviceListFilter = this.serviceList;
        } else {
            search = search.toString().toLowerCase();
            this.serviceListFilter = this.serviceList.filter(
                (bank) => {
                    return (
                        searchIndexOf(bank.name, search) ||
                        searchIndexOf(bank.pathName, search) ||
                        searchIndexOf(bank.displayName, search) ||
                        searchIndexOf(bank.startName, search) ||
                        searchIndexOf(bank.startMode, search) ||
                        searchIndexOf(bank.state, search) ||
                        searchIndexOf(bank.firstSeenAt.toString(), search)
                    );
                });
        }
        this.serviceDataSource = new MatTableDataSource(this.serviceListFilter);
        this.serviceDataSource.sort = this.sort;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    onRestartService(element: WindowsService): void {
        const message = '<h1>Tem certeza que deseja REINIAR esse serviço?</h1> <div>' + element.displayName + '</div>';
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: message,
                disableCancel: false,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
        matDialogRef.afterClosed().subscribe((value) => {
            if (value) {
                this.scriptExecutionDaoService.handlerServiceProcessByServerIdAndServerTypeAndProcessId(
                    this.baseServer.id,
                    this.serverType,
                    element.name,
                    ScriptExecutionType.SERVICE_RESTART).subscribe((response) => {
                });
                element.state = ServiceState.CONTINUE_PENDING;
                setTimeout(() => {
                    element.state = ServiceState.RUNNING;
                }, 5000);
                this.filterBanksMulti();
            }
        });

    }

    onStartStopService(element: WindowsService): void {
        let message = '<h1>Tem certeza que deseja PARAR esse serviço?</h1> <div>' + element.displayName + '</div>';
        if (element.state !== ServiceState.RUNNING) {
            message = '<h1>Tem certeza que deseja INICIAR esse serviço?</h1> <div>' + element.displayName + '</div>';
        }
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: message,
                disableCancel: false,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
        matDialogRef.afterClosed().subscribe((value) => {
            if (value) {
                if (element.state !== ServiceState.RUNNING) {
                    this.scriptExecutionDaoService.handlerServiceProcessByServerIdAndServerTypeAndProcessId(
                        this.baseServer.id,
                        this.serverType,
                        element.name,
                        ScriptExecutionType.SERVICE_START).subscribe((response) => {
                    });
                } else {
                    this.scriptExecutionDaoService.handlerServiceProcessByServerIdAndServerTypeAndProcessId(
                        this.baseServer.id,
                        this.serverType,
                        element.name,
                        ScriptExecutionType.SERVICE_STOP).subscribe((response) => {
                    });
                }
                const serviceIndex = this.serviceList.findIndex((service) => {
                    return service === element;
                });
                if (element.state !== ServiceState.RUNNING) {
                    element.state = ServiceState.RUNNING;
                } else {
                    element.state = ServiceState.STOPPED;
                }
                const serviceList = this.serviceList;
                serviceList[serviceIndex] = element;
                this.serviceList = serviceList;
                this.filterBanksMulti();
            }
        });
    }

}
