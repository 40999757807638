import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {PdfPrint} from '../../models';

import {PdfPrintDaoService} from './pdf-print-dao.service';
import {filter, startWith} from 'rxjs/operators';
import {DataSource} from '@angular/cdk/collections';
import {MatPaginator, PageEvent} from '@angular/material/paginator';

@Component({
    selector: 'app-my-prints',
    templateUrl: './my-prints.component.html',
    styleUrls: ['./my-prints.component.scss']
})


export class MyPrintsComponent implements AfterViewInit {
    displayedColumns: string[] = ['id', 'jobTitle', 'jobId',  'createdAt', 'printId'];
    dataSource: DataSource<PdfPrint>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    totalPdfPrints = 0;

    constructor(private pdfPrintDaoService: PdfPrintDaoService) {
        this.dataSource = this.pdfPrintDaoService;

        this.pdfPrintDaoService.total$.subscribe(total => {
            this.totalPdfPrints = total;
        });
    }

    ngAfterViewInit(): void {
        this.paginator.page.pipe(startWith({pageIndex: this.paginator.pageIndex, pageSize: this.paginator.pageSize}), filter(value => !!value)).subscribe((page: PageEvent) => {
            this.pdfPrintDaoService.load(page);
        });

    }

    openPDF(id: number): void {
        this.pdfPrintDaoService.downloadPdfById(id).subscribe(pdfPrint => {
            if (pdfPrint) {
                const objectUrl = URL.createObjectURL(pdfPrint);
                window.open(objectUrl);
            }
        });
    }
}
