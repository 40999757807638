<div fxLayout="column" class="mt-26" [@animate]="{value:'*',params:{y:'100%'}}">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Grupos</div>
                <div *ngIf="adminService.subProject.id && customerGroupService.customerGroup.id">

                    <button *appIfPermission="appCanCreateList"
                            type="button"
                            mat-raised-button color="accent" (click)="onCreateAdGroup()">Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="adminService.adGroups && adminService.adGroups.length > 0">

            <table class="remote-app-table" cellspacing="0" cellpadding="0">
                <thead>
                <tr class="bg-white">
                    <th style="min-width: 560px">Nome</th>
                    <th class="text-align-center">Status</th>
                    <th class="text-align-center min-width-100" class="p-0"></th>
                </tr>
                </thead>
                <tr *ngFor="let adGroup of adminService.adGroups">
                    <td>{{adGroup.name}}</td>
                    <td class="text-align-center">


                        <div *ngIf="(adGroup.changing || adGroup.deleting)">
                            <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(adGroup.updatedAt,15)"
                                      title="Criando... {{adGroup.updatedAt}}">add_circle
                            </mat-icon>
                            <div *ngIf="theDateIsGreaterThanNowPlusMinutes(adGroup.updatedAt,15)">
                                <mat-icon class="red-A700-fg"
                                          title="Algo errado, está demorando muito para executar a tarefa. {{adGroup.updatedAt}}">
                                    error
                                </mat-icon>
                                <button *appIfPermission="'WRITE'; data: adGroup; type: 'ADGroup'" type="button"
                                        mat-raised-button class="warn"
                                        (click)="openAdGroupModal(adGroup)">Delete
                                </button>
                            </div>


                        </div>


                    </td>
                    <td class="text-align-right p-0  pr-2">
                        <button *appIfPermission="'WRITE'; data: adGroup; type: 'ADGroup'" type="button"
                                mat-raised-button class="mat-green-600-bg"
                                (click)="openAdGroupModal(adGroup)">Edit
                        </button>
                    </td>

                </tr>
            </table>


        </mat-card-content>
    </mat-card>
</div>