import {FormControl} from '@angular/forms';

export function networkPrefixValidator(formControl: FormControl): any {
  const networkPrefix = formControl.value;
  const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (regex.test(networkPrefix)){
    return null;
  } else {
    return {'invalidNetworkPrefix': {value: networkPrefix}};
  }
}
