import {Component, OnInit} from '@angular/core';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {FormBuilder} from '@angular/forms';
import {Customer} from '../../../models';
import {ImageFileService} from '../../image-file/image-file-service';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-register-customer',
    templateUrl: './register-customer.component.html',
    styleUrls: ['./register-customer.component.scss', '../register.component.scss']
})
export class RegisterCustomerComponent extends ComponentCleaner implements OnInit {
    appCanCreateList: PermissionWrapper[];
    appCanWriteList: PermissionWrapper[];
    waiting = true;
    constructor(public adminService: R2CloudAdminService,
                public customerGroupService: CustomerGroupService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                public imageFileService: ImageFileService) {
        super();

        this.appCanCreateList = [
            {type: 'Customer', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
        this.appCanWriteList = [
            {type: 'Customer', permission: 'WRITE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];

        setTimeout(() => {
            this.waiting = false;
        }, 2000);


    }

    ngOnInit(): void {

    }


    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    onEditCustomer(customer: Customer): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {customer: customer}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }


    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);
        } else if (result.operation === 'SAVE') {
            const index = this.adminService.customers.findIndex(value => value.id === result.data.id);
            this.adminService.customers[index] = result.data;
        } else if (result.operation === 'DELETE') {
            this.adminService.removeCustomer(result.data);
        }
    }

}
