<form [formGroup]="myForm" (submit)="onSave()">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Hosts do Google</span>
    </div>
  </mat-toolbar>
  <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
       fxLayoutAlign="space-between stretch">
    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
      <mat-form-field>
        <mat-select placeholder="Política de Snapshot" formControlName="snapshotPolicyTypes" multiple>
          <mat-option *ngFor="let snapshotPolicyType of snapshotPolicyTypes" [value]="snapshotPolicyType">
            {{printSnapshotPolicyType(snapshotPolicyType)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <button type="button" mat-raised-button (click)="onTakeSnapshot()">Criar Snapshot agora</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
      <div matTooltip="Salvar">
        <button type="submit" mat-mini-fab color="accent" [disabled]="myForm.pristine || !myForm.valid"
                aria-label="Salvar">
          <mat-icon>
            check
          </mat-icon>
        </button>
      </div>
      <div>
        <button type="button" mat-mini-fab color="warn" (click)="onCancel()" class="float-right" aria-label="Cancelar"
                matTooltip="Cancelar">
          <mat-icon>
            close
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</form>
