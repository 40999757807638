import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {AdUserDaoService} from './ad-user-dao.service';
import {AdUserListComponent} from './ad-user-list/ad-user-list.component';
import {AdUserCreateComponent} from './ad-user-create/ad-user-create.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {AdUSerPasswordUpdateComponent} from './ad-user-password-update/ad-user-password-update.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';


const routes = [
    {
        path: 'ad-user',
        component: AdUSerPasswordUpdateComponent,
        canActivate: [AuthService],
        data: {permissions: ['ADUser.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatIconModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatRadioModule
    ],
    providers: [
        AdUserDaoService
    ],
    declarations: [AdUserListComponent, AdUserCreateComponent, AdUSerPasswordUpdateComponent]
})
export class AdUserModule {
}
