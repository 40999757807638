<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="space-between center"
         style="z-index: 99;margin-top: 15px;">
        <div fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="mr-10 font-size-24">search</mat-icon>
            <span class="h1 m-0">Informações e logs </span>
        </div>
        <div>
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <div class="search" fxLayout="row" fxLayoutAlign="start center">
                    <div class="float-right mr-25">
                        <mat-form-field appearance="outline" style="width: 300px;">
                            <mat-label>Período</mat-label>
                            <mat-select placeholder="Período" [formControl]="periodFilterControl">
                                <mat-option *ngFor="let period of periodList" [value]="period">
                                    {{period.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="float-right">
                        <mat-form-field appearance="outline" style="width: 300px;margin-right: 20px;">
                            <mat-label>E-mail</mat-label>
                            <mat-select placeholder="E-mail"
                                        [formControl]="managerUsersFilterControl">
                                <mat-option [value]="0">
                                    Todos
                                </mat-option>
                                <mat-option *ngFor="let managerUser of adminService.managerUsers"
                                            [value]="managerUser.id">
                                    {{managerUser.email}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-left: 15px">
        <mat-tab-group animationDuration="0ms" disableRipple="true" (selectedTabChange)="onSelectOrderType($event)">
            <mat-tab label="Log de sessão">
                <app-session-log [period]="period" [managerUserIdSearch]="managerUserIdSearch"></app-session-log>
            </mat-tab>
           <!-- <mat-tab label="Acesso aos servidores">
                Log de acesso aos servidores
            </mat-tab>-->
        </mat-tab-group>


    </div>


</div>