<form [formGroup]="myForm"
      (submit)="!((myForm.pristine) || (!myForm.value.id && !myForm.valid)) && onSubmit()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">
        {{adUserPoolRelationship?.id ? 'Edição' : 'Criação'}} de Usuário Pool de acesso R2 Cloud Serviço
          {{adUserPoolRelationship?.id ? adminService.getAdUserPoolService(adUserPoolRelationship?.adUserPoolId).login : ''}}
      </span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

            <div fxLayout="row" fxLayoutAlign="space-between start">
                <div fxLayout="column" fxLayoutAlign="space-between stretch"
                     style="width: 600px;border-right: solid 1px #ccc;padding-right: 25px;">
                    <mat-form-field appearance="outline"
                                    *ngIf="getADDomainsWithADUserPool() && getADDomainsWithADUserPool().length > 1"
                                    [hidden]="adUserPoolRelationship?.id">
                        <mat-label>Domínio</mat-label>
                        <mat-select placeholder="Domínio" formControlName="adDomainForm">
                            <mat-option *ngFor="let adDomain of getADDomainsWithADUserPool()" [value]="adDomain.id">
                                {{adDomain.name}} | {{adDomain.domainName}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{myForm.get('adDomainForm').errors | error}}
                        </mat-error>
                    </mat-form-field>
                    <div
                        *ngIf="!(!(canCreateANewADUserPoolOnCurrentADDomain() || adUserPoolRelationship?.id !== null) && currentADDomain)">
                        <mat-form-field appearance="outline" class="mb-10 w-550">
                            <mat-label>Usuários permitidos</mat-label>
                            <mat-select placeholder="Usuários que terão acesso" (click)="markAsTouchedAndDirty()"
                                        formControlName="managerUserServiceIds" multiple>
                                <mat-option *ngFor="let managerUser of managerUsers" [value]="managerUser.id">
                                    {{managerUser.email}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    <div *appIfPermission="appCanCreateList" class="mb-30">
                        <div class="mt-15 mb-15" *ngIf="adUserPoolRelationship?.id">
                            <div class="font-weight-900" *ngIf="adUserPw !=''">Senha:</div>
                            <div *ngIf="adUserPw ==''">
                                <button type="button" mat-raised-button
                                        (click)="findByLoginPw(adminService.getAdUserPoolService(adUserPoolRelationship?.adUserPoolId))">
                                    Mostrar senha
                                </button>
                            </div>
                            <div *ngIf="adUserPw !=''">
                                {{adUserPw}}
                            </div>

                        </div>
                    </div>
                    </div>
                    <h3 *ngIf="!(canCreateANewADUserPoolOnCurrentADDomain() || adUserPoolRelationship?.id !== null)"
                        class="ml-5 warn p-10">
                        Não existem usuários disponíveis neste dominio.
                    </h3>
                </div>

                <div style="padding-right: 30px;">
                    <div *ngFor="let adDomain of getADDomainsWithADUserPool();">
                        <div class="font-weight-900" style="margin-top: 15px;">
                            ({{adDomain.name}}) Usuários:
                        </div>
                        <div>{{this.getNumberOfADUsersPoolUsedForThisADDomain(adDomain.id)}}
                            /{{getTotalADUserPoolByADDomainId(adDomain.id)}}</div>
                    </div>
                </div>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

            <div matTooltip="Salvar">
               <button type="submit" mat-raised-button color="accent"
                        [disabled]="myForm.pristine || !myForm.valid || !(canCreateANewADUserPoolOnCurrentADDomain() || adUserPoolRelationship?.id)"
                        aria-label="Salvar">
                    {{adUserPoolRelationship?.id ? 'Salvar' : 'Criar'}}
                </button>
            </div>

            <div *ngIf="adUserPoolRelationship?.id">
                <button type="button" mat-raised-button color="warn" (click)="onRemove()">Excluir</button>
            </div>
            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

            </div>
        </div>
    </div>
</form>
