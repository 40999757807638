<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>Últimos ManagerUsers</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content  class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th style="width: 300px"></th>
                <th></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let obj of managerUsersSnapshots">
                <td>
                    <div>
                        <div><b>{{obj.state.email}}</b></div>
                        <div class="font-size-12"><b>customerID:</b> {{obj.state.customer?.cdoId}}
                        </div>
                        <div class="font-size-12"><b>por:</b> {{obj.commitMetadata?.author}}</div>
                        <div class="font-size-12">{{obj.commitMetadata?.commitDate}}</div>
                    </div>
                    <div *ngIf="obj.state.customer">
                        <ul *ngIf="statusServer.getCustomer(obj.state.customer.cdoId, customers) as customer">
                            <li>EmpresaID:
                                <b>{{statusServer.getCustomerGroup(customer?.customerGroupId, customerGroups)?.id}}</b>
                            </li>
                            <li>Empresa:
                                <b>{{statusServer.getCustomerGroup(customer?.customerGroupId, customerGroups)?.name}}</b>
                            </li>
                            <li>Filial: <b>{{customer?.name}}</b></li>
                        </ul>
                    </div>
                    {{obj.commitMetadata.commitDate | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>