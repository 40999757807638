import {Injectable} from '@angular/core';
import {UserAuthorityDaoService} from './main/user-authority/user-authority-dao.service';
import {ManagerUserPermissionsDaoService} from './main/manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {Observable} from 'rxjs';
import {DataCenterDAOService} from './main/data-center/data-center-dao';
import {ClientSettingsDaoService} from './main/desktop/client-settings-dao.service';
import {PermissionDAOService} from './main/iam/permission-dao.service';

@Injectable({
    providedIn: 'root'
})
export class CacheControlService {

    constructor(private userAuthorityDao: UserAuthorityDaoService,
                private managerUserPermissionsDao: ManagerUserPermissionsDaoService,
                private dataCenterDAOService: DataCenterDAOService,
                private clientSettingsDaoService: ClientSettingsDaoService,
                private permissionDAOService: PermissionDAOService) {
    }

    public setCacheObservable(ob: Observable<any>): void {
        this.userAuthorityDao.setCacheControl(ob);
        this.managerUserPermissionsDao.setCacheControl(ob);
        this.dataCenterDAOService.setCacheControl(ob);
        this.clientSettingsDaoService.setCacheControl(ob);
        this.permissionDAOService.setCacheControl(ob);
    }
}
