<!--<div *ngFor="let remoteApp of remoteAppHtml5List">-->
    <!--<img class="remote-app-img" width="32" height="32" [src]="'data:image/' + guessImageMime(remoteApp.imageBase64) + ';base64,' + remoteApp.imageBase64" />-->
<!--</div>-->

<app-customer-group-tool-bar [customStyle]="customStyle"></app-customer-group-tool-bar>
<div fxLayout="row wrap">
    <ng-container *ngFor="let remoteAppWrapper of remoteAppHtml5List">
        <div *ngIf="remoteAppWrapper.remoteAppId && remoteAppWrapper.status !== 'DELETING'"
             [@fadeIn]
             [title]="remoteAppWrapper?.remoteAppId"
             matTooltip="Servidor desconectado, o aplicativo talvez não abra"
             [matTooltipDisabled]="remoteAppWrapper.status === 'READY' || remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'"
             appDebounceClick
             (click)="onOpen(remoteAppWrapper)"
             [class.remote-app-error]="remoteAppWrapper.status === 'SERVER_NOT_CONNECTED'"
             [class.remote-app-ok]="remoteAppWrapper.status === 'READY' || remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'"
             class="m-14 remote-app" fxLayout="column" fxLayoutAlign="center center">
            <img [class.remote-app-image-error]="remoteAppWrapper.status === 'SERVER_NOT_CONNECTED'"
                 [class.remote-app-image-warn]="remoteAppWrapper.status === 'CLIENT_NOT_CONNECTED'  || remoteAppWrapper.status === 'PREPARING'"
                 [src]=imageFileService.getRemoteAppPictureUrl(remoteAppWrapper.imageUUID)
                 width="64" height="64">
            <div class="remote-app-name mt-8">{{remoteAppWrapper.appName}}</div>
        </div>
    </ng-container>
</div>