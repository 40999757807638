import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {InstanceScheduleDashboardComponent} from './instance-schedule-dashboard/instance-schedule-dashboard.component';
import {MatDividerModule} from '@angular/material/divider';
import {InstanceSnapshotDashboardComponent} from './instance-snapshot-dashboard/instance-snapshot-dashboard.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path: 'instance-snapshot-dashboard',
        component: InstanceSnapshotDashboardComponent,
        canActivate: [AuthService],
        data: {permissions: ['Instance.READ']}
    },
    {
        path: 'instance-schedule-dashboard',
        component: InstanceScheduleDashboardComponent,
        canActivate: [AuthService],
        data: {permissions: ['InstanceSchedule.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        NgxDatatableModule,
        MatCheckboxModule,
        MatCardModule,
        MatButtonModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule

    ],
    declarations: [InstanceSnapshotDashboardComponent, InstanceScheduleDashboardComponent]
})
export class InstanceModule {
}
