import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ValueWrapper} from '../../../helpers/value-wrapper';
import {LinuxServer} from '../../../models';
import {MatDialogRef} from '@angular/material/dialog';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {convertToFormGroup} from '../../../helpers/kluh';
import {LinuxServerDaoService} from '../linux-server-dao.service';
import {DomainPathService} from '../../../domain-path/domain-path.service';

@Component({
    selector: 'app-linux-server-create-token',
    templateUrl: './linux-server-create-token.component.html',
    styleUrls: ['./linux-server-create-token.component.scss']
})
export class LinuxServerCreateTokenComponent implements OnInit {


    myForm: FormGroup;
    token: ValueWrapper;
    script: string;
    copied: string;
    linuxServer: LinuxServer = null;

    constructor(public dialogRef: MatDialogRef<LinuxServerCreateTokenComponent>,
                public adminService: R2CloudAdminService,
                private fb: FormBuilder,
                private linuxServerDaoService: LinuxServerDaoService,
                private domainPathService: DomainPathService,
    ) {
    }

    ngOnInit(): void {

        this.linuxServer = {
            clientId: '',
            publicIp: '',
            privateIp: '',
            hostname: '',
            connectionFQDN: '',
            firewallFQDNIds: [],
            connectionPort: null,
            id: null,
            comment: '',
            active: true,
            instanceId: null,
            modified: null,
            optlock: null
        };
        this.myForm = this.fb.group(convertToFormGroup(this.linuxServer));

        this.generateInstallToken();


    }

    generateInstallToken(): void {
        this.linuxServerDaoService.generateInstallToken(this.linuxServer, this.adminService.subProject.id).subscribe((token) => {
            if (token) {
                this.token = token;
                this.script =
                    `curl ${this.domainPathService.staticURL}script/install-agent-linux.sh | bash -s -- -i ${this.token.value} ${this.domainPathService.domainPath.secure} ${this.domainPathService.domainPath.apiDomain} ${this.domainPathService.domainPath.monitoringDomain}`;

            }
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onCopyScript(value: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.copied = 'Copiado';
        setTimeout(() => {
            this.dialogRef.close();
        }, 1000);
    }
}