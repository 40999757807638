<mat-card>
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Domínios da empresa</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="adDomains && adDomains.length > 0">

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Domínio</mat-label>
            <mat-select placeholder="Domínio" [formControl]="adDomainFormControl">
                <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                    {{adDomain.domainName}} | {{adDomain.name}} | {{getDesktopServerHostname(adDomain.adServerId)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </mat-card-content>
</mat-card>

<mat-card class="mt-26 user-pool">
    <mat-card-header style="padding: 2px 0 0 0;">
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 7px;">


            <div class="ml-15"  fxLayout="row" fxLayoutAlign="start center">
                <div>Usuários do Pool</div>
                <div class="mat-subheading-2 pl-20 mb-0" *ngIf="(filteredADUsersPool && filteredADUsersPool.length > 0)">
                    {{amountOfADUserPoolService()}} de serviços | {{amountOfADUserPoolApp()}} de App | {{filteredADUsersPool.length}} usuários totais
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">


                <div *ngIf="this.currentADDomain?.domainName" style="margin-bottom: 5px;">
                    <button type="button" mat-raised-button color="accent" [matMenuTriggerFor]="menu"
                            aria-label="open menu">
                        Criar
                    </button>
                    <mat-menu #menu="matMenu" style="max-width: 372px">
                        <button mat-menu-item (click)="openADUserPoolCreateModal(false)">
                            <mat-icon>add</mat-icon>
                            <span>Usuário aplicação</span>
                        </button>
                        <button mat-menu-item (click)="openADUserPoolCreateModal(true)">
                            <mat-icon>add</mat-icon>
                            <span>Usuário serviço</span>
                        </button>
                    </mat-menu>
                </div>

                <mat-form-field appearance="outline" class="ml-25" style="height: 66px;width: 130px;">
                    <mat-label>Mostrar</mat-label>
                    <mat-select placeholder="Tipo de usuário" [formControl]="selectUserTypeFormControl">
                        <mat-option [value]="adUserPoolType.ALL">Todos</mat-option>
                        <mat-option [value]="adUserPoolType.USER_SERVICE">Serviço</mat-option>
                        <mat-option [value]="adUserPoolType.USER_APP">Aplicação</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="no-padding">

        <table *ngIf="(filteredADUsersPool && filteredADUsersPool.length > 0)"
               class="app-table" border="0" cellspacing="0">
            <tr *ngFor="let adUserPool of filteredADUsersPool">
                <td class="w-30">
                    <mat-icon *ngIf="adUserPool.serviceUser" title="Serviço" class="mat-icon material-icons" role="img"
                              aria-hidden="true">room_service
                    </mat-icon>
                </td>
                <td class="w-680">
                    {{adUserPool.login}}
                </td>
                <td class="w-30">
                    <div *ngIf="adUserPool.changing || adUserPool.deleting">
                        <mat-icon *ngIf="adUserPool.changing" title="Criando..." color="accent">add_circle</mat-icon>
                        <mat-icon *ngIf="adUserPool.deleting" title="Deletando..." color="warn">remove_circle</mat-icon>
                    </div>
                </td>
                <td class="text-align-right p-0">
                    <div fxLayout="row" fxLayoutAlign="end start">

                        <div class="mr-10">
                            <button type="button" mat-stroked-button color="accent" class="mat-green-600-bg "
                                    (click)="openADUserPoolInfoModal(adUserPool)">Info
                            </button>
                        </div>
                        <div *appIfPermission="appCanCreateList" class="mr-10">
                            <button *ngIf="(!adUserPool.changing && !adUserPool.deleting)" type="button"
                                    mat-stroked-button
                                    color="warn" class="mat-green-600-bg"
                                    (click)="openADUserPoolDeleteModal(adUserPool)">Delete
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
        </table>


    </mat-card-content>
</mat-card>
