import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataCenterRouterDAOService} from './data-center-router-dao.service';
import * as _ from 'lodash';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataCenterRouterWanDaoService} from './data-center-router-wan-dao.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {dyndnsValidator} from '../../../../validators/dyndns-validator';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {DataCenter, Router, RouterType, VpnConfig, Wan, WanProvider, WanTechnology} from '../../../../models';
import {convertToFormGroup} from '../../../../helpers/kluh';

@Component({
    selector: 'app-data-center-router',
    templateUrl: './data-center-router.component.html',
    styleUrls: ['./data-center-router.component.scss'],
    animations: fuseAnimations
})
export class DataCenterRouterComponent extends ComponentCleaner implements OnInit {
    /*private wansToDelete: number[] = [];*/
    dataCenter: DataCenter;
    router: Router;
    wans: Wan[];
    myForm: FormGroup;
    myWanForm: FormArray;
    @Output() modelEditingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input()
    projectName: string;
    editing: boolean;
    vpnConfig: VpnConfig;
    wanTechnologyList: WanTechnology[];
    wanProviderList: WanProvider[];
    routerTypeList: RouterType[];
    routerType: RouterType;

    constructor(private fb: FormBuilder,
                private routerDao: DataCenterRouterDAOService,
                private dataCenterDao: DataCenterRouterDAOService,
                private wanDao: DataCenterRouterWanDaoService) {
        super();
    }

    ngOnInit(): void {
    }

    @Input()
    set modelDataCenter(input: any) {
        this.dataCenter = input;
        this.getRouter();
    }

    private getRouter(): void {
        const subscription = this.dataCenterDao.filterOne({dataCenterId: this.dataCenter.id}).subscribe((router) => {
            this.setRouter(router);
        });
        this.addSubscription(subscription);
    }

    private setRouter(router: Router): void {
        this.router = router;
        if (this.router) {
            const subscription = this.wanDao.filter({routerId: this.router.id}).subscribe((wans) => {
                this.wans = wans;
            });
            this.addSubscription(subscription);
        }
    }

    @Input()
    set modelEditing(input: boolean) {
        this.editing = input;
        this.setForm();
    }

    private setForm(): void {
        if (this.router && this.router.id && this.vpnConfig && this.vpnConfig.id) {
            const subscription1 = this.routerDao.getRouterTypes().subscribe((routerTypes) => {
                this.routerTypeList = routerTypes;
            });
            this.addSubscription(subscription1);
            this.myForm = this.fb.group(convertToFormGroup(this.router), {asyncValidator: this.routerDao.validator});
            this.myForm.get('dyndns').setValidators([Validators.required, dyndnsValidator]);
            // const addressControl = this.myForm.get('address');
            // const networkBitsControl = this.myForm.get('networkBits');
            // const lanDHCPControl = this.myForm.get('lanDHCP');
            // addressControl.setValidators([Validators.required, ipValidator]);
            // networkBitsControl.setValidators([Validators.required, Validators.min(this.vpnConfig.networkPrefixBits + 1), Validators.max(30)]);
            // const setDHCPRangeValidator = () => {
            //   const lanDHCP = lanDHCPControl.value;
            //   const ipAddress = addressControl.value;
            //   const networkBits = networkBitsControl.value;
            //   const lanDHCPRangeControl = this.myForm.get('lanDHCPRange');
            //   if (lanDHCP && networkBitsControl.errors === null && addressControl.errors === null) {
            //     lanDHCPRangeControl.setValidators(null);
            //     lanDHCPRangeControl.setValidators([Validators.required, lanDHCPRangeValidator(ipAddress, networkBits)]);
            //   } else {
            //     lanDHCPRangeControl.setValidators(null);
            //   }
            //   lanDHCPRangeControl.updateValueAndValidity({emitEvent: true});
            // };
            // addressControl.valueChanges.merge(networkBitsControl.valueChanges).merge(lanDHCPControl.valueChanges).subscribe(setDHCPRangeValidator);
            // setDHCPRangeValidator();
            const lanDNSControl = this.myForm.get('lanDNS');
            const setRemoteDNSListValidator = (value) => {
                const remoteDNSListControl = this.myForm.get('remoteDNSList');
                if (value && remoteDNSListControl.disabled) {
                    remoteDNSListControl.enable();
                    remoteDNSListControl.updateValueAndValidity({emitEvent: true});
                } else if (!value && remoteDNSListControl.enabled) {
                    remoteDNSListControl.disable();
                }
                //
            };
            const subscription = lanDNSControl.valueChanges.subscribe(setRemoteDNSListValidator);
            this.addSubscription(subscription);
            // setRemoteDNSListValidator(lanDNSControl.value.takeUntil(this.destroy$));
        }
    }

    @Input()
    set modelVpnConfig(input: any) {
        this.vpnConfig = input;
        this.setForm();
    }

    onCreate(): void {
        const router: Router = {
            id: null,
            comment: null,
            active: true,
            remoteDNSList: '8.8.4.4, 8.8.8.8',
            address: '192.168.0.1',
            networkBits: 24,
            lanDHCP: true,
            lanDHCPRange: '192.168.0.100-192.168.0.200',
            lanDNS: true,
            hasVPN: false,
            dyndns: _.kebabCase(this.dataCenter.name) + '.' + _.kebabCase(this.projectName) + '.dyndns.kluh.com.br',
            routerType: RouterType.Mikrotik,
            dataCenterId: this.dataCenter.id,
            instanceId: null,
            modified: null,
            optlock: null
        };
        const subscription = this.routerDao.create(router).subscribe((newRouter) => {
            this.router = newRouter;
            this.editing = true;
            this.setForm();
        });
        this.addSubscription(subscription);
    }

    onSubmit(): void {
        const subscription = this.routerDao.save(this.myForm.value).subscribe((router) => {
            this.setRouter(router);
            this.onFinishEditing();
        });
        this.addSubscription(subscription);
        // const $obs: Observable<any>[] = [];
        // if (this.myForm && this.myForm.dirty) {
        //   const router: Router = this.myForm.value;
        //   $obs.push(this.dataCenterDao.saveWithDataCenterId(router, this.dataCenter.id).map((result) => {
        //     this.router = result;
        //     return this.dataCenterDao.saveTypeOfRouter(this.router.id, this.myForm.get('routerType').value);
        //   }).concatAll());
        // }
        // if (this.myWanForm) {
        //   for (const wanForm of this.myWanForm.controls) {
        //     if (wanForm.dirty) {
        //       let $wan;
        //       if (wanForm.value.id) {
        //         $wan = this.dataCenterDao.saveWanWithRouterId(wanForm.value, this.router.id);
        //       } else {
        //         $wan = this.dataCenterDao.createWanWithRouterId(wanForm.value, this.router.id);
        //       }
        //       $obs.push($wan.map((result) => {
        //         return this.dataCenterDao.saveTechnologyOfWan(result.id, wanForm.get('wanTechnology').value).map(() => {
        //           return this.dataCenterDao.saveProviderOfWan(result.id, wanForm.get('wanProvider').value);
        //         }).concatAll();
        //       }).concatAll());
        //     }
        //   }
        // }
        // for (const wanId of this.wansToDelete) {
        //   $obs.push(this.dataCenterDao.removeWan(wanId));
        // }
        // Observable.zip(...$obs).subscribe(() => {
        //   this.getRouter();
        //   this.onFinishEditing();
        // });
    }

    onCancel(): void {
        // this.setForm();
        // this.setWanForms();
        // this.wansToDelete = [];
        this.onFinishEditing();
    }

    onFinishEditing(): void {
        this.editing = false;
        this.modelEditingChange.emit(this.editing);
    }

    // onAddWan(): void {
    //   if (this.myWanForm) {
    //     const index = this.myWanForm.length + 1;
    //     const wan: Wan = {
    //       id: null,
    //       comment: null,
    //       active: true,
    //       // priority: index,
    //       // vpnPriority: index,
    //       // interfaceName: 'wan' + index,
    //       // originalInterfaceName: 'ether' + index,
    //       // hasVPN: false,
    //       // pppoE: false,
    //       // pppoEUserName: null,
    //       // pppoEPassword: null,
    //       // dhcp: true,
    //       // dyndns: null,
    //       // useDNS: false,
    //       // address: null,
    //       // networkBits: null,
    //       // gateway: null,
    //     };
    //     const wanForm = this.setWanForm(wan);
    //     wanForm.markAsDirty();
    //     this.myWanForm.push(wanForm);
    //     setTimeout(() => {
    //       this.myWanForm.markAsDirty();
    //       this.myWanForm.updateValueAndValidity();
    //     });
    //     // this.myWanForm.controls[this.myWanForm.length - 1].markAsDirty();
    //   }
    // }

    // onDeleteWan(index: number): void {
    //   const wanForm = this.myWanForm.at(index);
    //   if (wanForm.value.id) {
    //     this.wansToDelete.push(wanForm.value.id);
    //   }
    //   setTimeout(() => {
    //     this.myWanForm.removeAt(index);
    //     this.myWanForm.markAsDirty();
    //     this.myWanForm.updateValueAndValidity();
    //   });
    // }

    // findWanProvider(wan: Wan): WanProvider {
    //   const wanHelper = this.findWanHelper(wan);
    //   if (wanHelper) {
    //     return wanHelper.wanProvider;
    //   }
    //   return null;
    // }

    // findWanTechnology(wan: Wan): WanTechnology {
    //   const wanHelper = this.findWanHelper(wan);
    //   if (wanHelper) {
    //     return wanHelper.wanTechnology;
    //   }
    //   return null;
    // }

    // private findWanHelper(wan: Wan): WanHelper {
    //   return this.wanHelpers.find(x => x.wanId === wan.id);
    // }

    // private setWanForms(): void {
    //   this.myWanForm = this.fb.array([]);
    //   for (const wan of this.wans) {
    //     const wanForm = this.setWanForm(wan);
    //     this.myWanForm.push(wanForm);
    //   }
    // }

    // private setWanForm(wan: Wan): FormGroup {
    // const formGroup = this.fb.group(wan);
    // formGroup.get('dyndns').setValidators([Validators.required, dyndnsLinkValidator, uniqueWanValidator('dyndns')]);
    // formGroup.get('interfaceName').setValidators([Validators.pattern(/wan[1-9]+/), uniqueWanValidator('interfaceName')]);
    // formGroup.get('originalInterfaceName').setValidators([Validators.pattern(/(ether|wlan)[1-9]+/), uniqueWanValidator('originalInterfaceName')]);
    // formGroup.get('priority').setValidators([Validators.required, uniqueWanValidator('priority')]);
    // const setDhcpValidators = (value) => {
    //   for (const key of ['address', 'gateway']) {
    //     if (value) {
    //       formGroup.get(key).setValidators(null);
    //     } else {
    //       formGroup.get(key).setValidators([Validators.required, ipValidator]);
    //     }
    //     formGroup.get(key).updateValueAndValidity();
    //   }
    //   if (value) {
    //     formGroup.get('networkBits').setValidators(null);
    //   } else {
    //     formGroup.get('networkBits').setValidators([Validators.min(0), Validators.max(32)]);
    //   }
    //   formGroup.get('networkBits').updateValueAndValidity();
    // };
    // formGroup.get('dhcp').valueChanges.subscribe(setDhcpValidators);
    // setDhcpValidators(formGroup.get('dhcp').value);
    // const setPPPoEValidators = (value) => {
    //   for (const key of ['pppoEUserName', 'pppoEPassword']) {
    //     if (value) {
    //       formGroup.get(key).setValidators([Validators.required]);
    //     } else {
    //       formGroup.get(key).setValidators(null);
    //     }
    //     formGroup.get(key).updateValueAndValidity();
    //   }
    // };
    // formGroup.get('pppoE').valueChanges.subscribe(setPPPoEValidators);
    // setPPPoEValidators(formGroup.get('pppoE').value);
    // const setVpnPriorityValidator = (value) => {
    //   const vpnPriorityControl = formGroup.get('vpnPriority');
    //   if (value) {
    //     vpnPriorityControl.setValidators([Validators.required, uniqueWanValidator('vpnPriority')]);
    //   } else {
    //     vpnPriorityControl.setValidators(null);
    //   }
    //   vpnPriorityControl.updateValueAndValidity();
    // };
    // formGroup.get('hasVPN').valueChanges.subscribe(setVpnPriorityValidator);
    // setVpnPriorityValidator(formGroup.get('hasVPN').value);
    // if (wan.id) {
    //   this.dataCenterDao.getWanTechnologyByWanId(wan.id).subscribe((wanTechnology) => {
    //     this.dataCenterDao.getWanProviderByWanId(wan.id).subscribe((wanProvider) => {
    //       const wanHelper: WanHelper = {
    //         wanId: wan.id,
    //         wanProvider: wanProvider,
    //         wanTechnology: wanTechnology
    //       };
    //       this.wanHelpers.push(wanHelper);
    //       formGroup.addControl('wanTechnology', this.fb.control(wanHelper.wanTechnology.id, [Validators.required]));
    //       formGroup.addControl('wanProvider', this.fb.control(wanHelper.wanProvider.id, [Validators.required]));
    //     });
    //   });
    // } else {
    //   formGroup.addControl('wanTechnology', this.fb.control('', [Validators.required]));
    //   formGroup.addControl('wanProvider', this.fb.control('', [Validators.required]));
    // }
    // return formGroup;
    // }

}
