import {Injectable} from '@angular/core';
import {Image, ManagerUser, ManagerUserOnline, ManagerUserSimple, ManagerUserStatistic} from '../../models';
import {DomainPathService} from '../../domain-path/domain-path.service';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {ValueWrapper} from '../../helpers/value-wrapper';


@Injectable({
    providedIn: 'root'
})
export class ImageFileService {


    constructor(private domainPathService: DomainPathService,
                private http: R2CloudHttpApiService) {
    }

    private readonly basePath: string;
    private readonly managerUserPath = 'manager-user';
    private readonly customerPath = 'customer';
    private readonly customerGroupPath = 'customer-group';
    private readonly remoteAppPath = 'remote-app';
    private readonly marketPlaceProductAvatarPath = 'market-place-product-avatar';
    private readonly marketPlaceProductBannerPath = 'market-place-product-banner';
    private readonly marketPlaceProductScreenPath = 'market-place-product-screen';
    private readonly softwareCompanyPath = 'software-company';

    private static resolvePath(id: number | string, path: string): string {
        return `image/${path}/${id}`;
    }

    private resolvePathWithDomain(id: number | string, path: string): string {
        if (!id) {
            return '/assets/images/avatars/profile.jpg';
        }
        return `${this.domainPathService.apiURL}${ImageFileService.resolvePath(id, path)}`;
    }

    getPictureFromPath(id: string, path: string, size: number = 0): string {
        const sizeQuery = size > 0 ? `?w=${size}&square=true` : '';
        return `${this.resolvePathWithDomain(id, path)}${sizeQuery}`;
    }

    savePicture(id: number, path: string, data: any): Observable<ValueWrapper> {
        return this.http.post<ValueWrapper>(ImageFileService.resolvePath(id, path), {data: data} as Image);
    }

    getManagerUserPicture(managerUser: ManagerUser | ManagerUserOnline | ManagerUserStatistic | ManagerUserSimple): string {
        let url: string;
        if ((managerUser as ManagerUserOnline)?.imageUUID) {
            url = this.getManagerUserPictureUrl((managerUser as ManagerUserOnline)?.imageUUID);
        } else if (managerUser?.picture) {
            url = managerUser.picture;
        } else {
            url = this.getManagerUserPictureUrl((managerUser as ManagerUser)?.imageUUID);
        }
        return url;
    }

    getManagerUserPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/profile.jpg';
        }
        return this.getPictureFromPath(imageUUID, this.managerUserPath, size);
    }

    saveManagerUserPicture(managerUserId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(managerUserId, this.managerUserPath, data);
    }

    getCustomerPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/tenant.png';
        }
        return this.getPictureFromPath(imageUUID, this.customerPath, size);
    }

    saveCustomerPicture(customerId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(customerId, this.customerPath, data);
    }

    getCustomerGroupPictureUrl(imageUUID: string, size: number = 0): string {
        if (!imageUUID) {
            return '/assets/images/avatars/tenant.png';
        }
        return this.getPictureFromPath(imageUUID, this.customerGroupPath, size);
    }

    saveCustomerGroupPicture(customerGroupId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(customerGroupId, this.customerGroupPath, data);
    }

    getRemoteAppPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.remoteAppPath, size);
    }

    saveRemoteAppPicture(remoteAppId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(remoteAppId, this.remoteAppPath, data);
    }

    getMarketPlaceProductAvatarPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductAvatarPath, size);
    }

    saveMarketPlaceProductAvatarPicture(marketPlaceProductId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductId, this.marketPlaceProductAvatarPath, data);
    }

    getMarketPlaceProductBannerPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductBannerPath, size);
    }

    saveMarketPlaceProductBannerPicture(marketPlaceProductId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductId, this.marketPlaceProductBannerPath, data);
    }

    getMarketPlaceProductScreenPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.marketPlaceProductScreenPath, size);
    }

    saveMarketPlaceProductScreenPicture(marketPlaceProductScreenId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(marketPlaceProductScreenId, this.marketPlaceProductScreenPath, data);
    }

    getSoftwareCompanyPictureUrl(imageUUID: string, size: number = 0): string {
        return this.getPictureFromPath(imageUUID, this.softwareCompanyPath, size);
    }

    saveSoftwareCompanyPicture(softwareCompanyId: number, data: any): Observable<ValueWrapper> {
        return this.savePicture(softwareCompanyId, this.softwareCompanyPath, data);
    }

    // getPictureUrlFromEntity(imageFileId: number): string {
    //     let url = '';
    //     if (imageFileId) {
    //         url = this.domainPathService.apiURL + 'image-file/p/' + imageFileId;
    //     } else {
    //         url = '/assets/images/avatars/profile.jpg';
    //     }
    //     return url;
    // }

    getPictureUrlFromImageFileTemplateId(imageFileTemplateId: number, width?: number | null): string {
        let url = '';
        if (imageFileTemplateId) {
            url = this.domainPathService.customLayoutURL + 'image-file-template/public/' + imageFileTemplateId;
            if (width) {
                url = url + '?w=' + width + '&square=true';
            }
        } else {
            url = '/assets/images/avatars/profile.jpg';
        }
        return url;
    }

    // initImageFile(): ImageFile {
    //     return {
    //         id: null,
    //         active: true,
    //         comment: null,
    //         image: null
    //     };
    // }
}
