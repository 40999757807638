import {Component, Inject, OnInit} from '@angular/core';

import {InstanceDaoService} from '../instance-dao.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Instance, SnapshotPolicyType} from '../../../../../models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-instance-edit',
  templateUrl: './instance-edit.component.html',
  styleUrls: ['./instance-edit.component.scss']
})
export class InstanceEditComponent implements OnInit {
  instance: Instance;
  snapshotPolicyTypes: SnapshotPolicyType[];
  myForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<InstanceEditComponent>,
              private fb: FormBuilder,
              private instanceDao: InstanceDaoService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.instance = data.instance;
    this.snapshotPolicyTypes = data.snapshotPolicyTypes;
    const selected: SnapshotPolicyType[] = [];
    for (const snapshotPolicyType of data.snapshotPolicy.snapshotPolicyTypes) {
      const valueToAdd = this.snapshotPolicyTypes.find((x) => x === snapshotPolicyType);
      if (valueToAdd) {
        selected.push(valueToAdd);
      }
    }
    this.myForm = new FormGroup({
      instanceId: new FormControl(data.snapshotPolicy.instanceId),
      snapshotPolicyTypes: new FormControl(selected)
    });
  }

  ngOnInit(): void {
  }

  onSave(): void {
    if (this.myForm.dirty && this.myForm.valid) {
      this.instanceDao.saveSnapshotPolicy(this.myForm.value).subscribe((snapshotPolicy) => {
        this.dialogRef.close({snapshotPolicy: snapshotPolicy});
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onTakeSnapshot(): void {
    this.instanceDao.takeSnapshot(this.instance.id).subscribe();
  }

  printSnapshotPolicyType(snapshotPolicyType: SnapshotPolicyType): string {
    switch (snapshotPolicyType){
      case 'THREE_HOUR_BUSINESS':
        return 'A cada 3 horas';
      case 'DAILY':
        return 'Diário';
      case 'WEEKLY':
        return 'Semanal';
      case 'MONTHLY':
        return 'Mensal';
    }
  }

}
