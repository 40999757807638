import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {USER_TOPIC} from '../../../../main';
import {DownloadFileDaoService} from './download-file-dao.service';
import {DownloadFileEvent} from '../../models';
import {Subscription} from 'rxjs/internal/Subscription';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class DownloadFileService implements OnDestroy {
    private onDestroy$ = new Subject();
    private readonly stompSubscription: Subscription;

    constructor(private stomp: R2CloudStompService, private downloadFileDaoService: DownloadFileDaoService) {
        console.log('download-file: start');

        this.stompSubscription = this.stomp.stompTopic<DownloadFileEvent>(`${USER_TOPIC}/download-file`)
            .pipe(
                takeUntil(this.onDestroy$),
                filter(e => !!e && e.id > 0))
            .subscribe((event: DownloadFileEvent) => {
                this.downloadFileDaoService.downloadById(event.id).subscribe(downloadFile => {
                    if (downloadFile) {
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(downloadFile);
                        link.download = event.name;
                        link.click();
                    }
                });
            });
    }

    stompUnsubscribe(): void {
        // if (this.stompSubscription) {
        //     this.stompSubscription.unsubscribe();
        // }
        // console.log('download-file: closed');
    }

    ngOnDestroy(): void {
        this.stompUnsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
