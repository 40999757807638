import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {DesktopServer, ServerStatus, ServerStatusType, SubProject} from '../../../../models';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {SubProjectDaoService} from '../../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {DesktopServerStatusService} from '../../../../ws/desktop-server-status.service';
import {R2CloudClientService} from '../../../../ws/r2-cloud-client.service';
import {faWindows} from '@fortawesome/free-brands-svg-icons';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-desktop-server-support-card',
    templateUrl: './desktop-server-support-card.component.html',
    styleUrls: ['./desktop-server-support-card.component.scss'],
    animations: fuseAnimations
})
export class DesktopServerSupportCardComponent implements OnDestroy {
    public faWindows = faWindows;
    private _desktopServer: DesktopServer;
    public subProjects: SubProject[];
    private serverStatus: ServerStatus;
    private subscription: Subscription;

    constructor(
        private subProjectDao: SubProjectDaoService,
        private clientStatus: R2CloudClientService,
        private desktopServerStatusService: DesktopServerStatusService
    ) {

    }

    @Input()
    set desktopServer(desktopServer: DesktopServer) {
        this._desktopServer = desktopServer;
        this.subscription = this.desktopServerStatusService.onDesktopServerStatus(desktopServer.id).subscribe((result) => {
            this.serverStatus = result;
        });
        this.subProjectDao.filter({'desktopServerId': this.desktopServer.id}).subscribe((results) => {
            this.subProjects = results;
        });
    }

    get desktopServer(): DesktopServer {
        return this._desktopServer;
    }

    @Output()
    public close: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public connect: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public subProjectSelect: EventEmitter<SubProject> = new EventEmitter<SubProject>();

    public onClose(): void {
        this.close.emit();
    }

    public onSelectSubProject(subProject: SubProject): void {
        this.subProjectSelect.emit(subProject);
    }

    public onConnect(): void {
        this.connect.emit();
    }

    public isConnected(): boolean {
        const serverStatus = this.serverStatus;
        if (serverStatus){
            return serverStatus.status === ServerStatusType.CONNECTED;
        }
        return false;
    }

    public isClientConnected(): boolean {
        return this.clientStatus.isClientConnected();
    }

    ngOnDestroy(): void {
        if (this.subscription){
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }
}
