import {Component} from '@angular/core';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {ADDomain, ADGroup, ADUser, Customer, CustomerGroup, DesktopServerWithSubProject, ManagerUser, Project, RemoteApp, SubProject} from '../../models';
import {DesktopServerDaoService} from '../desktop-server/desktop-server-dao.service';
import {AdDomainDaoService} from '../ad-domain/ad-domain-dao.service';
import {SubProjectDaoService} from '../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {InstanceScheduleAuditDaoService} from '../support/data-center/instance/instance-schedule-audit/instance-schedule-audit-dao.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {AdUserDaoService} from '../ad-user/ad-user-dao.service';
import {AdGroupDaoService} from '../ad-group/ad-group-dao.service';
import {RemoteAppDaoService} from '../remote-app/remote-app-dao.service';
import {ComponentCleaner} from '../../component-cleaner';
import {BehaviorSubject, interval} from 'rxjs';
import {FormControl} from '@angular/forms';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {CustomerDaoService} from '../customer/customer-dao.service';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss']
})
export class StatusComponent extends ComponentCleaner {

    public adDomains: ADDomain[];
    public projects: Project[];
    public adGroups: ADGroup[];
    public desktopServerWithSubProjectIdsList$ = new BehaviorSubject<DesktopServerWithSubProject[]>([]);
    public remoteApps: RemoteApp[];
    public adUsers: ADUser[];
    public managerUsers: ManagerUser[];
    public customerGroups: CustomerGroup[];
    public customers: Customer[];
    public subProjects: SubProject[];
    public isOnlineData = true;
    public onlineDataControl = new FormControl(this.isOnlineData);

    constructor(private managerUserDao: ManagerUserDaoService,
                private desktopServerDaoService: DesktopServerDaoService,
                private adDomainDaoService: AdDomainDaoService,
                private subProjectDaoService: SubProjectDaoService,
                private instanceScheduleAuditDao: InstanceScheduleAuditDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private adUserDaoService: AdUserDaoService,
                private adGroupDaoService: AdGroupDaoService,
                private customerGroupDaoService: CustomerGroupDaoService,
                private customerDaoService: CustomerDaoService,
                private remoteAppDaoService: RemoteAppDaoService
    ) {
        super();
        this.loadDataInit();
        this.setOnlineData();
    }


    getSubProjects(): void {
        this.subProjectDaoService.get().subscribe((subProjects) => {
            this.subProjects = subProjects;
        });
    }

    getCustomerGroups(): void {
        this.customerGroupDaoService.getAllCache().subscribe((customerGroups) => {
            // this.customerGroupDaoService.get().subscribe((customerGroups) => {
            this.customerGroups = customerGroups;
        });
    }

    getAdGroups(): void {
        this.adGroupDaoService.get().subscribe((adGroups) => {
            this.adGroups = adGroups;
        });
    }

    getCustomers(): void {
        this.customerDaoService.get().subscribe((customers) => {
            this.customers = customers;
        });
    }

    getAdUsers(): void {
        this.adUserDaoService.get().subscribe((adUsers) => {
            this.adUsers = adUsers;
        });
    }

    getManagerUsers(): void {
        this.managerUserDao.get().subscribe((managerUsers) => {
            this.managerUsers = managerUsers;
        });
    }


    getRemoteApps(): void {
        this.remoteAppDaoService.get().subscribe((remoteApps) => {
            this.remoteApps = remoteApps;
        });
    }

    getAdDomains(): void {
        this.adDomainDaoService.get().subscribe((adDomains) => {
            this.adDomains = adDomains;
        });
    }

    loadDataInit(): void {
        this.getSubProjects();
        this.getCustomerGroups();
        this.getAdDomains();

        // this.getAdUsers();
        // this.getAdGroups();
        // this.getCustomers();
        // this.getManagerUsers();
        // this.getRemoteApps();

    }

    setOnlineData(): void {
        this.addSubscription(this.onlineDataControl.valueChanges.subscribe((isOnlineData) => {
            this.isOnlineData = isOnlineData;
        }));
        this.addSubscription(interval(300000).subscribe(() => {
            if (this.isOnlineData) {
                this.loadDataInit();
            }
        }));
    }

    onADUsersRead(adUsers: ADUser[]): void {
        if (adUsers) {
            console.log('onADUsersRead()');
            this.adUsers = adUsers;
        }
    }

    onADGroupsRead(adGroups: ADGroup[]): void {
        if (adGroups) {
            console.log('onADGroupsRead()');
            this.adGroups = adGroups;
        }
    }
}
