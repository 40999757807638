import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdDomainListComponent} from './ad-domain-list/ad-domain-list.component';
import {AdDomainCreateComponent} from './ad-domain-create/ad-domain-create.component';
import {AdDomainDaoService} from './ad-domain-dao.service';
import {RouterModule} from '@angular/router';
import {AuthService} from '../../auth.service';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';

import {AdDomainEditComponent} from './ad-domain-edit/ad-domain-edit.component';
import {AdDomainAndAdServerCreateComponent} from './ad-domain-and-ad-server-create/ad-domain-and-ad-server-create.component';
import {AdDomainWizardComponent} from './ad-domain-wizard/ad-domain-wizard.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {NgxMaskModule} from 'ngx-mask';

const routes = [
    {
        path: 'ad-domain',
        component: AdDomainListComponent,
        canActivate: [AuthService],
        data: {permissions: ['ADDomain.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatToolbarModule,
        MatDialogModule,
        MatRadioModule,
        MatSlideToggleModule,
        NgxMaskModule.forRoot()
    ],
    declarations: [AdDomainListComponent, AdDomainCreateComponent, AdDomainEditComponent, AdDomainWizardComponent, AdDomainAndAdServerCreateComponent],
    providers: [
        AdDomainDaoService
    ],
    entryComponents: [
        AdDomainCreateComponent, AdDomainWizardComponent
    ]
})
export class AdDomainModule {
}
