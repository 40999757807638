import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {DomainPathService} from '../../../domain-path/domain-path.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class TesteAuthDaoService extends BaseDao<any> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator,
                private domainPathService: DomainPathService) {
        super(http, validatorDAO, domainPathService.monitoringURL + 'hd-monitoring-configuration/test');
    }
}
