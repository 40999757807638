import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {R2CloudSupportComponent} from './r2-cloud-support-component/r2-cloud-support.component';
import {AuthService} from '../../../auth.service';
import {RouterModule} from '@angular/router';
import {KluhSharedModule} from '../../../kluh-shared.module';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {SubProjectSupportCardComponent} from './sub-project-support-card/sub-project-support-card.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DesktopServerSupportCardComponent} from './desktop-server-support-card/desktop-server-support-card.component';
import {LinuxServerSupportCardComponent} from './linux-server-support-card/linux-server-support-card.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

const routes = [
    {
        path: 'support',
        component: R2CloudSupportComponent,
        canActivate: [AuthService],
        // data: {roles: ['ROLE_ADMIN', 'ROLE_DESKTOP_ADMIN', 'ROLE_DESKTOP_SUPPORT']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        FontAwesomeModule,
        MatTooltipModule,
        MatButtonModule
    ],
    declarations: [R2CloudSupportComponent, SubProjectSupportCardComponent, DesktopServerSupportCardComponent, LinuxServerSupportCardComponent]
})
export class R2CloudSupportModule {
}
