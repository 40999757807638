import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';
import {AuthService} from '../../../kluh-manager/auth.service';
import {BackgroundImageService} from '../../../kluh-manager/helpers/background-image.service';
import {FirebaseUserDetails, ManagerUser} from '../../../kluh-manager/models';
import {ActivatedRoute, Router} from '@angular/router';
import {R2CloudClientService} from '../../../kluh-manager/ws/r2-cloud-client.service';
import {ImageFileService} from '../../../kluh-manager/main/image-file/image-file-service';
import {ComponentCleaner} from '../../../kluh-manager/component-cleaner';
import {ScriptExecutionService} from '../../../kluh-manager/main/script-execution/script-execution.service';
import {DesktopServerService} from '../../../kluh-manager/main/desktop-server/desktop-server.service';
import {UserAuthorityDaoService} from '../../../kluh-manager/main/user-authority/user-authority-dao.service';
import {clientVersion} from '../../../../client-version';
import {CustomLayoutService} from '../../../kluh-manager/main/custom-layout/custom-layout.service';
import {CustomerGroupTemplate} from '../../../kluh-manager/models-custom-layout';
import {CustomerGroupTemplateDaoService} from '../../../kluh-manager/main/custom-layout/customer-group-template/customer-group-template-dao.service';
import {CookieService} from 'ngx-cookie-service';
import {PermissionDAOService} from '../../../kluh-manager/main/iam/permission-dao.service';
import {CustomerGroupService} from '../../../kluh-manager/main/customer-group/customer-group.service';
import {R2MenuService} from '../../../kluh-manager/utils/r2-menu.service';
import {Title} from '@angular/platform-browser';
import {FooterService} from '../footer/footer.service';
import {CustomerGroupDaoService} from '../../../kluh-manager/main/customer-group/customer-group-dao.service';
import {customLayoutUrlCookieName} from '../../../kluh-manager/main/login/login.component';
import {isWindowsOS} from '../../../kluh-manager/helpers/kluh';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class ToolbarComponent extends ComponentCleaner implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    customerGroupHasTemplate = true;
    user$: Observable<FirebaseUserDetails>;
    public managerUser: ManagerUser;
    clientVersion = clientVersion;
    customerGroupTemplate: CustomerGroupTemplate;
    // Private
    private _unsubscribeAll: Subject<any>;
    clientStatusEnabled = isWindowsOS();
    loadingCustomGroupTemplate = true;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     * @param {AuthService} auth
     * @param footerService
     * @param r2MenuService
     * @param activatedRoute
     * @param userAuthorityDao
     * @param permissionDAOService
     * @param customLayoutService
     * @param customerGroupTemplateDaoService
     * @param customerGroupService
     * @param customerGroupDaoService
     * @param {BackgroundImageService} backgroundImage
     * @param titleService
     * @param cookieService
     * @param desktopServerService
     * @param {ImageFileService} imageFileService
     * @param scriptExecutionService
     * @param {Router} router
     * @param {R2CloudClientService} clientService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private auth: AuthService,
        private footerService: FooterService,
        private r2MenuService: R2MenuService,
        private activatedRoute: ActivatedRoute,
        private userAuthorityDao: UserAuthorityDaoService,
        private permissionDAOService: PermissionDAOService,
        private customLayoutService: CustomLayoutService,
        private customerGroupTemplateDaoService: CustomerGroupTemplateDaoService,
        private customerGroupService: CustomerGroupService,
        private customerGroupDaoService: CustomerGroupDaoService,
        private backgroundImage: BackgroundImageService,
        private titleService: Title,
        private cookieService: CookieService,
        public desktopServerService: DesktopServerService,
        public imageFileService: ImageFileService,
        private scriptExecutionService: ScriptExecutionService,
        private router: Router,
        private clientService: R2CloudClientService
    ) {
        super();
        this.clientService.enableChanges().subscribe((result) => {
            this.clientStatusEnabled = result;
            if (!isWindowsOS()) {
                this.clientStatusEnabled = false;
            }
        });
        this.user$ = userAuthorityDao.getMe();
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'pt-br',
                title: 'Brazilian Portuguese',
                flag: 'br'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.setCustomLayout();


    }

    setCustomLayout(): void {
        this.user$.subscribe(user => {
            if (user) {
                this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe((customerGroup) => {
                    if (customerGroup) {
                        this.customerGroupHasTemplate = !(customerGroup.customPortalTemplateId === null || customerGroup.customPortalTemplateId === 0);
                        setTimeout(() => {
                            this.customerGroupTemplateDaoService.findOneByManagerUserId(user.managerUser.id).subscribe((customerGroupTemplate) => {
                                    if (customerGroupTemplate) {
                                        this.customerGroupTemplate = customerGroupTemplate;
                                        this.customLayoutService.setDynamicStyle(this.customerGroupTemplate?.customPortalTemplate?.css);
                                        this.cookieService.set(customLayoutUrlCookieName, this.customerGroupTemplate?.url);
                                        if (this.customerGroupTemplate?.customPortalTemplate?.title) {
                                            this.titleService.setTitle(this.customerGroupTemplate?.customPortalTemplate.title);
                                        }
                                    }
                                    this.loadingCustomGroupTemplate = false;
                                }, error => {
                                    this.loadingCustomGroupTemplate = false;
                                }
                            );

                        }, 2200);
                    }
                });
            }
        });
    }

    onMenuOpen(): void {
        // console.log('menu: Open');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this.checkFirstCustomerGroupLoad();

    }

    checkFirstCustomerGroupLoad(): void {
        setTimeout(() => {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const customerGroupId = params.get('id');
            if (customerGroupId != null) {
                console.log('menu: checkFirstCustomerGroupLoad');
                this.r2MenuService.hideMenuWithoutPermission(+customerGroupId);
            }
        }, 2500);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key: any): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    toggleSidebarFolded(key: string): void {
        this._fuseSidebarService.getSidebar(key).toggleFold();
    }


    helpLink(): void {
        let helpLink = this.customerGroupTemplate?.customPortalTemplate?.helpLink;
        if (!(helpLink && helpLink?.length > 4)) {
            helpLink = 'https://wa.me/551141110868?text=Ol%C3%A1!%20preciso%20de%20ajuda.';
        }
        window.open(helpLink, '_blank');
    }


    /**
     * Search
     *
     * @param value
     */
    search(value: any): void {
        // Do your search here...
        console.debug(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang: any): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    logout(): void {
        // this.managerStompService.disconnect();
        this.footerService.closeTerminal();
        this.auth.logoutMicroservices$().subscribe((results) => {
                this.apiLogout();
            },
            error => {
                this.apiLogout();
            }
        );
    }

    apiLogout(): void {
        this.auth.logout().subscribe((result) => {
            this.backgroundImage.clearCache();
            this.customLayoutService.removeAllCustomStyle();
            this.customerGroupService.destroyer();
            this.permissionDAOService.clearCache();
            setTimeout(() => {
                window.location.href = '/login?url=' + this.cookieService.get(customLayoutUrlCookieName);
            }, 200);
        });
    }

    goToHome(): void {
        this.router.navigate(['/']);
    }

}
