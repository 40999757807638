<div class="mt-26" [@animate]="{value:'*',params:{y:'100%'}}">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Dominios - {{adminService.subProject?.name}}</div>
                <div *ngIf="adminService.subProject.id && customerGroupService.customerGroup.id">
                    <button *appIfPermission="appCanCreateList" type="button" mat-raised-button color="accent"
                            id="r2-cloud-admin-ad-domain-create-domain"
                            (click)="onCreateAdDomain()">Criar</button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="adminService.adDomains && adminService.adDomains.length > 0">



            <table class="remote-app-table" cellspacing="0" cellpadding="0">
                <thead>
                <tr class="bg-white">
                    <th class="min-width-250">Nome</th>
                    <th class="min-width-250">domain</th>
                    <th class="min-width-150">Controlador</th>
                    <th class="min-width-100"> </th>
                </tr>
                </thead>

                <tr *ngFor="let adDomain of adminService.adDomains" [ngClass]="{'disabled': !adDomain.active}">
                    <td>{{adDomain.name}}</td>
                    <td>{{adDomain.domainName}}</td>
                    <ng-container *ngIf="adDomain.adServerId !== null; else noDesktopServer;">
                        <td>
                            {{adminService.getDesktopServer(adDomain.adServerId)?.hostname}}
                        </td>
                    </ng-container>
                    <ng-template #noDesktopServer>
                        <td>
                            <mat-icon class="offline" title="Controlador não definido.">error</mat-icon>
                            <span>Sem Controller</span>
                        </td>
                    </ng-template>
                    <td class="text-align-right p-0  pr-2">
                        <button *appIfPermission="'WRITE'; data: adDomain; type: 'ADDomain'" type="button" mat-raised-button
                                class="mat-green-600-bg"
                                id="r2-cloud-admin-addomain-edit"
                                (click)="onEditAdDomain(adDomain)">Edit
                        </button>
                    </td>
                </tr>
            </table>
        </mat-card-content>
        <div fxLayout="row" fxLayoutAlign="end center" *ngIf="!(adminService.adDomains && adminService.adDomains.length > 0) && showHint" >
            <div class="arrow_box arrow_box_domain">
                <div class="inner_box">Cadastre um domínio</div>
            </div>
        </div>
    </mat-card>
</div>