<form [formGroup]="myForm" (submit)="onSubmit()">
  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Inserir Token de proprietario do projeto no GCP</span>
    </mat-toolbar-row>
  </mat-toolbar>


  <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
       fxLayoutAlign="space-between stretch">
    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
      <div>
        <div>
          <p><b>Para criar esse token, siga os próximos passos:</b></p>

          <ol>

          <li style="padding-bottom: 10px">Entre no console do Google e acesse contas de serviço pelo menu <b>( IAM e Admin -> Contas de serviço
            )</b>. </li>

          <li style="padding-bottom: 10px">Clique em (Criar conta de serviço), preencha:<br>
            <span style="padding-left: 15px">
              Nome da conta de serviço: <b>r2cloud</b><br>
              </span>
            <span style="padding-left: 15px">
              Selecionar um papel: <b>( Projeto -> Proprietário ) </b><br>
                 </span>
            <span style="padding-left: 15px">
              Deixe selecionado (<b>Fornecer uma nova chave privada</b>)<br>
            </span>
            <span style="padding-left: 15px">
              Tipo de chave: <b>JSON</b><br>
            </span>

          </li>
          <li style="padding-bottom: 10px">Pegue o conteúdo do arquivo e cole no campo abaixo</li>

          </ol>
        </div>
        <mat-divider class="divider"></mat-divider>

        <mat-form-field class="json">
          <textarea rows="16" matInput placeholder="Token" formControlName="token" name="token"
                    spellcheck="false"></textarea>
          <mat-error *ngIf="myForm.get('token').hasError('required'); else testValidToken">
            Preencha o Token
          </mat-error>
          <ng-template #testValidToken>
            <mat-error *ngIf="myForm.get('token').hasError('invalidJson');">
              {{myForm.get('token').getError('invalidJson').value}}
            </mat-error>
          </ng-template>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

      <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
              aria-label="Salvar">Salvar
      </button>
      <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

    </div>
  </div>
</form>
