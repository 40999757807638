import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {CustomerGroup, CustomerGroupLicensesDashboard} from '../../models';
import {BaseSearchDao} from '../../base-search-dao';
import {Observable} from 'rxjs/internal/Observable';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupDaoService extends BaseSearchDao<CustomerGroup> {


    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'customer-group');
    }


    customerGroupLicensesDashboard(showCreated: boolean, days: number): Observable<CustomerGroupLicensesDashboard[]> {
        return this.http.get<CustomerGroupLicensesDashboard[]>(this.url + '/customer-group-licenses-dashboard?showCreated=' + showCreated + '&days=' + days);
    }

    getOneIfItBelongsToMyCompanyOrIfHaveAnOrderForMyCompany(managerUserId: number, marketplaceOrderId: number): Observable<CustomerGroup> {
        return this.http.get<CustomerGroup>(this.url + '/get-one-if-it-belongs-to-my-company-or-if-have-an-order-for-my-company/' + managerUserId + '/' + marketplaceOrderId);
    }

    getByAuthenticatedManagerUser(): Observable<CustomerGroup> {
        return this.http.get<CustomerGroup>(this.url + '/get-by-authenticated-manager-user');
    }

    getMyCustomerGroups(): Observable<CustomerGroup[]> {
        return this.http.get<CustomerGroup[]>(this.url + '/my');
    }

    getOneExternalByCustomerId(customerId: number): Observable<CustomerGroup> {
        return this.http.get<CustomerGroup>(this.url + '/get-one-external-by-customer-id/' + customerId);
    }

}
