<div class="ml-16 mr-16">
  <ng-container *ngFor="let instance of instances; let i = index">
    <div class="mt-24 mb-24">
      <div fxLayout="column">
        <div class="m-8">
          {{instance.name}} <b *ngIf="getInstanceStatus(instance) === 'NOTFOUND'" class="warn-700-fg" > {{instance.id}} - Não encontrado no GCP</b>
        </div>
        <div class="action-buttons" fxLayout="row" fxLayoutAlign="start center">
          <button type="button" disabled class="m-8" mat-raised-button
                  *ngIf="getInstanceStatus(instance) === null || (getInstanceStatus(instance) !== 'RUNNING' && getInstanceStatus(instance) !== 'TERMINATED') && getInstanceStatus(instance) !== 'NOTFOUND'">
            <div class="my-spinner">
              <mat-spinner [diameter]="26"></mat-spinner>
            </div>
          </button>
          <div *ngIf="getInstanceStatus(instance) !== 'NOTFOUND'">
            <button class="m-8" *ngIf="getInstanceStatus(instance) === 'RUNNING'" type="button" mat-raised-button
                    (click)="onStopInstance(instance)" [disabled]="getInstancePending(instance)">Parar
            </button>
            <button class="m-8" *ngIf="getInstanceStatus(instance) === 'TERMINATED'" type="button" mat-raised-button
                    (click)="onStartInstance(instance)" [disabled]="getInstancePending(instance)">Iniciar
            </button>
          </div>

          <div *ngIf="getInstanceStatus(instance) === 'NOTFOUND'">
            <button class="m-8" type="button" mat-raised-button color="warn" (click)="onRemoveInstance(instance)">
              Deletar
            </button>
          </div>
          <button class="m-8" type="button" *ngIf="editing" mat-raised-button (click)="onEditSnapshot(instance)">
            Snapshot
          </button>
          <button class="m-8" type="button" *ngIf="editing" mat-raised-button
                  (click)="onEditInstanceSchedule(instance)">Agendamento
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="i !== instances.length - 1">
      <mat-divider></mat-divider>
    </div>
  </ng-container>
</div>
