import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';

export function addRequiredToFormGroup(formGroup: FormGroup, fields: string[]): void {
    for (const field of fields) {
        formGroup.get(field).setValidators([Validators.required]);
    }
}

export function guessImageMime(data: string): string {
    if (data.charAt(0) === '/') {
        return 'jpg';
    } else if (data.charAt(0) === 'R') {
        return 'gif';
    } else if (data.charAt(0) === 'i') {
        return 'png';
    } else if (data.charAt(0) === 'Q') {
        return 'bmp';
    }
    throw new Error('invalid image');
}

export function convertToFormGroup(object: any): any {
    const result = {};
    for (const property in object) {
        if (object.hasOwnProperty(property)) {
            let value = object[property];
            if (value && value.constructor === Array) {
                value = [value];
            }
            result[property] = value;
        }
    }
    return result;
    // return object;
}

export function markAsTouched(group: FormGroup | FormArray): void {
    Object.keys(group.controls).map((field) => {
        const control = group.get(field);
        if (control instanceof FormControl) {
            control.markAsDirty({onlySelf: true});
            control.updateValueAndValidity({onlySelf: true});
        } else if (control instanceof FormGroup) {
            markAsTouched(control);
        } else if (control instanceof FormArray) {
            markAsTouched(control);
        }
    });
    group.markAsDirty({onlySelf: true});
    group.updateValueAndValidity({onlySelf: true});
}

export function validateEmail(email: string): boolean {
    // tslint:disable-next-line
    const re = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return re.test(String(email).toLowerCase());
}

export class CrudOperationWrapper {
    public operation: CrudOperation;
    public data: any;
}

export type CrudOperation = 'CANCEL' | 'CREATE' | 'SAVE' | 'DELETE';

export class Province {
    public key: string;
    public value: string;
}

export function orderArrayAnyBy(array: any, field: string): any[] {
    array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
            return -1;
        } else if (a[field] > b[field]) {
            return 1;
        } else {
            return 0;
        }
    });
    return array;
}


export function triggerFormValidation(form: FormGroup): void {
    const controls = form.controls;
    for (const i in controls) {
        if (controls.hasOwnProperty(i) && controls[i] instanceof FormControl) {
            const control = controls[i];
            control.markAsDirty();
            control.markAsTouched();
            control.updateValueAndValidity();
        }
    }
}

export function keyMaker(size: number): string {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < size; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

export function formatFormControlWithLeadingZero(val: any, control: AbstractControl): void {
    if (val.toString().length < 2) {
        control.setValue('0' + val);
        control.markAsDirty();
        control.updateValueAndValidity();
    }
}


export function formatStringWithLeadingZero(num: any): string {
    let str = num.toString();
    if (str.length < 2) {
        str = `0${str}`;
    }
    return str;
}


export class DesktopServerVersionCount {
    public version: string;
    public amount: number;
}

export function trimNull(a: string): string {
    const c = a.indexOf('\0');
    if (c > -1) {
        return a.substr(0, c);
    }
    return a;
}


export function theDateIsGreaterThanNowPlusMinutes(dateToBeChecked: Date, minutes: number): boolean {
    if (dateToBeChecked && minutes) {
        let createdAt: Date = new Date(dateToBeChecked);
        createdAt = new Date(createdAt.getTime() + (createdAt.getTimezoneOffset() * 60000));
        const now: Date = new Date(Date.now());
        const ms: number = now.getTime() - createdAt.getTime();
        const secsFix = parseInt((ms / 1000).toString(), 10);
        const minsFix = parseInt((secsFix / 60).toString(), 10);
        if (minsFix > minutes) {
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export function isMacOS(): boolean {
    return navigator.platform.indexOf('Mac') > -1;
}

export function isWindowsOS(): boolean {
    return navigator.platform.indexOf('Win') > -1;
}

export function randomId(prefix: string): string {
    return Math.random().toString(36).replace('0.', prefix || '');
}

export function sortListByName(list: any[]): any[] {
    return list.sort((obj1, obj2) => {
        if (obj1.name > obj2.name) {
            return 1;
        }
        if (obj1.name < obj2.name) {
            return -1;
        }
        return 0;
    });
}

export function getDifferenceBetweenTodayAndADayInDays(begin: Date): number {
    return getDifferenceBetweenTwoDatesInDays(begin, new Date(Date.now()));
}

export function getDifferenceBetweenTwoDatesInDays(begin: Date, end: Date): number {
    const diffTime = Math.abs(end.getTime() - begin.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function getDifferenceBetweenTwoDatesInHours(begin: Date, end: Date): number {
    const diffTime = Math.abs(end.getTime() - begin.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60));
}

export function getDifferenceBetweenTwoDatesInMinutes(begin: Date, end: Date): number {
    const diffTime = Math.abs(end.getTime() - begin.getTime());
    return Math.ceil(diffTime / (1000 * 60));
}

export function getDifferenceBetweenTwoDatesInSeconds(begin: Date, end: Date): number {
    const diffTime = Math.abs(end.getTime() - begin.getTime());
    return Math.ceil(diffTime / 1000);
}


export function checkQuotes(control: FormControl): { [s: string]: boolean } {
    if (control.value.length < 2 || control.value.indexOf('\'') > -1 || control.value.indexOf(' ') > -1) {
        return { invalidChar: true };
    }
}

export function checkInvalidChars(control: FormControl): { [s: string]: boolean } {
    const format = /[ `!@#$%^&*()+=\[\]{};':"\\|,<>\/?~]/;
    if (control.value.length < 2 || format.test(control.value) || control.value.indexOf(' ') > -1) {
        return { invalidChar: true };
    }
}


export function getArrayDateFromPeriod(period: string): string[] {
    let subtractDaysFrom: number;
    let subtractDaysTo: number;
    const now = moment();
    if (period === Period.yesterday) {
        subtractDaysFrom = 1;
        subtractDaysTo = 1;
    } else if (period === Period.last7days) {
        subtractDaysFrom = 7;
        subtractDaysTo = 0;
    } else if (period === Period.last30days) {
        subtractDaysFrom = 30;
        subtractDaysTo = 0;
    } else if (period === Period.last60days) {
        subtractDaysFrom = 60;
        subtractDaysTo = 0;
    } else if (period === Period.thisYear) {
        subtractDaysFrom = now.clone().dayOfYear();
        subtractDaysTo = 0;
    } else {
        subtractDaysFrom = 0;
        subtractDaysTo = 0;
    }

    const from = now.clone().subtract(subtractDaysFrom, 'days').startOf('day').toISOString(true);
    const to = now.clone().subtract(subtractDaysTo, 'days').endOf('day').toISOString(true);
    return [from, to];
}

export enum Period {
    today = 'today',
    yesterday = 'yesterday',
    last7days = 'last7days',
    last30days = 'last30days',
    last60days = 'last60days',
    thisYear = 'thisYear'
}


