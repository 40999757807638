import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {ProfileComponent} from './profile.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

import {NgxMaskModule} from 'ngx-mask';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const routes = [
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthService]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatOptionModule,
        MatSelectModule,
        NgxMaskModule.forRoot(),
        MatSlideToggleModule
    ],
    declarations: [ChangePasswordComponent, ProfileComponent],
    entryComponents: [ChangePasswordComponent]
})
export class ProfileModule {
}
