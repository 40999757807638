import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-script-execution',
  templateUrl: './script-execution.component.html',
  styleUrls: ['./script-execution.component.scss']
})
export class ScriptExecutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
