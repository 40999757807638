<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <!-- TOP BACKGROUND -->
  <div class="top-bg mat-accent-bg"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header white-fg p-24" fxLayout="row" fxLayoutAlign="start center">
      <h2>Lista de Instancias</h2>
    </div>
    <!-- / CONTENT BODY -->
    <div class="content">
      <mat-card>
        <div class="widget-group" fxLayout="column" fxFlex="100" fxLayoutWrap *fuseIfOnDom
             [@animateStagger]="{value:'50'}">
          <div class="mb-4">
            <button type="button" mat-raised-button (click)="onSubmit()">Salvar</button>
          </div>
          <div>
            <ngx-datatable
              class="material"
              [rows]="rows"
              [loadingIndicator]="loadingIndicator"
              [columnMode]="'force'"
              [headerHeight]="48"
              [footerHeight]="56"
              [rowHeight]="'auto'"
              [reorderable]="reorderable">



              <ngx-datatable-column name="Projeto" prop="projectName">
              </ngx-datatable-column>

              <ngx-datatable-column name="Instancia" prop="instanceName">
              </ngx-datatable-column>

              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  <mat-checkbox [checked]="getAllDailySelected()" (change)="onSelectAllDaily()">
                    Diário
                  </mat-checkbox>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-checkbox type="checkbox" [(ngModel)]="row.snapshotPolicyDaily">
                  </mat-checkbox>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  <mat-checkbox [checked]="getAllThreeHourSelected()" (change)="onSelectAllThreeHour()">
                    A Cada Tres Horas
                  </mat-checkbox>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-checkbox type="checkbox" [(ngModel)]="row.snapshotPolicyThreeHour">
                  </mat-checkbox>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  <mat-checkbox [checked]="getAllWeeklySelected()" (change)="onSelectAllWeekly()">
                    Semanal
                  </mat-checkbox>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-checkbox type="checkbox" [(ngModel)]="row.snapshotPolicyWeekly">
                  </mat-checkbox>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column>
                <ng-template ngx-datatable-header-template>
                  <mat-checkbox [checked]="getAllMonthlySelected()" (change)="onSelectAllMonthly()">
                    Mensal
                  </mat-checkbox>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-row="row">
                  <mat-checkbox type="checkbox" [(ngModel)]="row.snapshotPolicyMonthly">
                  </mat-checkbox>
                </ng-template>
              </ngx-datatable-column>

            </ngx-datatable>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
