import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackgroundImageService} from '../../helpers/background-image.service';
import {DomSanitizer} from '@angular/platform-browser';
import {ComponentCleaner} from '../../component-cleaner';
import {AuthService} from '../../auth.service';
import {RemoteAppDaoService} from '../remote-app/remote-app-dao.service';
import {convertToFormGroup, guessImageMime, isMacOS} from '../../helpers/kluh';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RemoteAppWrapper} from './remote-app-wrapper';
import {StompConnectionStatus} from '../../ws/stomp-connection-status.enum';
import {ClientDownloadService} from './client-download.service';
import {ClientSettingsDaoService} from './client-settings-dao.service';

import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {AdUserDaoService} from '../ad-user/ad-user-dao.service';
import {AdDomainDaoService} from '../ad-domain/ad-domain-dao.service';
import {ClientSettings, ClientUpdate, CustomerGroup, DesktopServer, ManagerUser, R2CloudClientStatus, RemoteAppReady, RemoteAppReadyStatus, SubProject} from '../../models';
import {fuseAnimations} from '../../../../@fuse/animations';
import {DesktopServerDaoService} from '../desktop-server/desktop-server-dao.service';
import {DesktopServerService} from '../desktop-server/desktop-server.service';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SubProjectDaoService} from '../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {USER_TOPIC} from '../../../../main';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudClientService} from '../../ws/r2-cloud-client.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';
import {ImageFileService} from '../image-file/image-file-service';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-desktop',
    templateUrl: './desktop.component.html',
    styleUrls: ['./desktop.component.scss'],
    animations: fuseAnimations
})
export class DesktopComponent extends ComponentCleaner implements OnInit, OnDestroy {


    // https://www.flaticon.com/packs/google-2
    public waiting = false;
    timerLoadApps = false;
    public timer = null;
    public timerForButton = null;
    public waitingTimer = 0;
    public waitingTimerForButton = 10;
    public clientConnected = false;
    public clientConnectedOnce = false;
    public clientExists = false;
    public showInstall = false;
    private showInstallIntervalId: any | null;
    public showReconnectButton = false;
    public afterInstall = false;
    public clientUpdate = false;
    public remoteAppWrapperList: RemoteAppWrapper[] = [];
    public remoteAppWrapperCustomList: RemoteAppWrapper[] = [];
    public stompConnectionStatus: StompConnectionStatus;
    public StompConnectionStatus = StompConnectionStatus;
    public myForm: FormGroup;
    public managerUser: ManagerUser;
    private clientSettings: ClientSettings;
    private customerGroup: CustomerGroup;
    private subProjects: SubProject[] = [];
    desktopServerList: DesktopServer[] = null;
    private customerGroupAlreadyLoaded = false;
    desktopServerLoading = false;
    messageLoadingShow = false;
    private waitingComunicationIntervalId: any | null;
    waitingComunicationRestartButtonShow = false;


    constructor(private remoteAppDaoService: RemoteAppDaoService,
                private r2CloudClientService: R2CloudClientService,
                private clientSettingsDao: ClientSettingsDaoService,
                private managerUserDaoService: ManagerUserDaoService,
                private adUserDaoService: AdUserDaoService,
                private adDomainDaoService: AdDomainDaoService,
                private desktopServerDaoService: DesktopServerDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private subProjectDaoService: SubProjectDaoService,
                private customerGroupService: CustomerGroupService,
                private stomp: R2CloudStompService,
                private auth: AuthService,
                private dialog: MatDialog,
                private backgroundImage: BackgroundImageService,
                private clientDownload: ClientDownloadService,
                private sanitizer: DomSanitizer,
                public desktopServerService: DesktopServerService,
                public imageFileService: ImageFileService,
                private fb: FormBuilder) {
        super();

    }

    ngOnDestroy(): void {
        this.remoteAppWrapperCustomList = [];
    }

    ngOnInit(): void {

        this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            // this.addSubscription(this.customerGroupService.get().subscribe((customerGroup) => {
            // console.log('this.customerGroupService.get(). ' + customerGroup);
            if (!this.customerGroup || typeof this.customerGroup === 'undefined') {
                if (customerGroup && customerGroup.id) {
                    this.customerGroup = customerGroup;
                    this.loadSubProjectsAndReloadRemoteApps(customerGroup.id);
                }
            } else if (customerGroup && customerGroup.id && this.customerGroup.id !== customerGroup.id) {
                this.customerGroup = customerGroup;
                this.loadSubProjectsAndReloadRemoteApps(customerGroup.id);
            }
        }));


        setInterval(() => {
            this.timerLoadApps = true;
        }, 4000);


        const subscription = this.stomp.connectionStatus$.subscribe((status: StompConnectionStatus) => {
            console.debug('desktop component onConnectionChange: ' + status);
            this.stompConnectionStatus = status;

            // coloca um timer no botão reconetar que pode aparecer na home

            if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
                if (this.waitingTimerForButton === 10) {
                    console.debug('entrou reconnect onConnectionChange');
                    clearInterval(this.timerForButton);
                    this.timerForButton = setInterval(() => {
                        this.waitingTimerForButton--;
                        if (this.waitingTimerForButton < 1) {
                            clearInterval(this.timerForButton);
                            if (status === StompConnectionStatus.DISCONNECTED && !this.showReconnectButton) {
                                this.showReconnectButton = true;
                                console.debug('show button reconnect onConnectionChange ' + status);
                            }
                        }
                    }, 1000);
                }
            }
            if (status === StompConnectionStatus.OK) {
                clearInterval(this.timerForButton);
                this.showReconnectButton = false;
                this.waitingTimerForButton = 10;
                console.debug('hide button reconnect onConnectionChange');
            }
        });
        this.addSubscription(subscription);


        const subscription2 = this.stomp.stompTopic<ClientUpdate>(`${USER_TOPIC}/client-update`).subscribe(() => {
            this.clientUpdate = true;
        });
        this.addSubscription(subscription2);

        this.addSubscription(
            this.r2CloudClientService.clientConnectionChanges().subscribe((result) => {
                this.showInstall = false;
                this.clientUpdate = false;
                this.clientExists = false;
                this.clientConnected = false;
                switch (result.status) {
                    case R2CloudClientStatus.CONNECTED:
                        this.clientConnected = true;
                        this.clientConnectedOnce = true;
                        break;
                    case R2CloudClientStatus.CLIENT_UPDATE:
                        this.clientUpdate = true;
                        break;
                    case R2CloudClientStatus.NOT_CONNECTED:
                        break;
                    case R2CloudClientStatus.INSTALLED:
                        this.clientExists = true;
                        break;
                    case R2CloudClientStatus.NOT_INSTALLED:
                        clearInterval(this.showInstallIntervalId);
                        this.showInstallIntervalId = setTimeout(() => {
                            this.showInstall = true;
                        }, 5000);
                        break;
                    case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                        break;
                    case R2CloudClientStatus.OPENING:
                        break;

                }
                clearInterval(this.waitingComunicationIntervalId);
                this.waitingComunicationRestartButtonShow = false;
                this.waitingComunicationIntervalId = setTimeout(() => {
                    this.waitingComunicationRestartButtonShow = true;
                }, 8000);
                // this.showInstall = false;
                // this.clientUpdate = false;
                // this.clientExists = true;
                // this.clientConnected = true;
            })
        );
    }

    private readRemoteApps(): void {

        // console.log('customerGroupAlreadyLoaded.');
        this.customerGroupAlreadyLoaded = true;

        this.remoteAppWrapperCustomList = [];

        const subscription1 = this.stomp.stompTopic<RemoteAppReady[]>(`${USER_TOPIC}/remote-apps-ready`).subscribe((remoteAppReadyList: RemoteAppReady[]) => {
            for (const remoteAppReady of remoteAppReadyList) {
                switch (remoteAppReady.status) {
                    case RemoteAppReadyStatus.CLIENT_NOT_CONNECTED:
                    // this.clientConnected = false;
                    // if (!this.clientUpdate) {
                    //     this.startClient();
                    // }
                    // break;
                    default:
                        this.clientSettingsDao.getMyClientSettings().subscribe((clientSettings) => {
                            this.clientSettings = clientSettings;
                            this.setForm();
                        });
                        clearInterval(this.timer);

                        const find = this.remoteAppWrapperList.find((value) => {
                            return value.remoteAppId === remoteAppReady.remoteAppId;
                        });
                        if (find) {
                            find.remoteAppReadyStatus = remoteAppReady.status;
                        } else {
                            if (remoteAppReady.remoteAppId !== 0) {
                                const remoteAppWrapper = new RemoteAppWrapper(remoteAppReady.remoteAppId,
                                    null, remoteAppReady.status,
                                    false,
                                    remoteAppReady.customerGroupId,
                                    remoteAppReady.subProjectId,
                                    remoteAppReady.name,
                                    remoteAppReady.imageUUID
                                );
                                const indexRemoteApp = this.remoteAppWrapperList.findIndex((o) => {
                                    return o.remoteAppId === remoteAppWrapper.remoteAppId;
                                });
                                if (indexRemoteApp < 0) {
                                    this.remoteAppWrapperList.push(remoteAppWrapper);
                                }
                                // console.log('desktop: 1 remoteAppWrapper:', remoteAppWrapper);
                                // this.remoteAppDaoService.getOne(remoteAppReady.remoteAppId).subscribe((remoteApp) => {
                                //     const find2 = this.remoteAppWrapperList.find((value) => {
                                //         return value.remoteAppId === remoteApp.id;
                                //     });
                                //     if (find2) {
                                //         find2.remoteApp = remoteApp;
                                //     }

                                // if (remoteAppWrapper.remoteApp) {
                                const index = this.subProjects.findIndex((o) => {
                                    return o.id === remoteAppWrapper.subProjectId;
                                });
                                if (index > -1) {
                                    const indexUpdateRemoteApp = this.remoteAppWrapperCustomList.findIndex((o) => {
                                        return o.remoteAppId === remoteAppWrapper.remoteAppId;
                                    });
                                    if (indexUpdateRemoteApp > -1) {
                                        this.remoteAppWrapperCustomList[indexUpdateRemoteApp] = remoteAppWrapper;
                                    } else {
                                        this.remoteAppWrapperCustomList.push(remoteAppWrapper);
                                    }
                                }
                                // }
                                // });
                            }
                        }
                        break;
                }
            }
        });
        this.addSubscription(subscription1);


    }

    guessImageMime(base64: string): string {
        return guessImageMime(base64);
    }

    downloadLatestVersion(): void {
        this.afterInstall = true;
        this.showInstall = false;
        this.clientDownload.download();
    }

    connectStomp(): void {
        this.clientConnected = false;
        this.clientExists = false;
        this.showInstall = false;
        this.stompConnectionStatus = StompConnectionStatus.DISCONNECTED;
    }

    restartApps(): void {
        this.loadingShow();
        this.waiting = true;
        this.waitingTimer = 90;
        this.timer = setInterval(() => {
            this.waitingTimer--;
            if (this.waitingTimer < 1) {
                clearInterval(this.timer);
                location.reload();
            }
        }, 1200);
    }


    startClient(e: Event): void {
        this.r2CloudClientService.startClientClick(e);
        // this.r2CloudClientService.startClient(this.stomp.webClientId);
        // setTimeout(() => {
        //     this.firebaseService.getToken().pipe(take(1)).subscribe((token) => {
        //         console.debug('starting kluh manager client');
        //         ProtocolCheck.protocolCheck('KluhR2CloudClient://' + token + ',' + this.stomp.webClientId, () => {
        //             console.debug('could not open client');
        //             this.showInstall = true;
        //
        //         }, () => {
        //             this.showInstall = false;
        //             this.afterInstall = false;
        //             this.clientUpdate = false;
        //         });
        //
        //     });
        // }, 5500);
    }


    onOpen(remoteAppWrapper: RemoteAppWrapper): void {
        if (this.stompConnectionStatus === StompConnectionStatus.OK && this.clientConnected) {
            remoteAppWrapper.loading = true;
            this.stomp.send('/stomp/open',
                {remoteAppId: remoteAppWrapper.remoteAppId});
            setTimeout(() => {
                remoteAppWrapper.loading = false;
            }, 5000);
        }
        // console.debug('onOpen: ' + program);
    }

    onSubmit(): void {
        this.clientSettingsDao.saveMyClientSettings(this.myForm.value).subscribe((clientSettings) => {
            this.clientSettings = clientSettings;
            this.setForm();
        });
    }

    onCancel(): void {
        this.setForm();
    }

    private setForm(): void {
        this.myForm = this.fb.group(convertToFormGroup(this.clientSettings), {asyncValidator: this.clientSettingsDao.validator});
    }


    isMac(): boolean {
        return isMacOS();
    }

    private loadSubProjectsAndReloadRemoteApps(customerGroupId: number): void {
       this.subProjectDaoService.getSubProjectsByCustomerGroupId(customerGroupId).subscribe((subProjects) => {
             if (subProjects) {
                this.subProjects = subProjects;
                if (!this.customerGroupAlreadyLoaded) {
                    this.readRemoteApps();
                }
                this.remoteAppWrapperCustomList = [];

                 for (const remoteAppWrapper of this.remoteAppWrapperList) {
                    const index = this.subProjects.findIndex((o) => {
                        return o.id === remoteAppWrapper.subProjectId;
                    });
                    if (index > -1) {
                        this.remoteAppWrapperCustomList.push(remoteAppWrapper);
                    }
                }
            }
        });
    }

    getTooltip(remoteAppWrapper: RemoteAppWrapper): string {
        if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.SERVER_NOT_CONNECTED) {
            return 'Servidor desconectado, o aplicativo talvez não abra';
        } else if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.CLIENT_NOT_CONNECTED) {
            return 'Client desconectado';
        } else if (remoteAppWrapper.remoteAppReadyStatus === RemoteAppReadyStatus.PREPARING) {
            return 'Aplicação ainda não foi configurada no Servidor';
        }
    }

    findDesktopServerOnline(): void {
        if (!this.desktopServerList) {
            this.desktopServerLoading = true;
            this.addSubscription(this.userAuthorityDaoService.getMe().subscribe((user) => {
                if (user) {
                    this.addSubscription(this.desktopServerDaoService.getAllDesktopServersManagerUser(user.managerUser.id).subscribe((desktopServerList) => {
                        if (desktopServerList) {
                            this.desktopServerList = desktopServerList;
                            this.desktopServerLoading = false;
                        }
                    }));
                }
            }));
        }
    }


    onChangeVpnAccess(): void {
        const connectUsingVpn: boolean = this.myForm.get('connectUsingVpn').value;
        if (!connectUsingVpn) {
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Se você não estiver conectado nessa VPN, você não conseguirá abrir aplicativos.',
                    disableCancel: false,
                    confirmButtonValue: 'Ativar',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                this.myForm.get('connectUsingVpn').setValue(!!result);
            });
        }
    }


    loadingShow(): boolean {
        this.messageLoadingShow = true;
        setTimeout(() => {
            this.messageLoadingShow = false;
        }, 8000);
        return true;
    }

    refreshBrowser(): void {
        window.location.reload();
    }
}
