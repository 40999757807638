import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DotNetServerPlugin} from '../../../models';
import {DotNetServerPluginDaoService} from '../services/server-plugin-dao.service';
import {DotNetServerPluginService} from '../services/server-plugin.service';
import {convertToFormGroup} from '../../../helpers/kluh';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-server-plugin-create-edit',
    templateUrl: './server-plugin-create-edit.component.html',
    styleUrls: ['./server-plugin-create-edit.component.scss']
})
export class DotNetServerPluginCreateEditComponent implements OnInit {
    myForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<DotNetServerPluginCreateEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private serverPluginDao: DotNetServerPluginDaoService,
        private serverPluginService: DotNetServerPluginService
    ) {
        const asyncValidator = this.serverPluginDao.validator;
        this.myForm = this.fb.group(convertToFormGroup(data.serverPlugin), {asyncValidator: asyncValidator});
    }

    ngOnInit(): void {
    }

    onSubmit(): void {
        const serverPlugin: DotNetServerPlugin = this.myForm.value;
        this.serverPluginService.saveServerPlugin(serverPlugin).subscribe(() => {
            this.dialogRef.close();
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

}
