import {Injectable} from '@angular/core';
import {ADUser, ADUserPoolRelationship, ADUserServiceExternal, ManagerUser} from '../../../models';
import {AdUserServiceExternalDaoService} from '../../ad-user-service-external/ad-user-service-external-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {MatDialog} from '@angular/material/dialog';
import {ServiceUserWizardComponent} from '../../manager-user-and-ad-user/service-user-wizard/service-user-wizard.component';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {
    AdUserPoolRelationshipUserServiceCreateComponent
} from '../../ad-user-pool-relationship/ad-user-pool-relationship-user-service-create/ad-user-pool-relationship-user-service-create.component';

@Injectable({
    providedIn: 'root'
})
export class AdUserServiceService extends ComponentCleaner {
    private adUserExternalList: ADUser[] = [];
    private managerUserExternalList: ManagerUser[] = [];
    private adUserExternalLoading = false;
    private managerUserExternalLoading = false;

    constructor(
        private dialogRef: MatDialog,
        private adUserServiceExternalDaoService: AdUserServiceExternalDaoService,
        public adminService: R2CloudAdminService,
        private dialog: MatDialog,
    ) {
        super();
        this.findADUserFromADUserServiceExternal();
        this.findManagerUserFromADUserServiceExternal();
    }

    findManagerUser(managerUserId: number): ManagerUser {
        let managerUser = this.managerUserExternalList.find(x => x.id === managerUserId);
        if (!managerUser) {
            managerUser = this.adminService.managerUsers.find(x => x.id === managerUserId);
        }
        if (!managerUser && !this.managerUserExternalLoading) {
            this.managerUserExternalLoading = true;
            this.addSubscription(this.adUserServiceExternalDaoService.findAllManagerUserBySubProjectId(this.adminService.subProject.id).subscribe((managerUserExternalList) => {
                if (managerUserExternalList) {
                    this.managerUserExternalList = managerUserExternalList;
                    this.findManagerUser(managerUserId);
                }
                this.managerUserExternalLoading = false;
            }));
        }
        return managerUser;
    }

    openServiceUserModal(adUser: ADUser, adUserServiceExternal: ADUserServiceExternal): void {
        const dialogRef = this.dialog.open(ServiceUserWizardComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                managerUsers: this.adminService.managerUsers,
                adUser: adUser,
                adUserServiceExternal: adUserServiceExternal,
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((crudOperation: CrudOperationWrapper) => {
            const data = crudOperation.data;
            if (crudOperation.operation === 'CREATE') {
                if (crudOperation.data?.login) {
                    this.adminService.addADUserService(data);
                } else {
                    this.adminService.addADUserServiceExternal(data);
                }
            } else if (crudOperation.operation === 'SAVE') {
                if (crudOperation.data?.login) {
                    this.adminService.replaceADUserService(data);
                } else {
                    this.adminService.replaceADUserServiceExternal(data);
                    this.replaceADUserExternalListLocal(data);
                }
            } else if (crudOperation.operation === 'DELETE') {
                if (crudOperation.data?.login) {
                    this.adminService.removeADUserService(data);
                } else {
                    this.adminService.removeADUserServiceExternal(data.id);
                }
            }
            this.dialogRef.closeAll();
        });
        this.addSubscription(subscription);
    }

    openUserPoolCreateModal(adUserPoolRelationship: ADUserPoolRelationship, adUserServiceExternal: ADUserServiceExternal): void {
        const dialogRef = this.dialog.open(AdUserPoolRelationshipUserServiceCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                managerUsers: this.adminService.managerUsers,
                adUserPoolRelationship: adUserPoolRelationship,
                adUserServiceExternal: adUserServiceExternal,
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((crudOperation: CrudOperationWrapper) => {
            this.dialogRef.closeAll();
        });
        this.addSubscription(subscription);
    }

    private findManagerUserFromADUserServiceExternal(): void {
        this.addSubscription(this.adUserServiceExternalDaoService.findAllManagerUserBySubProjectId(this.adminService.subProject.id).subscribe((managerUserExternalList) => {
            if (managerUserExternalList) {
                this.managerUserExternalList = managerUserExternalList;
            }
        }));
    }


    findADUser(adUserId: number): ADUser {
        const adUser = this.adUserExternalList.find(x => x.id === adUserId);
        if (!adUser && !this.adUserExternalLoading) {
            this.adUserExternalLoading = true;
            this.addSubscription(this.adUserServiceExternalDaoService.findAllADUserBySubProjectId(this.adminService.subProject.id).subscribe((adUserExternalList) => {
                if (adUserExternalList) {
                    this.adUserExternalList = adUserExternalList;
                    this.findADUser(adUserId);
                }
                this.adUserExternalLoading = false;
            }));
        }
        return adUser;
    }

    replaceADUserExternalListLocal(adUserServiceExternal: ADUserServiceExternal): void {
        const index = this.adUserExternalList.findIndex(x => x.id === adUserServiceExternal.adUserId);
        if (index > -1) {
            this.adUserExternalList[index].managerUserServiceIds = adUserServiceExternal.managerUserIds;
        }
    }

    private findADUserFromADUserServiceExternal(): void {
        this.addSubscription(this.adUserServiceExternalDaoService.findAllADUserBySubProjectId(this.adminService.subProject.id).subscribe((adUserExternalList) => {
            if (adUserExternalList) {
                this.adUserExternalList = adUserExternalList;
            }
        }));
    }

}
