import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {CloudConfig} from '../../../../models';
import {shareReplay} from 'rxjs/operators';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';


@Injectable()
export class CloudConfigDaoService extends BaseDao<CloudConfig> {
  private allGoogleZones$: Observable<string[]>;

  constructor(protected http: R2CloudHttpApiService, protected validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'cloud-config');
  }
  /*
  clearCache(): void {
    this.allGoogleZones$ = null;
  }
*/
  getAllGoogleZones(): Observable<string[]> {
    if (this.allGoogleZones$ == null) {
      this.allGoogleZones$ = this.http.get<string[]>(this.url + '/zones/google').pipe(shareReplay(1));
    }
    return this.allGoogleZones$;
  }

  setCloudConfigZone(cloudConfigId: number, zone: string): Observable<CloudConfig> {
    return this.http.post<CloudConfig>(this.url + '/' + cloudConfigId + '/zone', zone);
  }
}
