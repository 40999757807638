import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {NewUserWizardComponent} from '../../../manager-user-and-ad-user/new-user-wizard/new-user-wizard.component';
import {CrudOperationWrapper} from '../../../../helpers/kluh';
import {R2CloudAdminService} from '../../r2-cloud-admin.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {AdUserPoolRelationshipCreateComponent} from '../../../ad-user-pool-relationship/ad-user-pool-relationship-create/ad-user-pool-relationship-create.component';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';


@Component({
    selector: 'app-r2-cloud-admin-user-type-choice',
    templateUrl: './r2-cloud-admin-user-type-choice.component.html',
    styleUrls: ['./r2-cloud-admin-user-type-choice.component.scss']
})
export class R2CloudAdminUserTypeChoiceComponent extends ComponentCleaner  {

    appCanCreateADUserPoolRelationship: PermissionWrapper[];
    constructor(
        public dialogRef: MatDialogRef<R2CloudAdminUserTypeChoiceComponent>,
        private dialog: MatDialog,
        public adminService: R2CloudAdminService,
        public customerGroupService: CustomerGroupService,
    ) {
        super();
        this.appCanCreateADUserPoolRelationship = [
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }

    openUserCreateModal(): void {
        const dialogRef = this.dialog.open(NewUserWizardComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                subProject: this.adminService.subProject,
                customers: this.adminService.customers
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            if (result && result.constructor === Array) {
                const managerUserOperation = result[0];
                const adUserOperation = result[1];
                if (managerUserOperation.operation === 'CREATE' || adUserOperation.operation === 'CREATE') {
                    const managerUserCheck = this.adminService.managerUsers.find(x => x.id === managerUserOperation.data.id);
                    if (!managerUserCheck) {
                        this.adminService.addManagerUser(managerUserOperation.data);
                    }
                    const adUserCheck = this.adminService.adUsers.find(x => x.id === adUserOperation.data.id);
                    if (!adUserCheck) {
                        this.adminService.addADUser(adUserOperation.data);
                    }
                }
            }
            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }

    openUserPoolCreateModal(): void {
        const dialogRef = this.dialog.open(AdUserPoolRelationshipCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large'
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper[] | CrudOperationWrapper | null) => {
            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
