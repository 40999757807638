<form *ngIf="myForm" [formGroup]="myForm" (ngSubmit)="myForm.valid && onSave()">
<div fxLayout="column" fxLayoutAlign="center stretch">
    <div>
        <mat-toolbar matDialogTitle class="mat-accent m-0" fxFlexAlign="row" fxLayoutAlign="center center">
            <!--<fa-icon [icon]="icon" class="nav-link-icon"></fa-icon>-->
            Escolha o tipo de servidor
        </mat-toolbar>
    </div>
    <!--<div class="p-16 message" fxLayout="row" fxLayoutAlign="space-between center">-->
    <!--<span class="mat-body-2"></span>-->
    <!--</div>-->
        <ng-container formGroupName="useExistingServerForm"
                      *ngIf="(desktopServers.length > 0 || linuxServers.length > 0) && adminService.subProject.projectId">


            <mat-slide-toggle formControlName="useExistingServer" name="useExistingServer"
                              style="padding: 40px 60px 60px 140px;">
                Usar um Servidor existente
            </mat-slide-toggle>

            <div *ngIf="desktopServers && useExistingServerForm.get('useExistingServer').value"
                 class=" grey-50-bg border-top" style="padding: 10px 60px 10px 60px;">


                <div fxLayout="column" fxLayoutAlign="space-evenly stretch"
                     style="height: 140px; margin-top: 20px;   margin-bottom: 20px;">

                    <mat-form-field *ngIf="desktopServers && desktopServers.length > 0">
                        <mat-select placeholder="Servidor Windows existentes" formControlName="desktopServerId">
                            <mat-option *ngFor="let desktopServer of desktopServers" [value]="desktopServer.id">
                                {{desktopServer.hostname}} - {{desktopServer.connectionFQDN}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field *ngIf="linuxServers && linuxServers.length > 0">
                        <mat-select placeholder="Servidor Linux existentes" formControlName="linuxServerId">
                            <mat-option *ngFor="let linuxServer of linuxServers" [value]="linuxServer.id">
                                {{linuxServer.hostname}} - {{linuxServer.connectionFQDN}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>
        </ng-container>
        <div *ngIf="!(desktopServers && useExistingServerForm.get('useExistingServer').value)" fxLayout="row"
             fxLayoutAlign="space-around end" fxFill class="p-50 grey-50-bg border-top">
            <div>
                <button mat-raised-button color="accent" (click)="onWindows()">Windows</button>
            </div>
            <div>
                <button mat-raised-button color="accent" (click)="onLinux()">Linux</button>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-around center"
             fxFill class="p-16 grey-50-bg border-top">

            <button *ngIf="desktopServers && useExistingServerForm.get('useExistingServer').value"
                    [disabled]="myForm.pristine || !myForm.valid"
                    type="submit" mat-raised-button color="accent">Salvar
            </button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>

</div>
</form>
