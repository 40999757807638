import {Injectable, OnDestroy} from '@angular/core';
import {R2CloudClientSubscriber} from './r2-cloud-client-subscriber';
import {LinuxServerStatus, ServerStatusType} from '../models';

import {StompConnectionStatus} from './stomp-connection-status.enum';
import {Observable, Observer, Subscription} from 'rxjs';
import {USER_TOPIC} from '../../../main';
import {R2CloudStompService} from './r2-cloud-stomp.service';

@Injectable({
  providedIn: 'root'
})
export class LinuxServerStatusService implements OnDestroy {

    private subscribers: R2CloudClientSubscriber<LinuxServerStatus>[] = [];
    private connected = false;
    private status: LinuxServerStatus[] = [];
    private subscriptions: Subscription[] = [];

    constructor(private stomp: R2CloudStompService) {
        const subscription = this.stomp.connectionStatus$.subscribe((stompStatus: StompConnectionStatus) => {
            if (stompStatus === StompConnectionStatus.OK) {
                this.connected = true;
            } else {
                this.connected = false;
                for (const status of this.status){
                    this.emit({linuxServerId: status.linuxServerId, status: ServerStatusType.DISCONNECTED, clientVersion: '-'});
                }
                this.status = [];
                this.emit({linuxServerId: 0, status: ServerStatusType.STOMP_DISCONNECT, clientVersion: '-'});
            }
        });
        this.subscriptions.push(subscription);
        const subscription1 = this.stomp.stompTopic<LinuxServerStatus>(`${USER_TOPIC}/linux-server-status`).subscribe((serverStatus) => {
            // console.debug('received LinuxServerStatus:');
            // console.debug(serverStatus);
            if (serverStatus.status === ServerStatusType.CONNECTED) {
                const index = this.status.findIndex((item) => {
                    return serverStatus.linuxServerId === item.linuxServerId;
                });
                if (index === -1) {
                    this.status.push(serverStatus);
                }
            } else if (serverStatus.status === ServerStatusType.DISCONNECTED) {
                const index = this.status.findIndex((item) => {
                    return serverStatus.linuxServerId === item.linuxServerId;
                });
                if (index > -1) {
                    this.status.splice(index, 1);
                }
            }
            this.emit(serverStatus);
        });
        this.subscriptions.push(subscription1);
    }

    public serverStatusChanges(): Observable<LinuxServerStatus> {
        return new Observable<LinuxServerStatus>((observer: Observer<LinuxServerStatus>) => {
            const r2CloudClientSubscriber = new R2CloudClientSubscriber<LinuxServerStatus>(observer);
            this.subscribers.push(r2CloudClientSubscriber);
            this.next(observer);
            return () => {
                console.debug('DesktopServerStatusService serverStatusChanges unsubscribe');
                this.subscribers = this.subscribers.filter((value, index, array) => {
                    return r2CloudClientSubscriber.id !== value.id;
                });
                observer.complete();
            };
        });
    }

    public isServerConnected(linuxServerId: number): boolean {
        const b = this.status.findIndex((item) => item.linuxServerId === linuxServerId && item.status === ServerStatusType.CONNECTED) > -1;
        // console.debug('linuxServerId: ' + linuxServerId + ' isConnected: ' + b);
        // console.debug(this.status);
        // console.debug('LinuxServerStatusService isConnected: ' + this.connected);
        return b;
    }

    private next(observer: Observer<LinuxServerStatus>): void {
        for (const status of this.status) {
            observer.next(status);
        }

    }

    private emit(status: LinuxServerStatus): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.next(status);
        }
    }

    ngOnDestroy(): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.complete();
        }
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }
}
