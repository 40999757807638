import {Component, Inject, OnInit} from '@angular/core';
import {CustomerGroup, RemoteAppHtml5, RemoteAppReadyStatus} from '../../../models';
import {guessImageMime} from '../../../helpers/kluh';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map, scan} from 'rxjs/operators';
import {CustomerGroupService} from '../../../main/customer-group/customer-group.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {RemoteAppHtml5Service} from '../../remote-app-html5.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ImageFileService} from '../../../main/image-file/image-file-service';

@Component({
    selector: 'app-app-drawer',
    templateUrl: './app-drawer.component.html',
    styleUrls: ['./app-drawer.component.scss'],
    animations: fuseAnimations
})
export class AppDrawerComponent extends ComponentCleaner implements OnInit {
    remoteAppHtml5List: RemoteAppHtml5[];
    customStyle = {
        color: '#ffffff',
        padding: '15px 5px 5px 5px'
    };

    constructor(public dialogRef: MatDialogRef<AppDrawerComponent>,
                private customerGroupService: CustomerGroupService,
                private remoteAppHtml5Service: RemoteAppHtml5Service,
                public imageFileService: ImageFileService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();

        const observable = this.remoteAppHtml5Service.remoteAppChanges().pipe(scan((acc: RemoteAppHtml5[], value: RemoteAppHtml5) => {
            const index = acc.findIndex((item) => item.remoteAppId === value.remoteAppId);
            if (index > -1){
                acc[index] = value;
            } else {
                acc.push(value);
            }
            return acc;
        }, []));

        this.addSubscription(combineLatest([observable, this.customerGroupService.get()]).pipe(map((value: [RemoteAppHtml5[], CustomerGroup]) => {
            const remoteAppHtml5List = value[0];
            const customerGroup = value[1];
            return remoteAppHtml5List.filter(value2 => {
                return value2.customerGroupId === customerGroup?.id;
            });
        })).subscribe(remoteAppHtml5FilteredList => {
            this.remoteAppHtml5List = remoteAppHtml5FilteredList.sort((a, b) => {
                const aStatus = this.translateStatusToNumber(a);
                const bStatus = this.translateStatusToNumber(b);
                if (aStatus === bStatus) {
                    return (a.remoteAppId > b.remoteAppId) ? 1 : -1;
                } else {
                    return (aStatus > bStatus) ? -1 : 1;
                }
            });
        }));



        //
        // this.remoteAppHtml5List = data.remoteAppHtml5List.sort((a, b) => {
        //     const aStatus = this.translateStatusToNumber(a);
        //     const bStatus = this.translateStatusToNumber(b);
        //     if (aStatus === bStatus) {
        //         return (a.remoteAppId > b.remoteAppId) ? 1 : -1;
        //     } else {
        //         return (aStatus > bStatus) ? -1 : 1;
        //     }
        // });


    }

    private translateStatusToNumber(remoteAppHtml5: RemoteAppHtml5): number {
        if (remoteAppHtml5.status === RemoteAppReadyStatus.READY) {
            return 1;
        } else {
            return -1;
        }
    }

    ngOnInit(): void {
    }

    public guessImageMime(base64: string): string {
        return guessImageMime(base64);
    }

    onOpen(remoteAppHtml5: RemoteAppHtml5): void {
        this.dialogRef.close(remoteAppHtml5);
    }

}
