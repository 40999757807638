import {AbstractControl, ValidatorFn} from '@angular/forms';

export function jsonValidator(keysToCheck: string[]): ValidatorFn{
  return (control: AbstractControl): any => {
    const error = function (value: string): any {
      return {'invalidJson': {value: value}};
    };
    const json = control.value;
    if (json) {
      const regex = /"([^"]+)":/g;
      const keys = json.match(regex);
      if (keys) {
        for (const obj of keysToCheck) {
          const keyToCheck = '"' + obj + '":';
          const key = keys.find((entry): boolean => {
            return entry === keyToCheck;
          });
          if (!key) {
            return error('Faltando campo ' + obj);
          }
        }
      } else {
        return error('Json Inválido');
      }
    } else {
      return error('Json Inválido');
    }
    return null;
  };
}

