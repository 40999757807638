import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubProjectDaoService} from './sub-project-dao.service';
import {SubProjectCreateEditComponent} from './sub-project-create-edit/sub-project-create-edit.component';
import {KluhSharedModule} from '../../../kluh-shared.module';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../../@fuse/components/index';
import {SubProjectDialogComponent} from './sub-project-dialog/sub-project-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    FuseSharedModule,
    FuseWidgetModule,
    KluhSharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatToolbarModule,
    MatDialogModule,
    MatSlideToggleModule,
  ],
  declarations: [SubProjectCreateEditComponent, SubProjectDialogComponent],
  providers: [
    SubProjectDaoService
  ],
  entryComponents: [SubProjectCreateEditComponent, SubProjectDialogComponent]
})
export class SubProjectModule { }
