import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ADDomain, ADGroup, CustomerGroup, SubProject} from '../../../models';
import {AdGroupDaoService} from '../../ad-group/ad-group-dao.service';
import {StatusService} from '../status-service';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';

@Component({
    selector: 'app-ad-group-changing-or-deleting',
    templateUrl: './ad-group-changing-or-deleting.component.html',
    styleUrls: ['./ad-group-changing-or-deleting.component.scss', '../status.component.scss']
})
export class AdGroupChangingOrDeletingComponent {

    @Input()
    adDomains: ADDomain[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    @Input()
    adGroups: ADGroup[];

    // @Input()
    // remoteApps: RemoteApp[];

    adGroupsPendingOrDeleting: ADGroup[];


    @Output()
    adGroupsRead: EventEmitter<ADGroup[]> = new EventEmitter();


    loading = false;
    alreadyRead = false;


    constructor(private adGroupDaoService: AdGroupDaoService,
                public statusServer: StatusService) {

    }


    private getData(): void {
        this.loading = true;
        this.alreadyRead = true;
        this.getAdGroups().subscribe(adGroups => {
            this.adGroupsRead.emit(adGroups);
            this.adGroupDaoService.getAllAdGroupsPendingOrDeleting().subscribe((adGroupsPendingOrDeleting) => {
                this.loading = false;
                this.adGroupsPendingOrDeleting = adGroupsPendingOrDeleting;
            });
        });

    }

    getAdGroups(): Observable<ADGroup[]> {
        this.adGroupDaoService.get().subscribe((adGroups) => {
            this.adGroups = adGroups;
        });
        if (!this.adGroups || this.adGroups?.length < 1 || this.alreadyRead) {
            console.log('getAdGroups()');
            return this.adGroupDaoService.get();
        }
        return of(this.adGroups);
    }
}
