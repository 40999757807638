import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {DesktopServerDaoService} from '../../../desktop-server/desktop-server-dao.service';
import {faLinux, faWindows} from '@fortawesome/free-brands-svg-icons';
import {faDesktop} from '@fortawesome/free-solid-svg-icons';
import {DesktopServer, LinuxServer, ServerStatus, ServerStatusType, SubProject} from '../../../../models';
import {DesktopServerStatusService} from '../../../../ws/desktop-server-status.service';
import {Router} from '@angular/router';
import {R2CloudClientService} from '../../../../ws/r2-cloud-client.service';
import {LinuxServerStatusService} from '../../../../ws/linux-server-status.service';
import {LinuxServerDaoService} from '../../../linux-server/linux-server-dao.service';
import {ComponentCleaner} from '../../../../component-cleaner';

@Component({
    selector: 'app-sub-project-support-card',
    templateUrl: './sub-project-support-card.component.html',
    styleUrls: ['./sub-project-support-card.component.scss'],
    animations: fuseAnimations
})
export class SubProjectSupportCardComponent extends ComponentCleaner {
    private _subProject: SubProject;
    private _desktopServers: DesktopServer[] = [];
    private _desktopServerStatusDict = {};
    private _linuxServers: LinuxServer[];
    public faWindows = faWindows;
    public faLinux = faLinux;
    public faDesktop = faDesktop;

    @Output()
    public connect: EventEmitter<DesktopServer> = new EventEmitter<DesktopServer>();

    @Output()
    public linuxConnect: EventEmitter<LinuxServer> = new EventEmitter<LinuxServer>();

    @Output()
    public desktopServerSelect: EventEmitter<DesktopServer> = new EventEmitter<DesktopServer>();

    @Output()
    public linuxServerSelect: EventEmitter<LinuxServer> = new EventEmitter<LinuxServer>();

    @Output()
    public close: EventEmitter<void> = new EventEmitter<void>();


    constructor(private desktopServerDao: DesktopServerDaoService,
                private desktopServerStatusService: DesktopServerStatusService,
                private linuxServerDao: LinuxServerDaoService,
                private linuxServerStatusService: LinuxServerStatusService,
                private router: Router,
                private clientStatus: R2CloudClientService) {
        super();
    }

    @Input()
    set subProject(input: SubProject) {
        this._subProject = input;
        this.desktopServerDao.filter({subProjectId: this._subProject.id}).subscribe((result) => {
            this._desktopServers = result;
            for (const desktopServer of this._desktopServers) {
                this.addSubscription(
                    this.desktopServerStatusService.onDesktopServerStatus(desktopServer.id).subscribe((result2) => {
                        this._desktopServerStatusDict[result2.desktopServerId] = result2;
                    })
                );
            }
        });
        this.linuxServerDao.filter({subProjectId: this._subProject.id}).subscribe((result) => {
            this._linuxServers = result;
        });
    }

    get subProject(): SubProject {
        return this._subProject;
    }

    get desktopServers(): DesktopServer[] {
        return this._desktopServers;
    }

    get linuxServers(): LinuxServer[] {
        return this._linuxServers;
    }

    onConnect(desktopServer: DesktopServer): void {
        this.connect.emit(desktopServer);
    }

    onLinuxServerConnect(linuxServer: LinuxServer): void {
        this.linuxConnect.emit(linuxServer);
    }

    onDesktopServerSelect(desktopServer: DesktopServer): void {
        this.desktopServerSelect.emit(desktopServer);
    }

    onLinuxServerSelect(linuxServer: LinuxServer): void {
        this.linuxServerSelect.emit(linuxServer);
    }

    onClose(): void {
        this.close.emit();
    }

    onEdit(): void {
        this.router.navigate(['/admin'], {queryParams: {subProjectId: this.subProject.id, projectId: this.subProject.projectId}});
    }

    isConnected(desktopServer: DesktopServer): boolean {
        const desktopServerStatus: ServerStatus = this._desktopServerStatusDict[desktopServer.id];
        if (desktopServerStatus){
            return desktopServerStatus.status === ServerStatusType.CONNECTED;
        }
        return false;
    }

    isLinuxServerConnected(linuxServer: LinuxServer): boolean {
        return this.linuxServerStatusService.isServerConnected(linuxServer.id);
    }

    isClientConnected(): boolean {
        return this.clientStatus.isClientConnected();
    }
}
