<fuse-widget class="widget" fxLayout="row" [@animate]="{value:'*',params:{y:'100%'}}">
  <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
    <div class="px-16 border-bottom cliente-header" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutWrap>

      <div fxFlex class="py-8 h3"><span class="cliente-title">{{dataCenter.name}}</span></div>

      <div fxFlex="0 1 auto" class="py-8" fxLayout="row" *appIsAdmin>
        <div matTooltip="Habilitar Edição" *ngIf="!isEditing()">

          <button type="button" style="margin-bottom: 4px"  (click)="onEdit()" mat-raised-button  aria-label="Salvar">Habilitar Edição</button>

        </div>
        <div matTooltip="Sair da Edição" *ngIf="isEditing()">
          <button type="button" mat-icon-button class="fuse-widget-flip-button" aria-label="more"
                  (click)="onCancel()">
            <mat-icon>undo</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-between stretch">
      <mat-tab-group class="cliente-tab-header" (selectedIndexChange)="onTabChange($event)">
        <mat-tab label="Hosts" [disabled]="isEditing()">
          <app-instance-list [modelInstances]="instances" [editing]="editing1" [snapshotPolicyTypes]="snapshotPolicyTypes">

          </app-instance-list>
        </mat-tab>
        <mat-tab label="Roteador" [disabled]="isEditing()">
          <div class="cliente-tab-body">
            <!--<app-customer-router [modelCustomer]="customer" [modelVpnConfig]="vpnConfig" [(editing)]="editing2">-->
            <!--</app-customer-router>-->
            <app-data-center-router [projectName]="project.name" [modelDataCenter]="dataCenter" [modelVpnConfig]="vpnConfig" [(modelEditing)]="editing2"></app-data-center-router>
          </div>
        </mat-tab>
        <mat-tab *ngIf="dataCenter.type === 'Local'" label="Infra-estrutura" [disabled]="isEditing()">

        </mat-tab>
        <mat-tab *ngIf="dataCenter.type === 'Google'" label="Google" [disabled]="isEditing()">
          <app-cloud-config [dataCenter]="dataCenter" [(editing)]="editing3" [(modelInstances)]="instances" [modelCloudConfig]="cloudConfig">

          </app-cloud-config>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</fuse-widget>
