<div class="fuse-card blue-color">
    <div class="p-16" fxLayout="row" fxLayoutAlign="space-between start">

        <div class="pr-16">
            <div class="h4 secondary-text">Servidor</div>
            <div class="h1">{{linuxServer.hostname}}</div>
        </div>

        <div class="w-80 h-80">
            <div class="close-button">
                <button type="button" mat-mini-fab class="fuse-white-bg my-fab" (click)="onClose()">
                    <mat-icon aria-label="Fechar" color="warn">close</mat-icon>
                </button>
            </div>
            <img src="assets/kluh/images/linux-logo.png">
        </div>



    </div>

    <div class="p-16 pt-0 line-height-1.75" *ngIf="linuxServer.comment">
        {{linuxServer.comment}}
    </div>

    <div class="card-divider"></div>


    <div class="p-16 pt-0 pb-0">
        <div fxLayout="column" fxLayoutAlign="center">
            <div fxLayout="row" fxLayoutAlign="center start" class="mb-12">
                <div fxFlex="30" class="linux-server-prop-label">
                    IP público:
                </div>
                <div fxFlex="70" class="linux-server-prop">
                    {{linuxServer.publicIp}}
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start">
                <div fxFlex="30" class="linux-server-prop-label">
                    IP privado:
                </div>
                <div fxFlex="70" class="linux-server-prop">
                    {{linuxServer.privateIp}}
                </div>
            </div>
            <!--<div fxLayout="column" fxLayoutAlign="start end" class="mr-8">-->
            <!--<div>-->
            <!--IP público:-->
            <!--</div>-->
            <!--<div>-->
            <!--IP privado:-->
            <!--</div>-->
            <!--</div>-->
            <!--<div fxLayout="column" fxLayoutAlign="start start" class="ml-8">-->
            <!--<div>-->
            <!--{{linuxServer.publicIp}}-->
            <!--</div>-->
            <!--<div>-->
            <!--{{linuxServer.privateIp}}-->
            <!--</div>-->
            <!--</div>-->
        </div>
        <!--<div class="mb-12" fxLayout="row" fxLayoutAlign="space-between center"-->
        <!--*ngFor="let linuxServer of linuxServers">-->
        <!--<div>{{linuxServer.hostname}}</div>-->
        <!--<div fxLayout="row" fxLayoutAlign="start center">-->
        <!--<fa-icon class="s-16 mr-8 windows-icon" [icon]="faWindows"></fa-icon>-->
        <!--<mat-icon class="s-16 mr-8 online" title="onLine" *ngIf="isConnected(linuxServer); else offline">check_circle</mat-icon>-->
        <!--<ng-template #offline>-->
        <!--<mat-icon class="s-16 mr-8 offline" title="offLine">error</mat-icon>-->
        <!--</ng-template>-->
        <!--<button type="button" mat-button color="primary" (click)="isConnected(linuxServer) && onConnect(linuxServer)" [disabled]="!isConnected(linuxServer)">-->
        <!--<fa-icon [icon]="faDesktop"></fa-icon>-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->
    </div>

    <div class="card-divider"></div>

    <div class="p-16 pt-0 pb-0">
        <div class="h4 secondary-text">Projetos</div>
        <a [routerLink]="" class="sub-project-link" *ngFor="let subProject of subProjects" (click)="onSelectSubProject(subProject)">
            {{subProject.name}}
        </a>
    </div>

    <div class="card-divider"></div>
    <div class="p-16 pt-4">
        <button type="button" mat-raised-button (click)="isConnected() && isClientConnected() && onConnect()" [disabled]="!isConnected() || !isClientConnected()">Conectar</button>
    </div>

</div>