import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {SessionProviderService} from '../../../html5/session-provider.service';
import {ScriptExecutionDaoService} from '../../script-execution/script-execution-dao.service';
import {TesteAuthDaoService} from './teste-auth.dao.service';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
    test: Observable<string>;
    serverAgentTokenControl: FormControl = new FormControl('');
    serverPublicIpControl: FormControl = new FormControl('');
    // testServerAgent: TestServerAgent;
    testServerAgentError: any;

    constructor(private http: R2CloudHttpApiService,
                private sessionProvider: SessionProviderService,
                private testeAuthDaoService: TesteAuthDaoService,
                private scriptExecutionDaoService: ScriptExecutionDaoService) {
        // this.getTest();
    }

    getTest(): void {
        this.test = this.http.get<string>('test');
    }

    onTestServerAgent(): void {
        let tokenString: string = this.serverAgentTokenControl.value;
        const publicIp: string = this.serverPublicIpControl.value;
        tokenString = tokenString.trim()
            .replace('GET /stomp/login?', '')
            .replace(' HTTP/1.1', '')
            .replace(/\r?\n|\r/g, '');
        const strings = tokenString.split('&');
        const token = {};
        for (const string of strings) {
            const pair = string.split('=');
            let encodedURIComponent;
            try {
                encodedURIComponent = pair[1].replace(/\+/g, '%20');
            } catch (e) {
                encodedURIComponent = '';
            }
            token[pair[0]] = decodeURIComponent(encodedURIComponent);
        }
        // const serverAgentToken: TestServerAgentToken = {
        //     clientId: token['X-Client-Id'],
        //     publicIp: publicIp,
        //     token: token['X-Server-Auth']
        // };
        // this.http.post<TestServerAgent>('test/server-agent-token', serverAgentToken).subscribe((result) => {
        //     this.testServerAgent = result;
        //     this.testServerAgentError = null;
        // }, (error) => {
        //     this.testServerAgentError = error;
        //     this.testServerAgent = null;
        // });
    }

    ngOnInit(): void {

    }


    onCreateScriptExecution(): void {

        // const scriptExecution: ScriptExecution = {
        //     id: null,
        //     comment: null,
        //     active: true,
        //     script: 'logoff (((quser /server:winlocalapps | Where-Object { $_ -match \'fabio.2067\' }) -split ‘ +’)[3]) /server:’winlocalapps’',
        //     desktopServerId: 86,
        //     linuxServerId: null,
        //     managerUserId: 4,
        //     pending: true,
        //     scriptExecutionType: ScriptExecutionType.USER_LOGOFF,
        //     errorDescription: null,
        //     numbersOfErrors: null,
        //     maximumNumberOfAttempts: 5
        // };
        //
        //
        // this.scriptExecutionDaoService.create(scriptExecution).subscribe((response) => {
        //     if (response) {
        //         console.debug(response);
        //     }
        // });
        //
        // this.scriptExecutionDaoService.onUserLogOff(86, 'fabio.2067', 4).subscribe((response) => {
        //     if (response) {
        //         console.debug('onUserLogOff: ' + response);
        //     }
        // });


    }


    onErrorSimulate(): void {
        throw new Error('Oooops valendo!');
    }

    onTestAuth(): void {
        this.testeAuthDaoService.get().subscribe(valendo => {
            // console.log('valendo = ' + valendo[0]);
        });
    }
}
