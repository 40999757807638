<div id="support" class="page-layout simple fullwidth">


    <!-- HEADER -->
    <div class="header mat-accent-bg" fxLayout="row" fxLayoutAlign="start end">
        <div class="header-content" fxLayout="column" fxLayoutAlign="space-between">
            <div class="header-top" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="mat-display-1 mb-0" [@animate]="{value:'*',params:{x:'50px'}}">
                    <mat-form-field [hidden]="onHideSelectProject()" class="mat-form-field-select-project">
                        <input type="text" placeholder="Selecione o Grupo" aria-label="Selecione o Projeto" matInput
                               [formControl]="selectProjectForm"
                               [matAutocomplete]="auto" (click)="clear()" (keyup.enter)="selectFirst()"
                               #selectProjectElement>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button type="button" class="button-clear-select-project" *ngIf="selectProjectForm?.value?.id"
                            mat-mini-fab (click)="clear()">
                        <mat-icon>list</mat-icon>
                    </button>
                    <button type="button" (click)="onCreateProject()" fxHide fxShow.gt-xs
                            style="position: absolute; right: 15px; top: 12px;"
                            mat-raised-button aria-label="Criar Projeto">Criar novo Projeto
                    </button>
                </div>
                <div class="selected-customerGroup" *ngIf="selectProjectForm?.value?.name">
                    {{selectProjectForm.value.name}}
                </div>
            </div>
        </div>

    </div>

    <!-- CONTENT -->
    <div class="content" *ngIf="selectProjectForm?.value?.id" fusePerfectScrollbar>
        <mat-tab-group  dynamicHeight>
            <mat-tab label="Data Centers">
                <!--<div class="widget-group p-12" fxLayout="row" fxFlex="100" fxLayoutWrap *fuseIfOnDom fxLayoutAlign="space-around none" [@animateStagger]="{value:'50'}">-->
                <app-data-center-list [modelProjects]="projects" [modelProject]="selectProjectForm.value"
                                      [vpnConfig]="vpnConfig"></app-data-center-list>
            </mat-tab>
            <mat-tab label="Unidades">

                <!--<div class="widget-group p-12" fxLayout="row" fxFlex="100" fxLayoutWrap *fuseIfOnDom fxLayoutAlign="space-around none"-->
                <!--[@animateStagger]="{value:'50'}">-->
                <!--<ng-container *ngFor="let customer of customers | async">-->

                <!--<app-customer-config [customer]="customer" [vpnConfig]="vpnConfig | async" [@animate]="{value:'*',params:{y:'100%'}}">-->

                <!--</app-customer-config>-->
                <!--</ng-container>-->
                <!--</div>-->
            </mat-tab>
            <ng-container *appIsAdmin>
                <mat-tab label="Configuração" *ngIf="selectProjectForm?.value?.id">
                    <app-project-vpn-config [modelCustomerGroup]="selectProjectForm?.value"
                                            [modelVpnConfig]="vpnConfig"></app-project-vpn-config>
                    <!--<app-customer-group-cloud-config [modelCustomerGroup]="selectCustomerGroupForm.value"></app-customer-group-cloud-config>-->
                </mat-tab>
            </ng-container>
        </mat-tab-group>
    </div>
</div>

