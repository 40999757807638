<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()" *ngIf="myForm">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Aplicativo Remoto {{remoteApp.name}}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2 remote-app" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

            <mat-slide-toggle formControlName="serviceApp" name="serviceApp">
                Aplicativo Serviço
            </mat-slide-toggle>


            <mat-divider class="m-12"></mat-divider>
            <hr class="m-12 border-none"/>

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="name" placeholder="Nome"  >
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>


            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Servidor</mat-label>
                <mat-select placeholder="Servidor" formControlName="desktopServerId"
                            [disabled]="myForm.get('id').value">
                    <mat-option *ngFor="let desktopServer of desktopServers" [value]="desktopServer.id">
                        {{desktopServer.hostname}}.{{getAdDomainName(desktopServer.adDomainId)}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('desktopServerId').errors | error}}
                </mat-error>
            </mat-form-field>


            <mat-form-field *ngIf="!myForm.get('serviceApp').value"  class="full-width h-70" appearance="outline">
                <mat-label>Executável</mat-label>
                <input matInput formControlName="app" placeholder="Executável">
                <mat-error>
                    {{myForm.get('app').errors | error}}
                </mat-error>
            </mat-form-field>

            <button type="button" class="mb-30" mat-raised-button color="primary" *ngIf="!myForm.get('serviceApp').value"
                    [disabled]="!desktopServer || !desktopServerStompUsername"
                    (click)="desktopServer && desktopServerStompUsername && onOpenFileBrowser()">Procurar
            </button>


            <ng-container *ngIf="!myForm.get('serviceApp').value">
                <mat-form-field *ngIf="adGroups && adGroups.length > 0"  class="full-width  h-70" appearance="outline">
                    <mat-label>Grupos</mat-label>
                    <mat-select placeholder="Grupos" formControlName="adGroupIds" multiple>
                        <mat-option *ngFor="let adGroup of adGroups" [value]="adGroup.id">
                            {{adGroup.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="adUsers && adUsers.length > 0" class="full-width  h-70" appearance="outline">
                    <mat-label>Usuários</mat-label>
                    <mat-select placeholder="Usuários" formControlName="adUserIds" multiple>
                        <mat-option *ngFor="let adUser of adUsers" [value]="adUser.id">
                            {{getManagerUserEmail(adUser.managerUserId)}} - {{adUser.login}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *appIfPermission="appCanCreateADUserPoolRelationship">
                    <mat-form-field *ngIf="adUsers && adUsers.length > 0" class="full-width" appearance="outline">
                        <mat-label>Usuários do pool</mat-label>
                        <mat-select placeholder="Usuários do pool" formControlName="adUserPoolRelationshipIds" multiple>
                            <mat-option *ngFor="let adUserPoolRelationship of adminService.findADUsersPoolRelationshipByADUserPoolService(false)" [value]="adUserPoolRelationship.id">
                                {{adminService.getManagerUser(adUserPoolRelationship.managerUserId).email}} - {{adminService.getAdUserPool(adUserPoolRelationship.adUserPoolId).login}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{myForm.get('adUserPoolRelationshipIds').errors | error}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="myForm.get('serviceApp').value">
                <mat-form-field  class="full-width h-70" appearance="outline">
                    <mat-label>Usuários de Serviço</mat-label>
                    <mat-select placeholder="Usuários de Serviço" (valueChange)="onChangeSelect(false)" formControlName="adUserIds" multiple>
                        <mat-option #adUserOption *ngFor="let adUser of adUsers" [value]="adUser.id"
                                    [disabled]="myForm.get('adUserIds').value.length === 1 && !adUserOption.selected">
                            {{adUser.login}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{myForm.get('adUserIds').errors | error}}
                    </mat-error>
                </mat-form-field>
                <div *appIfPermission="appCanCreateADUserPoolRelationship">
                    <mat-form-field *ngIf="adminService.findADUsersPoolRelationshipByADUserPoolService(true) && adminService.findADUsersPoolRelationshipByADUserPoolService(true).length > 0" class="full-width" appearance="outline">
                        <mat-label>Usuários serviço do pool</mat-label>
                        <mat-select placeholder="Usuários do pool"  (valueChange)="onChangeSelect(true)" formControlName="adUserPoolRelationshipIds" multiple>
                            <mat-option #adUserPoolRelationshipOption *ngFor="let adUserPoolRelationship of adminService.findADUsersPoolRelationshipByADUserPoolService(true)" [value]="adUserPoolRelationship.id"
                                        [disabled]="myForm.get('adUserPoolRelationshipIds').value.length === 1 && !adUserPoolRelationshipOption.selected"
                            >
                                {{adminService.getAdUserPoolService(adUserPoolRelationship.adUserPoolId).login}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{myForm.get('adUserPoolRelationshipIds').errors | error}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    Avançado
                </mat-expansion-panel-header>
                <div fxLayout="column" fxLayoutAlign="none stretch">
                    <mat-form-field  class="full-width" appearance="outline">
                        <mat-label>Args do executável</mat-label>
                        <input matInput formControlName="appArgs" placeholder="Args do executável">
                        <mat-error>
                            {{myForm.get('appArgs').errors | error}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field  class="full-width" appearance="outline">
                        <mat-label>Atraso na abertura do programa em milisegundos</mat-label>
                        <input matInput formControlName="openDelay"
                               placeholder="Atraso na abertura do programa em milisegundos">
                        <mat-error>
                            {{myForm.get('openDelay').errors | error}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="myForm.get('id').value"  class="full-width" appearance="outline">
                        <mat-label>Alias</mat-label>
                        <input matInput formControlName="alias" placeholder="Alias" [readonly]="myForm.get('id').value">
                        <mat-error>
                            {{myForm.get('alias').errors | error}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Comentários</mat-label>
                        <textarea rows="4" matInput formControlName="comment" placeholder="Comentários">
                        </textarea>
                        <mat-error>
                            {{myForm.get('comment').errors | error}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>

            <div class="remote-app-search-icon">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Buscar icones</mat-label>
                    <input placeholder="Buscar icones" [formControl]="searchIconsCtrl" matInput fxFlex
                           autocomplete="off"
                           (focus)="onFocusSearch()"
                           (focusout)="onFocusOutSearch()"
                    >
                </mat-form-field>
                <ul class="suggestions" *ngIf="showSuggestions && searchIconsCtrl.value">
                    <li *ngIf="loading" style="width: 100%;">
                        <img src="{{domainPathService.staticURL}}icons/loading.gif" width="15"
                             height="15" alt="Carregando"> Carregando...
                    </li>
                    <li *ngFor="let imageUUID of imageUUIDList;" (mousedown)="selectImageFile(imageUUID.value)"
                        [ngClass]="[imageUUID.value === imageFileIdSelected ? ' active' : '']">
                        <img src="{{imageFileService.getRemoteAppPictureUrl(imageUUID.value, 64)}}" width="64" height="64">
                    </li>
                </ul>
            </div>
            <div class="text-align-center p-5">ou</div>
            <button type="button" mat-raised-button (click)="inputFile.click()" style="min-height: 36px;">
                <mat-icon>library_add</mat-icon>
                <span>Enviar arquivo do icone</span>
                <input #inputFile type="file" (change)="onImageFileChange($event)" style="display:none;"
                       accept=".png,.jpg,.gif,.bmp"/>
            </button>
            <div *ngIf="imageFileElement || imageFileIdSelected" class="remote-app-background mt-8"
                 fxLayout="column"
                 fxLayoutAlign="center center">
                <div class="remote-app-icon-wrapper fuse-card" fxLayout="column" fxLayoutAlign="center center">
                    <div class="remote-app-icon" fxLayout="column" fxLayoutAlign="center center">

                        <img *ngIf="imageFileElement"
                             [src]="'data:image/png;base64,' + imageFileElement" width="64" height="64"
                             class="mt-15">
                        <img *ngIf="imageFileIdSelected"
                             src="{{imageFileService.getRemoteAppPictureUrl(imageFileIdSelected, 64)}}" width="64"
                             height="64" class="mt-15">
                        <div
                            class="card-supporting-text remote-app-name secondary-text mt-8">{{myForm.get('name').value}}</div>
                    </div>
                </div>
            </div>

            <hr class="m-12 border-none"/>


            <mat-slide-toggle formControlName="active" name="active" class="mt-20">
                Ativo
            </mat-slide-toggle>


            <hr class="m-12 border-none"/>

        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <div matTooltip="Salvar">


            <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                    aria-label="Salvar">

                <span *ngIf="this.remoteApp.id;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>

            </button>
        </div>
        <div *ngIf="this.remoteApp.id">
            <div *appIfPermission="'AUDIT'; data: this.remoteApp; type: 'RemoteApp'">
                <button type="button" mat-raised-button color="accent" (click)="onHistory()">Histórico</button>
            </div>

            <button type="button" mat-raised-button color="warn" (click)="onRemove()">Excluir</button>
            <button type="button" mat-raised-button color="warn" (click)="onForceDelete()">Exclusão forçada</button>
        </div>

        <div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>


    </div>
</form>
