import {Component, Input, OnInit} from '@angular/core';
import {DesktopServer, FirebaseUserDetails, ServerStatusType} from '../../../../models';
import {R2CloudAdminService} from '../../r2-cloud-admin.service';
import {faWindows} from '@fortawesome/free-brands-svg-icons';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {OpenModalAdAdminComponent} from '../../../ad-admin/open-modal-ad-admin/open-modal-ad-admin.component';
import {MatDialog} from '@angular/material/dialog';
import {DesktopServerCreateComponent} from '../../../desktop-server/desktop-server-create/desktop-server-create.component';
import {filter, take} from 'rxjs/operators';
import {CrudOperationWrapper} from '../../../../helpers/kluh';
import {ComponentCleaner} from '../../../../component-cleaner';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {ManagerUserPermissionsDaoService} from '../../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {DesktopServerStatusService} from '../../../../ws/desktop-server-status.service';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {R2CloudStompService} from '../../../../ws/r2-cloud-stomp.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[app-r2-cloud-admin-desktop-server-item]',
    templateUrl: './r2-cloud-admin-desktop-server-item.component.html',
    styleUrls: ['./r2-cloud-admin-desktop-server-item.component.scss']
})
export class R2CloudAdminDesktopServerItemComponent extends ComponentCleaner implements OnInit {
    public faWindows = faWindows;
    public faCircle = faCircle;
    public online = false;
    public version = '-';

    @Input('desktopServerCallback')
    private desktopServerCallback: (result: CrudOperationWrapper[]) => void;

    @Input('app-r2-cloud-admin-desktop-server-item')
    public desktopServer: DesktopServer;

    public user: FirebaseUserDetails;

    public isAdmin = false;

    constructor(
        public adminService: R2CloudAdminService,
        private dialog: MatDialog,
        private stomp: R2CloudStompService,
        private userAuthorityDao: UserAuthorityDaoService,
        private managerUserPermissionsDao: ManagerUserPermissionsDaoService,
        public customerGroupService: CustomerGroupService,
        private desktopServerStatusService: DesktopServerStatusService
    ) {
        super();
        this.userAuthorityDao.getMe().subscribe((user) => {
            this.user = user;
        });
        this.managerUserPermissionsDao.getMyUserPermissions().pipe(take(1)).subscribe((result) => {
            if (result.admin) {
                this.isAdmin = true;
            }
        });
    }

    ngOnInit(): void {
        this.addSubscription(
            this.desktopServerStatusService.onDesktopServerStatus(this.desktopServer.id).subscribe((result) => {
                this.version = result.clientVersion;
                this.online = result.status === ServerStatusType.CONNECTED;
            })
        );
    }

    onOpenModalADAdmin(desktopServer: DesktopServer): void {
        this.dialog.open(OpenModalAdAdminComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: desktopServer
            }
        });
    }

    onEditDesktopServer(desktopServerInput: DesktopServer): void {
        const dialogRef = this.dialog.open(DesktopServerCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                adDomains: this.adminService.adDomains,
                desktopServer: desktopServerInput,
                desktopServers: this.adminService.desktopServers,
                instances: this.adminService.instances,
                subProject: this.adminService.subProject,
                project: this.adminService.getProject(this.adminService.subProject.projectId)
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            this.desktopServerCallback(result);
        });
        this.addSubscription(subscription);
    }


    /*
    onEditVPN(desktopServer: DesktopServer, linuxServer: LinuxServer): void {
        const dialogRef = this.dialog.open(VpnMonitoringConfigurationComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                desktopServer: desktopServer,
                linuxServer: linuxServer,
                subProject: this.adminService.subProject
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            this.desktopServerCallback(result);
        });
        this.addSubscription(subscription);
    }
*/

    public openDesktopServer(desktopServer: DesktopServer): void {
        desktopServer.comment = 'LOADING';
        this.stomp.send('/stomp/open-local-admin', {id: desktopServer.id});
        setTimeout(() => {
            desktopServer.comment = '';
        }, 5000);

    }
}
