import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {AdUserPoolRelationshipDaoService} from '../ad-user-pool-relationship-dao.service';
import {AdUserPoolDaoService} from '../../ad-user-pool/ad-user-pool-dao.service';
import {combineLatest} from 'rxjs';
import {ADDomain, ADUserPool, ManagerUser, NumberADUsersPoolADDomain} from '../../../models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched, triggerFormValidation, validateEmail} from '../../../helpers/kluh';
import {ManagerUserService} from '../../manager-user/manager-user-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';

@Component({
    selector: 'app-ad-user-pool-relationship-create-component',
    templateUrl: './ad-user-pool-relationship-create.component.html',
    styleUrls: ['./ad-user-pool-relationship-create.component.scss']
})
export class AdUserPoolRelationshipCreateComponent extends ComponentCleaner {
    public myForm: FormGroup;
    public currentADDomain: ADDomain;
    public hasCustomerId = true;
    numberADUsersPoolADDomainList: NumberADUsersPoolADDomain[] = [];
    public newManagerUser: boolean;

    constructor(
        public dialogRef: MatDialogRef<AdUserPoolRelationshipCreateComponent>,
        private dialog: MatDialog,
        public adminService: R2CloudAdminService,
        private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
        private adUserPoolDaoService: AdUserPoolDaoService,
        private fb: FormBuilder,
        private managerUserService: ManagerUserService,
        private managerUserDaoService: ManagerUserDaoService,
    ) {
        super();
        let singleAdDomainId = null;
        if (this.getADDomainsWithADUserPool().length === 1) {
            singleAdDomainId = this.getADDomainsWithADUserPool()[0].id;
            this.currentADDomain = this.adminService.getAdDomain(singleAdDomainId);
        }
        this.initADUserPoolValues();

        const emailForm = this.fb.control('', [Validators.required, Validators.email]);
        const domainForm = this.fb.control(singleAdDomainId);
        const managerUserForm = this.fb.group(convertToFormGroup(this.managerUserService.initManagerUser()));
        this.myForm = this.fb.group({
            email: emailForm,
            domainForm: domainForm,
            managerUserForm: managerUserForm,
        });


        const emailForm$ = emailForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(value => {
                if (value) {
                    return validateEmail(value);
                }
                return false;
            })
        );

        const domainForm$ = domainForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            startWith(<number>singleAdDomainId),
            filter(value => {
                return value && value > 0;
            })
        );

        domainForm$.subscribe((adDomainId) => {
            this.currentADDomain = this.adminService.getAdDomain(adDomainId);
        });
        combineLatest([emailForm$, domainForm$]).subscribe((value) => {
            const email = value[0];
            const adDomainId = value[1];
            this.managerUserDaoService.findByEmail(email).subscribe((result) => {
                if (result && result.customerId) {
                    this.adUserPoolRelationshipDaoService.existsADUserPoolRelationship(
                        result.id,
                        adDomainId,
                        this.adminService.subProject.id
                    ).subscribe((exists) => {
                        if (!exists) {
                            this.hasCustomerId = true;
                            this.newManagerUser = false;
                            this.myForm.get('managerUserForm').setValue(result as any);
                            this.myForm.get('email').markAsDirty();
                            this.myForm.get('domainForm').markAsDirty();
                        } else {
                            const error = {email: 'E-mail já existe nesse projeto e domínio'};
                            this.myForm.get('email').setErrors(error);
                        }
                    });
                } else {
                    this.newManagerUser = true;
                    this.hasCustomerId = false;
                    const managerUserInput = this.managerUserService.initManagerUser();
                    managerUserInput.email = email;
                    this.myForm.get('managerUserForm').setValue(managerUserInput as any);
                }
                triggerFormValidation(managerUserForm);
            });
        });
    }

    canCreateANewADUserPoolOnCurrentADDomain(): boolean {
        if (!this.currentADDomain?.id) {
            return false;
        }
        const totalOfADUserPool = this.getTotalADUserPoolByADDomainId(this.currentADDomain?.id);
        return this.getNumberOfADUsersPoolUsedForThisADDomain(this.currentADDomain?.id) < totalOfADUserPool;
    }

    getTotalADUserPoolByADDomainId(adDomainId: number): number {
        return this.getADUserPoolListByADDomainId(adDomainId).length;
    }

    getADDomainsWithADUserPool(): ADDomain[] {
        const adDomains: ADDomain[] = [];
        this.adminService.adDomains.forEach(adDomain => {
            if (this.getTotalADUserPoolByADDomainId(adDomain.id) > 0) {
                adDomains.push(adDomain);
            }
        });
        return adDomains;
    }


    onCreate(): void {
        let managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        const subProject = this.adminService.subProject;
        const adDomainId = this.myForm.get('domainForm').value;

        const email = this.myForm.get('email').value;
        const customerId = this.myForm.get('managerUserForm').get('customerId').value;

        let managerUser$: Observable<ManagerUser>;
        let managerUserOperation: CrudOperation;
        managerUser.email = email?.trim();
        if (managerUser.id) {
            managerUser$ = of(managerUser);
            managerUserOperation = 'CANCEL';
        } else {
            managerUser$ = this.managerUserDaoService.create(managerUser);
            managerUserOperation = 'CREATE';
        }

        managerUser$.subscribe((result) => {
            managerUser = result;
            if (managerUserOperation === 'CREATE') {
                this.adminService.addManagerUser(managerUser);
            }
            this.adUserPoolRelationshipDaoService.createADUserPoolRelationship(managerUser.id, subProject.id, +adDomainId).subscribe((adUserPoolRelationship) => {
                if (adUserPoolRelationship) {
                    if (!this.adminService.getManagerUser(managerUser.id)?.email) {
                        this.adminService.addManagerUser(managerUser);
                    }
                    this.adminService.addADUserPoolRelationship(adUserPoolRelationship);
                } else {
                    console.log('ERROR: addADUserPoolRelationship');
                }
                this.dialogRef.close();
            });
        });
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    private getADUserPoolListByADDomainId(adDomainId: number): ADUserPool[] {
        const adUsersPoolADDomain: ADUserPool[] = [];
        this.adminService.adUsersPool?.forEach(adUserPool => {
            if (adUserPool.adDomainId === adDomainId && adUserPool.changing === false && adUserPool.deleting === false && !adUserPool.serviceUser) {
                adUsersPoolADDomain.push(adUserPool);
            }
        });
        return adUsersPoolADDomain;
    }

    private initADUserPoolValues(): void {
        combineLatest([
            this.adUserPoolRelationshipDaoService.filter({
                'subProjectId': this.adminService.subProject.id
            }),
            this.adUserPoolDaoService.findAllByADDomainIds(this.adminService.subProject.adDomainIds.toString()),
            this.adUserPoolRelationshipDaoService.listOfNumberOfUsersPoolUsedPerADDomainOfThisSubProjectIdByUserType(this.adminService.subProject.id, false)
        ]).subscribe((value) => {
            const adUsersPoolRelationshipAll = value[0];
            const adUsersPool = value[1];
            this.numberADUsersPoolADDomainList = value[2];


            if (adUsersPoolRelationshipAll) {
                this.adminService.adUsersPoolRelationship = adUsersPoolRelationshipAll;
            } else {
                this.adminService.adUsersPoolRelationship = [];
            }

            if (adUsersPool) {
                this.adminService.adUsersPool = adUsersPool.filter((adUserPool) => {
                    return adUserPool.serviceUser === false;
                });
                this.adminService.adUsersPoolService = adUsersPool.filter((adUserPool) => {
                    return adUserPool.serviceUser === true;
                });
            } else {
                this.adminService.adUsersPool = [];
                this.adminService.adUsersPoolService = [];
            }
        });
    }

    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'create-customer-dialog',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    getNumberOfADUsersPoolUsedForThisADDomain(aDDomainId: number): number {
        const foundNumberADUsersPoolADDomain = this.numberADUsersPoolADDomainList.find((numberADUsersPoolADDomain) => {
            return numberADUsersPoolADDomain.adDomainId === aDDomainId;
        });
        if (foundNumberADUsersPoolADDomain) {
            return foundNumberADUsersPoolADDomain.amount;
        }
        return 0;
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);
            this.myForm.get('managerUserForm').get('customerId').setValue(result.data.id);
            markAsTouched(this.myForm);
        } else if (result.operation === 'SAVE') {

        } else if (result.operation === 'DELETE') {

        }
    }
}
