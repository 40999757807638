<mat-card>
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
            <div>Usuários
                <span class="mat-subheading-2 pl-20"
                      *ngIf="adminService.managerUsers && adminService.managerUsers.length > 0">
                <span class="pl-20">{{adminService.amountOfInternalManagerUser(adminService.managerUsers)}}
                    da empresa</span>
                    <span class="pl-20">|</span>
                    <span class="pl-20">{{adminService.amountOfExternalManagerUser(adminService.managerUsers)}}
                        externos</span>
                    <span class="pl-20">|</span>
                    <span class="pl-20">{{managerUserDataSource.data.length}}
                        na lista</span>
                </span>
            </div>
            <button *appIfPermission="appCanCreateList" type="button" mat-raised-button color="accent"
                    id="register-manager-user-user-create-modal" (click)="openUserCreateModal()">
                Criar
            </button>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="no-padding">
        <div>
            <div class="float-left">
                <input matInput [formControl]="managerUserFormControl" autocomplete="off" class="search-manager-user"
                       placeholder="Busca">
            </div>
            <div class="float-left" style="margin-top: 28px;margin-left: 15px;">
                <mat-checkbox class="mt-24" (click)="onOnlineUsers()" [formControl]="onlineUsersFormControl">
                    Usuários online
                </mat-checkbox>
            </div>
        </div>
        <div style="clear: both"></div>
        <table mat-table matSort [dataSource]="managerUserDataSource"
               class="font-size-12 mt-20 width-100-percent manager-user-list">
            <!-- Name Column -->
            <ng-container matColumnDef="picture" class="width-100-percent">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="no-padding">
                    <img src="{{imageFileService.getManagerUserPicture(element)}}?w=40&square=true"
                         class="ml-8 pt-8 pb-8"
                         width="40" height="40">
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="displayName" class="width-100-percent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-120"> Nome</th>
                <td mat-cell *matCellDef="let element" class="capitalize p-5 ">{{element.displayName | lowercase}}</td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> E-mail</th>
                <td mat-cell *matCellDef="let element" class="text-max-250  pt-20">
                    <div>{{element.email | lowercase}}</div>
                    <div *ngIf="element.managerUserAccessPeriodProfileId" class="orange-A400-fg">
                        ( {{managerUserAccessPeriodService.findManagerUserAccessPeriodTimesById(element.managerUserAccessPeriodProfileId)?.name}}
                        )
                    </div>
                    <div *ngIf="!element.active" class="red">
                        DISABLE
                    </div>
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-10"> Online</th>
                <td mat-cell *matCellDef="let element">
                    <div *appIfPermission="appCanLogoutList;else noPermission">
                        <button
                            *ngIf="managerUserIsOnline$(element.id) | async"
                            class="no-padding"
                            mat-button color="warn"
                            (click)="logoutManagerUser(element)">Logout
                        </button>
                    </div>
                    <ng-template #noPermission>
                        <div *ngIf="managerUserIsOnline$(element.id) | async" class="blue-color">
                            Online
                        </div>
                    </ng-template>

                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="lastLogin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Último login</th>
                <td mat-cell *matCellDef="let element">
                    {{adminService.getManagerUserIdWithLastLoginByManagerUserId(element.id)?.lastLogin | timeDiff: 'large'}}
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="customerId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Filial</th>
                <td mat-cell *matCellDef="let element">
                    <div class="text-max-80 ">
                        <div>
                            <b>{{getCustomerGroupOfCustomer(getCustomerOfManagerUserId(element))?.name}}</b>
                        </div>
                        <div>{{getCustomerOfManagerUserId(element)?.name}}</div>
                    </div>
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="roleId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Papel</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let rolesOfCustomerGroup of getAllRoleCustomerGroupManagerUsers(element.id);">
                        <div class="text-max-120 ">{{adminService.getRole(rolesOfCustomerGroup.roleId).name}}</div>
                        <div style="clear: both;"></div>
                    </div>
                    <div class="text-max-120 " *ngIf="getAllRoleCustomerGroupManagerUsers(element.id).length < 1">
                        DeskTop
                    </div>
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index" class="no-padding w-70">
                    <div *ngIf="getCustomerOfManagerUserId(element) as customer">
                        <div *ngIf="customer.customerGroupId as customerGroupId">
                            <div *ngIf="customerGroupId">
                                <button
                                    *ngIf="(userAuthorityDaoService.hasParentPermissionCache('CustomerGroup', customerGroupId, 'ManagerUser', 'WRITE') | async)"
                                    class="no-padding"
                                    id="register-manager-user-user-edit-{{adminService.isExternalManagerUser(element)}}"
                                    mat-button color="accent"
                                    (click)="editManagerUser(element)">Editar
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="managerUserTable"></tr>
            <tr mat-row *matRowDef="let row; columns: managerUserTable;"
                [ngClass]="{'yellow-200-bg': adminService.isExternalManagerUser(row), 'manager-user-disable': !row.active}"
            ></tr>
        </table>
    </mat-card-content>
</mat-card>
