import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AuthService} from '../../auth.service';
import {DesktopComponent} from './desktop.component';
import {ClientDownloadService} from './client-download.service';
import {ClientSettingsDaoService} from './client-settings-dao.service';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


const routes = [
    {
        path: 'desktop',
        component: DesktopComponent,
        canActivate: [AuthService],
        // data: {permissions: ['DataCenter.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        MatProgressBarModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDividerModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatProgressSpinnerModule
    ],
    declarations: [DesktopComponent],
    providers: [ClientDownloadService, ClientSettingsDaoService]
})
export class DesktopModule {
}
