import {AuthService} from '../../../../auth.service';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../../../@fuse/components';
import {KluhSharedModule} from '../../../../kluh-shared.module';
import {FuseSharedModule} from '../../../../../../@fuse/shared.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {R2CloudSupportModule} from '../../../r2-cloud/r2-cloud-support/r2-cloud-support.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MemoryUsageComponent} from './memory-usage.component';
import {MemoryUsageDaoService} from './memory-usage-dao.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';


const routes = [
    {
        path: 'memory-usage',
        component: MemoryUsageComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        R2CloudSupportModule,
        MatProgressSpinnerModule,
        FontAwesomeModule,
        MatExpansionModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRippleModule
    ],
    providers: [
        MemoryUsageDaoService
    ],
    entryComponents: [],
    declarations: [MemoryUsageComponent],

    exports: [
        MemoryUsageComponent
    ]
})
export class MemoryUsageModule {
}
