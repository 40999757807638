import {EMPTY, Observable, of, OperatorFunction} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, finalize, switchMap, take} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';

export abstract class R2CloudHttpBase {

    protected constructor(private http: HttpClient,
                          private addRequestIndicator: () => string,
                          private removeRequestIndicator: (id: string) => void,
                          private tokenProvider: () => Observable<string | null>,
                          public urlProvider: Observable<string>,
                          private onUnauthorized: (caught: Observable<any>) => Observable<any> = (_) => {
                              return EMPTY;
                          }
    ) {
    }

    // private domain = new BehaviorSubject('');

    protected static initHeaders(token: string | null): HttpHeaders {
        let headers = new HttpHeaders();
        if (token) {
            const _webClientId = localStorage.getItem('_webClientId');
            if (_webClientId) {
                headers = headers.set('x-firebase-auth', token).set('x-web-client-id', localStorage.getItem('_webClientId'));
            } else {
                headers = headers.set('x-firebase-auth', token);
            }
        }
        return headers;
    }

    private static urlGenerate(domain: string, url: string): string {
        return domain + url;
    }

    private static initParams(input: any | null): HttpParams {
        let params = new HttpParams();
        if (input) {
            for (const property in input) {
                if (input.hasOwnProperty(property)) {
                    const value = input[property];
                    if (value === Object(value)) {
                        params = params.append(property, JSON.stringify(value));
                    } else {
                        params = params.append(property, value);
                    }
                }
            }
        }
        return params;
    }

    private useProgressBar(ob: Observable<any>, path: string): Observable<any> {
        // console.debug('useProgressBar');
        let result: Observable<any>;
        if (path.indexOf('&load=0') < 0) {
            result = of(null).pipe(switchMap(() => {
                // console.debug('switchMapAddToProgressBar: ');
                const asyncId = this.addRequestIndicator();
                return of(asyncId);
            }), switchMap((asyncId) => {
                // console.debug('switchMapOriginalOb');
                return ob.pipe(finalize(() => {
                    // console.debug('tapRemoveAsync');
                    this.removeRequestIndicator(asyncId);
                }));
            }));
        } else {
            result = ob.pipe(finalize(() => {
            }));
        }
        return result.pipe(take(1));
    }

    private resolveApiUrl(): Observable<string> {
        return this.urlProvider;
    }

    public get<T>(url: string, params?: any | null, responseType?: string | null): Observable<T> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((token) => {
            const options: any = {
                headers: R2CloudHttpBase.initHeaders(token),
                params: R2CloudHttpBase.initParams(params)
            };
            if (responseType) {
                options.responseType = responseType;
            }
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.get<T>(R2CloudHttpBase.urlGenerate(apiUrl, url), options).pipe(this.catchError());
            }));
        })), url);
    }

    public post<T>(url: string, object: any): Observable<T> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((token) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.post<T>(R2CloudHttpBase.urlGenerate(apiUrl, url), object, {headers: R2CloudHttpBase.initHeaders(token)})
                    .pipe(this.catchError());
            }));
        })), url);
    }

    public upload<T>(data: any, url: string): Observable<T> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((token) => {
            const headers = R2CloudHttpBase.initHeaders(token);
            headers.set('Content-Type', 'multipart/form-data');
            headers.set('Accept', 'application/json');
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.post<T>(R2CloudHttpBase.urlGenerate(apiUrl, url), data, {headers: headers}).pipe(this.catchError());
            }));
        })), url);
    }

    public download(url: string): Observable<Blob> {
        return this.get(url, null, 'blob').pipe(this.catchError());
    }

    public put<T>(url: string, object: any): Observable<T> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((token) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.put<T>(R2CloudHttpBase.urlGenerate(apiUrl, url), object, {headers: R2CloudHttpBase.initHeaders(token)}).pipe(this.catchError());
            }));
        })), url);
    }

    public putVoid(url: string): Observable<string> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((token) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.put(R2CloudHttpBase.urlGenerate(apiUrl, url), null, {
                    headers: R2CloudHttpBase.initHeaders(token),
                    responseType: 'text'
                }).pipe(this.catchError());
            }));
        })), url);
    }

    public patch<T>(url: string, object: any): Observable<T> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((result) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.patch<T>(R2CloudHttpBase.urlGenerate(apiUrl, url), object, {headers: R2CloudHttpBase.initHeaders(result)}).pipe(this.catchError());
            }));
        })), url);
    }

    public remove(url: string): Observable<string> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((result) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.delete(R2CloudHttpBase.urlGenerate(apiUrl, url), {
                    headers: R2CloudHttpBase.initHeaders(result),
                    responseType: 'text'
                }).pipe(this.catchError());
            }));
        })), url);
    }

    public delete(url: string, object: any): Observable<void> {
        return this.useProgressBar(this.resolveToken().pipe(switchMap((result) => {
            return this.resolveApiUrl().pipe(switchMap((apiUrl) => {
                return this.http.request<void>('DELETE', R2CloudHttpBase.urlGenerate(apiUrl, url), {
                    headers: R2CloudHttpBase.initHeaders(result),
                    body: object
                }).pipe(this.catchError());
            }));
        })), url);
    }

    private catchError(): OperatorFunction<any, any> {
        return catchError((err, caught) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    return this.onUnauthorized(caught);
                }
            }
            // return EMPTY;
            return throwError(err);
        });
    }

    private resolveToken(): Observable<string> {
        return this.tokenProvider().pipe(take(1));
    }
}
