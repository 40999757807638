import {Component, Inject, OnInit} from '@angular/core';

import {BaseDao} from '../../../base-dao';
import {BaseDTOIdLong, ManagerUserSimple} from '../../../models';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {flatMap, map} from 'rxjs/operators';
import {forkJoin} from 'rxjs';
import {validateEmail} from '../../../helpers/kluh';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-entity-history',
    templateUrl: './entity-history.component.html',
    styleUrls: ['./entity-history.component.scss']
})
export class EntityHistoryComponent implements OnInit {
    private dao: BaseDao<BaseDTOIdLong>;
    private id: number;
    public snapshotList: JaversSnapshotWithManagerUserSimple[];

    public static openHistory(id: number, dao: BaseDao<BaseDTOIdLong>, dialog: MatDialog): MatDialogRef<EntityHistoryComponent> {
        return dialog.open(EntityHistoryComponent, {
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                dao: dao,
                id: id
            }
        });
    }

    constructor(private dialogRef: MatDialogRef<EntityHistoryComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public imageFileService: ImageFileService,
                private managerUserDao: ManagerUserDaoService) {
        if (!data.dao) {
            throw new Error('EntityHistoryComponent requires dao');
        }
        if (!data.id) {
            throw new Error('EntityHistoryComponent requires id');
        }
        this.dao = data.dao;
        this.id = data.id;
    }

    ngOnInit(): void {
        this.dao.entitySnapshots(this.id).pipe(flatMap((result) => {
            return forkJoin(result.filter((snapshot) => {
                return validateEmail(snapshot.commitMetadata.author);
            }).map((snapshot) => {
                return this.managerUserDao.getManagerUserSimpleByEmail(snapshot.commitMetadata.author).pipe(map((managerUserSimple) => {
                   return {snapshot, managerUserSimple} as JaversSnapshotWithManagerUserSimple;
                }));
            }));
        })).subscribe((result) => {
            this.snapshotList = result;
        });
    }

    getChangedContent(snapshot: JaversSnapshot<any>): any {
        const result = {};
        for (const changedProperty of snapshot.changedProperties) {
            const stateElement = snapshot.state[changedProperty];
            if (stateElement === undefined || stateElement === null) {
                result[changedProperty] = 'null';
            } else {
                result[changedProperty] = stateElement;
            }
        }
        return result;
    }

    onClose(): void {
        this.dialogRef.close();
    }
}

export class JaversSnapshotWithManagerUserSimple {
    snapshot: JaversSnapshot<any>;
    managerUserSimple: ManagerUserSimple;
}
