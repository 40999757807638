<mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Informações:  {{adUserPool.login}}</span>
    </mat-toolbar-row>
</mat-toolbar>
<div class="p-16 message" fxLayout="column" fxLayoutAlign="space-between stretch" matDialogContent>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <div class="card-pool-info">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *appIfPermission="appCanViewPasswordList" class="mb-30">
                    <div *ngIf="adUserPw ==''">
                        <button type="button" mat-raised-button
                                (click)="findByLoginPw(adUserPool)">
                            Mostrar senha
                        </button>
                    </div>
                    <div *ngIf="adUserPw !=''">
                        <div class="font-weight-900" style="margin-top: 15px;">Senha:</div>
                        {{adUserPw}}
                    </div>
                </div>
            </div>
            <table class="width-100-percent">
                <thead>
                <tr class="bg-white">
                    <th style="width: 30%;">Projeto</th>
                    <th style="width: 30%;">Empresa</th>
                    <th style="width: 8%;">Serviço</th>
                    <th style="width: 32%;">Usuário</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let adUserPoolInfo of adUserPoolInfoList">
                    <td>{{adUserPoolInfo.subProjectName}}</td>
                    <td>{{adUserPoolInfo.customerGroupName}}</td>
                    <td class="text-align-center">
                        <mat-icon *ngIf="adUserPoolInfo.serviceUser" class="success-icon">check_circle</mat-icon>
                    </td>
                    <td>
                        <div *ngFor="let email of adUserPoolInfo.emails">
                            {{email}}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-around end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
    <button type="button" mat-raised-button color="accent" (click)="onSetRandomPassword()">
        Gerar senha aleatória
    </button>
    <button type="button" mat-raised-button (click)="onSetLegacyRandomPassword()">
        Gerar senha aleatória (Legada)
    </button>
    <button mat-raised-button type="button" color="primary" class="md-small" (click)="onCancel()">Fechar
    </button>
</div>

