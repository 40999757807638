import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {filter, map, switchMap} from 'rxjs/operators';
import {RoleDAOService} from '../role-dao.service';
import {combineLatest, Observable, of} from 'rxjs';
import {Permission, Role} from '../../../models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {convertToFormGroup} from '../../../helpers/kluh';
import {PermissionDAOService} from '../permission-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {SelectionModel} from '@angular/cdk/collections';
import {IAMAddPermissionsModalComponent} from './iamadd-permissions-modal/iamadd-permissions-modal.component';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
    selector: 'app-iamcreate-edit',
    templateUrl: './iamcreate-edit.component.html',
    styleUrls: ['./iamcreate-edit.component.scss']
})
export class IAMCreateEditComponent extends ComponentCleaner implements OnInit {
    myForm: FormGroup;

    displayedColumns: string[] = ['select', 'name', 'comment'];
    dataSource: MatTableDataSource<Permission>;
    selection: SelectionModel<Permission>;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    private permissions: Permission[];

    constructor(private route: ActivatedRoute,
                private roleDao: RoleDAOService,
                private permissionDao: PermissionDAOService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        const role$ = combineLatest([this.route.queryParamMap, this.route.paramMap]).pipe(switchMap(([queryMap, paramMap]: ParamMap[]): Observable<Role> => {
            const idString = paramMap.get('id');
            const copy: boolean = Boolean(queryMap.get('copy'));
            if (idString) {
                const id: number = Number(idString);
                if (id) {
                    if (copy) {
                        return this.roleDao.getOne(id).pipe(map((role): Role => {
                            const copiedResult: Role = {
                                id: null,
                                active: role.active,
                                name: 'Cópia de ' + role.name,
                                comment: 'Cópia de ' + role.comment,
                                permissionIds: role.permissionIds,
                                modified: null,
                                optlock: null
                            };
                            return copiedResult;
                        }));
                    } else {
                        return this.roleDao.getOne(id);
                    }
                }
            }
            const result: Role = {
                id: null,
                name: 'Novo',
                active: true,
                comment: 'Descrição',
                permissionIds: [],
                modified: null,
                optlock: null
            };
            return of(result);
        }));
        const permissions$ = this.permissionDao.get();
        this.addSubscription(combineLatest([role$, permissions$])
            .subscribe(([role, permissions]) => {
                this.permissions = permissions;
                this.myForm = this.fb.group(convertToFormGroup(role), {asyncValidator: this.roleDao.validator});
                const rolePermissions = this.getRolePermissions(role);
                this.setPermissionsTable(rolePermissions);
            }));
    }

    private setPermissionsTable(rolePermissions: Permission[]): void {
        this.selection = new SelectionModel<Permission>(true, rolePermissions);
        this.addSubscription(this.selection.changed.subscribe(() => {
            this.myForm.markAsDirty();
            this.myForm.updateValueAndValidity();
        }));
        this.dataSource = new MatTableDataSource(rolePermissions);
        this.setPaginator();
    }

    /**
     * bug com *ngIf (paginator e sort demoram para ser instanciados)
     */
    private setPaginator(): void {
        setTimeout(() => {
            if (this.paginator && this.sort){
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
            } else {
                this.setPaginator();
            }
        }, 10);
    }

    private getRolePermissions(role: Role): Permission[] {
        const rolePermissions: Permission[] = [];
        for (const permissionId of role.permissionIds) {
            const item = this.findPermission(permissionId);
            rolePermissions.push(item);
        }
        return rolePermissions;
    }

    applyFilter(value: string): void {
        this.dataSource.filter = value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    onCancel(): void {
        this.router.navigate(['/iam-roles']);
    }

    onSubmit(): void {
        const permissionIds = this.selection.selected.map((o) => o.id);
        const role: Role = this.myForm.value;
        role.permissionIds = permissionIds;
        let role$: Observable<Role>;
        if (role.id){
            role$ = this.roleDao.save(role);
        } else {
            role$ = this.roleDao.create(role);
        }
        role$.subscribe(() => {
            this.router.navigate(['/iam-roles']);
        });
    }

    private findPermission(permissionId: number): Permission {
        return this.permissions.find(o => o.id === permissionId);
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle(): void {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    onAddPermissionsModal(): void {
        const rolePermissions = this.dataSource.data;
        const availablePermissions = this.permissions.filter(o => rolePermissions.indexOf(o) === -1);
        const dialogRef = this.dialog.open(IAMAddPermissionsModalComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                permissions: availablePermissions,
            }
        });
        this.addSubscription(dialogRef.afterClosed().pipe(filter((x) => !!(x) && x.length > 0)).subscribe((permissions) => {
            rolePermissions.push(...permissions);
            this.setPermissionsTable(rolePermissions);
            this.myForm.markAsDirty();
            this.myForm.updateValueAndValidity();
        }));
    }
}
