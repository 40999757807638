import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {ErrorSnackBarComponent} from './error-snack-bar/error-snack-bar-component';
import {AuthService} from '../auth.service';
import {catchError} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ManagerHttpInterceptorService implements HttpInterceptor {
    private snackBar: MatSnackBar;

    private xCanAccessHeader = 'x-can-access';
    private auth: AuthService;

    constructor(private injector: Injector) {
        this.snackBar = this.injector.get(MatSnackBar);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(err => this.handleError(err, req)));
    }

    private handleError(err: HttpErrorResponse, req: HttpRequest<any>): Observable<never> {
        if (err.status === 403 && err.headers.has(this.xCanAccessHeader)) {
            const allowed = JSON.parse(err.headers.get(this.xCanAccessHeader));
            const timeStart = err.headers.get(this.xCanAccessHeader + '-start');
            const timeEnd = err.headers.get(this.xCanAccessHeader + '-end');
            const timeNow = err.headers.get(this.xCanAccessHeader + '-now');
            if (!this.auth) {
                this.auth = this.injector.get(AuthService);
            }
            this.auth.processCanAccess({allowed: allowed, start: timeStart, end: timeEnd, now: timeNow});
        } else if (err.status !== 401) {
            this.snackBar.openFromComponent(ErrorSnackBarComponent, {
                data: {
                    err: err,
                    req: req
                },
                duration: 3000
            });
        }
        // if (err.status === 403) {
        //     this.snackBar.open('Não autorizado');
        // }
        return throwError(err);
    }


}
