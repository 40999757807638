import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {AdGroupDaoService} from '../ad-group-dao.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {InstanceEditComponent} from '../../support/data-center/instance/instance-edit/instance-edit.component';

import {convertToFormGroup, CrudOperation, CrudOperationWrapper} from '../../../helpers/kluh';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ADDomain, ADGroup, ADUser, ADUserPoolRelationship, ManagerUser, Project, SubProject} from '../../../models';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Subject} from 'rxjs/internal/Subject';
import {debounceTime, distinctUntilChanged, take, takeUntil} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {atLeastOne} from '../../../validators/at-least-one-validator';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../customer-group/customer-group.service';

@Component({
    selector: 'app-ad-group-create-edit',
    templateUrl: './ad-group-create-edit.component.html',
    styleUrls: ['./ad-group-create-edit.component.scss']
})
export class AdGroupCreateEditComponent extends ComponentCleaner implements AfterViewInit {
    public myForm: FormGroup;
    public adDomains: ADDomain[];
    public adUsers: ADUser[];
    public adUsersPoolRelationship: ADUserPoolRelationship[];
    public managerUsers: ManagerUser[];
    public adGroup: ADGroup;
    public subProject: SubProject;
    public project: Project;
    public adUsersPoolRelationshipFromData: ADUserPoolRelationship[];
    public adUsersFromData: ADUser[];
    public adUsersServiceFromData: ADUser[];
    public managerUsersFromData: ManagerUser[];

    public adUserMultiFilterCtrl: FormControl = new FormControl();
    public adUserPoolRelationshipMultiFilterCtrl: FormControl = new FormControl();
    public filteredADUsersMulti: ReplaySubject<ADUser[]> = new ReplaySubject<ADUser[]>(1);
    public filteredADUsersPoolRelationshipMulti: ReplaySubject<ADUserPoolRelationship[]> = new ReplaySubject<ADUserPoolRelationship[]>(1);
    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;
    protected _onDestroy = new Subject<void>();

    appCanCreateADUserPoolRelationship: PermissionWrapper[];

    constructor(public dialogRef: MatDialogRef<InstanceEditComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private adGroupDaoService: AdGroupDaoService,
                private adUserDao: AdUserDaoService,
                public adminService: R2CloudAdminService,
                private managerUserDao: ManagerUserDaoService,
                private dialog: MatDialog,
                public customerGroupService: CustomerGroupService,
                private fb: FormBuilder) {
        super();
        this.appCanCreateADUserPoolRelationship = [
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADUserPoolRelationship', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
        this.project = data.project;
        this.adDomains = data.adDomains;
        this.subProject = data.subProject;
        if (this.adDomains) {
            this.adDomains = this.adDomains.filter(x => x.active === true);
        }
        this.adGroup = data.adGroup;
        console.log('adGroup', this.adGroup);
        this.adGroup.subProjectId = this.subProject.id;
        // this.adGroup.prefix = this.subProject.id + '-' + this.subProject.name;
        this.adUsersPoolRelationshipFromData = adminService.findADUsersPoolRelationshipByADUserPoolService(false);
        this.adUsersFromData = data.adUsers;
        this.adUsersServiceFromData = data.adUsersService;
        this.managerUsersFromData = data.managerUsers;

        if (this.adDomains && this.adDomains.length > 0 && !this.adGroup.adDomainId) {
            this.adGroup.adDomainId = this.adDomains[0].id;
            this.getDomainData(this.adGroup.adDomainId);
        }

        this.myForm = this.fb.group(convertToFormGroup(this.adGroup), {asyncValidator: this.adGroupDaoService.validator});
        this.myForm.get('adDomainId').setValue(this.adGroup.adDomainId);


        if (this.adGroup.id) {
            this.getDomainData(this.adGroup.adDomainId);
        } else {
            const subscription = this.myForm.get('adDomainId').valueChanges.subscribe((adDomainId) => {
                this.adUsers = null;
                this.adUsersPoolRelationship = null;
                this.managerUsers = null;
                this.getDomainData(adDomainId);
                this.myForm.get('adUserIds').setValue([]);
                this.myForm.get('adUserPoolRelationshipIds').setValue([]);
            });
            this.addSubscription(subscription);
        }
        this.filteredADUsersMulti.next(this.adUsers.slice());
        this.filteredADUsersPoolRelationshipMulti.next(this.adUsersPoolRelationship.slice());

        this.adUserMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterBanksMulti();
            });

        this.adUserPoolRelationshipMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterADUserPoolRelationshipBanksMulti();
            });

        setTimeout(() => {
            this.setInitialValue();
            this.setInitialValueADUserPoolRelationship();
        }, 1000);

        this.myForm.get('adUserIds').valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()).subscribe((adUserId => {
            console.log('adUserIds', adUserId);
            this.myForm.markAsTouched();
        }));
        this.myForm.get('adUserPoolRelationshipIds').valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged()).subscribe((adUserPoolRelationshipId => {
                console.log('adUserPoolRelationshipIds', adUserPoolRelationshipId);
            this.myForm.markAsTouched();
        }));

    }

    ngAfterViewInit(): void {
        this.myForm.get('adUserIds').setValidators([
            atLeastOne({adUserIds: 'Um dos dois tipos de usuários precisam ser preenchidos'}, this.myForm, ['adUserIds', 'adUserPoolRelationshipIds'])
        ]);
        this.myForm.get('adUserPoolRelationshipIds').setValidators([
            atLeastOne({adUserPoolRelationshipIds: 'Um dos dois tipos de usuários precisam ser preenchidos'}, this.myForm, ['adUserIds', 'adUserPoolRelationshipIds'])
        ]);
    }

    protected setInitialValueADUserPoolRelationship(): void {
        this.filteredADUsersPoolRelationshipMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                if (typeof this.multiSelect !== 'undefined') {
                    this.multiSelect.compareWith = (a: ADUserPoolRelationship, b: ADUserPoolRelationship) => a && b && a.id === b.id;
                }
            });
    }

    protected filterADUserPoolRelationshipBanksMulti(): void {
        if (!this.adUsersPoolRelationship) {
            return;
        }
        let search = this.adUserPoolRelationshipMultiFilterCtrl.value;
        if (!search) {
            this.filteredADUsersPoolRelationshipMulti.next(this.adUsersPoolRelationship.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredADUsersPoolRelationshipMulti.next(
            this.adUsersPoolRelationship.filter(bank => this.adminService.getManagerUser(bank.managerUserId).email.toLowerCase().indexOf(search) > -1)
        );
    }

    protected setInitialValue(): void {
        this.filteredADUsersMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                if (typeof this.multiSelect !== 'undefined') {
                    this.multiSelect.compareWith = (a: ADUser, b: ADUser) => a && b && a.id === b.id;
                }
            });
    }


    protected filterBanksMulti(): void {
        if (!this.adUsers) {
            return;
        }
        let search = this.adUserMultiFilterCtrl.value;
        if (!search) {
            this.filteredADUsersMulti.next(this.adUsers.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredADUsersMulti.next(
            this.adUsers.filter(bank => bank.login.toLowerCase().indexOf(search) > -1)
        );
    }


    public getAdDomain(adDomainId: number): ADDomain {
        if (this.adDomains) {
            return this.adDomains.find((adDomain) => {
                return adDomain.id === adDomainId;
            });
        }
        return null;
    }

    private getDomainData(adDomainId: number): void {
        if (adDomainId) {
            if (this.adUsersFromData) {
                this.adUsers = this.adUsersFromData.filter(x => x.adDomainId === adDomainId);
            }
            if (this.managerUsersFromData) {
                this.managerUsers = this.managerUsersFromData.filter(x => {
                    for (const adUser of this.adUsers) {
                        if (adUser.managerUserId === x.id) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            this.filteredADUsersMulti.next(this.adUsers.slice());

            if (this.adUsersPoolRelationshipFromData) {
                this.adUsersPoolRelationship = this.adUsersPoolRelationshipFromData.filter(x => this.adminService.getAdUserPool(x.adUserPoolId).adDomainId === adDomainId);
            }

        }
    }

    getManagerUserEmail(managerUserId: number): string {
        let result = '';
        if (this.managerUsersFromData) {
            const managerUser = this.managerUsersFromData.find((x) => x.id === managerUserId);
            if (managerUser) {
                result = managerUser?.email;
            }
        }
        return result;
    }

    getADUserById(aduserId: number): ADUser {
        let result;
        if (this.adUsersFromData) {
            const aduser = this.adUsersFromData.find((x) => x.id === aduserId);
            if (aduser) {
                result = aduser;
            }
        }
        return result;
    }

    onSubmit(): void {
        let operation: CrudOperation;
        const entity: ADGroup = this.myForm.value;
        entity.name = entity.name.replace(/[&\/\\#,+()$~%.'":*?<>{}|]/g, '');
        let adGroupObservable;
        entity.subProjectId = this.subProject.id;
        if (entity.id) {
            operation = 'SAVE';
            adGroupObservable = this.adGroupDaoService.save(entity);
        } else {
            operation = 'CREATE';
            adGroupObservable = this.adGroupDaoService.create(entity);
        }
        adGroupObservable.subscribe((adGroup) => {
            const result: CrudOperationWrapper = {
                data: adGroup,
                operation: operation
            };
            this.dialogRef.close(result);
        });
    }

    onCancel(): void {
        this.dialogRef.close({
            operation: 'CANCEL'
        } as CrudOperationWrapper);
    }

    onRemove(): void {
        const aDGroup: ADGroup = this.myForm.value;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Quer mesmo remover esse grupo?',
                disableCancel: false,
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            console.debug('result: ' + result);
            if (result) {
                this.adGroupDaoService.remove(aDGroup.id).subscribe(() => {
                    this.dialogRef.close({
                        operation: 'DELETE',
                        data: aDGroup.id
                    } as CrudOperationWrapper);
                });
            }
        });
        this.addSubscription(subscription);
    }

    onForceDelete(): void {
        const aDGroup: ADGroup = this.myForm.value;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<h2 class="warn-800-fg">Esse procedimento não tem volta.</h2> ' +
                    '<p>Quer mesmo <span class="warn-800-fg">EXCLUIR ESSE GRUPO</span> de forma forçada?.<br>' +
                    'Este procedimento removerá o grupo apenas do R2.</p>' +
                    '<br><b>Confirme</b> a exclusão digitando: <b>' + aDGroup.name + '</b> no campo abaixo <br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: aDGroup.name
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.adGroupDaoService.forceDelete(aDGroup.id).subscribe((remoteAppRemove) => {
                    this.dialogRef.close({
                        data: aDGroup.id,
                        operation: 'DELETE'
                    } as CrudOperationWrapper);
                });
            }
        });
        this.addSubscription(subscription);
    }
}

