<mat-card class="padding-none width-100-percent mt-20" >
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>Últimos projetos</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="scroll-content">
        <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th></th>
                <th class="w-115"></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let obj of subProjectsSnapshots">
                <td>
                    <div>
                        <div><b>{{obj.state.name}}</b></div>
                        <div class="font-size-12">{{obj.type}}</div>
                        <div class="font-size-12">{{obj.commitMetadata?.commitDate}}</div>
                    </div>
                </td>
                <td>
                    {{obj.commitMetadata.commitDate | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>