import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MonitoringDetailsService} from '../monitoring-details.service';
import {MatTableDataSource} from '@angular/material/table';
import {ManagerUserCustomerGroupData, ServerType} from '../../../../models';
import {UserDetailsTableDaoService} from './user-details-table-dao.service';
import {MatSort} from '@angular/material/sort';
import {DesktopServerService} from '../../../desktop-server/desktop-server.service';
import {Subscription} from 'rxjs';
import {UserUsage} from '../../../../models-monitoring';
import {LinuxServerService} from '../../../linux-server/linux-server.service';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';


@Component({
    selector: 'app-user-details-table',
    templateUrl: './user-details-table.component.html',
    styleUrls: ['./user-details-table.component.scss']
})
export class UserDetailsTableComponent implements AfterViewInit, OnDestroy {

    userTable: string[] = ['adUser', 'processorUsage', 'memoryUsage', 'actions'];
    userDataSource = new MatTableDataSource<UserUsage>();
    managerUserCustomerGroupData: ManagerUserCustomerGroupData[] = [];
    userUsageList: UserUsage[] = [];
    userUsageListFilter: UserUsage[] = [];
    userUsageRemovedTempList: UserUsage[] = [];

    @Input()
    clientId: string;

    @Input()
    serverId: number;

    @Input()
    serverType: ServerType;

    ServerType = ServerType;

    private subscription: Subscription;

    @ViewChild(MatSort, {static: true}) sort: MatSort;


    constructor(private dialog: MatDialog,
                private userDetailsTableDaoService: UserDetailsTableDaoService,
                private desktopServerService: DesktopServerService,
                private managerUserDaoService: ManagerUserDaoService,
                private linuxServerService: LinuxServerService,
                private webStompService: MonitoringStompService,
                private monitoringDetailsService: MonitoringDetailsService) {
        this.monitoringDetailsService.changePath('monitoring-details', 'users');
    }


    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        console.log('user-usage: p1 - ' + this.serverId);
        if (this.serverId) {
            console.log('user-usage: ' + this.serverId);
            this.subscription = this.webStompService.stompSubscribe<UserUsage[]>('/topic/user-usage.' + this.serverType + '.' + this.serverId)
                .subscribe((userUsageList) => {
                    this.userUsageListHandler(userUsageList);
                });
            this.findUserUsage();
        }
    }


    userUsageListHandler(userUsageList: UserUsage[]): void {
        console.log('user-usage: stomp: ' + userUsageList.length);
        for (let i = 0; i < userUsageList.length; i++) {
            // console.log('user-usage: ' + userUsageList[i].adUser);
            if (userUsageList[i].toRemove) {
                this.remove(userUsageList[i]);
            } else {
                this.save(userUsageList[i]);
            }
        }
    }

    save(userUsage: UserUsage): void {
        if (this.userUsageList) {
            const index: number = this.userUsageList.findIndex(x => x.adUser === userUsage.adUser && x.adDomain === userUsage.adDomain);
            const indexRemovedTemp: number = this.userUsageRemovedTempList.findIndex(x => x.adUser === userUsage.adUser && x.adDomain === userUsage.adDomain);
            if (indexRemovedTemp === -1) {
                if (index !== -1) {
                    this.userUsageList[index] = userUsage;
                } else {
                    this.userUsageList.push(userUsage);
                }
                this.userDataSource = new MatTableDataSource(this.userUsageList);
            }
        }
    }

    remove(userUsage: UserUsage): void {
        if (this.userUsageList) {
            const index: number = this.userUsageList.findIndex(x => x.adUser === userUsage.adUser && x.adDomain === userUsage.adDomain);
            if (index !== -1) {
                this.userUsageList.splice(index, 1);
                this.userDataSource = new MatTableDataSource(this.userUsageList);
            }
        }
    }

    findUserUsage(): void {
        if (this.serverId) {
            this.userDetailsTableDaoService.findAllByClientId(this.clientId).subscribe((userUsageList) => {
                if (userUsageList) {
                    this.userUsageList = userUsageList;
                    const adUserLoginList: string[] = this.userUsageList.map(userUsage => (userUsage.adUser));

                    this.managerUserDaoService.findManagerUserCustomerGroupFromAdUserList(adUserLoginList, this.serverId).subscribe(result => {
                        if (result) {
                            this.managerUserCustomerGroupData = result;
                            this.userDataSource = new MatTableDataSource(this.userUsageList);
                            this.userDataSource.sort = this.sort;
                        }
                    });
                }
            });
        }
    }

    findManagerUserCustomerGroupData(login: string): ManagerUserCustomerGroupData {
        return this.managerUserCustomerGroupData.find(x => x.login === login);
    }

    onUserLogOff(element: UserUsage): void {
        const message = 'Deseja desconectar o usuário:<br /><b>' + element.adUser + '</b><br />';
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: message,
                disableCancel: false,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
        matDialogRef.afterClosed().subscribe((value) => {
            if (value) {
                if (this.serverType === ServerType.WINDOWS) {
                    this.removeUserUsageFromList(element);
                    this.desktopServerService.onUserLogOff(element.adUser, this.serverId);
                } else if (this.serverType === ServerType.LINUX) {
                    console.log('onUserLogOff() Linux implementar!!!!');
                }
            }
        });
    }

    onUserReset(element: UserUsage): void {
        const message = 'Deseja forçar a desconexão do usuário:<br /><b>' + element.adUser + '</b><br />';
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: message,
                disableCancel: false,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
        matDialogRef.afterClosed().subscribe((value) => {
            if (value) {
                this.removeUserUsageFromList(element);
                if (this.serverType === ServerType.WINDOWS) {
                    this.desktopServerService.onUserReset(element.adUser, this.serverId);
                } else if (this.serverType === ServerType.LINUX) {
                    this.linuxServerService.userLogoffByServerIdAndServerType(this.serverId, this.serverType, element.adUser);
                }
            }
        });
    }

    private removeUserUsageFromList(element: UserUsage): void {
        const index: number = this.userUsageList.findIndex(x => x.adUser === element.adUser);
        if (index !== -1) {
            this.userUsageList.splice(index, 1);
            this.userDataSource = new MatTableDataSource(this.userUsageList);
            this.userDataSource.sort = this.sort;

            this.userUsageRemovedTempList.push(element);
            setTimeout(() => {
                this.userUsageRemovedTempList = [];
            }, 5000);
        }
    }
}
