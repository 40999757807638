<div fxLayout="column" class="mt-26" [@animate]="{value:'*',params:{y:'100%'}}">
    <mat-card>
        <mat-card-header>
            <mat-card-title fxLayout="row" fxLayoutAlign="space-between center">
                <div> Aplicativos</div>
                <div>
                    <button *appIfPermission="appCanCreateList"
                        type="button"
                        mat-raised-button color="accent" (click)="onCreateRemoteApp()">Criar
                    </button>
                </div>
            </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="adminService.remoteApps && adminService.remoteApps.length > 0" >
            <div fxLayout="column mt-4">
                <table class="remote-app-table" cellspacing="0" cellpadding="0">
                    <thead>
                    <tr class="bg-white">
                        <th></th>
                        <th>Nome</th>
                        <th>Dominio</th>
                        <th>Server</th>
                        <th>Grupo</th>
                        <th>Usuários</th>
                        <th class="text-align-center">Status</th>
                        <th class="text-align-center p-0" ></th>
                    </tr>
                    </thead>
                    <tr *ngFor="let remoteApp of adminService.remoteApps;let i=index" class="ml-16"
                        [ngClass]="{'disabled': (!remoteApp.active || (!adminService.getDesktopServer(remoteApp.desktopServerId)?.active) || (!adminService.getAdDomain(adminService.getDesktopServer(remoteApp.desktopServerId)?.adDomainId)?.active) ) }">

                        <td style="width: 40px">

                            <img [src]="imageFileService.getRemoteAppPictureUrl(remoteApp.imageUUID, 32)" width="32"
                                 height="32">
                        </td>

                        <td class="font-size-12">
                            <div class="text-max-120">
                                {{remoteApp.name}}
                            </div>
                        </td>
                        <td class=" font-size-12">
                            <div class=" text-max-120">
                                {{adminService.getAdDomain(adminService.getDesktopServer(remoteApp.desktopServerId)?.adDomainId)?.name}}
                            </div>
                        </td>

                        <td class=" font-size-12">
                            <div class=" text-max-120">
                                {{adminService.getDesktopServer(remoteApp.desktopServerId)?.hostname}}
                            </div>
                        </td>

                        <td class=" font-size-12">
                            <div *ngFor="let adGroupId of remoteApp.adGroupIds" style="clear: both">
                                <div class="text-max-120" >
                                    {{adminService.getAdGroup(adGroupId)?.name}}
                                </div>
                            </div>
                            <div *ngIf="remoteApp.serviceApp" class="margin-left-10 cursor-default">
                                <mat-icon title="Serviço" class="mat-icon material-icons" role="img"
                                          aria-hidden="true">room_service
                                </mat-icon>
                            </div>
                        </td>
                        <td>
                            <div *ngFor="let adUserId of remoteApp.adUserIds" class="font-size-12">
                                <div style="clear: both" class="w-50"></div>
                                <div class="text-max-120 text-email">{{adminService.getManagerUser(adminService.getAdUser(adUserId)?.managerUserId)?.email}}</div>
                            </div>
                            <div *ngFor="let adUserPoolRelationshipId of remoteApp.adUserPoolRelationshipIds" class="font-size-12">
                                <div style="clear: both" class="w-50"></div>
                                <div class="text-max-120 text-email">{{adminService.getManagerUser(adminService.getAdUserPoolRelationship(adUserPoolRelationshipId)?.managerUserId)?.email}}</div>
                            </div>
                        </td>
                        <td class="text-align-center">
                            <div *ngIf="(remoteApp.changing || remoteApp.deleting)">
                                <mat-icon *ngIf="!theDateIsGreaterThanNowPlusMinutes(remoteApp.updatedAt,15)" title="Criando... {{remoteApp.updatedAt}}">add_circle</mat-icon>
                                <div *ngIf="theDateIsGreaterThanNowPlusMinutes(remoteApp.updatedAt,15)" >
                                    <mat-icon  class="red-A700-fg" title="Algo errado, está demorando muito para executar a tarefa. {{remoteApp.updatedAt}}">error</mat-icon>
                                    <button *appIfPermission="'WRITE'; data: remoteApp; type: 'RemoteApp'" type="button"
                                            mat-raised-button class="warn"
                                            (click)="openRemoteAppModal(remoteApp)">Delete
                                    </button>
                                </div>
                            </div>

                            <button  *ngIf="(!(remoteApp.changing || remoteApp.deleting)) &&
                                            managerUserCanAccessRemoteApp(remoteApp) && !isRemoteAppIsLoading(remoteApp.id)" type="button"
                                            mat-raised-button class="mat-green-600-bg"
                                    (click)="openRemoteApp(remoteApp.id)">Abrir
                            </button>
                            <mat-spinner *ngIf="isRemoteAppIsLoading(remoteApp.id)" [diameter]="24" color="primary" style="margin-left: 10px;"></mat-spinner>
                        </td>
                        <td class="text-align-right p-0  pr-2">
                            <button *appIfPermission="'WRITE'; data: remoteApp; type: 'RemoteApp'" type="button"
                                    mat-raised-button class="mat-green-600-bg"
                                    (click)="openRemoteAppModal(remoteApp)">Edit
                            </button>
                        </td>

                    </tr>

                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>