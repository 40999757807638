import {ScriptExecutionFinishedEvent, ScriptExecutionType} from '../../models';
import {Injectable} from '@angular/core';
import {ConfirmDialogComponent} from '../../helpers/confirm-dialog/confirm-dialog.component';
import {AuthService} from '../../auth.service';

import {ComponentCleaner} from '../../component-cleaner';
import {USER_TOPIC} from '../../../../main';
import {MatDialog} from '@angular/material/dialog';
import {R2CloudStompService} from '../../ws/r2-cloud-stomp.service';


@Injectable({
    providedIn: 'root'
})
export class ScriptExecutionService extends ComponentCleaner {
    constructor(private stomp: R2CloudStompService,
                private auth: AuthService,
                private dialog: MatDialog,
    ) {
        super();
        const subScriptExecution = this.stomp.stompTopic<ScriptExecutionFinishedEvent>(`${USER_TOPIC}/script-execution-web`)
            .subscribe((scriptExecutionFinishedEvent) => {
                if (scriptExecutionFinishedEvent) {
                    if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.USER_LOGOFF) {
                        this.logOffUserAlert(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.DISCONNECT_ALL_USERS_FROM_SERVER) {
                        this.disconnectAllUsersFromServerAlert(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.PRINT) {
                        this.printAlert(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.PROCESS_KILL) {
                        this.processKill(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.SERVICE_START) {
                        this.serviceStart(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.SERVICE_STOP) {
                        this.serviceStop(scriptExecutionFinishedEvent);
                    } else if (scriptExecutionFinishedEvent.scriptExecutionType === ScriptExecutionType.SERVICE_RESTART) {
                        this.serviceRestart(scriptExecutionFinishedEvent);
                    }
                }
            });
        this.addSubscription(subScriptExecution);
    }

    public logOffUserAlert(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {
        let message = '';
        if (scriptExecutionFinishedEvent.error) {
            message = '<h1>Erro</h1><div class="mb-30">O usuário não está conectado ao servidor.</div>' +
                '<div><b>descrição do erro:</b><div class="mb-30">' +
                '<span class="font-size-10">' + scriptExecutionFinishedEvent.output + '</span></div></div>';
        } else {
            message = '<h1>Usuário desconectado com sucesso.</h1>';
        }
        const subscriptionDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
        this.addSubscription(subscriptionDialog);
        // console.debug('scriptExecutionEvent: ' + scriptExecutionFinishedEvent.output);
    }


    public disconnectAllUsersFromServerAlert(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {

    }

    public printAlert(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {

    }


    public processKill(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {
        let message = '';
        if (scriptExecutionFinishedEvent.error) {
            message = '<h1>Erro</h1><div class="mb-30">O processo não foi finalizado.</div>' +
                '<div><b>descrição do erro:</b><div class="mb-30">' +
                '<span class="font-size-10">' + scriptExecutionFinishedEvent.output + '</span></div></div>';
        } else {
            message = '<h1>Processo finalizado com sucesso.</h1>';
        }
        const subscriptionDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
        this.addSubscription(subscriptionDialog);
        // console.debug('scriptExecutionEvent: ' + scriptExecutionFinishedEvent.output);
    }

    public serviceStart(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {
        let message = '';
        if (scriptExecutionFinishedEvent.error) {
            message = '<h1>Erro</h1><div class="mb-30">O serviço não pode ser iniciado.</div>' +
                '<div><b>descrição do erro:</b><div class="mb-30">' +
                '<span class="font-size-10">' + scriptExecutionFinishedEvent.output + '</span></div></div>';
        } else {
            message = '<h1>Serviço iniciado com sucesso.</h1>';
        }
        const subscriptionDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
        this.addSubscription(subscriptionDialog);
        // console.debug('scriptExecutionEvent: ' + scriptExecutionFinishedEvent.output);
    }

    public serviceStop(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {
        let message = '';
        if (scriptExecutionFinishedEvent.error) {
            message = '<h1>Erro</h1><div class="mb-30">O serviço não pode ser parado.</div>' +
                '<div><b>descrição do erro:</b><div class="mb-30">' +
                '<span class="font-size-10">' + scriptExecutionFinishedEvent.output + '</span></div></div>';
        } else {
            message = '<h1>Serviço parado com sucesso.</h1>';
        }
        const subscriptionDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
        this.addSubscription(subscriptionDialog);
        // console.debug('scriptExecutionEvent: ' + scriptExecutionFinishedEvent.output);
    }

    public serviceRestart(scriptExecutionFinishedEvent: ScriptExecutionFinishedEvent): void {
        let message = '';
        if (scriptExecutionFinishedEvent.error) {
            message = '<h1>Erro</h1><div class="mb-30">O serviço não pode ser reiniciado.</div>' +
                '<div><b>descrição do erro:</b><div class="mb-30">' +
                '<span class="font-size-10">' + scriptExecutionFinishedEvent.output + '</span></div></div>';
        } else {
            message = '<h1>Serviço reiniciado com sucesso.</h1>';
        }
        const subscriptionDialog = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
        });
        this.addSubscription(subscriptionDialog);
        // console.debug('scriptExecutionEvent: ' + scriptExecutionFinishedEvent.output);
    }

}
