import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SubProjectDaoService} from '../../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {R2CloudClientService} from '../../../../ws/r2-cloud-client.service';
import {LinuxServerStatusService} from '../../../../ws/linux-server-status.service';
import {LinuxServer, SubProject} from '../../../../models';

@Component({
    selector: 'app-linux-server-support-card',
    templateUrl: './linux-server-support-card.component.html',
    styleUrls: ['./linux-server-support-card.component.scss']
})
export class LinuxServerSupportCardComponent {

    private _linuxServer: LinuxServer;
    public subProjects: SubProject[];

    constructor(private subProjectDao: SubProjectDaoService, private linuxServerStatus: LinuxServerStatusService, private clientStatus: R2CloudClientService) {
    }

    @Input()
    set linuxServer(linuxServer: LinuxServer) {
        this._linuxServer = linuxServer;
        this.subProjectDao.filter({'linuxServerId': this.linuxServer.id}).subscribe((results) => {
            this.subProjects = results;
        });
    }

    get linuxServer(): LinuxServer {
        return this._linuxServer;
    }

    @Output()
    public close: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public connect: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public subProjectSelect: EventEmitter<SubProject> = new EventEmitter<SubProject>();

    public onClose(): void {
        this.close.emit();
    }

    public onSelectSubProject(subProject: SubProject): void {
        this.subProjectSelect.emit(subProject);
    }

    public onConnect(): void {
        this.connect.emit();
    }

    public isConnected(): boolean {
        return this.linuxServerStatus.isServerConnected(this.linuxServer.id);
    }

    public isClientConnected(): boolean {
        return this.clientStatus.isClientConnected();
    }

}
