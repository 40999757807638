/* tslint:disable */
import {R2CloudClientStatus} from '../../models';

export class ProtocolCheck {
    // public static timeoutMs = 3000;

    public static protocolCheckPromise(uri: string, timeoutMs: number): Promise<R2CloudClientStatus> {
        return new Promise((resolve, reject) => {
            const successCb = () => {
                // console.log('protocolCheckPromise successCb');
                resolve(R2CloudClientStatus.INSTALLED);
            };
            const failCb = () => {
                // console.log('protocolCheckPromise failCb');
                resolve(R2CloudClientStatus.NOT_INSTALLED);
            };
            try {
                console.log('protocolCheckPromise, document.hidden', document.hidden);
                // if (document.hidden) {
                //     failCb();
                // } else {
                //     this.protocolCheck(uri, failCb, successCb, timeoutMs);
                // }
                this.protocolCheck(uri, failCb, successCb, timeoutMs);
            } catch (e) {
                reject(e);
            }
        });
    }

    public static protocolCheck(uri: string, failCb: any, successCb: any, timeoutMs: number): any {

        if (navigator.msLaunchUri) { // for IE and Edge in Win 8 and Win 10
            ProtocolCheck.openUriWithMsLaunchUri(uri, failCb, successCb);
        } else {
            const browser = ProtocolCheck.checkBrowser();
            if (browser.isFirefox) {
                ProtocolCheck.openUriUsingFirefox(uri, failCb, successCb);
            } else if (browser.isChrome) {
                ProtocolCheck.openUriWithTimeoutHack(uri, failCb, successCb, timeoutMs);
            } else if (browser.isIE) {
                ProtocolCheck.openUriUsingIEInOlderWindows(uri, failCb, successCb, timeoutMs);
            } else {
                // not supported, implement please
            }
        }
    }

    private static openUriWithMsLaunchUri(uri: string, failCb: any, successCb: any): void {
        navigator.msLaunchUri(uri,
            successCb,
            failCb
        );
    }

    private static openUriUsingFirefox(uri: string, failCb: any, successCb: any): void {
        let iframe: HTMLIFrameElement = document.querySelector('#hiddenIframe');

        if (!iframe) {
            iframe = ProtocolCheck.createHiddenIframe(document.body, 'about:blank');
        }

        try {
            iframe.contentWindow.location.href = uri;
            successCb();
        } catch (e) {
            console.error(e)
            if (e.name === 'NS_ERROR_UNKNOWN_PROTOCOL') {
                failCb();
            }
        }
    }

    private static createHiddenIframe(target: any, uri: string): HTMLIFrameElement {
        const iframe = document.createElement('iframe') as HTMLIFrameElement;
        iframe.src = uri;
        iframe.id = 'hiddenIframe';
        iframe.style.display = 'none';
        target.appendChild(iframe);

        return iframe;
    }

    private static checkBrowser(): any {
        // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
        // const isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        const isOpera = (navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1;
        // Firefox 1.0+
        // const isFirefox = typeof InstallTrigger !== 'undefined';
        const isFirefox = navigator.userAgent.indexOf('Firefox') != -1;
        // Safari 3.0+
        // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
        const isSafari = navigator.userAgent.indexOf('Safari') != -1;
        // Internet Explorer 6-11
        // const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const isIE = (navigator.userAgent.indexOf('MSIE') != -1);
        // Edge 20+
        // const isEdge = !isIE && !!window.StyleMedia;
        // Chrome 1+
        // const isChrome = !!window.chrome && !!window.chrome.webstore;
        const isChrome = navigator.userAgent.indexOf('Chrome') != -1;
        // Blink engine detection
        // const isBlink = (isChrome || isOpera) && !!window.CSS;
        return {
            isOpera: isOpera,
            isFirefox: isFirefox,
            isSafari: isSafari,
            isChrome: isChrome,
            isIE: isIE
        };
    }

    private static openUriWithTimeoutHack(uri: string, failCb: any, successCb: any, timeoutMs: number): void {
        const timeout = setTimeout(() => {
            failCb();
            handler.remove();
        }, timeoutMs);

        // handle page running in an iframe (blur must be registered with top level window)
        let target: any = window;
        while (target !== target.parent) {
            target = target.parent;
        }

        const handler = ProtocolCheck.registerEvent(target, 'blur', onBlur);

        function onBlur(): void {
            clearTimeout(timeout);
            handler.remove();
            successCb();
        }

        window.location.href = uri;
    }

    private static registerEvent(target: any, eventType: string, cb: any): any {
        if (target.addEventListener) {
            target.addEventListener(eventType, cb);
            return {
                remove: () => {
                    target.removeEventListener(eventType, cb);
                }
            };
        } else {
            target.attachEvent(eventType, cb);
            return {
                remove: () => {
                    target.detachEvent(eventType, cb);
                }
            };
        }
    }

    private static openUriUsingIEInOlderWindows(uri: string, failCb: any, successCb: any, timeoutMs: number): void {
        const internetExplorerVersion = ProtocolCheck.getInternetExplorerVersion();
        if (internetExplorerVersion === 10) {
            ProtocolCheck.openUriUsingIE10InWindows7(uri, failCb, successCb, timeoutMs);
        } else if (internetExplorerVersion === 9 || internetExplorerVersion === 11) {
            ProtocolCheck.openUriWithHiddenFrame(uri, failCb, successCb, timeoutMs);
        } else {
            ProtocolCheck.openUriInNewWindowHack(uri, failCb, successCb, timeoutMs);
        }
    }

    private static getInternetExplorerVersion(): number {
        let rv = -1;
        if (navigator.appName === 'Microsoft Internet Explorer') {
            const ua = navigator.userAgent;
            const re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) != null) {
                rv = parseFloat(RegExp.$1);
            }
        }
        else if (navigator.appName === 'Netscape') {
            const ua = navigator.userAgent;
            const re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
            if (re.exec(ua) != null) {
                rv = parseFloat(RegExp.$1);
            }
        }
        return rv;
    }

    private static openUriUsingIE10InWindows7(uri: string, failCb: any, successCb: any, timeoutMs: number): void {
        const timeout = setTimeout(failCb, timeoutMs);
        window.addEventListener('blur', () => {
            clearTimeout(timeout);
            successCb();
        });

        let iframe: HTMLIFrameElement = document.querySelector('#hiddenIframe');
        if (!iframe) {
            iframe = ProtocolCheck.createHiddenIframe(document.body, 'about:blank');
        }
        try {
            iframe.contentWindow.location.href = uri;
        } catch (e) {
            failCb();
            clearTimeout(timeout);
        }
    }

    private static openUriWithHiddenFrame(uri: string, failCb: any, successCb: any, timeoutMs: number): void {

        const timeout = setTimeout(() => {
            failCb();
            handler.remove();
        }, timeoutMs);

        let iframe: HTMLIFrameElement = document.querySelector('#hiddenIframe');
        if (!iframe) {
            iframe = ProtocolCheck.createHiddenIframe(document.body, 'about:blank');
        }

        const handler = ProtocolCheck.registerEvent(window, 'blur', onBlur);

        function onBlur(): void {
            clearTimeout(timeout);
            handler.remove();
            successCb();
        }

        iframe.contentWindow.location.href = uri;
    }


    private static openUriInNewWindowHack(uri: string, failCb: any, successCb: any, timeoutMs: number): void {
        const myWindow = window.open('', '', 'width=0,height=0');

        myWindow.document.write('<iframe src=\'' + uri + '\'></iframe>');

        setTimeout(() => {
            try {
                // myWindow.location.href;
                myWindow.setTimeout('window.close()', timeoutMs);
                successCb();
            } catch (e) {
                myWindow.close();
                failCb();
            }
        }, timeoutMs);
    }

}
