import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {R2CloudAdminComponent} from './r2-cloud-admin.component';
import {AuthService} from '../../auth.service';
import {RouterModule} from '@angular/router';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {R2CloudSupportModule} from '../r2-cloud/r2-cloud-support/r2-cloud-support.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {R2CloudAdminADDomainComponent} from './r2-cloud-admin-addomain/r2-cloud-admin-addomain.component';
import {R2CloudAdminServerComponent} from './r2-cloud-admin-server/r2-cloud-admin-server.component';
import {R2CloudAdminADUserComponent} from './r2-cloud-admin-aduser/r2-cloud-admin-aduser.component';
import {R2CloudAdminADGroupComponent} from './r2-cloud-admin-adgroup/r2-cloud-admin-adgroup.component';
import {R2CloudAdminService} from './r2-cloud-admin.service';
import {R2CloudAdminADUserServiceComponent} from './r2-cloud-admin-aduser-service/r2-cloud-admin-aduser-service.component';
import {R2CloudAdminRemoteAppComponent} from './r2-cloud-admin-remote-app/r2-cloud-admin-remote-app.component';
import {R2CloudAdminSubProjectComponent} from './r2-cloud-admin-sub-project/r2-cloud-admin-sub-project.component';
import {R2CloudAdminDesktopServerItemComponent} from './r2-cloud-admin-server/r2-cloud-admin-desktop-server-item/r2-cloud-admin-desktop-server-item.component';
import {R2CloudAdminLinuxServerItemComponent} from './r2-cloud-admin-server/r2-cloud-admin-linux-server-item/r2-cloud-admin-linux-server-item.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {R2CloudAdminUserTypeChoiceComponent} from './r2-cloud-admin-aduser/r2-cloud-admin-user-type-choice/r2-cloud-admin-user-type-choice.component';
import {
    R2CloudAdminUserServiceTypeChoiceComponent
} from './r2-cloud-admin-aduser-service/r2-cloud-admin-user-service-type-choice/r2-cloud-admin-user-service-type-choice.component';
import {AdUserServiceService} from './r2-cloud-admin-aduser/ad-user-service.service';


const routes = [
    {
        path: 'admin',
        component: R2CloudAdminComponent,
        canActivate: [AuthService],
        data: {
            permissions: ['SubProject.READ'],
            preCondition: true}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        R2CloudSupportModule,
        MatProgressSpinnerModule,
        FontAwesomeModule

    ],
    declarations: [R2CloudAdminComponent, R2CloudAdminADDomainComponent, R2CloudAdminServerComponent,
        R2CloudAdminADUserComponent, R2CloudAdminADGroupComponent, R2CloudAdminADUserServiceComponent,
        R2CloudAdminRemoteAppComponent, R2CloudAdminSubProjectComponent, R2CloudAdminDesktopServerItemComponent, R2CloudAdminLinuxServerItemComponent,
        R2CloudAdminUserTypeChoiceComponent,
        R2CloudAdminUserServiceTypeChoiceComponent],
    providers: [
        R2CloudAdminService, AdUserServiceService
    ]
})
export class R2CloudAdminModule { }
