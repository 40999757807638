import {AfterViewInit, Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder, FormControl} from '@angular/forms';
import {ProcessDetails} from '../../../../models-monitoring';
import {ProcessDetailsTableDaoService} from './process-details-table-dao.service';
import {MatTableDataSource} from '@angular/material/table';
import {BaseServer, ServerType} from '../../../../models';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {MonitoringDetailsService} from '../monitoring-details.service';
import {DesktopServerService} from '../../../desktop-server/desktop-server.service';
import {Subscription} from 'rxjs';
import {ProcessDetailsTableService} from './process-details-table.service';
import {searchIndexOf} from '../helper/helper';
import {LinuxServerService} from '../../../linux-server/linux-server.service';
import {MonitoringStompService} from '../../websocket-stomp/monitoring-stomp.service';

@Component({
    selector: 'app-process-details-table',
    templateUrl: './process-details-table.component.html',
    styleUrls: ['../monitoring-details.component.scss', './process-details-table.component.scss']
})
export class ProcessDetailsTableComponent implements AfterViewInit, OnDestroy {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    processDetailsTable: string[] = ['name', 'processId', 'processorUsage', 'memoryUsage', 'path', 'firstSeenAt', 'actions'];
    processDataSource = new MatTableDataSource<ProcessDetails>();
    processList: ProcessDetails[] = [];
    processListFilter: ProcessDetails[] = [];
    searchMultiFilterCtrl: FormControl;
    protected _onDestroy = new Subject<void>();
    private waiting = 0;

    private subscription: Subscription;

    // @Input()
    // desktopServer: DesktopServer;
    //
    // @Input()
    // linuxServer: LinuxServer;

    @Input()
    baseServer: BaseServer;

    @Input()
    serverType: ServerType;

    ServerType = ServerType;

    constructor(
        private dialog: MatDialog,
        private processDetailsTableDaoService: ProcessDetailsTableDaoService,
        private desktopServerService: DesktopServerService,
        private linuxServerService: LinuxServerService,
        private fb: FormBuilder,
        private processDetailsTableService: ProcessDetailsTableService,
        private webStompService: MonitoringStompService,
        private monitoringDetailsService: MonitoringDetailsService
    ) {
        this.searchMultiFilterCtrl = fb.control(['']);
        this.monitoringDetailsService.changePath('monitoring-details', 'processes');
    }

    ngAfterViewInit(): void {
        if (this.baseServer) {
            this.subscription = this.webStompService.stompSubscribe<ProcessDetails[]>('/topic/process-details.' + this.serverType + '.' + this.baseServer.id)
                .subscribe((processDetailList) => {
                    console.log(processDetailList.length + ' | /topic/process-details.' + this.serverType + '.' + this.baseServer.id);
                    this.processList = this.processDetailsTableService.listHandler(processDetailList, this.processList);
                    this.filterBanksMulti();
                });
            this.findAll();
        }
    }

    findAll(): void {
        if (this.baseServer) {
            this.processDetailsTableDaoService.findAllByClientId(this.baseServer.clientId).subscribe((processList) => {
                if (processList) {
                    this.processList = processList;
                    this.filterBanksMulti();
                    this.searchMultiFilterCtrl.valueChanges
                        .pipe(takeUntil(this._onDestroy))
                        .subscribe(() => {
                            this.filterBanksMulti();
                        });
                }
            });
        }
    }

    protected filterBanksMulti(): void {
        if (this.processList) {
            let search = this.searchMultiFilterCtrl.value;
            if (!search) {
                this.processListFilter = this.processList;
            } else {
                search = search.toString().toLowerCase();
                this.processListFilter = this.processList.filter(
                    (bank) => {
                        return (
                            searchIndexOf(bank.name, search) ||
                            searchIndexOf(bank.processId.toString(), search) ||
                            searchIndexOf(bank.path, search) ||
                            searchIndexOf(bank.firstSeenAt.toString(), search) ||
                            searchIndexOf(bank.name, search)
                        );
                    });
            }
        }
        this.processDataSource = new MatTableDataSource(this.processListFilter);
        this.processDataSource.sort = this.sort;
    }

    onKillProcess(element: ProcessDetails): void {
        const message = '<h1>Tem certeza que deseja fechar esse processo?</h1> <div>' + element.name + '</div>';
        const matDialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: message,
                disableCancel: true,
                icon: 'info_outline',
                confirmButtonValue: 'OK'
            }
        });
        matDialogRef.afterClosed().subscribe((value) => {
            if (value) {
                const index: number = this.processList.findIndex(x => x.processId === element.processId);
                if (index !== -1) {
                    this.waiting = 15;
                    this.processList.splice(index, 1);
                    this.filterBanksMulti();
                }
                if (this.serverType === ServerType.WINDOWS) {
                    this.desktopServerService.onKillProcess(element.processId, this.baseServer.id);
                } else if (this.serverType === ServerType.LINUX) {
                    this.linuxServerService.killProcessByServerIdAndServerTypeAndProcessId(this.baseServer.id, this.serverType, element.processId);
                }
                this.waiting = 10;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

