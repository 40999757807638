import {Injectable} from '@angular/core';
import {BaseDao} from '../../../base-dao';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {VpnConfig} from '../../../models';
import {R2CloudHttpApiService} from '../../../r2-cloud-http-api.service';

@Injectable()
export class VpnConfigDaoService extends BaseDao<VpnConfig> {

  constructor(protected http: R2CloudHttpApiService, protected validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'vpn-configs');
  }

}
