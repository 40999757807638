<div class="page-layout carded fullwidth">
    <div class="top-bg accent"></div>
    <div class="header accent p-24" fxLayout="row" fxLayoutAlign="start center">
        <h1 class="m-0" style="z-index: 99;    margin-top: 60px !important;">Status </h1>
        <mat-slide-toggle color="primary" [formControl]="onlineDataControl"
                          style="margin-top: 60px !important;margin-left: 20px;z-index: 999;">Online
        </mat-slide-toggle>
    </div>
    <div class="center blue-color" style="margin-top: 60px;">
        <div class="content-card mt-20" style="background: none;box-shadow: none;">
            <app-access-chart></app-access-chart>
            <div fxLayout="row">
                <div fxFlex="50" style="padding-right: 10px;">
                    <app-k8s></app-k8s>
                    <app-instance-schedule-audits></app-instance-schedule-audits>
                    <app-manager-user-online></app-manager-user-online>
                </div>
                <div fxFlex="50" style="padding-left: 10px;">

                    <app-servers-offline [adDomains]="adDomains" [subProjects]="subProjects"></app-servers-offline>

                    <app-servers-online [adDomains]="adDomains" [subProjects]="subProjects"></app-servers-online>
                    <app-project-changing-or-deleting></app-project-changing-or-deleting>

                    <app-manager-user-changing-or-deleting
                        [customers]="customers"
                        [customerGroups]="customerGroups">
                    </app-manager-user-changing-or-deleting>

                    <app-remote-apps-changing-or-deleting
                        [subProjects]="subProjects"
                        [customerGroups]="customerGroups">
                    </app-remote-apps-changing-or-deleting>


                    <app-remote-app-snapshots
                        [customerGroups]="customerGroups"
                        [subProjects]="subProjects">
                    </app-remote-app-snapshots>


                    <app-desktop-server-version
                        [customerGroups]="customerGroups"
                        [subProjects]="subProjects">
                    </app-desktop-server-version>

                    <app-ad-user-changing-or-deleting
                        [adDomains]="adDomains"
                        [subProjects]="subProjects"
                        [managerUsers]="managerUsers"
                        [customerGroups]="customerGroups"
                        [adUsers]="adUsers"
                        (adUsersRead)="onADUsersRead($event)"

                    >
                    </app-ad-user-changing-or-deleting>

                    <app-ad-group-changing-or-deleting
                        [adDomains]="adDomains"
                        [subProjects]="subProjects"
                        [adGroups]="adGroups"
                        [customerGroups]="customerGroups"
                        (adGroupsRead)="onADGroupsRead($event)"
                    >
                    </app-ad-group-changing-or-deleting>

                    <app-ad-user-snapshots
                        [adDomains]="adDomains"
                        [managerUsers]="managerUsers"
                        [customerGroups]="customerGroups"
                        [subProjects]="subProjects"
                        [adUsers]="adUsers">
                    </app-ad-user-snapshots>

                    <app-ad-group-snapshots
                        [adDomains]="adDomains"
                        [managerUsers]="managerUsers"
                        [customerGroups]="customerGroups"
                        [subProjects]="subProjects"
                        [adGroups]="adGroups"
                        (adGroupsRead)="onADGroupsRead($event)"
                    >
                    </app-ad-group-snapshots>

                </div>
            </div>
            <div style="width: 100%;height: 100px"></div>
        </div>
    </div>
</div>