import {Injectable, OnDestroy} from '@angular/core';
import {StompConnectionStatus} from '../ws/stomp-connection-status.enum';
import {RemoteAppHtml5, RemoteAppReady} from '../models';
import {ComponentCleaner} from '../component-cleaner';
import {Observable, Observer} from 'rxjs';
import {R2CloudClientSubscriber} from '../ws/r2-cloud-client-subscriber';
import {USER_TOPIC} from '../../../main';
import {RemoteAppDaoService} from '../main/remote-app/remote-app-dao.service';
import {R2CloudStompService} from '../ws/r2-cloud-stomp.service';

@Injectable({
    providedIn: 'root'
})
export class RemoteAppHtml5Service extends ComponentCleaner implements OnDestroy {
    private connected = false;
    private subscribers: R2CloudClientSubscriber<RemoteAppHtml5>[] = [];
    private status: RemoteAppHtml5[] = [];

    constructor(private stomp: R2CloudStompService, private remoteAppDao: RemoteAppDaoService) {
        super();
        this.addSubscription(this.stomp.connectionStatus$.subscribe((stompStatus: StompConnectionStatus) => {
            if (stompStatus === StompConnectionStatus.OK) {
                this.connected = true;
            } else {
                this.connected = false;
            }
        }));
        this.addSubscription(this.stomp.stompTopic<RemoteAppReady[]>(`${USER_TOPIC}/remote-apps-ready`).subscribe((list: RemoteAppReady[]) => {
            for (const remoteAppReady of list) {
                if (!remoteAppReady.serviceApp) {
                    const find = this.status.find((value) => {
                        return value.remoteAppId === remoteAppReady.remoteAppId;
                    });
                    if (find) {
                        find.status = remoteAppReady.status;
                        this.emit(find);
                    } else {
                        this.remoteAppDao.getOneHtml5(remoteAppReady.remoteAppId).subscribe((result) => {
                            result.status = remoteAppReady.status;
                            this.status.push(result);
                            this.emit(result);
                        });
                    }
                }
            }
        }));
    }

    remoteAppChanges(): Observable<RemoteAppHtml5> {
        return new Observable<RemoteAppHtml5>((observer: Observer<RemoteAppHtml5>) => {
            const r2CloudClientSubscriber = new R2CloudClientSubscriber<RemoteAppHtml5>(observer);
            this.subscribers.push(r2CloudClientSubscriber);
            this.next(observer);
            return () => {
                this.subscribers = this.subscribers.filter((value) => {
                    return r2CloudClientSubscriber.id !== value.id;
                });
                observer.complete();
            };
        });
    }

    private next(observer: Observer<RemoteAppHtml5>): void {
        for (const status of this.status) {
            observer.next(status);
        }
    }

    private emit(status: RemoteAppHtml5): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.next(status);
        }
    }

    ngOnDestroy(): void {
        for (const subscriber of this.subscribers) {
            subscriber.observer.complete();
        }
        super.ngOnDestroy();
    }
}
