import {Component} from '@angular/core';
import {ComponentCleaner} from '../../component-cleaner';
import {BackgroundImageTemplateDaoService} from './background-image-template-list/background-image-template-dao.service';
import {UserAuthorityDaoService} from '../user-authority/user-authority-dao.service';
import {BackgroundImageTemplate, CustomPortalTemplate} from '../../models-custom-layout';
import {ManagerUserPermissionsDaoService} from '../manager-user/manager-user-permissions/manager-user-permissions-dao.service';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {CustomPortalTemplateDaoService} from './custom-portal-list/custom-portal-template-dao.service';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {CustomerGroup} from '../../models';

@Component({
    selector: 'app-custom-layout',
    templateUrl: './custom-layout.component.html',
    styleUrls: ['../../styles/tabs-search-blue-top.scss', './custom-layout.component.scss']
})
export class CustomLayoutComponent extends ComponentCleaner {
    backgroundImageTemplateList: BackgroundImageTemplate[] = [];
    backgroundImageTemplateFilterList: BackgroundImageTemplate[] = [];
    customPortalTemplateList: CustomPortalTemplate[] = [];
    customPortalTemplateFilterList: CustomPortalTemplate[] = [];
    searchMultiFilterCtrl: FormControl = new FormControl();
    protected _onDestroy = new Subject<void>();
    isAdmin = false;
    search: string;
    customerGroup: CustomerGroup;

    constructor(private backgroundImageTemplateDaoService: BackgroundImageTemplateDaoService,
                private customPortalTemplateDaoService: CustomPortalTemplateDaoService,
                private userAuthorityDaoService: UserAuthorityDaoService,
                private customerGroupDaoService: CustomerGroupDaoService,
                private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService) {
        super();
        this.getMyCustomerGroup();
        this.checkAdmin();
        this.findAllBackgroundImageTemplate();
        this.findAllCustomPortalTemplate();
        this.checkSearchValue();
    }

    private getMyCustomerGroup(): void {
        this.customerGroupDaoService.getByAuthenticatedManagerUser().subscribe((customerGroup) => {
            if (customerGroup) {
                this.customerGroup = customerGroup;
            }
        });
    }

    checkSearchValue(): void {
        this.searchMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterBanksMulti();
            });
    }


    findAllCustomPortalTemplate(): void {
        this.customPortalTemplateDaoService.findMyList().subscribe((customPortalTemplateList) => {
            if (customPortalTemplateList) {
                this.customPortalTemplateList = customPortalTemplateList;
                this.filterBanksMulti();
            }
        });
    }

    findAllBackgroundImageTemplate(): void {
        this.backgroundImageTemplateDaoService.findMyList().subscribe((backgroundImageTemplateList) => {
            if (backgroundImageTemplateList) {
                this.backgroundImageTemplateList = backgroundImageTemplateList;
                this.filterBanksMulti();
            }
        });
    }

    private checkAdmin(): void {
        this.userAuthorityDaoService.getMe().subscribe((user) => {
            if (user) {
                this.managerUserPermissionsDaoService.isAdminUser(user.managerUser.id).subscribe((isAdmin) => {
                    this.isAdmin = isAdmin;
                });
            }
        });
    }

    protected filterBanksMulti(): void {
        let search = this.searchMultiFilterCtrl.value;
        this.search = search;
        if (!search) {
            this.backgroundImageTemplateFilterList = this.backgroundImageTemplateList;
            this.customPortalTemplateFilterList = this.customPortalTemplateList;
            return;
        } else {
            search = search.toLowerCase();
        }
        this.backgroundImageTemplateFilterList = this.backgroundImageTemplateList.filter(
            (bank) => {
                return (
                    bank.name.toLowerCase().indexOf(search) > -1
                );
            });
        this.customPortalTemplateFilterList = this.customPortalTemplateList.filter(
            (bank) => {
                return (
                    bank.name.toLowerCase().indexOf(search) > -1
                );
            });
    }
}
