<div class="page-layout carded fullwidth" fusePerfectScrollbar>
  <!-- TOP BACKGROUND -->
  <div class="top-bg mat-accent-bg"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- CONTENT HEADER -->
    <div class="header white-fg p-24" fxLayout="row" fxLayoutAlign="start center">
      <h2>Lista de Data Centers</h2>
      <button type="button" mat-mini-fab id="addDatacenter" aria-label="Adicionar" matTooltip="Adicionar" (click)="onAdd()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <!-- / CONTENT BODY -->
    <div class="content">
      <mat-card>
        <div class="widget-group" fxLayout="column" fxFlex="100" fxLayoutWrap *fuseIfOnDom
             [@animateStagger]="{value:'50'}">
          <div *ngFor="let dataCenter of dataCenters">
            {{dataCenter?.name}} - {{findProjectById(dataCenter?.projectId)?.name}}
          </div>
          <!--<app-customer-item *ngFor="let customer of customers$ | async" [customerModel]="customer"-->
                             <!--[projects]="(projects$ | async)" (onDeleted)="onDeleted($event)">-->

          <!--</app-customer-item>-->
        </div>
      </mat-card>
    </div>
  </div>
</div>
