<div fusePerfectScrollbar class="page-layout simple fullwidth">
    <div class="content p-24">
        <div fxLayout="column">
            <!--<div>-->
                <!--<button type="button" mat-raised-button (click)="getTest()">-->
                    <!--Teste-->
                <!--</button>-->
                <!--{{test | async | json}}-->
            <!--</div>-->
            <div>
                <form (submit)="onTestServerAgent()" fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Token</mat-label>
                        <textarea matInput required [formControl]="serverAgentTokenControl">

                        </textarea>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
                        <mat-label>IP Público</mat-label>
                        <input matInput [formControl]="serverPublicIpControl" required>
                        <mat-error>Requerido</mat-error>
                    </mat-form-field>
                    <button type="submit">DotNetServerAgent</button>
<!--                    <div *ngIf="testServerAgent">-->
<!--                        <fuse-highlight lang="json" [code]="testServerAgent | json">-->

<!--                        </fuse-highlight>-->
<!--                    </div>-->
                    <div *ngIf="testServerAgentError">
                        <fuse-highlight lang="json" [code]="testServerAgentError | json">

                        </fuse-highlight>
                    </div>
                </form>

<hr style="margin-top: 200px;margin-bottom: 200px">

                <div>
                    <div>Teste de erro:</div>

                    <button type="button" mat-raised-button color="primary" (click)="onErrorSimulate()">Simular erro</button>


                </div>

                <!--<form method="POST" (submit)="onTestImageFileUpload()" enctype="multipart/form-data" action="/image-file/upload" >-->
                    <!--<table>-->
                        <!--<tr><td>File to upload:</td><td>-->
                            <!--<input (change)="onChangeImageFile($event)"-->
                                <!--[formControl]="imageFileControl" type="file" name="file" accept="image/*" /></td></tr>-->
                        <!--<tr><td></td><td><input type="submit" value="Upload" /></td></tr>-->
                    <!--</table>-->
                <!--</form>-->

                <!--<hr>-->





<!--                <button type="button" mat-raised-button color="primary" (click)="onCreateScriptExecution()">Criar script execution</button>-->

            </div>
        </div>
    </div>


    <button mat-raised-button type="button" color="primary" class="md-small" (click)="onTestAuth()">Testar auth</button>


</div>