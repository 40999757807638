import {Injectable, OnDestroy} from '@angular/core';
import {CustomerGroup} from '../../models';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {CustomerGroupDaoService} from './customer-group-dao.service';
import {QueryParamsService} from '../../query-params.service';
import {CookieService} from 'ngx-cookie-service';
import {R2MenuService} from '../../utils/r2-menu.service';

@Injectable({
    providedIn: 'root'
})
export class CustomerGroupService implements OnDestroy {
    private customerGroup$ = new BehaviorSubject<CustomerGroup>(null);
    private customerGroupParam = 'id';
    private subscription: Subscription;
    private _customerGroups: CustomerGroup[];

    constructor(private route: ActivatedRoute,
                private location: Location,
                private queryParamsService: QueryParamsService,
                private r2MenuService: R2MenuService,
                private cookieService: CookieService,
                private customerGroupDaoService: CustomerGroupDaoService) {
        r2MenuService.customerGroupService = this;
        this.subscription = this.route.queryParamMap.subscribe((queryParamMap) => {
            // console.debug('menu: queryParamChanged');
            // console.debug('menu: ' + queryParamMap);
            if (queryParamMap && queryParamMap.has(this.customerGroupParam)) {
                const customerGroupId: number = Number(queryParamMap.get(this.customerGroupParam));
                this.customerGroupDaoService.getOne(customerGroupId).subscribe((customerGroup) => {
                    this.set(customerGroup);
                });
            }
        });
    }

    public set(customerGroup: CustomerGroup): void {
        if (!customerGroup && this.customerGroup$.value) {
            this.setCustomerGroup(customerGroup);
        } else if (customerGroup && this.customerGroup$.value && customerGroup.id !== this.customerGroup$.value.id) {
            this.setCustomerGroup(customerGroup);
        } else if (customerGroup && customerGroup.id && !this.customerGroup$.value) {
            this.setCustomerGroup(customerGroup);
        }
    }

    private setCustomerGroup(customerGroup: CustomerGroup): void {
        this.customerGroup$.next(customerGroup);
        if (customerGroup && customerGroup.id) {
            this.queryParamsService.setUrlQueryParam({
                id: this.customerGroupParam,
                preserve: true,
                value: customerGroup.id
            });
            localStorage.setItem('customerGroupId', customerGroup.id.toString());
            console.log('menu: setCustomerGroup');
            this.r2MenuService.hideMenuWithoutPermission(customerGroup.id);
        }
    }

    public get(): Observable<CustomerGroup> {
        return this.customerGroup$;
    }

    get customerGroup(): CustomerGroup {
        return this.customerGroup$.getValue();
    }

    set customerGroup(customerGroup: CustomerGroup) {
        this.setCustomerGroup(customerGroup);
    }

    get customerGroups(): CustomerGroup[] {
        return this._customerGroups;
    }

    set customerGroups(customerGroups: CustomerGroup[]) {
        this._customerGroups = customerGroups;
    }


    public getCustomerGroup(customerGroupId: number): CustomerGroup {
        const customerGroupsInner = this._customerGroups;
        let customerGroup;
        if (customerGroupsInner) {
            const index = customerGroupsInner.findIndex((o) => o.id === customerGroupId);
            if (index > -1) {
                customerGroup = customerGroupsInner[index];
            }
        }
        return customerGroup;
    }

    public getLastSelectedCustomerGroupByCookie(): CustomerGroup {
        const lastSelectedCustomerGroupIdByCookie = localStorage.getItem('customerGroupId');
        return this.getCustomerGroup(Number(lastSelectedCustomerGroupIdByCookie));
    }

    destroyer(): void {
        this._customerGroups = [];
        this.subscription.unsubscribe();
        this.customerGroup$.complete();
        this.customerGroupDaoService.clearCache();
        // console.log('customerGroup: destroyer();');
    }

    ngOnDestroy(): void {
        this.destroyer();
    }
}
