import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerGroupDaoService} from './customer-group-dao.service';
import {CustomerGroupCreateEditComponent} from './customer-group-create-edit/customer-group-create-edit.component';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {CustomerGroupListComponent} from './customer-group-list/customer-group-list.component';
import {AuthService} from '../../auth.service';
import {CustomerGroupService} from './customer-group.service';
import {CustomerGroupSearchComponent} from './customer-group-search/customer-group-search.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatTabsModule} from '@angular/material/tabs';

const routes = [
    {
        path: 'customer-group',
        component: CustomerGroupListComponent,
        canActivate: [AuthService],
        data: {permissions: ['CustomerGroup.READ']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
        NgxMatSelectSearchModule,
        MatTabsModule
    ],
    entryComponents: [CustomerGroupCreateEditComponent, CustomerGroupSearchComponent],
    declarations: [CustomerGroupCreateEditComponent, CustomerGroupListComponent, CustomerGroupSearchComponent],
    providers: [CustomerGroupDaoService, CustomerGroupService]
})
export class CustomerGroupModule {
}
