<mat-card>
  <form (ngSubmit)="onSubmit()" *ngIf="vpnConfig && vpnConfig.id ; else createNew" [formGroup]="myForm"
        fxLayout="column">
    <div>
      <mat-slide-toggle formControlName="active" name="active">
        Habilitar VPN
      </mat-slide-toggle>
    </div>
    <div class="mt-16">
      <mat-form-field>
        <input matInput placeholder="Prefixo de Rede" formControlName="networkPrefix" name="networkPrefix">
        <mat-error *ngIf="myForm.get('networkPrefix').hasError('required'); else testNetworkPrefix">
          Preencha o Prefixo de Rede
        </mat-error>
        <ng-template #testNetworkPrefix>
          <mat-error *ngIf="myForm.get('networkPrefix').hasError('invalidNetworkPrefix')">
            Prefixo de Rede Inválido
          </mat-error>
        </ng-template>
      </mat-form-field>
    </div>
    <div class="mt-16">
      <mat-form-field>
        <input matInput placeholder="Network Bits" formControlName="networkPrefixBits" name="networkPrefixBits" type="number">
        <mat-error *ngIf="myForm.get('networkPrefixBits').errors;">
          Bits de Rede Inválido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="mt-16">
      <button type="submit" [disabled]="myForm.pristine || !myForm.valid" mat-raised-button color="primary">
        Salvar
      </button>
      <button type="button" [disabled]="myForm.pristine" (click)="onCancel()" mat-raised-button color="warn">
        Cancelar
      </button>
    </div>
  </form>
  <ng-template #createNew>
    <div class="mat-error">
      Sem Configuração de VPN
    </div>
    <button type="button" *appIsAdmin mat-raised-button color="primary" (click)="onCreateVpnConfig()">Criar Configuração VPN</button>
  </ng-template>

</mat-card>
