import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {BaseDao} from '../../base-dao';
import {ScriptExecution, ScriptExecutionType, ServerType} from '../../models';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class ScriptExecutionDaoService extends BaseDao<ScriptExecution> {
    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'script-execution');
    }

    logoffUserOnDesktopServer(desktopServerId: number, aDLogin: string): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/user-logoff?desktopServerId=' + desktopServerId + '&aDLogin=' + aDLogin);
    }

    resetUserOnDesktopServer(desktopServerId: number, aDLogin: string): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/user-reset?desktopServerId=' + desktopServerId + '&aDLogin=' + aDLogin);
    }


    killProcess(desktopServerId: number, processId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/kill-process?desktopServerId=' + desktopServerId + '&processId=' + processId);
    }

    userLogoffByServerIdAndServerType(serverId: number, serverType: ServerType, aDLogin: string): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/user-logoff-by-server-id-and-server-type/' + serverType + '/' + serverId + '/' + aDLogin);
    }

    killProcessByServerIdAndServerTypeAndProcessId(serverId: number, serverType: ServerType, processId: number): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/kill-process-by-server-id-and-server-type-and-process-id/' + serverType + '/' + serverId + '/' + processId);
    }

    handlerServiceProcessByServerIdAndServerTypeAndProcessId(
        serverId: number,
        serverType: ServerType,
        serviceName: string,
        scriptExecutionType: ScriptExecutionType): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/handler-service-by-server-id-and-server-type-and-service-name/' + serverType + '/' + serverId + '/' + scriptExecutionType + '?serviceName=' + serviceName);
    }

    sendWhichModuleIsOpen(serverId: number, serverType: ServerType, moduleName: string): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/which-module-is-open/' + serverType + '/' + serverId + '/' + moduleName);
    }
}
