<div class="dialog-content-wrapper">
        <form [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()"
              id="manager-user-create-form" >
            <mat-toolbar class="mat-accent m-0">
                <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="title dialog-title">Cadastro de Usuário na empresa</span>
                </mat-toolbar-row>
            </mat-toolbar>

            <div class="mat-white-bg mat-elevation-z2 " fxLayout="column" fxLayoutAlign="space-between stretch"
                 matDialogContent>

                <table>
                    <tr>
                        <td class="vertical-align-top w-150">
                            <div [hidden]="myForm.get('managerUserForm').disabled">
                                <div>
                                    <img src="{{imageFileService.getManagerUserPicture(managerUser)}}?w=150&square=true"
                                         width="150"
                                         height="150"
                                         *ngIf="!imageFileElement">
                                    <img [src]="'data:image/jpg;base64,' + imageFileElement" width="150" height="150"
                                         *ngIf="imageFileElement">
                                </div>
                                <button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                        class="green-800-bg green-800"
                                        onclick="document.getElementById('fileToUpload').click()">
                                    Alterar Foto
                                </button>
                                <input (change)="onImageFileChange($event)" name="file"
                                       id="fileToUpload" accept="image/*" type="file"  style="display: none;">
                            </div>
                        </td>
                        <td class="pl-20 vertical-align-top">


                            <mat-form-field class="full-width" [hidden]="data.managerUser">
                                <input matInput placeholder="E-mail" name="email" required formControlName="emailForm" id="manager-user-create-email" >
                                <mat-error>
                                    Email inválido.
                                </mat-error>
                            </mat-form-field>
                            <div class="font-size-16 font-weight-700 mt-10 mb-20"
                                 id="manager-user-create-customer-manager-email-delete"
                                 *ngIf="data.managerUser">
                                {{data.managerUser.email}}
                            </div>

                            <div *ngIf="emailAlReadyExists">
                                <button type="button" mat-raised-button color="primary"
                                        (click)="onAddRoleToTheManagerUser()">
                                    Adicionar um papel ao usuário
                                </button>
                            </div>


                            <div formGroupName="managerUserForm" [hidden]="myForm.get('managerUserForm').disabled">
                                <mat-form-field class="full-width"
                                                *ngIf="adminService.customers && adminService.customers.length > 0">
                                    <mat-select placeholder="Filiais" formControlName="customerId" required  id="manager-user-create-customer-id">
                                        <mat-option *ngFor="let customer of adminService.customers; let i = index;"  id="manager-user-create-customer-id-{{i}}"
                                                    [value]="customer.id">
                                            {{customer.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('customerId').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                            </div>


                            <div formGroupName="managerUserForm" [hidden]="myForm.get('managerUserForm').disabled">
                                <mat-form-field class="full-width"
                                                *ngIf="managerUserAccessPeriodProfiles">
                                    <mat-select placeholder="Horário de Acesso permitido"
                                                formControlName="managerUserAccessPeriodProfileId">
                                        <mat-option [value]="null"> -</mat-option>
                                        <mat-option
                                            *ngFor="let managerUserAccessPeriodProfile of managerUserAccessPeriodProfiles"
                                            [value]="managerUserAccessPeriodProfile.id">
                                            {{managerUserAccessPeriodProfile.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('customerId').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                            </div>


                            <div formGroupName="managerUserForm" [hidden]="myForm.get('managerUserForm').disabled">


                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Nome" name="displayName" required
                                           formControlName="displayName"  id="manager-user-create-name">
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('displayName').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Número do Celular" name="phone" mask="(00)-00000000000"
                                           [validation]="false" formControlName="phone" id="manager-user-create-phone" >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('phone').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Estado" formControlName="province" id="manager-user-create-province" >
                                        <mat-option *ngFor="let province of provinceList; let i = index;" [value]="province.key"
                                                    id="manager-user-create-province-id-{{i}}" >
                                            {{province.value}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('province').errors | error}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Cidade" name="city" formControlName="city" id="manager-user-create-city" >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('city').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Bairro" name="neighborhood"  id="manager-user-create-neighborhood"
                                           formControlName="neighborhood">
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('neighborhood').errors | error}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Endereço" name="address" formControlName="address"
                                           id="manager-user-create-address"  >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('address').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Número" mask="00000000000" [validation]="false"
                                           name="addressNumber" formControlName="addressNumber" id="manager-user-create-address-number" >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('addressNumber').errors | error}}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Complemento" name="addressAddOn"
                                           formControlName="addressAddOn" id="manager-user-create-address-add-on" >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('addressAddOn').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <mat-form-field class="full-width">
                                    <input matInput placeholder="CEP" mask="00000-000" name="zipCode"
                                           formControlName="zipCode" id="manager-user-create-zip-code" >
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('zipCode').errors | error}}
                                    </mat-error>
                                </mat-form-field>


                                <div style="margin: 30px 0 45px 0;">
                                    <mat-slide-toggle formControlName="twoFactorAuth" name="twoFactorAuth" (change)="changeTwoFacture()">
                                        Autenticação em dois fatores
                                    </mat-slide-toggle>
                                </div>


                                <div style="margin: 20px 0 50px 0;">
                                    <mat-slide-toggle formControlName="active" name="active">
                                        Ativo
                                    </mat-slide-toggle>
                                </div>
                            </div>

                        </td>
                    </tr>
                </table>


            </div>
            <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
                 matDialogActions>
                <button type="submit" mat-raised-button color="accent" id="manager-user-create-submit"
                        [disabled]="!myForm.get('managerUserForm').valid || myForm.get('managerUserForm').pristine"
                        aria-label="Salvar">
                    <span *ngIf="myForm.get('managerUserForm').get('id').value;else newUser">Salvar</span>
                    <ng-template #newUser>Criar</ng-template>
                </button>
                <div
                    *ngIf="(!myForm.get('managerUserForm').valid || myForm.get('managerUserForm').pristine) && managerUser.id">
                    <button type="button" mat-raised-button color="warn" id="manager-user-create-remove" (click)="onRemove()">Excluir</button>
                </div>
                <button type="button" mat-raised-button color="primary" id="manager-user-create-cancel"  (click)="onCancel()">Cancelar</button>
            </div>
        </form>
</div>

