<form *ngIf="myForm" [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onCreate()">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Criar Usuário Pool de acesso R2Cloud</span>
        </div>
    </mat-toolbar>
    <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch">

        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">

            <ng-container>

                <div fxLayout="row" fxLayoutAlign="space-between start">
                    <div fxLayout="column" fxLayoutAlign="space-between stretch" style="width: 600px;border-right: solid 1px #ccc;padding-right: 25px;">



                        <mat-form-field class="full-width min-width-450"  appearance="outline" *ngIf="getADDomainsWithADUserPool() && getADDomainsWithADUserPool().length > 1">
                            <mat-label>Domínio</mat-label>
                            <mat-select class="full-width"  placeholder="Domínio" formControlName="domainForm">
                                <mat-option *ngFor="let adDomain of getADDomainsWithADUserPool()" [value]="adDomain.id">
                                    {{adDomain.name}} | {{adDomain.domainName}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{myForm.get('domainForm').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" *ngIf="canCreateANewADUserPoolOnCurrentADDomain()">
                            <mat-label>E-mail</mat-label>
                            <input matInput formControlName="email" placeholder="E-mail">
                            <mat-error>
                                {{this.myForm.get('email').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <h3 *ngIf="!canCreateANewADUserPoolOnCurrentADDomain()" class="ml-5 warn p-10">
                            Não existem usuários disponíveis neste dominio.
                        </h3>

                        <div fxLayout="row" fxLayoutAlign="space-between start" >
                            <div>
                                <mat-form-field class="full-width min-width-450"  appearance="outline" formGroupName="managerUserForm" *ngIf="adminService.customers && adminService.customers.length > 0 && !hasCustomerId" style="width: 290px;" >
                                    <mat-label>Filiais</mat-label>
                                    <mat-select class="full-width"  placeholder="Filiais" formControlName="customerId">
                                        <mat-option *ngFor="let customer of adminService.customers" [value]="customer.id">
                                            {{customer.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        {{myForm.get('managerUserForm').get('customerId').errors | error}}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="!hasCustomerId || adminService.customers.length < 1" style="margin-top: 16px;" >
                                <button type="button" mat-raised-button color="accent" aria-label="Criar Filial" (click)="onCreateCustomer()">Criar Filial</button>

                            </div>

                        </div>

                    </div>
                    <div style="padding-right: 30px;">
                        <div *ngFor="let adDomain of getADDomainsWithADUserPool();">
                            <div class="font-weight-900" style="margin-top: 15px;">
                                ({{adDomain.name}}) Usuários:
                            </div>
                            <div>{{getNumberOfADUsersPoolUsedForThisADDomain(adDomain.id)}}
                                /{{getTotalADUserPoolByADDomainId(adDomain.id)}}</div>
                        </div>
                    </div>
                </div>


            </ng-container>


        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">

            <div>
                <button type="submit" mat-raised-button color="accent" aria-label="Excluir"
                        [disabled]="myForm.pristine || !myForm.valid || !canCreateANewADUserPoolOnCurrentADDomain()" >Criar
                </button>
            </div>

            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

            </div>
        </div>

    </div>
</form>