<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">

    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Grupo:  {{adGroup.name}}</span>
        </mat-toolbar-row>
    </mat-toolbar>


    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch">
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
            <mat-form-field class="full-width" appearance="outline"  *ngIf="!adGroup.id" [ngClass]="{'hidden': (adDomains && adDomains.length === 1)}">
                <mat-label>Domínio</mat-label>
                <mat-select placeholder="Domínio" formControlName="adDomainId">
                    <mat-option *ngFor="let adDomain of adDomains" [value]="adDomain.id">
                        {{adDomain.domainName}} | {{adDomain.name}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('adDomainId').errors | error}}
                </mat-error>
            </mat-form-field>
            <div *ngIf="adGroup.id" class="domain-edit">
                <div><b>Domínio:</b></div>
                {{getAdDomain(adGroup.adDomainId).domainName}}
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between" >
                <mat-form-field appearance="outline" class="min-width-400">
                    <mat-label>Nome do grupo</mat-label>
                    <input matInput formControlName="name" placeholder="Nome do grupo">
                    <mat-error>
                        {{myForm.get('name').errors | error}}
                    </mat-error>
                </mat-form-field>


                <div *ngIf="adGroup.prefix && adGroup.prefix.length > 0" style="margin-top: 10px;">
                    <div>
                        <b>Prefixo:</b>
                    </div>
                    <div> {{adGroup.prefix}}</div>
                </div>
            </div>


            <mat-form-field class="full-width" appearance="outline" *ngIf="adUsers">
                <mat-label>Usuários comuns</mat-label>
                <mat-select placeholder="Usuários comuns" formControlName="adUserIds" [multiple]="true" #multiSelect>
                    <mat-option>
                        <ngx-mat-select-search [formControl]="adUserMultiFilterCtrl"
                                               placeholderLabel="busca"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let adUser of filteredADUsersMulti | async" [value]="adUser.id">
                        {{getManagerUserEmail(adUser.managerUserId)}} - {{adUser.login}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    {{myForm.get('adUserIds').errors | error}}
                </mat-error>
            </mat-form-field>

            <div *appIfPermission="appCanCreateADUserPoolRelationship">
                <div *ngIf="adUsersPoolRelationship.length > 0">
                    <mat-form-field class="full-width" appearance="outline" *ngIf="adUsers">
                        <mat-label>Usuários do pool</mat-label>
                        <mat-select placeholder="Usuários do pool" formControlName="adUserPoolRelationshipIds" [multiple]="true" #multiSelect >
                            <mat-option>
                                <ngx-mat-select-search [formControl]="adUserPoolRelationshipMultiFilterCtrl"
                                                       placeholderLabel="busca"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let adUserPoolRelationship of filteredADUsersPoolRelationshipMulti | async" [value]="adUserPoolRelationship.id">
                                {{adminService.getManagerUser(adUserPoolRelationship.managerUserId).email}} - {{adminService.getAdUserPool(adminService.getAdUserPoolRelationship(adUserPoolRelationship.id).adUserPoolId).login}}
                            </mat-option>
                        </mat-select>
                        <mat-error>
                            {{myForm.get('adUserPoolRelationshipIds').errors | error}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <table class="aduser-table" *ngIf="myForm.get('adUserIds')?.value?.length > 0 || myForm.get('adUserPoolRelationshipIds')?.value?.length > 0">
                <thead>
                <tr class="bg-white">
                    <th>E-mail</th>
                    <th>User</th>
                </tr>
                </thead>
                <tr *ngFor="let adUserPoolRelationshipId of myForm.get('adUserPoolRelationshipIds')?.value">
                    <td class="text-max-335 text-email">{{adminService.getManagerUser(adminService.getAdUserPoolRelationship(adUserPoolRelationshipId).managerUserId).email}}</td>
                    <td>
                        <div class="text-max-120">{{adminService.getAdUserPool(adminService.getAdUserPoolRelationship(adUserPoolRelationshipId).adUserPoolId).login}}</div>
                    </td>
                </tr>
                <tr *ngFor="let adUserId of myForm.get('adUserIds')?.value">
                    <td class="text-max-335 text-email">{{getManagerUserEmail(getADUserById(adUserId).managerUserId)}}</td>
                    <td>
                        <div class="text-max-120">{{getADUserById(adUserId).login}}</div>
                    </td>
                </tr>
            </table>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
            <div matTooltip="Salvar">
                <button type="submit" mat-raised-button color="accent" [disabled]="myForm.pristine || !myForm.valid"
                        aria-label="Salvar">

                    <span *ngIf="adGroup.id;else newUser">Salvar</span>
                    <ng-template #newUser>Criar</ng-template>

                </button>
            </div>

            <div *ngIf="adGroup.id">
                <button type="button" mat-raised-button color="warn" (click)="onRemove()">Excluir</button>
            </div>

            <div *ngIf="adGroup.id">
                <button type="button" mat-raised-button color="warn" (click)="onForceDelete()">Exclusão forçada</button>
            </div>

            <div>
                <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
            </div>
        </div>
    </div>
</form>
