import {AfterViewInit, Component} from '@angular/core';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {ADDomain, ADUserPool, DesktopServer} from '../../../../models';
import {FormControl} from '@angular/forms';
import {AdDomainDaoService} from '../../../ad-domain/ad-domain-dao.service';
import {DesktopServerDaoService} from '../../../desktop-server/desktop-server-dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {AdUserPoolDaoService} from '../../ad-user-pool-dao.service';
import {filter} from 'rxjs/operators';
import {ComponentCleaner} from '../../../../component-cleaner';
import {MatDialog} from '@angular/material/dialog';
import {CrudOperationWrapper, orderArrayAnyBy} from '../../../../helpers/kluh';
import {AdUserPoolInfoComponent} from '../ad-user-pool-info/ad-user-pool-info.component';
import {ConfirmDialogComponent} from '../../../../helpers/confirm-dialog/confirm-dialog.component';
import {AdUserPoolRelationshipDaoService} from '../../../ad-user-pool-relationship/ad-user-pool-relationship-dao.service';

@Component({
    selector: 'app-ad-user-pool-list',
    templateUrl: './ad-user-pool-list.component.html',
    styleUrls: ['./ad-user-pool-list.component.scss', '../ad-user-pool-edit.component.scss']
})
export class AdUserPoolListComponent extends ComponentCleaner implements AfterViewInit {
    appCanCreateList: PermissionWrapper[];
    appCanDeleteList: PermissionWrapper[];
    adUserPoolType = ADUserPoolType;
    adUsersPool: ADUserPool[] = [];
    filteredADUsersPool: ADUserPool[] = [];
    adDomains: ADDomain[] = [];
    currentADDomain: ADDomain;
    desktopServers: DesktopServer[] = [];
    adDomainFormControl: FormControl = new FormControl('');
    selectUserTypeFormControl: FormControl = new FormControl('');

    constructor(
        private dialogRef: MatDialog,
        public customerGroupService: CustomerGroupService,
        private adDomainDaoService: AdDomainDaoService,
        private desktopServerDaoService: DesktopServerDaoService,
        private adUserPoolDaoService: AdUserPoolDaoService,
        private adUserPoolRelationshipDaoService: AdUserPoolRelationshipDaoService,
        private dialog: MatDialog,
    ) {
        super();
        this.appCanCreateList = [
            {type: 'ADUserPool', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
        this.appCanDeleteList = [
            {type: 'ADUserPool', permission: 'DELETE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
        ];
    }

    amountOfADUserPoolService(): number {
        return this.amountOfADUserPoolByType(true);
    }
    amountOfADUserPoolApp(): number {
        return this.amountOfADUserPoolByType(false);
    }
    private amountOfADUserPoolByType(isServiceUser: boolean): number {
        return this.filteredADUsersPool.filter(adUserPool => adUserPool.serviceUser === isServiceUser).length;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.customerGroupService.get().subscribe(customerGroup => {
                const adDomains$ = this.adDomainDaoService.filter({'customerGroupId': customerGroup.id});
                const desktopServers$ = this.desktopServerDaoService.getAllByCustomerGroupId(customerGroup.id);
                combineLatest([adDomains$, desktopServers$]).subscribe((values) => {
                    const adDomains = values[0];
                    const desktopServers = values[1];
                    if (desktopServers) {
                        this.desktopServers = desktopServers;
                    }
                    if (adDomains) {
                        this.adDomains = adDomains;
                        this.currentADDomain = this.adDomains[0];
                        this.adDomainFormControl.setValue(this.currentADDomain.id);
                        this.getADUsersPoolByAdDomainId(this.currentADDomain.id);
                    } else {
                        this.adDomains = [];
                        this.adUsersPool = [];
                        this.filteredADUsersPool = [];
                        this.currentADDomain = null;
                    }
                });
            });
            this.selectUserTypeFormControl.setValue(this.adUserPoolType.ALL);
            this.adDomainFormControl.valueChanges.subscribe((adDomainId) => {
                if (adDomainId && this.currentADDomain.id !== adDomainId) {
                    this.currentADDomain = this.adDomains.find(adDomain => adDomain.id === adDomainId);
                    this.getADUsersPoolByAdDomainId(adDomainId);
                }
            });
            this.selectUserTypeFormControl.valueChanges.subscribe((userType) => {
                const adUsersPoolSort = orderArrayAnyBy(this.adUsersPool, 'login');
                if (userType === this.adUserPoolType.ALL) {
                    this.filteredADUsersPool = adUsersPoolSort;
                } else if (userType === this.adUserPoolType.USER_SERVICE) {
                    this.filteredADUsersPool = adUsersPoolSort.filter((adUserPool) => {
                        return adUserPool.serviceUser === true;
                    });
                } else if (userType === this.adUserPoolType.USER_APP) {
                    this.filteredADUsersPool = adUsersPoolSort.filter((adUserPool) => {
                        return adUserPool.serviceUser === false;
                    });
                }
            });
        }, 300);
    }


    openADUserPoolCreateModal(userService: boolean): void {
        const textUserApp = 'usuário do pool (APLICAÇÃO)';
        const textUserService = 'usuário do pool (SERVIÇO)';
        let userTypeText = textUserApp;
        if (userService) {
            userTypeText = textUserService;
        }
        this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            data: {
                message: '' +
                    'Tem certeza que deseja <b><u>CRIAR</u></b> um novo ( <b><u>' + userTypeText + '</u></b> ) no domínio ( ' + this.currentADDomain.domainName + ' )?' +
                    '<br><br>Para CRIAR digite <u>CRIAR</u> no campo abaixo',
                disableCancel: false,
                cancelButtonValue: 'Cancelar',
                confirmButtonValue: 'Criar',
                icon: 'error_outline',
                confirmFieldValue: 'CRIAR'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const adUserPool = this.adUserPoolDaoService.initADUserPool();
                adUserPool.login = 'x.' + Math.floor((Math.random() * 99999) + 1);
                adUserPool.adDomainId = this.currentADDomain.id;
                adUserPool.serviceUser = userService;
                this.adUserPoolDaoService.create(adUserPool).subscribe((adUserPoolCreated) => {
                    if (adUserPoolCreated) {
                        this.adUsersPool.push(adUserPoolCreated);
                    }
                    this.dialogRef.closeAll();
                });
            } else {
                this.dialogRef.closeAll();
            }
        });

    }

    openADUserPoolDeleteModal(adUserPool: ADUserPool): void {
        this.adUserPoolRelationshipDaoService.amountOfAdUserPoolRelationship(adUserPool.id).subscribe((adUserPoolRelationshipSize) => {
            if (adUserPoolRelationshipSize < 1) {
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: false,
                    data: {
                        message: '' +
                            'Tem certeza que deseja <b><u>DELETAR</u></b> o ' +
                            '( usuário do pool ) <b><u>' + adUserPool.login + '</u></b> do domínio ( ' + this.currentADDomain.domainName + ' )? ' +
                            '<br><br>Para deletar digite <u>DELETE</u> no campo abaixo',
                        disableCancel: false,
                        cancelButtonValue: 'Cancelar',
                        confirmButtonValue: 'Deletar',
                        icon: 'error_outline',
                        confirmFieldValue: 'DELETE'
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.adUserPoolDaoService.remove(adUserPool.id).subscribe((deleted) => {
                            this.getADUsersPoolByAdDomainId(adUserPool.adDomainId);
                            this.dialogRef.closeAll();
                        });
                    } else {
                        this.dialogRef.closeAll();
                    }
                });
            } else {
                this.dialog.open(ConfirmDialogComponent, {
                    disableClose: false,
                    data: {
                        message: 'Não é possivel' +
                            ' <b><u>DELETAR</u></b> esse usuário, ainda existem projetos usando, acesse esses projetos e remova antes de deletar.',
                        disableCancel: false,
                        confirmButtonValue: 'OK',
                        icon: 'error_outline'
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.dialogRef.closeAll();
                    }
                });
            }
        });

    }


    openADUserPoolInfoModal(adUserPool: ADUserPool): void {
        const dialogRef = this.dialog.open(AdUserPoolInfoComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-x-large',
            data: {
                adUserPool: adUserPool,
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            this.dialogRef.closeAll();
        });
        this.addSubscription(subscription);
    }

    private getADUsersPoolByAdDomainId(adDomainId: number): void {
        this.adUsersPool = [];
        this.adUserPoolDaoService.findAllByADDomainIds([adDomainId].toString()).subscribe((adUsersPool) => {
            this.adUsersPool = [];
            this.filteredADUsersPool = [];
            if (adUsersPool) {
                this.adUsersPool = adUsersPool;
                this.filteredADUsersPool = orderArrayAnyBy(this.adUsersPool, 'login');
            }
        });
    }

    private getDesktopServer(adServerId: number): DesktopServer {
        return this.desktopServers.find(desktopServer => desktopServer.id === adServerId);
    }

    getDesktopServerHostname(adServerId: number): string {
        const hostname = this.getDesktopServer(adServerId)?.hostname;
        if (!hostname) {
            return 'Servidor não encontrado!';
        }
        return hostname;
    }


}

export enum ADUserPoolType {
    ALL = 'ALL',
    USER_SERVICE = 'USER_SERVICE',
    USER_APP = 'USER_APP'
}

