import {OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

export abstract class ComponentCleaner implements OnDestroy {
    private subscriptions: Subscription[] = [];
    onDestroy$ = new Subject();

    ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        this.subscriptions = [];
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    protected addSubscription(subscription: Subscription): Subscription {
        this.subscriptions.push(subscription);
        return subscription;
    }
}
