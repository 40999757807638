<form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()" autocomplete="off">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Tema do portal</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
         fxLayoutAlign="space-between stretch" matDialogContent>
        <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
            <mat-form-field appearance="outline">
                <mat-label>Nome do tema</mat-label>
                <input matInput formControlName="name">
                <mat-error>
                    {{myForm.get('name').errors | error}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Titúlo da página</mat-label>
                <input matInput formControlName="title">
                <mat-error>
                    {{myForm.get('title').errors | error}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Link de ajuda</mat-label>
                <input matInput formControlName="helpLink">
                <mat-error>
                    {{myForm.get('helpLink').errors | error}}
                </mat-error>
            </mat-form-field>

            <div class="full-width">
                <button mat-raised-button type="button" class="green-bg" (click)="fileInput.click()"
                        style="min-height: 36px;">
                    <mat-icon>library_add</mat-icon>
                    <span class="ml-5">Adicionar logo</span>
                    <input #fileInput type="file" (change)="onImageFileChange($event)" style="display:none;"
                           accept="image/*"/>
                </button>
            </div>

            <div *ngIf="customPortalTemplate.logoId || (imageFileElement)">
                <span *ngIf="imageFileElement" class="p-14">
                     <img [src]="'data:image/jpg;base64,' + imageFileElement" width="80" height="80"
                          *ngIf="imageFileElement">
                </span>
                <span *ngIf="customPortalTemplate.logoId && !imageFileElement" class="p-14">
                    <img
                        src="{{imageFileService.getPictureUrlFromImageFileTemplateId(customPortalTemplate?.logoId, 80)}}"
                        width="80">
                </span>
            </div>


            <mat-form-field class="full-width min-width-230">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>CSS customizado do portal</mat-label>
                    <textarea rows="15" matInput  formControlName="css" class="full-width" ></textarea>
                </mat-form-field>
            </mat-form-field>

            <div *ngIf="!customPortalTemplate?.id">
                <div class="full-width min-width-230 mt-50 mb-60" *appIsAdmin>
                    <div>Tema público, todos podem usar e copiar</div>
                    <mat-slide-toggle formControlName="public">
                        público
                    </mat-slide-toggle>
                </div>
            </div>


        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top" matDialogActions>
        <div matTooltip="Salvar">

            <button type="submit" color="accent" mat-raised-button class="mat-green-600-bg" [disabled]="(!myForm.valid || myForm.pristine)">
        <span class="mat-button-wrapper">
            Salvar
        </span>
            </button>
        </div>
        <div *ngIf="!customPortalTemplate?.public || isAdmin">
            <button type="button" mat-raised-button color="warn" (click)="onDelete(customPortalTemplate)">Excluir</button>
        </div>
        <div>
            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>
        </div>
    </div>
</form>
