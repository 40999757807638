import {Component, Inject, OnInit} from '@angular/core';
import {DataCenterDAOService} from '../data-center-dao';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {addRequiredToFormGroup, convertToFormGroup} from '../../../helpers/kluh';
import {CustomerDaoService} from '../../customer/customer-dao.service';

import {filter} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {Customer, DataCenter, DataCenterType, Project} from '../../../models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-data-center-create',
  templateUrl: './data-center-create.component.html',
  styleUrls: ['./data-center-create.component.scss']
})
export class DataCenterCreateComponent implements OnInit{

  dataCenterForm: FormGroup;
  projects: Project[];
  project: Project;
  types: DataCenterType[];
  customers: Customer[];
  clearName: boolean;

  constructor(private dao: DataCenterDAOService,
              private customerDAO: CustomerDaoService,
              private fb: FormBuilder,
              private dialogRef: MatDialogRef<DataCenterCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.projects = data.projects;
    this.types = data.dataCenterTypes;
    this.project = data.project;
    this.clearName = data.clearName;


    const dataCenter: DataCenter = {
      id: null,
      comment: null,
      active: true,
      name: null,
      type: null,
      projectId: null,
      customerId: null,
        modified: null,
        optlock: null
    };

    // se tiver um project, só vai poder criar o datacenter para o projeto especifico
    if (this.project) {
      dataCenter.projectId = this.project.id;
    }
    dataCenter.type = DataCenterType.Google;
    if (!this.clearName) {
      dataCenter.name = 'Nuvem GCP ' + this.project.name;
    }

    this.dataCenterForm = this.fb.group(convertToFormGroup(dataCenter), {asyncValidator: this.dao.validator});
    addRequiredToFormGroup(this.dataCenterForm, ['name', 'type']);
    this.dataCenterForm.addControl('projectId', this.fb.control('', [Validators.required]));
    this.dataCenterForm.addControl('customerId', this.fb.control(''));
    combineLatest([this.dataCenterForm.get('type').valueChanges, this.dataCenterForm.get('customerId').valueChanges]).subscribe((result) => {
      const nameControl = this.dataCenterForm.get('name');
      if (result[0] === 'Local' && result[1]){
        const customer = this.customers.find(x => x.id === result[1]);
        nameControl.setValue(customer.name);
        nameControl.disable();
      } else if (nameControl.disabled) {
        nameControl.enable();
      }
    });
    this.dataCenterForm.get('projectId').valueChanges.pipe(filter(x => !!x)).subscribe((projectId) => {
      this.customerDAO.getActiveByProjectId(projectId).subscribe((customers) => {
        this.customers = customers;
      });
    });
  }

  onSubmit(): void{
    const dataCenter: DataCenter = this.dataCenterForm.value;
    if (dataCenter.type === 'Local'){
      dataCenter.name = this.dataCenterForm.get('name').value;
    }
    this.dao.create(dataCenter).subscribe((dataCenterResult) => {
      this.dialogRef.close(dataCenterResult);
    });
  }

  onCancel(): void{
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.dataCenterForm.get('name').markAsDirty();
      this.dataCenterForm.get('name').updateValueAndValidity();
    }, 300);

  }

}
