<form [formGroup]="dataCenterForm" fxLayout="column"
      (submit)="dataCenterForm.valid && onSubmit()">

    <mat-toolbar class="mat-accent m-0">
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Cadastro de Data Center</span>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-form-field style="padding: 15px 15px 0px 15px;">
      <input matInput formControlName="name" placeholder="Nome do Data Center">
    </mat-form-field>
    <mat-form-field  style="padding: 0px 15px 0px 15px;">
      <mat-select placeholder="Tipo" formControlName="type">
        <mat-option *ngFor="let type of types" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field   style="padding: 0px 15px 0px 15px;" *ngIf="!project">
      <mat-select placeholder="Projeto" formControlName="projectId">
        <mat-option *ngFor="let projectInner of projects" [value]="projectInner.id">
          {{projectInner.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="project" style="padding: 10px 15px 25px 15px;" >
      <b>Projeto : </b> {{project.name}}
    </div>
    <mat-form-field   style="padding: 0px 15px 0px 15px;" *ngIf="dataCenterForm.get('type').value === 'Local' && dataCenterForm.get('projectId').value">
    <mat-select placeholder="Cliente" formControlName="customerId">
      <mat-option *ngFor="let customer of customers" [value]="customer.id">
        {{customer.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
    <button type="submit" mat-raised-button color="accent" [disabled]="!dataCenterForm.valid"
            aria-label="Salvar">Criar</button>
    <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

  </div>
</form>
