<mat-toolbar class="p-0 mat-elevation-z1">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div class="logo-group" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <!--<button mat-icon-button class="navbar-toggle-button"-->
            <!--*ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>-->
            <!--*ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>-->
            <!--<mat-icon class="secondary-text">menu</mat-icon>-->
            <!--</button>-->
            <ng-container *ngIf="!hiddenNavbar; else showBackButton">
                <button mat-icon-button class="navbar-toggle-button" fxHide.lt-lg
                        (click)="toggleSidebarFolded('navbar')">
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

                <button mat-icon-button class="navbar-toggle-button" fxHide fxShow.lt-lg
                        (click)="toggleSidebarOpen('navbar')">
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>
            </ng-container>

            <ng-template #showBackButton>
                <button mat-icon-button class="navbar-toggle-button"
                        (click)="goToHome()">
                    <mat-icon class="secondary-text">arrow_back</mat-icon>
                </button>
            </ng-template>


            <div class="toolbar-separator" *ngIf="false && !hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div class="logo" *ngIf="
            !customerGroupTemplate?.customPortalTemplate?.logoId &&
            !customerGroupHasTemplate &&
            !loadingCustomGroupTemplate">
<!--                <img class="logo-icon" src="assets/images/logos/r2/medium/r2-cloud-robo-medium.png">-->
<!--                <img fxHide.lt-md class="logo-img-text"-->
<!--                     src="assets/images/logos/r2/medium/r2-cloud-logo-texto-white.png">-->
<!--                <img fxHide.lt-md class="logo-img-text"-->
<!--                     *ngIf="customerGroupTemplate?.defaultBase64PortalLogo"-->
<!--                     src="assets/images/logos/r2/medium/r2-cloud-logo-texto-white.png">-->
                <img  style="max-height: 64px;max-width: 200px;" [src]="customerGroupTemplate?.defaultBase64PortalLogo"
                     *ngIf="customerGroupTemplate?.defaultBase64PortalLogo">
            </div>
            <div class="logo" *ngIf="customerGroupTemplate?.customPortalTemplate?.logoId && customerGroupTemplate?.customPortalTemplate.logoId > 0 && !loadingCustomGroupTemplate">
                <img fxHide.lt-md
                     src="{{imageFileService.getPictureUrlFromImageFileTemplateId(customerGroupTemplate?.customPortalTemplate.logoId)}}"
                     style="max-height: 64px"
                    class="p-5">
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

            <div class="px-8 px-md-16">
                <fuse-shortcuts *ngIf="false" [navigation]="navigation"></fuse-shortcuts>
            </div>
            <div class="toolbar-separator"></div>
            <app-customer-group-tool-bar style="width: 235px"></app-customer-group-tool-bar>
            <div class="toolbar-separator"></div>

        </div>
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center">
            <app-search></app-search>
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">


            <!--<fuse-search-bar (input)="search($event)"></fuse-search-bar>-->



            <ng-container *ngIf="clientStatusEnabled">
                <app-client-status-toolbar></app-client-status-toolbar>
            </ng-container>
            <div class="toolbar-separator"></div>
            <button aria-haspopup="true" class="language-button mat-button" fxhide="" fxshow.gt-xs="" mat-button=""
                    (click)="helpLink()">
                <span class="mat-button-wrapper">
                    <div fxLayout="row" class="mr-8 s-16">
                        <mat-icon aria-hidden="false" class="yellow-900-fg" aria-label="Ajuda">help</mat-icon>
                         <div class="ml-8 " style="font-size: 16px;margin-top: 1px;">
                             Ajuda
                        </div>
                    </div>
                </span>
                <div class="mat-button-ripple mat-ripple" matripple="">

                </div>
                <div class="mat-button-focus-overlay"></div>
            </button>
            <div class="toolbar-separator"></div>
            <button mat-button [matMenuTriggerFor]="userMenu" (onMenuOpen)="onMenuOpen()" *ngIf="(user$|async) as user"
                    class="user-button" id="toolbar-menu-profile">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16"
                         src="{{imageFileService.getManagerUserPicture(user.managerUser)}}?w=150&square=true" *ngIf="user;">
                    <span class="username mr-12 text-max-120" fxHide fxShow.gt-sm
                          *ngIf="user && user.managerUser?.displayName; else noDisplayName">{{user.managerUser?.displayName}}</span>
                    <ng-template #noDisplayName>
                        <span class="username mr-12 text-max-120" fxHide fxShow.gt-sm>{{user.email}}</span>
                    </ng-template>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>
            <div>
            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item routerLink="/profile" id="toolbar-menu-profile-edit">
                    <mat-icon>account_circle</mat-icon>
                    <span>Minha conta</span>
                </button>

                <!--<button mat-menu-item class="">-->
                <!--<mat-icon>mail</mat-icon>-->
                <!--<span>Inbox</span>-->
                <!--</button>-->


                <button mat-menu-item class="" (click)="logout()" id="toolbar-menu-logout">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

<!--
                <div [hidden]="!desktopServerService.existsDesktopServerConnected()">
                    <hr class="separator"/>
                    <h3 class="accent-A700-fg text-align-center mt-25 mb-5">Desconectar de servidores:</h3>
                    <button mat-menu-item class="min-width-230"
                            *ngFor="let desktopServer of desktopServerService.desktopServerList"
                            (click)="desktopServerService.onUserReset(desktopServerService.getAdUser(desktopServer.adDomainId)?.login, desktopServer.id)"
                            [hidden]="!desktopServerService.desktopServerConnected(desktopServer.id) || !desktopServerService.getAdUser(desktopServer.adDomainId)"
                            title="Desconectar do Servidor {{desktopServer.hostname}}">
                        <mat-icon>exit_to_app</mat-icon>
                        <span class="font-size-12">{{desktopServer.hostname}}</span>
                    </button>
                </div>
                -->
                <hr class="separator"/>
                <button mat-menu-item >
                    <mat-icon>copyright</mat-icon>
                    <span>v {{clientVersion}}</span>
                </button>
            </mat-menu>
            </div>

            <button *ngIf="false" mat-button fxHide fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                </div>
            </button>

            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu>

            <div *ngIf="false" class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button *ngIf="false" mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
