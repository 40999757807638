<div class="dialog-content-wrapper">
    <form [formGroup]="myForm" (submit)="myForm.valid && myForm.dirty && onSubmit()">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de Servidor Linux</span>
            </mat-toolbar-row>
        </mat-toolbar>

        <div class="mat-white-bg " fxLayout="column"
             fxLayoutAlign="space-between stretch" matDialogContent>
            <mat-form-field appearance="outline">
                <mat-label>Hostname</mat-label>
                <input matInput formControlName="hostname">
                <mat-error>
                    {{myForm.get('hostname').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>ID do Client (na instalação do client)</mat-label>
                <input matInput formControlName="clientId">
                <mat-error>
                    {{myForm.get('clientId').errors | error}}
                </mat-error>
            </mat-form-field>
            <div class="client-server-url mb-30">
                instale o client no servidor Linux:<br>
                <a href="{{clientDownloadService.getLinuxServerClientUrl() | async}}" target="_blank">{{clientDownloadService.getLinuxServerClientUrl()
                    | async}}</a>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>IP Externo</mat-label>
                <input matInput formControlName="publicIp">
                <mat-error>
                    {{myForm.get('publicIp').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>IP Interno</mat-label>
                <input matInput formControlName="privateIp">
                <mat-error>
                    {{myForm.get('privateIp').errors | error}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>FQDN (dynDNS do server)</mat-label>
                <input matInput formControlName="connectionFQDN">
                <mat-error>
                    {{myForm.get('connectionFQDN').errors | error}}
                </mat-error>
            </mat-form-field>


            <div class="mb-40 mt-10 pl-10">
                <div class="title-list-fqdn">Lista de IP ou FQDN de possíveis saída do servidor</div>
                <table>
                    <tr>
                        <td *ngIf="firewallFQDNList.length > 0">
                            <div *ngFor="let firewallFQDN of firewallFQDNList" class="p-5 font-size-16">
                                {{firewallFQDN.fqdn}}
                            </div>
                        </td>
                        <td class="width-100-percent pl-10">
                            <button type="button" mat-raised-button
                                    (click)="openFirewallFQDNList()">
                                <span *ngIf="firewallFQDNList.length > 0">Editar Lista</span>
                                <span *ngIf="firewallFQDNList.length == 0">Inserir IP ou FQDN</span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>


            <mat-form-field appearance="outline">
                <mat-label>SSH Port (deixar em branco para a padrão 22)</mat-label>
                <input matInput formControlName="connectionPort">
                <mat-error>
                    {{myForm.get('connectionPort').errors | error}}
                </mat-error>
            </mat-form-field>

            <!--<mat-form-field *ngIf="instancesWithoutReference && instancesWithoutReference.length > 1">-->
            <!--<mat-select placeholder="Instância relacionada" formControlName="instanceId">-->
            <!--<mat-option *ngFor="let instance of instancesWithoutReference" [value]="instance.id">-->
            <!--{{instance.id}} | {{instance.name}} | {{instance.googleZone}}-->
            <!--</mat-option>-->
            <!--<mat-option [value]="">-->
            <!--&#45;&#45; sem instância &#45;&#45;-->
            <!--</mat-option>-->
            <!--</mat-select>-->
            <!--<mat-error>-->
            <!--{{myForm.get('instanceId').errors | error}}-->
            <!--</mat-error>-->
            <!--</mat-form-field>-->


            <div fxLayout="row" fxLayoutAlign="space-between" fxFill class="p-16">

                <!--<mat-slide-toggle *ngIf="project.hasSubProjects && !linuxServer?.id" [formControl]="copyInAllSharedSubProjectsFilterControl">-->
                    <!--Copiar em todos os compartilhados-->
                <!--</mat-slide-toggle>-->


                <mat-slide-toggle formControlName="active" name="active">
                    Ativo
                </mat-slide-toggle>
            </div>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent" [disabled]="!myForm.valid || myForm.pristine"
                    aria-label="Salvar">
                <span *ngIf="linuxServer;else newLinuxServer">Salvar</span>
                <ng-template #newLinuxServer>Criar</ng-template>
            </button>
            <button *ngIf="linuxServer" type="button" mat-raised-button class="mat-green-600-bg"
                    (click)="onGenerateNewPrivateKey()">Nova Chave
            </button>
            <button *appIfPermission="'AUDIT'; type: 'LinuxServer'; data: linuxServer" type="button" mat-raised-button (click)="onJaversHistory()">Histórico</button>
            <button *appIfPermission="'DELETE'; type: 'LinuxServer'; data: linuxServer" type="button" mat-raised-button color="warn" (click)="onDelete()">Deletar</button>

            <button type="button" mat-raised-button color="primary" (click)="onCancel()">Cancelar</button>

        </div>
    </form>
</div>