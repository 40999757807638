import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseDao} from '../../../../base-dao';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Router, RouterType} from '../../../../models';
import {shareReplay} from 'rxjs/operators';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class DataCenterRouterDAOService extends BaseDao<Router> {
  private routerTypes$: Observable<RouterType[]>;

  constructor(http: R2CloudHttpApiService, validatorDao: KluhManagerValidator){
    super(http, validatorDao, 'router');
  }

  getRouterTypes(): Observable<RouterType[]> {
    if (this.routerTypes$ == null){
      this.routerTypes$ = this.http.get<RouterType[]>(this.url + '/types').pipe(shareReplay(1));
    }
    return this.routerTypes$;
  }
}
