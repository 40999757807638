import {EventProvider} from './event-provider';
import {SessionProviderEvent} from './enum/session-provider-event';
import {SessionProviderDelegate} from './session-provider-delegate';
import {SessionType} from './enum/session-type';
import {SessionEvent} from './enum/session-event';
import {SessionFactoryEvent} from './enum/session-factory-event';
import {SessionState} from './enum/session-state';
import {ConnectionState} from './enum/connection-state';
import {ConnectionEvent} from './enum/connection-event';
import {SessionFactory} from './session-factory';
import {SessionStore} from './session-store';
import {Session} from './session';
import {ConnectionFactory} from './connection-factory';
import {Bookmark} from './bookmark';
import {Logger} from './logger';
import {RdpConnection} from './rdp-connection';

export class SessionProvider {
    events: any;
    delegate: SessionProviderDelegate;
    activeSession: any;
    private i: any = {};
    reconnecting = !1;

    constructor(private sessionFactory: SessionFactory,
                private b: ConnectionFactory,
                private sessionStore: SessionStore,
                private d: any,
                private e: any,
                private f: Logger) {
        this.events = new EventProvider();
        this.delegate = new SessionProviderDelegate();
        sessionFactory.events.subscribe(SessionFactoryEvent.SessionCreated, this.onSessionCreated),
            sessionFactory.events.subscribe(SessionFactoryEvent.SessionDestroyed, this.onSessionDestroyed),
            sessionFactory.events.subscribe(SessionFactoryEvent.SessionFocused, this.onSessionFocused),
            sessionFactory.events.subscribe(SessionFactoryEvent.ConnectionCreated, this.onConnectionCreated),
            sessionFactory.events.subscribe(SessionFactoryEvent.ConnectionCreateFailed, this.onConnectionCreateFailed),
            sessionFactory.events.subscribe(SessionFactoryEvent.ConnectionDestroyed, this.onConnectionDestroyed),
            sessionFactory.events.subscribe(SessionFactoryEvent.RedirectionsRequest, this.onRedirectionsRequest);
    }

    private g = (a: Session): any => {
        return this.sessionStore.save(a),
            a.delegate.canvasNeeded = this.canvasNedded,
            a.delegate.retireCanvas = this.retireCanvas,
            a.delegate.monitorBounds = this.monitorBounds,
            a.events.subscribe(SessionEvent.DidDisconnect, this.onDidDisconnect),
            a.events.subscribe(SessionEvent.ApplicationIdChanged, this.onApplicationIdChanged),
            this.events.fireEventCallback(SessionProviderEvent.SessionCreated, a.id), a.id;
    };

    private onWindowOrderChanged = (a: any): void => {
        let b, c = 0;
        this.i = {};
        for (b in a) {
            if (a.hasOwnProperty(b)) {
                this.i[a[b]] = c++;
            }
        }
        console.debug('(SessionProvider) called j with arg');
        console.debug(a);
        console.debug(this.i);
        this.events.fireEventCallback(SessionProviderEvent.SessionOrderChanged);
    };

    private canvasNedded = (a: any): any => {
        return this.delegate.canvasNeeded ? this.delegate.canvasNeeded(a) : null;
    };

    private retireCanvas = (a: any): void => {
        this.delegate.retireCanvas && this.delegate.retireCanvas(a);
    };

    private monitorBounds = (): any => {
        return this.delegate.monitorBounds ? this.delegate.monitorBounds() : null;
    };

    private n = (): void => {
        this.activeSession && this.activeSession.setFocus(!1), this.activeSession = null;
    };

    private o = (): void => {
        let a;
        if (this.activeSession && this.activeSession.sessionType === SessionType.RemoteApp && 0 === this.activeSession.getSiblingCount()) {
            let b;
            for (a = this.sessionStore.list(null), b = 0; b < a.length; b++) {
                if (a[b].sessionType === SessionType.Desktop && a[b].getConnectionId() === this.activeSession.getConnectionId()) {
                    this.focusToSession(a[b].id, null);
                    break;
                }
            }
        } else {
            this.activeSession && this.activeSession.sessionType === SessionType.Desktop && !this.sessionStore.find(this.activeSession.id) &&
            (a = this.sessionStore.list(null), this.n(), a.length > 0 && this.focusToSession(a[0].id, null));
        }
    };

    private p = (): void => {
        let a;
        const b = this.sessionStore.list(null);
        for (a = 0; a < b.length; a++) {
            const d = b[a];
            d.connection.getState() === ConnectionState.Dismissed && (this.sessionStore.remove(d.id), this.events.fireEventCallback(SessionProviderEvent.SessionDestroyed, d.id));
        }
    };

    private onSessionCreated = (a: Session): void => {
        this.f.log('[SessionProvider] Session created received in provider'), this.p();
        let b = a;
        if (b.sessionType === SessionType.Desktop && b.connection.isExistingConnection) {
            const c = [];
            this.sessionList(c);
            for (const d in c) {
                if (c[d].getConnectionId() === b.getConnectionId()) {
                    this.f.log('[SessionProvider] Relaunch of an existing remote desktop connection'), b = c[d];
                    break;
                }
            }
        } else {
            this.g(b);
        }
        this.focusToSession(b.id, null);
    };

    private onDidDisconnect = (): void => {
        this.f.log('[SessionProvider] Session disconnect received in provider'), this.p(), this.o();
    };

    private onApplicationIdChanged = (): void => {
        this.events.fireEventCallback(SessionProviderEvent.SessionGroupingChanged);
    };

    private onWindowsLoaded = (): void => {
        this.events.fireEventCallback(SessionProviderEvent.SessionsLoaded);
    };

    private onWindowIconChanged = (): void => {
        this.events.fireEventCallback(SessionProviderEvent.SessionIconChanged);
    };

    private onConnectionCreated = (a: any, b: any): void => {
        this.f.log('[SessionProvider] Connection created event received.'),
            b.events.subscribe(ConnectionEvent.WindowOrderChanged, this.onWindowOrderChanged),
            b.events.subscribe(ConnectionEvent.WindowsLoaded, this.onWindowsLoaded),
            b.events.subscribe(ConnectionEvent.WindowIconChanged, this.onWindowIconChanged);
    };

    private onConnectionCreateFailed = (a: any): void => {
        this.f.error('[SessionProvider] Connection create failed event received.'), this.f.error(a.message),
            this.events.fireEventCallback(SessionProviderEvent.SessionCreateFailed, a);
    };

    private onConnectionDestroyed = (a: any): void => {
        a.events.unsubscribe(ConnectionEvent.WindowOrderChanged, this.onWindowOrderChanged),
            a.events.unsubscribe(ConnectionEvent.WindowsLoaded, this.onWindowsLoaded),
            a.events.unsubscribe(ConnectionEvent.WindowIconChanged, this.onWindowIconChanged), this.p(), this.o(), this.f.log('[SessionProvider] Connection destroyed event received.');
    };

    private onSessionDestroyed = (a: any): void => {
        this.sessionStore.find(a) && (this.f.log('[SessionProvider] Session destroyed received in provider'), this.sessionStore.remove(a), this.o(),
            this.events.fireEventCallback(SessionProviderEvent.SessionDestroyed, a));
    };

    private onSessionFocused = (a: any): void => {
        const b = this.sessionStore.find(a);
        console.debug('(SessionFocused)');
        console.debug(a);
        b && (this.f.log('[SessionProvider] Session focused received in provider'), this.activeSession = b, this.events.fireEventCallback(SessionProviderEvent.SessionFocused, a));
    };

    private onRedirectionsRequest = (a: any): void => {
        this.events.fireEventCallback(SessionProviderEvent.RedirectionsRequest, a);
    };

    sessionList(a: any): Session[] {
        return this.sessionStore.list(a);
    }

    connectionList(): RdpConnection[] {
        return this.b.listActiveConnections();
    }

    getConnection(a: any): any {
        return this.b.findConnectionById(a);
    }

    getReconnecting(): boolean {
        return this.reconnecting;
    }

    setReconnecting(a: boolean): void {
        this.reconnecting = a;
    }

    desktopSessionList(aa: any): any {
        let b, a = aa;
        const d = this.sessionStore.list(null);
        for (void 0 === a || null === a ? a = [] : a.splice(0), b = 0; b < d.length; b++) {
            d[b].sessionType === SessionType.Desktop && 0 === d[b].getSiblingCount() && a.push(d[b]);
        }
        return a;
    }

    sessionListGrouped(aa: any): any {
        let b, d, e, f, g, a = aa;
        const h = this.sessionStore.list(null);
        if (void 0 === a || null === a) {
            a = {};
        } else {
            for (b in a) {
                if (a.hasOwnProperty(b)) {
                    delete a[b];
                }
            }
            // tslint:disable-next-line:forin
            for (b in h) {
                h.hasOwnProperty(b) && h[b].sessionType === SessionType.RemoteApp && (d = h[b], e = d.getApplicationId(), g = a[e],
                void 0 === g && (g = {}, g.thumbnail = d.getThumbnail(), a[e] = g), f = g.sessionList, void 0 === f && (f = [], g.sessionList = f),
                    f.length > 0 && this.i[d.windowId] < this.i[f[0].windowId] ? f.unshift(d) : f.push(d));
            }
        }
        return a;
    }

    disconnect(): void {
        this.sessionStore.removeAll(), this.b.disconnectAllConnections();
    }

    createSession(b: Bookmark, c: boolean, remoteAppId: number): void {
        this.sessionFactory.createSession(b, c, remoteAppId);
    }

    find(a: any): any {
        return this.sessionStore.find(a);
    }

    focusToSession(a: string, b: boolean): void {
        const d: Session = this.sessionStore.find(a);
        d && d !== this.activeSession && (this.activeSession && this.activeSession.getConnectionId() !== d.getConnectionId() && this.n(), d.setFocus(!0, b), this.activeSession = d,
            this.events.fireEventCallback(SessionProviderEvent.SessionFocused, d.id));
    }

    dismissSession(a: any): void {
        const d = this.sessionStore.find(a);
        d && (d.state === SessionState.Disconnected ? (this.b.removeConnection(d.connection), this.p(), this.o()) : d.disconnect(null));
    }

    invalidateCanvasForActiveConnections(): void {
        this.b.invalidateCanvasForActiveConnections();
    }

    hasActiveConnections(): any {
        return this.b.hasActiveConnections();
    }

    windowsLoaded(): any {
        return this.b.windowsLoaded();
    }

}
