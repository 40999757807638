import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DataCenterDAOService} from '../../../data-center/data-center-dao';
import {fuseAnimations} from '../../../../../../@fuse/animations';

import {DataCenterCreateComponent} from '../../../data-center/data-center-create/data-center-create.component';
import {CloudConfigDaoService} from '../cloud-config/cloud-config-dao.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {InstanceDaoService} from '../instance/instance-dao.service';
import {DataCenter, DataCenterType, Project, VpnConfig} from '../../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-data-center-list',
  templateUrl: './data-center-list.component.html',
  styleUrls: ['./data-center-list.component.scss'],
  animations: fuseAnimations
})
export class DataCenterListComponent extends ComponentCleaner {
  @Input()
  vpnConfig: VpnConfig;
  dataCenters: DataCenter[];
  project: Project;
  projects: Project[];
  dataCenterTypes: DataCenterType[];
  @Output() editingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private dataCenterDAO: DataCenterDAOService,
    private cloudConfigDao: CloudConfigDaoService,
    private instanceDao: InstanceDaoService,
    private dialog: MatDialog
  ) {
    super();

  }

  @Input()
  set modelProject(project: Project) {
    this.project = project;
    this.dataCenterDAO.getActive({projectId: project.id}).subscribe((dataCenters) => {
      this.dataCenters = dataCenters;
      if (!this.dataCenters) {
        this.dataCenters = [];
      }
      this.dataCenterDAO.getDataCenterTypes().subscribe((dataCenterTypes) => {
        this.dataCenterTypes = dataCenterTypes;
        if (!this.dataCenters || (this.dataCenters && this.dataCenters.length < 1)) {
          this.onCreate(false);
        }
      });
    });
  }

  @Input()
  set modelProjects(projects: Project[]) {
    this.projects = projects;
  }

  public onCreate(clearName: boolean): void {
    const dialogRef = this.dialog.open(DataCenterCreateComponent, {
      disableClose: true,
      panelClass: 'generic-edit-dialog',
      data: {
        dataCenterTypes: this.dataCenterTypes,
        projects: this.projects,
        project: this.project,
        clearName: clearName
      }
    });
    dialogRef.afterClosed().subscribe((dataCenter: DataCenter) => {
      if (dataCenter) {
        this.dataCenters.push(dataCenter);
      }
    });
  }

}
