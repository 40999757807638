import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {WanTechnology} from '../../../../models';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class DataCenterRouterWanTechnologyDaoService extends BaseDao<WanTechnology> {

  constructor(http: R2CloudHttpApiService, validatorDao: KluhManagerValidator) {
    super(http, validatorDao, 'wan-technology');
  }

}
