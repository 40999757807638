import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {KluhSharedModule} from '../../kluh-shared.module';
import {AuthService} from '../../auth.service';
import {DotNetServerPluginComponent} from './server-plugin.component';
import {MatCardModule} from '@angular/material/card';
import {DotNetServerPluginTemplateCardComponent} from './server-plugin-template-card/server-plugin-template-card.component';
import {DotNetServerPluginCardComponent} from './server-plugin-card/server-plugin-card.component';
import {DotNetServerPluginFileCardComponent} from './server-plugin-file-card/server-plugin-file-card.component';
import {DotNetServerPluginDaoService} from './services/server-plugin-dao.service';
import {DotNetServerPluginTemplateDaoService} from './services/server-plugin-template-dao.service';
import {DotNetServerPluginFileDaoService} from './services/server-plugin-file-dao.service';
import {DotNetServerPluginService} from './services/server-plugin.service';
import {DotNetServerPluginCreateEditComponent} from './server-plugin-create-edit/server-plugin-create-edit.component';
import {DotNetServerPluginFileCreateEditComponent} from './server-plugin-file-create-edit/server-plugin-file-create-edit.component';
import {DotNetServerPluginTemplateCreateEditComponent} from './server-plugin-template-create-edit/server-plugin-template-create-edit.component';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NgxMaskModule} from 'ngx-mask';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {DotNetServerAgentFileCardComponent} from './server-agent-file-card/server-agent-file-card.component';
import {DotNetServerAgentUpdaterFileCardComponent} from './server-agent-updater-file-card/server-agent-updater-file-card.component';
import {DotNetServerAgentUpdaterFileDaoService} from './services/server-agent-updater-file-dao.service';
import {DotNetServerAgentFileDaoService} from './services/server-agent-file-dao.service';
import {DotNetServerPluginDependencyFileCreateEditComponent} from './server-plugin-dependency-file-create-edit/server-plugin-dependency-file-create-edit.component';
import {DotNetServerPluginDependencyFileCardComponent} from './server-plugin-dependency-file-card/server-plugin-dependency-file-card.component';
import {MatTabsModule} from '@angular/material/tabs';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

const routes = [
    {
        path: 'server-plugin',
        component: DotNetServerPluginComponent,
        canActivate: [AuthService],
        data: {permissions: ['DotNetServerPlugin.WRITE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        KluhSharedModule,
        MatCardModule,
        MatButtonModule,
        MatToolbarModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgxMaskModule,
        MatChipsModule,
        MatIconModule,
        MatTabsModule,
        FontAwesomeModule
    ],
    providers: [
        DotNetServerPluginService,
        DotNetServerPluginDaoService,
        DotNetServerPluginTemplateDaoService,
        DotNetServerPluginFileDaoService,
        DotNetServerAgentUpdaterFileDaoService,
        DotNetServerAgentFileDaoService
    ],
    entryComponents: [
        DotNetServerPluginComponent,
        DotNetServerPluginCreateEditComponent,
        DotNetServerPluginFileCreateEditComponent,
        DotNetServerPluginTemplateCreateEditComponent,
        DotNetServerPluginDependencyFileCreateEditComponent
    ],
    declarations: [
        DotNetServerPluginComponent,
        DotNetServerPluginTemplateCardComponent,
        DotNetServerPluginCardComponent,
        DotNetServerPluginFileCardComponent,
        DotNetServerPluginCreateEditComponent,
        DotNetServerPluginFileCreateEditComponent,
        DotNetServerPluginTemplateCreateEditComponent,
        DotNetServerAgentFileCardComponent,
        DotNetServerAgentUpdaterFileCardComponent,
        DotNetServerPluginDependencyFileCardComponent,
        DotNetServerPluginDependencyFileCreateEditComponent
    ]
})
export class DotNetServerPluginModule {
}
