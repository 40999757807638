import {Component, Inject, OnInit} from '@angular/core';
import {InstanceScheduleDaoService} from './instance-schedule-dao.service';

import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {convertToFormGroup, formatFormControlWithLeadingZero, markAsTouched} from '../../../../../helpers/kluh';
import {InstanceDaoService} from '../instance-dao.service';
import {forkJoin, Observable} from 'rxjs';
import {ComponentCleaner} from '../../../../../component-cleaner';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {Instance, InstanceSchedule} from '../../../../../models';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-instance-schedule',
    templateUrl: './instance-schedule.component.html',
    styleUrls: ['./instance-schedule.component.scss']
})
export class InstanceScheduleComponent extends ComponentCleaner implements OnInit {
    private readonly instanceScheduleList: InstanceSchedule[];
    public readonly instance: Instance;
    public myForm: FormGroup;
    startHoursAll: FormControl = new FormControl('');
    startMinutesAll: FormControl = new FormControl('');
    stopHoursAll: FormControl = new FormControl('');
    stopMinutesAll: FormControl = new FormControl('');


    constructor(public dialogRef: MatDialogRef<InstanceScheduleComponent>,
                private fb: FormBuilder,
                private instanceScheduleDao: InstanceScheduleDaoService,
                private instanceDao: InstanceDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.instanceScheduleList = data.instanceScheduleList;
        this.instance = data.instance;
        this.myForm = this.fb.group({
            instanceForm: this.fb.group(convertToFormGroup(this.instance), {asyncValidator: this.instanceDao.validator}),
            list: this.fb.array([])
        });
        if (!this.instanceScheduleList || this.instanceScheduleList.length === 0) {
            this.instanceScheduleList = [];
            for (let i = 1; i < 8; i++) {
                const instanceSchedule: InstanceSchedule = {
                    active: true,
                    comment: null,
                    id: null,
                    dayOfWeek: i,
                    instanceId: this.instance.id,
                    startHours: 6,
                    startMinutes: 0,
                    stopHours: 22,
                    stopMinutes: 0,
                    modified: null,
                    optlock: null
                };
                this.instanceScheduleList.push(instanceSchedule);
            }
        }
        /*Deixa o domingo por último*/
        this.instanceScheduleList.sort((a, b) => {
            if (a.dayOfWeek === 1) {
                return 8 - b.dayOfWeek;
            }
            return a.dayOfWeek - b.dayOfWeek;
        });

        const list: FormArray = this.myForm.get('list') as FormArray;
        this.instanceScheduleList.forEach((instanceSchedule) => {
            const formGroup = this.fb.group(convertToFormGroup(instanceSchedule), {asyncValidator: this.instanceScheduleDao.validator});
            list.push(formGroup);
        });


        (this.myForm.get('list') as FormArray).controls.forEach((formGroup: FormGroup) => {
            const fields: string[] = ['startHours', 'startMinutes', 'stopHours', 'stopMinutes'];
            fields.forEach((field) => {
                const control = formGroup.controls[field];
                if (field.indexOf('Hours') !== -1) {
                    control.setValidators([Validators.min(0), Validators.max(23)]);
                }
                if (field.indexOf('Minutes') !== -1) {
                    control.setValidators([Validators.min(0), Validators.max(59)]);
                }
                formatFormControlWithLeadingZero(control.value, control);
                const subscription = control.valueChanges.pipe(
                    debounceTime(500),
                    distinctUntilChanged(),
                    filter(x => !isNaN(x))
                ).subscribe((val) => {
                    formatFormControlWithLeadingZero(val, control);
                });
                this.addSubscription(subscription);
            });
        });


    }


    onSubmit(): void {
        if (this.myForm.valid) {
            const entityList: InstanceSchedule[] = (this.myForm.get('list') as FormArray).value;
            const newEntities: InstanceSchedule[] = [];
            const existingEntities: InstanceSchedule[] = [];
            entityList.forEach((instanceSchedule) => {
                if (instanceSchedule.id) {
                    existingEntities.push(instanceSchedule);
                } else {
                    newEntities.push(instanceSchedule);
                }
            });
            const observables: Observable<any>[] = [];
            if (newEntities.length > 0) {
                observables.push(this.instanceScheduleDao.createAll(newEntities));
            }
            if (existingEntities.length > 0) {
                observables.push(this.instanceScheduleDao.saveAll(existingEntities));
            }
            observables.push(this.instanceDao.save((this.myForm.get('instanceForm') as FormGroup).value));
            forkJoin(observables).subscribe(() => {
                this.dialogRef.close();
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    // noinspection JSMethodCanBeStatic
    getDayOfWeek(dayOfWeek: number): string {
        switch (dayOfWeek) {
            case 1:
                return 'Domingo';
            case 2:
                return 'Segunda';
            case 3:
                return 'Terça';
            case 4:
                return 'Quarta';
            case 5:
                return 'Quinta';
            case 6:
                return 'Sexta';
            case 7:
                return 'Sábado';
        }
        throw new Error('Invalid Day of Week');
    }


    // startHours: 6,
    // startMinutes: 0,
    // stopHours: 22,
    // stopMinutes: 0
    ngOnInit(): void {

        setTimeout(() => {
            markAsTouched(this.myForm);
        }, 1000);

    }

    insertInAllFields(hours: number, minutes: number, startOrStop: string): void {
        (this.myForm.get('list') as FormArray).controls.forEach((formGroup: FormGroup) => {
            const fields: string[] = ['startHours', 'startMinutes', 'stopHours', 'stopMinutes'];
            fields.forEach((field) => {
                const control = formGroup.controls[field];
                if (field.toLowerCase().indexOf(startOrStop.toLowerCase()) !== -1) {
                    if (field.indexOf('Hours') !== -1) {
                        control.setValue(hours);
                    }
                    if (field.indexOf('Minutes') !== -1) {
                        control.setValue(minutes);
                    }
                }
            });
        });
    }

    onInsertInAllStop(hours: number, minutes: number): void {
        this.insertInAllFields(hours, minutes, 'stop');
    }
    onInsertInAllStart(hours: number, minutes: number): void {
        this.insertInAllFields(hours, minutes, 'start');
    }
}
