import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BackgroundImageService} from '../helpers/background-image.service';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit, AfterViewInit {

  backgroundUrl: string;

  @Input()
  type: string;

  @Input()
  imageUrls: number[];

  private imgLoaded = false;
  private imageClass: string;
  private originalImageClass: string;

  ngAfterViewInit(): void {
    this.backgroundImage.getRandomBackgroundUrl(this.imageUrls).subscribe((backgroundUrl) => {
      setTimeout(() => {
        this.backgroundUrl = backgroundUrl;
        this.originalImageClass = 'background-image background-image-' + this.type;
        this.imageClass = this.originalImageClass;
      });
    });
  }

  constructor(private backgroundImage: BackgroundImageService) {
  }

  ngOnInit(): void {
  }

  setTransition(): void {
    this.imageClass = this.originalImageClass + ' background-image-loaded';
  }
}
