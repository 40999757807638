<div id="login" [class]="loginTransition" *fuseIfOnDom fxLayout="row" fxLayoutAlign="start">
    <app-background type="full" *ngIf="!loadingCustomLayout || displayOriginalPortal"
                    [imageUrls]="customerGroupTemplate?.backgroundImageTemplate?.imageFileTemplateIds"></app-background>
    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div [innerHTML]="customerGroupTemplate?.html | safeHtml: 'html'"
             *ngIf="customerGroupTemplate?.html && !displayOriginalPortal">
        </div>

        <div *ngIf="(!loadingCustomLayout && !customerGroupTemplate?.html) || displayOriginalPortal">
          <!--  <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
                <img src="assets/images/logos/r2/large/r2-cloud-logo-large-google.png" class=" shadowed r2-logo-alone">
            </div>
            <div class="title shadowed" *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
                <span *ngIf="(!loadingCustomLayout && !customerGroupTemplate?.html) || displayOriginalPortal">
            A nuvem privada perfeita para sua empresa.<br><br>
            Segurança e alta performance na mesma rede dos <br>
            aplicativos da <b class="g-blue">G</b><b class="g-red">o</b><b class="g-yellow">o</b><b class="g-blue">g</b><b
                    class="g-green">l</b><b class="g-red">e</b>.
         </span>

            </div>-->
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">

            <div class="logo mb-60" style="margin-top: 0px"
                 *ngIf="!loadingCustomLayout && customerGroupTemplate?.customPortalTemplate?.creatorCustomerGroupId && customerGroupImageUUID">
                <img src="{{imageFileService.getCustomerGroupPictureUrl(customerGroupImageUUID, 128)}}"
                     width="128">
            </div>
            <div class="logo"
                 *ngIf="!(!loadingCustomLayout && customerGroupTemplate?.customPortalTemplate?.creatorCustomerGroupId && customerGroupImageUUID)">
                <img width="128" src="assets/images/logos/icone-simples.png">
            </div>

            <div class="login-box" [hidden]="showTokenForm" >
                <div class="title">LOGIN EM SUA CONTA {{uri}}</div>
                <form name="loginForm" [formGroup]="loginForm" novalidate
                      id="login-page-form"
                      (submit)="checkTwoFactor()">

                    <mat-form-field>
                        <input matInput placeholder="Email" formControlName="email" autocomplete="username"
                               id="login-page-username">
                        <mat-error *ngIf="loginFormErrors.email.required">
                            Preencha o e-mail
                        </mat-error>
                        <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                            E-mail inválido
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput type="password" placeholder="Senha" formControlName="password"
                               autocomplete="current-password" id="login-page-password">
                        <mat-error *ngIf="loginFormErrors.password.required">
                            Preencha a senha
                        </mat-error>
                        <div class="error-caps" *ngIf="capsLockActive">
                            CAPS LOCK ESTÁ ATIVO!
                        </div>
                    </mat-form-field>

                    <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                         fxLayoutAlign="space-between center">
                        <mat-checkbox class="remember-me" aria-label="Remember Me" formControlName="rememberMe"
                                      id="login-page-rememberMe">
                            Lembrar
                        </mat-checkbox>

                        <a class="forgot-password" (click)="onForgotPassword()" [routerLink]=""
                           id="login-page-forgot-password">
                            Esqueci a senha
                        </a>
                    </div>

                    <button type="submit" mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
                            id="login-page-login-submit"
                            [disabled]="!loginForm.valid || loginForm.pristine">
                        LOGIN
                    </button>
                    <mat-error *ngIf="credentialsError">
                        {{credentialsErrorMessage}}
                    </mat-error>
                </form>
                <div class="separator">
                    <span class="text">OU</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="start center">

                    <button type="button" mat-raised-button class="google" (click)="onLoginWithGoogle()"
                            id="login-page-login-google">
                        Login com Google
                    </button>

                    <!--<button mat-raised-button class="facebook">-->
                    <!--Login com Facebook-->
                    <!--</button>-->

                </div>

                <!--<div class="register" fxLayout="column" fxLayoutAlign="center center">-->
                <!--<span class="text">Don't have an account?</span>-->
                <!--<a class="link" [routerLink]="'/pages/auth/register-2'">Create an account</a>-->
                <!--</div>-->
            </div>

            <div class="two-factor-box" [hidden]="!showTokenForm" >
                <div class="title">Digite o token enviado<br>por e-mail</div>

                <form name="twoFactor" novalidate (submit)="!sendingToken && checkLoginToken()">
                    <mat-form-field>
                        <input matInput placeholder="token" mask="00000" [formControl]="tokenControl">
                    </mat-form-field>
                    <h3 style="color: #990000;" *ngIf="tokenErrorMsg">{{tokenErrorMsg}}</h3>
                    <button type="submit" mat-raised-button color="accent"
                            [disabled] = "sendingToken"
                            class="submit-button" aria-label="ENVIAR">
                        ENVIAR
                    </button>
                </form>
            </div>

        </div>


    </div>
</div>
