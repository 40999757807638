import {Component, Inject, OnInit} from '@angular/core';
import {ManagerUser, RoleIdCustomerGroupIdManagerUserId} from '../../../../models';
import {ManagerUserCreateComponent} from '../../../manager-user/manager-user-create/manager-user-create.component';
import {CrudOperationWrapper} from '../../../../helpers/kluh';
import {R2CloudAdminService} from '../../../r2-cloud-admin/r2-cloud-admin.service';
import {ComponentCleaner} from '../../../../component-cleaner';
import {
    RoleCustomerGroupManagerUserEditComponent
} from '../../../iam/role-customer-group-manager-user/role-customer-group-manager-user-edit/role-customer-group-manager-user-edit.component';
import {RoleCustomerGroupManagerUserDAOService} from '../../../iam/role-customer-group-manager-user-dao.service';
import {CustomerGroupService} from '../../../customer-group/customer-group.service';
import {CustomerDaoService} from '../../../customer/customer-dao.service';
import {CustomerGroupDaoService} from '../../../customer-group/customer-group-dao.service';
import {ImageFileService} from '../../../image-file/image-file-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ManagerUserChangePasswordComponent} from '../manager-user-change-password/manager-user-change-password.component';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {PermissionWrapper} from '../../../../directives/if-permission.directive';
import {UserAuthorityDaoService} from '../../../user-authority/user-authority-dao.service';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ManagerUserPermissionsDaoService} from '../../../manager-user/manager-user-permissions/manager-user-permissions-dao.service';

@Component({
    selector: 'app-role-customer-group-or-manager-user-edit',
    templateUrl: './role-customer-group-or-manager-user-edit.component.html',
    styleUrls: ['./role-customer-group-or-manager-user-edit.component.scss']
})
export class RoleCustomerGroupOrManagerUserEditComponent extends ComponentCleaner implements OnInit {

    managerUser: ManagerUser;
    loggedManagerUser: ManagerUser;
    roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId;
    appCanCreateList: PermissionWrapper[] = [];
    appCanEditRoleList: PermissionWrapper[] = [];
    isAdmin = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RoleCustomerGroupOrManagerUserEditComponent>,
        public adminService: R2CloudAdminService,
        private customerGroupService: CustomerGroupService,
        private customerDaoService: CustomerDaoService,
        private customerGroupDaoService: CustomerGroupDaoService,
        private managerUserDaoService: ManagerUserDaoService,
        private roleCustomerGroupManagerUserDao: RoleCustomerGroupManagerUserDAOService,
        private userAuthorityDaoService: UserAuthorityDaoService,
        private managerUserPermissionsDaoService: ManagerUserPermissionsDaoService,
        public imageFileService: ImageFileService,
        private dialog: MatDialog) {
        super();

        this.appCanCreateList = [
            {type: 'ManagerUser', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup}
        ];

        this.appCanEditRoleList = [
            {type: 'ManagerUserPermissions', permission: 'READ', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup}
        ];
    }

    ngOnInit(): void {
        this.userAuthorityDaoService.getMe().subscribe(loggedManagerUser => {
            if (loggedManagerUser) {
                this.loggedManagerUser = loggedManagerUser.managerUser;
                this.managerUser = this.data.managerUser;
                combineLatest([
                    this.managerUserPermissionsDaoService.isAdminUser(this.loggedManagerUser.id),
                    this.roleCustomerGroupManagerUserDao.findAllByParentIdAndMemberId(this.customerGroupService.customerGroup.id, this.managerUser.id)
                ]).subscribe(values => {
                    this.isAdmin = values[0];
                    const roleIdCustomerGroupIdManagerUserIdList = values[1];
                    if (roleIdCustomerGroupIdManagerUserIdList) {
                        this.roleIdCustomerGroupIdManagerUserId = roleIdCustomerGroupIdManagerUserIdList[0];
                    } else {
                        this.roleIdCustomerGroupIdManagerUserId.customerGroupId = this.customerGroupService.customerGroup.id;
                        this.roleIdCustomerGroupIdManagerUserId.managerUserId = this.managerUser.id;
                    }
                });
            }
        });
    }


    onEditRoleCustomerGroupManagerUser(managerUser: ManagerUser): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                roleIdCustomerGroupIdManagerUserId: this.roleIdCustomerGroupIdManagerUserId,
                managerUser: this.managerUser
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((operationWrapper: CrudOperationWrapper) => {
            if (operationWrapper && operationWrapper.operation === 'DELETE') {
                if (this.getAllRoleCustomerGroupManagerUsers(managerUser.id).length < 1) {
                    this.adminService.removeManagerUser(managerUser.id);
                }
            }
            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    onEditRoleCustomerGroupManagerUserWithRole(managerUser: ManagerUser, roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                roleIdCustomerGroupIdManagerUserId: roleIdCustomerGroupIdManagerUserId,
                managerUser: this.managerUser
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((operationWrapper: CrudOperationWrapper) => {
            if (operationWrapper && operationWrapper.operation === 'DELETE') {
                if (this.getAllRoleCustomerGroupManagerUsers(managerUser.id).length < 1 && this.adminService.isExternalManagerUser(managerUser)) {
                    this.adminService.removeManagerUser(managerUser.id);
                }
            }
            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    getAllRoleCustomerGroupManagerUsers(managerUserId: number): RoleIdCustomerGroupIdManagerUserId[] {
        const roleIdCustomerGroupIdManagerUserId: RoleIdCustomerGroupIdManagerUserId[] = [];
        for (const roleCustomerGroup of this.adminService.roleCustomerGroupManagerUsers) {
            if (managerUserId === roleCustomerGroup.managerUserId) {
                roleIdCustomerGroupIdManagerUserId.push(roleCustomerGroup);
            }
        }
        return roleIdCustomerGroupIdManagerUserId;
    }


    onAddRoleToTheManagerUser(managerUser: ManagerUser): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                managerUser: managerUser
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((operationWrapper: CrudOperationWrapper) => {
            if (operationWrapper && operationWrapper.operation === 'CREATE') {
                this.adminService.addOrReplaceManagerUser(managerUser);
                this.customerDaoService.getOne(managerUser.customerId).subscribe(externalCustomerFromDao => {
                    if (externalCustomerFromDao) {
                        this.adminService.addOrReplaceExternalCustomer(externalCustomerFromDao);
                        this.customerGroupDaoService.getOne(externalCustomerFromDao.customerGroupId).subscribe(externalCustomerGroupFromDao => {
                            if (externalCustomerGroupFromDao) {
                                this.adminService.addOrReplaceExternalCustomerGroup(externalCustomerGroupFromDao);
                            }
                        });
                    }
                });
            }

            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    onEditManagerUser(managerUser: ManagerUser): void {
        const dialogRef = this.dialog.open(ManagerUserCreateComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {managerUser: managerUser}
        });
        const subscription = dialogRef.afterClosed().subscribe((managerUserOperation: CrudOperationWrapper) => {
            if (managerUserOperation.operation === 'SAVE') {
                this.adminService.replaceManagerUser(managerUserOperation.data);
            } else if (managerUserOperation.operation === 'DELETE') {
                this.adminService.removeManagerUser(managerUserOperation.data.id);
            }
            this.dialogRef.close(managerUserOperation);
        });
        this.addSubscription(subscription);
    }

    onCancel(): void {
        this.dialogRef.close();
    }


    onChangePassword(managerUser: ManagerUser): void {
        const subscription = this.dialog.open(ManagerUserChangePasswordComponent, {
            disableClose: true
        }).afterClosed().subscribe((managerUserOperation: CrudOperationWrapper) => {
            if (managerUserOperation && managerUserOperation.operation === 'SAVE') {
                this.managerUserDaoService.changePassword(
                    managerUserOperation.data.password,
                    managerUserOperation.data.forceChangePasswordOnFirstLogin,
                    managerUser.id).subscribe(changed => {
                    if (changed) {
                        this.dialogRef.close(managerUserOperation);
                    }
                });
            } else {
                this.dialogRef.close();
            }
        });
        this.addSubscription(subscription);
    }
}
