import {Injectable} from '@angular/core';
import {InstanceScheduleAudit} from '../../../../../models';
import {Observable} from 'rxjs';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';


@Injectable({
    providedIn: 'root'
})
export class InstanceScheduleAuditDaoService  {
    private url = 'instance-schedule-audit';

    constructor(protected http: R2CloudHttpApiService) {

    }

    getStatus(): Observable<InstanceScheduleAudit[]> {
        return this.http.get<InstanceScheduleAudit[]>(this.url + '/status/');
    }
}
