import {BaseDao} from '../../base-dao';
import {Injectable, OnDestroy} from '@angular/core';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {Observable, Subject} from 'rxjs';
import {DataCenter, DataCenterType} from '../../models';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {CacheControl} from '../../helpers/CacheControl';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';

@Injectable()
export class DataCenterDAOService extends BaseDao<DataCenter> implements OnDestroy, CacheControl {
    private onDestroy$ = new Subject();
    dataCenterTypes$: Observable<DataCenterType[]>;

    constructor(protected http: R2CloudHttpApiService, protected validatorDao: KluhManagerValidator) {
        super(http, validatorDao, 'data-center');
    }



    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    setCacheControl(ob: Observable<any>): void {
        ob.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.clearCache();
        });
    }

    clearCache(): void {
        this.dataCenterTypes$ = null;
    }

    getDataCenterTypes(): Observable<DataCenterType[]> {
        if (this.dataCenterTypes$ == null) {
            this.dataCenterTypes$ = this.http.get<DataCenterType[]>(this.url + '/types').pipe(shareReplay(1));
        }
        return this.dataCenterTypes$;
    }




}
