import {timeZones} from './enum/time-zones';
import {Bookmark} from './bookmark';

declare const Module;

export class ConnectionProperties {
    bookmark: Bookmark;
    hostName: any;
    port: any;
    gatewayHostName: any;
    gatewayPort: any;
    remoteApplicationMode: any;
    remoteApplicationProgram: any;
    remoteApplicationCmdLine: any;
    connectionSettings: any;
    tenantId: any;
    properties: any;
    EventLogUploadAddress: any;
    GatewayCertLogonAuthority: any;

    constructor(a: Bookmark) {
        this.bookmark = a;
        this.hostName = null;
        this.port = 3389;
        this.gatewayHostName = null;
        this.gatewayPort = 443;
        this.remoteApplicationMode = !1;
        this.remoteApplicationProgram = '';
        this.remoteApplicationCmdLine = '';
        this.connectionSettings = null;
        this.tenantId = null;
        this.properties = null;
        this.tenantId = a && a.tenantId || null;
        this.applyProperties(a && a.rdpFileString || null);
        this.connectionSettings && (this.connectionSettings.MultiTenant = a.multiTenantMode, a.endpointCert && this.connectionSettings.SetEndpointCertificate(a.endpointCert));
    }


    applyProperties(a: any): void {
        if (a) {
            this.connectionSettings = Module.RdpConnectionSettings.CreateFromString(a);
            this.connectionSettings.AudioMode = !0;
            this.connectionSettings.AzureRemoteApp = !1;
            this.connectionSettings.UseGraphicsWebWorkers = !1;
            this.connectionSettings.UseConnectionWebWorkers = !0;
            this.connectionSettings.TimeZoneBias = (new Date).getTimezoneOffset();
            const b = Intl && Intl.DateTimeFormat && new Intl.DateTimeFormat;
            this.connectionSettings.TimeZoneName = b && b.resolvedOptions().timeZone || '';
            this.connectionSettings.TimeZoneName = timeZones[this.connectionSettings.TimeZoneName] || this.connectionSettings.TimeZoneName;
            this.hostName = this.connectionSettings.HostName;
            this.port = this.connectionSettings.Port;
            this.gatewayHostName = this.connectionSettings.GatewayHostName;
            this.gatewayPort = this.connectionSettings.GatewayPort;
            this.remoteApplicationProgram = this.connectionSettings.RemoteAppProgram;
            this.remoteApplicationMode = this.connectionSettings.RemoteAppMode;
            this.remoteApplicationCmdLine = this.connectionSettings.RemoteAppCmdLine;
            this.EventLogUploadAddress = this.connectionSettings.EventLogUploadAddress;
            this.GatewayCertLogonAuthority = this.connectionSettings.GatewayCertLogonAuthority;
            this.properties = this.connectionSettings;
        }
    }

    isEqual(a: any): any {
        let b;
        const c = this.properties || {},
            d = a.properties || {};
        return b = c.HostPoolID || d.HostPoolID ? c.HostPoolID === d.HostPoolID : c.LBInfo === d.LBInfo,
        this.hostName === a.hostName && this.remoteApplicationMode === a.remoteApplicationMode && b && c.GatewayUsageMethod === d.GatewayUsageMethod &&
        c.GatewayProfileUsageMethod === d.GatewayProfileUsageMethod && c.GatewayCredentialsSource === d.GatewayCredentialsSource && c.GatewayHostName === d.GatewayHostName;
    }

}
