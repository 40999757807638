import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {networkPrefixValidator} from '../../../validators/network-prefix-validator';
import {VpnConfigDaoService} from './vpn-config-dao.service';
import {Project, VpnConfig} from '../../../models';
import {convertToFormGroup} from '../../../helpers/kluh';

@Component({
  selector: 'app-project-vpn-config',
  templateUrl: './project-vpn-config.component.html',
  styleUrls: ['./project-vpn-config.component.scss']
})
export class ProjectVpnConfigComponent implements OnInit {
  @Output()
  modelVpnConfigChange: EventEmitter<VpnConfig> = new EventEmitter<VpnConfig>();
  private project: Project;
  myForm: FormGroup;
  vpnConfig: VpnConfig;

  constructor(private vpnConfigDao: VpnConfigDaoService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
  }

  @Input()
  set modelVpnConfig(input: any) {
    this.setVpnConfig(input);
  }

  @Input()
  set modelCustomerGroup(input: any) {
    if (input) {
      this.project = <Project> input;
    }
  }

  private setVpnConfig(result: VpnConfig): void {
    this.vpnConfig = result;
    this.setForm();
  }

  private setForm(): void {
    if (this.vpnConfig && this.vpnConfig.id) {
      this.myForm = this.fb.group(convertToFormGroup(this.vpnConfig));
      this.myForm.get('networkPrefix').setValidators([Validators.required, networkPrefixValidator]);
      this.myForm.get('networkPrefixBits').setValidators([Validators.required, Validators.min(8), Validators.max(29)]);
    }
  }

  onSubmit(): void {
    if (this.project && this.project.id) {
      const vpnConfig: VpnConfig = this.myForm.value;
      this.vpnConfigDao.save(vpnConfig).subscribe((result) => {
        this.setVpnConfig(result);
        this.modelVpnConfigChange.emit(this.vpnConfig);
      });
    }
  }

  onCancel(): void {
    this.setForm();
  }

  onCreateVpnConfig(): void {
    const vpnConfig: VpnConfig = {
      id: null,
      comment: null,
      active: false,
      projectId: this.project.id,
      networkPrefix: '192.168',
      networkPrefixBits: 16,
        modified: null,
        optlock: null
    };
    this.vpnConfigDao.create(vpnConfig).subscribe((result) => {
      this.setVpnConfig(result);
      this.modelVpnConfigChange.emit(this.vpnConfig);
    });
  }

}
