import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseWidgetModule} from '../../../../../@fuse/components';
import {KluhSharedModule} from '../../../kluh-shared.module';
import {AuthService} from '../../../auth.service';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {HipcomBulkUpdateComponent} from './hipcom-bulk-update/hipcom-bulk-update.component';
import {HipcomBulkUpdateDetailComponent} from './hipcom-bulk-update/hipcom-bulk-update-detail/hipcom-bulk-update-detail.component';
import {HipcomBulkUpdateCreateEditComponent} from './hipcom-bulk-update/hipcom-bulk-update-create-edit/hipcom-bulk-update-create-edit.component';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {MatMenuModule} from '@angular/material/menu';
import {HipcomBulkUpdateDetailDaoService} from './hipcom-bulk-update/hipcom-bulk-update-detail/hipcom-bulk-update-detail-dao.service';
import {HipcomBulkUpdateDetailLogDaoService} from './hipcom-bulk-update/hipcom-bulk-update-detail-log/hipcom-bulk-update-detail-log-dao.service';
import {MatBadgeModule} from '@angular/material/badge';
import {HipcomBulkUpdateLatestSoftwareVersionDaoService} from './hipcom-bulk-update/hipcom-bulk-update-latest-software-version/hipcom-bulk-update-latest-software-version-dao.service';
import {HipcomBulkUpdateCurrentSoftwareVersionDaoService} from './hipcom-bulk-update/hipcom-bulk-update-current-software-version/hipcom-bulk-update-current-software-version-dao.service';
import {HipcomBulkUpdateScheduleCreateEditComponent} from './hipcom-bulk-update/hipcom-bulk-update-schedule-create-edit/hipcom-bulk-update-schedule-create-edit.component';
import {HipcomBulkUpdateLatestSoftwareVersionComponent} from './hipcom-bulk-update/hipcom-bulk-update-latest-software-version/hipcom-bulk-update-latest-software-version.component';
import {HipcomBulkUpdateDetailLogComponent} from './hipcom-bulk-update/hipcom-bulk-update-detail-log/hipcom-bulk-update-detail-log.component';
import {HipcomBulkUpdateDashboardComponent} from './hipcom-bulk-update/hipcom-bulk-update-dashboard/hipcom-bulk-update-dashboard.component';
import {HipcomBulkUpdatePathCreateEditComponent} from './hipcom-bulk-update/hipcom-bulk-update-path-create-edit/hipcom-bulk-update-path-create-edit.component';
import {HipcomBulkUpdatePathDaoService} from './hipcom-bulk-update/hipcom-bulk-update-path/hipcom-bulk-update-path-dao.service';
import {HipcomBulkUpdateCloseSoftwareCreateEditComponent} from './hipcom-bulk-update/hipcom-bulk-update-close-software-create-edit/hipcom-bulk-update-close-software-create-edit.component';
import {HipcomBulkUpdateMessageEditComponent} from './hipcom-bulk-update/hipcom-bulk-update-message-edit/hipcom-bulk-update-message-edit.component';
import {HipcomBulkUpdateMessageDaoService} from './hipcom-bulk-update/hipcom-bulk-update-message-edit/hipcom-bulk-update-message-dao.service';
import {HipcomBulkUpdateCloseSoftwareDaoService} from './hipcom-bulk-update/hipcom-bulk-update-close-software-create-edit/hipcom-bulk-update-close-software-dao.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {HipcomBulkUpdateMessageSendMessageComponent} from './hipcom-bulk-update/hipcom-bulk-update-message-send-message/hipcom-bulk-update-message-send-message.component';
import {HipcomBulkUpdateService} from './hipcom-bulk-update/hipcom-bulk-update.service';

const routes = [
    {
        path: 'partners/hipcom/hipcom-bulk-update',
        component: HipcomBulkUpdateComponent,
        canActivate: [AuthService],
        // data: {permissions: ['CustomerGroup.READ']}
        data: {permissions: ['HipcomBulkUpdateDetail.WRITE', 'HipcomBulkUpdateDetail.CREATE']}
    },
    {
        path: 'partners/hipcom/hipcom-bulk-update/detail',
        component: HipcomBulkUpdateDetailComponent,
        canActivate: [AuthService],
        // data: {permissions: ['CustomerGroup.READ']}
        data: {permissions: ['HipcomBulkUpdateDetail.WRITE', 'HipcomBulkUpdateDetail.CREATE']}
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        FuseWidgetModule,
        KluhSharedModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatButtonModule,
        MatInputModule,
        MatDialogModule,
        MatTableModule,
        MatSortModule,
        NgxMatDatetimePickerModule,
        MatDatepickerModule,

        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxMatTimepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatMomentModule,
        MatMenuModule,
        MatBadgeModule,
        FontAwesomeModule,
    ],
    entryComponents: [],
    declarations: [HipcomBulkUpdateComponent,
        HipcomBulkUpdateDetailComponent,
        HipcomBulkUpdateCreateEditComponent,
        HipcomBulkUpdateScheduleCreateEditComponent,
        HipcomBulkUpdateLatestSoftwareVersionComponent,
        HipcomBulkUpdateDetailLogComponent,
        HipcomBulkUpdateDashboardComponent,
        HipcomBulkUpdatePathCreateEditComponent,
        HipcomBulkUpdateCloseSoftwareCreateEditComponent,
        HipcomBulkUpdateMessageEditComponent,
        HipcomBulkUpdateMessageSendMessageComponent],
    providers: [
        HipcomBulkUpdateDetailDaoService,
        HipcomBulkUpdateDetailLogDaoService,
        HipcomBulkUpdateLatestSoftwareVersionDaoService,
        HipcomBulkUpdatePathDaoService,
        HipcomBulkUpdateMessageDaoService,
        HipcomBulkUpdateCloseSoftwareDaoService,
        HipcomBulkUpdateService,
        HipcomBulkUpdateCurrentSoftwareVersionDaoService]
})
export class HipcomModule {
}
