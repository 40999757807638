<mat-card class="padding-none width-100-percent mt-20">
    <mat-card-header>
        <mat-card-title fxLayout="row" fxLayoutAlign="space-between center"
                        style="margin-top: 7px;">
            <div>RemoteApps (Changing ou Deleting)</div>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="scroll-content">
         <table class="statistic-table width-100-percent" cellspacing="0"
               cellpadding="0">
            <thead>
            <tr>
                <th></th>
                <th class="w-115"></th>
            </tr>
            </thead>
            <tbody class="body-list">
            <tr *ngFor="let remoteApp of remoteAppsPendingOrDeleting">
                <td style="width: 32px;padding: 0 0 0 2px;">
                    <img
                         [src]=imageFileService.getRemoteAppPictureUrl(remoteApp.imageUUID)
                         width="32" height="32">

                </td>
                <td>
                    <div>
                        <div><b>{{remoteApp.name}}</b></div>
                        <div class="font-size-12"
                             style="margin-bottom: 10px">
                            <span title="{{remoteApp.app}}" class="text-max-335">{{remoteApp.app}}</span>
                        </div>
                        <div style="clear: both;"></div>
                        <div class="font-size-12">{{remoteApp.updatedAt}}</div>
                        <ul *ngIf="statusServer.getSubProject(remoteApp.subProjectId, subProjects) as subProject">
                            <li>
                                ID:<b> {{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.id}} </b>
                            </li>
                            <li>Empresa:
                                <b>{{statusServer.getCustomerGroup(subProject?.customerGroupId, customerGroups)?.name}} </b>
                            </li>
                            <li>subProject:<b> {{subProject?.name}} </b></li>
                        </ul>
                    </div>
                </td>
                <td>
                    {{remoteApp.updatedAt | timeDiff: 'large'}}
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </mat-card-content>
</mat-card>