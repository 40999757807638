import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, from, fromEvent, merge, Observable, of} from 'rxjs';
import {StompConnectionStatus} from './stomp-connection-status.enum';
import {ProtocolCheck} from '../main/desktop/protocol-check';
import {ClientStatus, R2CloudClientStatus} from '../models';
import {distinctUntilKeyChanged, filter, map, startWith, switchMap, take, takeUntil, tap, throttleTime} from 'rxjs/operators';
import {USER_TOPIC} from '../../../main';
import {ComponentCleaner} from '../component-cleaner';
import {R2CloudStompService} from './r2-cloud-stomp.service';
import {DomainPathService} from '../domain-path/domain-path.service';
import {AuthService} from '../auth.service';
import {DomainPath} from '../domain-path/models-domain';
import {Subscription} from 'rxjs/internal/Subscription';
import {isWindowsOS} from '../helpers/kluh';

@Injectable({
    providedIn: 'root'
})
export class R2CloudClientService extends ComponentCleaner {

    constructor(private stomp: R2CloudStompService,
                private authService: AuthService,
                private domainPathService: DomainPathService) {
        super();

        this._$startClient = this.initStartClientObservable();
    }

    private numberOfAttemptsToOpenWindowsClient = 0;
    private static timeoutMs = 3000;
    private clientListenerSubscription: Subscription;
    private connectedOnce = false;
    private status: ClientStatus = {clientId: undefined, webClientId: undefined, status: R2CloudClientStatus.STOMP_NOT_CONNECTED};
    private readonly _$startClient: Observable<ClientStatus>;
    private readonly enabledSubject = new BehaviorSubject<boolean>(true);
    private readonly clientStatusSubject = new BehaviorSubject<ClientStatus>(this.status);
    private readonly manualClick = new BehaviorSubject<Event>({isTrusted: true} as Event);

    private static startProtocolCheck(token: string, clientId: String, domainPath: DomainPath): Observable<ClientStatus> {
        if (isWindowsOS()) {
            const promise = ProtocolCheck
                .protocolCheckPromise(
                    `KluhR2CloudClient://${token},${clientId},${domainPath.secure},${domainPath.apiDomain},${domainPath.staticDomain}`,
                    R2CloudClientService.timeoutMs
                );
            return from(promise).pipe(
                take(1),
                map((status) => {
                    return {
                        status: status,
                        webClientId: null,
                        clientId: null
                    } as ClientStatus;
                }),
                tap((status) => {
                    // console.log('startProtocolCheck emitting status', status);
                })
            );
        } else {
            return of({status: R2CloudClientStatus.OPENING, clientId: null, webClientId: null} as ClientStatus);
        }
    }

    startClientListener(): void {
        if (!this.clientListenerSubscription) {
            this.clientListenerSubscription = this._$startClient.pipe(takeUntil(this.onDestroy$)).subscribe((status) => {
                this.status = status;
                this.clientStatusSubject.next(status);
            });
        }
        this.onDestroy$.subscribe(() => {
            this.clientStatusSubject.complete();
            this.enabledSubject.complete();
        });
    }

    clientConnectionChanges(): Observable<ClientStatus> {
        return this.clientStatusSubject.asObservable();
    }

    private initStartClientObservable(): Observable<ClientStatus> {
        const eventObservable = merge(
            merge(
                fromEvent(document, 'click'),
                fromEvent(document, 'contextmenu'),
                fromEvent(document, 'dblclick'),
                fromEvent(document, 'mousedown'),
                fromEvent(document, 'mouseenter'),
                fromEvent(document, 'mouseleave'),
                fromEvent(document, 'mousemove'),
                fromEvent(document, 'mouseout'),
                fromEvent(document, 'mouseover'),
                fromEvent(document, 'mouseup'),
            ).pipe(throttleTime(R2CloudClientService.timeoutMs), filter(() => this.connectedOnce)),
            merge(
                fromEvent(document, 'visibilitychange').pipe(filter(() => !document.hidden)),
                this.manualClick
            )
        );
        return combineLatest([
            this.enabledSubject,
            this.stomp.connectionStatus$.pipe(startWith(StompConnectionStatus.DISCONNECTED)),
            this.stomp.stompTopic<ClientStatus>(`${USER_TOPIC}/client-status`)
                .pipe(startWith({clientId: null, webClientId: null, status: R2CloudClientStatus.STOMP_NOT_CONNECTED} as ClientStatus)),
            this.domainPathService.domainPath$,
            this.authService.getFirebaseToken(),
            eventObservable
        ]).pipe(switchMap((combined: [boolean, StompConnectionStatus, ClientStatus, DomainPath, string, Event]) => {
                const enabled = combined[0];
                if (!enabled) {
                    return of({clientId: null, webClientId: null, status: R2CloudClientStatus.NOT_CONNECTED} as ClientStatus);
                }
                const stompConnectionStatus: StompConnectionStatus = combined[1];
                if (stompConnectionStatus !== StompConnectionStatus.OK) {
                    return of({clientId: null, webClientId: null, status: R2CloudClientStatus.STOMP_NOT_CONNECTED} as ClientStatus);
                }
                const clientStatus: ClientStatus = combined[2];
                const event = combined[5];
                const clientId: string = this.stomp.webClientId;
                const domainPath: DomainPath = combined[3];
                const firebaseToken: string = combined[4];
                // noinspection FallThroughInSwitchStatementJS
                console.log('clientStatus.status', clientStatus.status);
                switch (clientStatus.status) {
                    case R2CloudClientStatus.CONNECTED:
                        if (this.numberOfAttemptsToOpenWindowsClient < 1) {
                            this.numberOfAttemptsToOpenWindowsClient++;
                            // return this.startClient(event, clientStatus, firebaseToken, clientId, domainPath);
                            this.tryToConnectFewTimes(event, clientStatus, firebaseToken, clientId, domainPath);
                            return of(clientStatus);
                        }
                    // tslint:disable-next-line
                    case R2CloudClientStatus.CLIENT_UPDATE:
                        this.connectedOnce = true;
                    // tslint:disable-next-line
                    case R2CloudClientStatus.STOMP_NOT_CONNECTED:
                        return of(clientStatus);
                    case R2CloudClientStatus.OPENING:
                    case R2CloudClientStatus.INSTALLED:
                    case R2CloudClientStatus.NOT_CONNECTED:
                    case R2CloudClientStatus.NOT_INSTALLED:
                        return this.startClient(event, clientStatus, firebaseToken, clientId, domainPath);
                }
            }),
            tap((status) => {
                // console.log('emitting status', status);
            }),
            distinctUntilKeyChanged('status'));
    }

    private tryToConnectFewTimes(event: Event, clientStatus: ClientStatus, firebaseToken: string, clientId: String, domainPath: DomainPath): void {
        this.startClient(event, clientStatus, firebaseToken, clientId, domainPath);
        // console.log('clientStatus.status now');
        for (let i = 1; i < 4; i++) {
            setTimeout(() => {
                this.startClient(event, clientStatus, firebaseToken, clientId, domainPath);
                // console.log('clientStatus.status setTimeout ' + i);
            }, 4000 * i);
        }
    }

    private startClient(event: Event, clientStatus: ClientStatus, firebaseToken: string, clientId: String, domainPath: DomainPath): Observable<ClientStatus> {
        if (!event.isTrusted || document.hidden) {
            return of(clientStatus);
        }
        return R2CloudClientService.startProtocolCheck(firebaseToken, clientId, domainPath).pipe(
            startWith({status: R2CloudClientStatus.OPENING, clientId: null, webClientId: null} as ClientStatus));
    }

    isClientConnected(): boolean {
        return this.status.status === R2CloudClientStatus.CONNECTED;
    }

    disable(): void {
        this.enabledSubject.next(false);
    }

    enable(): void {
        this.enabledSubject.next(true);
    }

    enableChanges(): Observable<boolean> {
        return this.enabledSubject.asObservable();
    }

    startClientClick(e: Event): void {
        this.connectedOnce = true;
        this.manualClick.next(e);
    }
}
