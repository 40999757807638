import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerGroupService} from '../customer-group/customer-group.service';
import {SubProject} from '../../models';
import {CustomerDaoService} from '../customer/customer-dao.service';
import {ManagerUserDaoService} from '../manager-user/manager-user-dao.service';
import {AdUserDaoService} from '../ad-user/ad-user-dao.service';
import {SubProjectDaoService} from '../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {RoleDAOService} from '../iam/role-dao.service';
import {R2CloudAdminService} from '../r2-cloud-admin/r2-cloud-admin.service';
import {RoleCustomerGroupManagerUserDAOService} from '../iam/role-customer-group-manager-user-dao.service';
import {ComponentCleaner} from '../../component-cleaner';
import {CustomerGroupDaoService} from '../customer-group/customer-group-dao.service';
import {ImageFileService} from '../image-file/image-file-service';
import {AuthService} from '../../auth.service';
import {orderArrayAnyBy} from '../../helpers/kluh';
import {ManagerUserAccessPeriodService} from '../manager-user-access-period/manager-user-access-period.service';
import {ManagerUserAccessPeriodProfileDaoService} from '../manager-user-access-period/manager-user-access-period-profile-dao.service';
import {ManagerUserAccessPeriodTimeDaoService} from '../manager-user-access-period/manager-user-access-period-time-dao.service';
import {PermissionWrapper} from '../../directives/if-permission.directive';


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent extends ComponentCleaner implements OnInit, OnDestroy {

    subProjects: SubProject[] = [];
    canEditList: PermissionWrapper[];

    constructor(
        public managerUserAccessPeriodService: ManagerUserAccessPeriodService,
        public customerGroupService: CustomerGroupService,
        private customerDaoService: CustomerDaoService,
        private auth: AuthService,
        private managerUserDaoService: ManagerUserDaoService,
        private adUserDaoService: AdUserDaoService,
        private subProjectDaoService: SubProjectDaoService,
        private roleDaoService: RoleDAOService,
        public adminService: R2CloudAdminService,
        private customerGroupDaoService: CustomerGroupDaoService,
        private imageFileService: ImageFileService,
        private roleCustomerGroupManagerUserDao: RoleCustomerGroupManagerUserDAOService,
        private managerUserAccessPeriodProfileDaoService: ManagerUserAccessPeriodProfileDaoService,
        private managerUserAccessPeriodTimeDaoService: ManagerUserAccessPeriodTimeDaoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.clearData();
        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup && customerGroup.id) {
                this.clearData();
                this.canEditList =
                    [
                        {object: this.customerGroupService.get(), type: 'CustomerGroup', permission: 'WRITE'},
                        {object: this.adminService.customers$, type: 'Customer', permission: 'READ'},
                        // {object: this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles$, type: 'ManagerUserAccessPeriodProfile', permission: 'WRITE'},
                        // {type: 'ManagerUserAccessPeriodProfile', permission: 'CREATE', parentType: 'CustomerGroup', parent: customerGroup}
                        {type: 'ManagerUser', permission: 'WRITE', parentType: 'CustomerGroup', parent: customerGroup},
                    ];
                this.managerUserAccessPeriodProfileDaoService.filter(
                    {customerGroupId: customerGroup.id}
                ).subscribe(results => {
                    this.managerUserAccessPeriodService.managerUserAccessPeriodProfiles = results;
                });
                this.managerUserAccessPeriodTimeDaoService.filter(
                    {customerGroupId: customerGroup.id}
                ).subscribe(results => {
                    this.managerUserAccessPeriodService.managerUserAccessPeriodTimes = results;
                });
                this.customerDaoService.getActive({customerGroupId: customerGroup.id}).subscribe(customers => {
                    if (customers) {
                        this.adminService.customers = customers;

                        this.managerUserDaoService.filter({managerUsersInCustomerGroupId: customerGroup.id}).subscribe(managerUsers => {
                            if (managerUsers) {
                                const managerUserIDs: number[] = [];
                                for (const managerUser of managerUsers) {
                                    managerUserIDs.push(managerUser.id);
                                    this.adminService.addOrReplaceManagerUser(managerUser);
                                }
                                this.adminService.managerUsers = orderArrayAnyBy(this.adminService.managerUsers, 'id');
                            }
                        });
                    }
                });
                this.roleDaoService.get().subscribe((roles) => {
                    if (roles) {
                        this.adminService.roles = roles;
                        this.managerUserDaoService.filter({managerUsersInRoleCustomerGroupId: customerGroup.id}).subscribe((managerUsersCustomerGroup) => {
                            if (managerUsersCustomerGroup) {


                                for (const managerUser of managerUsersCustomerGroup) {

                                    this.adminService.addOrReplaceManagerUser(managerUser);
                                    // this.customerDaoService.filterOne({managerUserId: managerUser.id}).subscribe(customer => {
                                    this.customerDaoService.getOneExternalByManagerUserId(managerUser.id).subscribe(customer => {
                                        if (customer) {
                                            this.adminService.externalCustomers.push(customer);
                                            this.customerGroupDaoService.getOneExternalByCustomerId(customer.id).subscribe(externalCustomerGroup => {
                                                if (externalCustomerGroup) {
                                                    this.adminService.externalCustomerGroups.push(externalCustomerGroup);
                                                }
                                            });
                                        }
                                    });
                                }


                                this.roleCustomerGroupManagerUserDao.getByParentId(customerGroup.id).subscribe((roleSubProjectManagerUserList) => {
                                    if (roleSubProjectManagerUserList) {
                                        this.adminService.roleCustomerGroupManagerUsers = roleSubProjectManagerUserList;
                                    }
                                });
                                this.adminService.managerUsers = orderArrayAnyBy(this.adminService.managerUsers, 'id');
                            }
                        });
                    }
                });
            }
        });
    }


    private clearData(): void {
        this.subProjects = [];
        this.adminService.roles = [];
        this.adminService.roleCustomerGroupManagerUsers = [];
        this.adminService.managerUsersAdministrator = [];
        this.adminService.managerUsers = [];
        this.adminService.customers = [];
        this.adminService.externalCustomerGroups = [];
    }

    ngOnDestroy(): void {
        this.clearData();
    }
}
