import {Component, OnDestroy, OnInit} from '@angular/core';
import {ComponentCleaner} from '../../../component-cleaner';
import {ADGroup} from '../../../models';
import {AdGroupDaoService} from '../../ad-group/ad-group-dao.service';
import {AdGroupCreateEditComponent} from '../../ad-group/ad-group-create-edit/ad-group-create-edit.component';
import {filter} from 'rxjs/operators';
import {R2CloudAdminService} from '../r2-cloud-admin.service';
import {CrudOperationWrapper, theDateIsGreaterThanNowPlusMinutes} from '../../../helpers/kluh';
import {AdUserDaoService} from '../../ad-user/ad-user-dao.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {UserAuthorityDaoService} from '../../user-authority/user-authority-dao.service';
import {PermissionWrapper} from '../../../directives/if-permission.directive';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-r2-cloud-admin-adgroup',
    templateUrl: './r2-cloud-admin-adgroup.component.html',
    styleUrls: ['./r2-cloud-admin-adgroup.component.scss'],
    animations: fuseAnimations
})
export class R2CloudAdminADGroupComponent extends ComponentCleaner implements OnInit, OnDestroy {

    public timerPendingChangesLoad = null;
    canAccess = false;
    appCanCreateList: PermissionWrapper[];


    constructor(private adGroupDao: AdGroupDaoService,
                private adUserDao: AdUserDaoService,
                public adminService: R2CloudAdminService,
                public customerGroupService: CustomerGroupService,
                public userAuthorityDaoService: UserAuthorityDaoService,
                private dialog: MatDialog) {
        super();
        this.appCanCreateList = [
            {type: 'ADGroup', permission: 'CREATE', parentType: 'CustomerGroup', parent: this.customerGroupService.customerGroup},
            {type: 'ADGroup', permission: 'CREATE', parentType: 'SubProject', parent: this.adminService.subProject},
        ];
    }

    ngOnInit(): void {

    }



    ngOnDestroy(): void {
        clearInterval(this.timerPendingChangesLoad);
    }


    onCreateAdGroup(): void {
        const adGroup: ADGroup = {
            id: null,
            comment: null,
            active: true,
            name: null,
            prefix: this.adminService.subProject.id + '-' + this.adminService.subProject.name,
            adDomainId: null,
            adUserIds: [],
            adUserPoolRelationshipIds: [],
            subProjectId: null,
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
        this.openAdGroupModal(adGroup);
    }

    openAdGroupModal(adGroup: ADGroup): void {
        const dialogRef = this.dialog.open(AdGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog',
            data: {
                adDomains: this.adminService.adDomains,
                adGroup: adGroup,
                adUsers: this.adminService.adUsers,
                adUsersService: this.adminService.adUsersService,
                subProject: this.adminService.subProject,
                managerUsers: this.adminService.managerUsers
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper) => {
            if (result.operation === 'CANCEL') {
                return;
            } else if (result.operation === 'DELETE') {
                this.adminService.removeADGroup(result.data);
            } else if (result.operation === 'SAVE') {
                this.adminService.replaceADGroup(result.data);
                this.reloadAdUsers();
            } else if (result.operation === 'CREATE') {
                this.adminService.addADGroup(result.data);
                this.reloadAdUsers();
            }
        });
        this.addSubscription(subscription);
    }


    private reloadAdUsers(): void {
        this.adUserDao.filter({
            'subProjectId': this.adminService.subProject.id,
            'serviceUser': false
        }).subscribe((adUsers) => {
            this.adminService.adUsers = adUsers;
        });
    }

    theDateIsGreaterThanNowPlusMinutes(updatedAt: Date, minutes: number): boolean {
        return theDateIsGreaterThanNowPlusMinutes(updatedAt, minutes);
    }
}
