<mat-card>
  <div *ngIf="cloudConfig && cloudConfig.id ; else createNew">
    <ng-container *ngIf="cloudConfig.googleCloudJson">
      <div>
        ProjectID: {{cloudConfig.googleCloudJson.project_id}}
      </div>
      <div>
        ClientEmail: {{cloudConfig.googleCloudJson.client_email}}
      </div>
      <div *ngIf="!editing">
        Zona: {{cloudConfig.zone}}
      </div>
      <div *ngIf="editing">
        <form [formGroup]="myForm">
          <mat-form-field>
            <mat-select placeholder="Zona" formControlName="zone">
              <mat-option *ngFor="let zone of googleZones" [value]="zone">
                {{zone}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </ng-container>
    <ng-container *appIsAdmin>
      <button type="button" mat-raised-button color="primary" (click)="onInsertToken()" class="mt-20" [disabled]="!editing">Inserir
        Token
      </button>
      <button type="button" mat-raised-button color="primary" (click)="onGetInstances()" class="mt-20" [disabled]="!editing">Obter
        hosts do Google
      </button>
    </ng-container>
  </div>
  <ng-template #createNew>
    <button type="button" mat-raised-button color="primary" (click)="onCreateCloudConfig()" [disabled]="!editing">Criar Configuração
      Cloud
    </button>
  </ng-template>
</mat-card>
