import {Injectable} from '@angular/core';
import {BaseDao} from '../../base-dao';
import {KluhManagerValidator} from '../../validators/kluh-manager-validator';
import {R2CloudHttpApiService} from '../../r2-cloud-http-api.service';
import {ADUserHtml5, ADUserPool, ADUserPoolInfo, ADUserWithPassword} from '../../models';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class AdUserPoolDaoService extends BaseDao<ADUserPool> {

    constructor(http: R2CloudHttpApiService, validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'ad-user-pool');
    }

    initADUserPool(): ADUserPool {
        return {
            id: null,
            comment: null,
            active: true,
            modified: null,
            optlock: null,
            login: null,
            serviceUser: null,
            adDomainId: null,
            changing: null,
            deleting: null,
            createdAt: null,
            updatedAt: null,
        };
    }

    findByLoginPw(login: string, adDomainId: number): Observable<ADUserWithPassword> {
        return this.http.get<ADUserWithPassword>(this.url + '/find-by-login-pw?login=' + login + '&adDomainId=' + adDomainId);
    }

    findAllADUserPoolInfo(adUserPoolId: number, login: string): Observable<ADUserPoolInfo[]> {
        return this.http.get<ADUserPoolInfo[]>(this.url + '/find-all-ad-user-pool-info?adUserPoolId=' + adUserPoolId + '&login=' + login);
    }

    setRandomPassword(adUserPoolId: number): Observable<ADUserPool> {
        return this.http.get<ADUserPool>(this.url + '/set-random-password?adUserPoolId=' + adUserPoolId);
    }

    setLegacyRandomPassword(adUserPoolId: number): Observable<ADUserPool> {
        return this.http.get<ADUserPool>(this.url + '/set-legacy-random-password?adUserPoolId=' + adUserPoolId);
    }

    findAllByADDomainIds(adDomainIds: string): Observable<ADUserPool[]> {
        return this.http.get<ADUserPool[]>(this.url + '/find-all-by-ad-domain-ids?adDomainIds=' + adDomainIds);
    }

    getOneHtml5(adUserId: number): Observable<ADUserHtml5> {
        return this.http.get<ADUserHtml5>(this.url + '/html5/' + adUserId);

    }
}
