import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {FormControl} from '@angular/forms';
import {ProjectDaoService} from '../../../project/project-dao.service';
import {SubProjectDaoService} from '../../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';
import {ManagerUserDaoService} from '../../../manager-user/manager-user-dao.service';
import {CustomerDaoService} from '../../../customer/customer-dao.service';
import {CustomerGroupDaoService} from '../../../customer-group/customer-group-dao.service';
import {combineLatest} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith} from 'rxjs/operators';
import {ComponentCleaner} from '../../../../component-cleaner';
import {SupportCard, SupportCardType} from './support-card';
import {R2CloudClientService} from '../../../../ws/r2-cloud-client.service';
import {faCircle, faLaptopCode, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {BaseDTOIdLong, ClientStatus, DesktopServer, LinuxServer, R2CloudClientStatus, SubProject} from '../../../../models';
import {DesktopServerDaoService} from '../../../desktop-server/desktop-server-dao.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {LinuxServerDaoService} from '../../../linux-server/linux-server-dao.service';
import {QueryParamsService} from '../../../../query-params.service';
import {R2CloudStompService} from '../../../../ws/r2-cloud-stomp.service';

@Component({
    selector: 'app-r2-cloud-support',
    templateUrl: './r2-cloud-support.component.html',
    styleUrls: ['./r2-cloud-support.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class R2CloudSupportComponent extends ComponentCleaner {
    public faLaptopCode = faLaptopCode;
    public faCircle = faCircle;
    public faTimesCircle = faTimesCircle;
    public search = new FormControl('');
    public searchTypes = ['Todos', 'Projeto', 'Empresa', 'Nome', 'Email', 'Servidor'];
    public searchType = new FormControl('Todos');
    public cards: SupportCard[] = [];
    public SupportCardType = SupportCardType;
    public clientStatus: ClientStatus;
    public R2CloudClientStatus = R2CloudClientStatus;
    private searchParamId = 'search';
    private searchTypeParamId = 'searchType';
    private desktopServerParamId = 'desktopServerId';
    private subProjectParamId = 'subProjectId';


    constructor(private projectDao: ProjectDaoService,
                private subProjectDao: SubProjectDaoService,
                private managerUserDao: ManagerUserDaoService,
                private customerDao: CustomerDaoService,
                private customerGroupDao: CustomerGroupDaoService,
                private desktopServerDao: DesktopServerDaoService,
                private linuxServerDao: LinuxServerDaoService,
                private clientService: R2CloudClientService,
                private stomp: R2CloudStompService,
                private queryParamsService: QueryParamsService,
                private route: ActivatedRoute,
                private router: Router,
                private location: Location) {
        super();
        this.restoreSearchFromUrl();
        this.restoreDesktopServerFromUrl();
        this.restoreSubProjectFromUrl();
        // const subscription = this.clientService.clientConnectionChanges().subscribe((result) => {
        //     this.clientStatus = result;
        //     if (result.status === R2CloudClientStatus.NOT_CONNECTED) {
        //         this.startClient();
        //     }
        // });
        // this.addSubscription(subscription);
        const search$ = this.search.valueChanges.pipe(startWith(<string>this.search.value));
        const searchType$ = this.searchType.valueChanges.pipe(startWith(<string>this.searchType.value));
        const subscription1 = combineLatest([search$, searchType$])
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter((x): boolean => {
                    return !!x && !!x[0] && x[0].length > 2 && !!x[1];
                }))
            .subscribe((result) => {
                const search = result[0];
                const searchType = result[1];
                this.setSearchUrl(searchType, search);
                this.handleSearch(searchType, search);
            });
        this.addSubscription(subscription1);
    }

    private handleSearch(searchType: string, search: string): void {
        switch (searchType) {
            case 'Projeto':
                this.searchSubProject(search);
                break;
            case 'Empresa':
                this.searchCustomer(search);
                break;
            case 'Nome':
                this.searchManagerUserName(search);
                break;
            case 'Email':
                this.searchManagerUserEmail(search);
                break;
            case 'Servidor':
                this.searchDesktopServer(search);
                this.searchLinuxServer(search);
                break;
            default:
                this.searchEverything(search);
        }
    }

    // startClient(): void {
    //     this.clientService.startClient();
    // }

    private searchEverything(search: string): void {
        this.searchSubProject(search);
        this.searchCustomer(search);
        this.searchManagerUserName(search);
        this.searchManagerUserEmail(search);
        this.searchDesktopServer(search);
        this.searchLinuxServer(search);
    }

    private searchSubProject(search: string): void {
        this.subProjectDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'name', value: search, limit: 3}).subscribe((result) => {
            for (const subProject of result.content) {
                this.addSubProjectCard(subProject, 0);
            }
        });
    }

    private searchCustomer(search: string): void {

    }

    private searchManagerUserName(search: string): void {

    }

    private searchManagerUserEmail(search: string): void {

    }

    private searchDesktopServer(search: string): void {
        this.desktopServerDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'hostname', value: search, limit: 3}).subscribe((result) => {
            for (const desktopServer of result.content) {
                this.addDesktopServerCard(desktopServer, 0);
            }
        });
        this.desktopServerDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'connectionFQDN', value: search, limit: 3}).subscribe((result) => {
            for (const desktopServer of result.content) {
                this.addDesktopServerCard(desktopServer, 0);
            }
        });
    }

    private searchLinuxServer(search: string): void {
        this.linuxServerDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'hostname', value: search, limit: 3}).subscribe((result) => {
            for (const linuxServer of result.content) {
                this.addLinuxServerCard(linuxServer, 0);
            }
        });
        this.linuxServerDao.search({fieldOrder: undefined, order: undefined, page: undefined, field: 'connectionFQDN', value: search, limit: 3}).subscribe((result) => {
            for (const linuxServer of result.content) {
                this.addLinuxServerCard(linuxServer, 0);
            }
        });
    }

    private addDesktopServerCard(desktopServer: DesktopServer, index: number): void {
        if (this.cards.findIndex((item) => {
            return item.cardType === SupportCardType.DESKTOP_SERVER && item.value.id === desktopServer.id;
        }) === -1) {
            this.cards.splice(index, 0, {cardType: SupportCardType.DESKTOP_SERVER, value: desktopServer});
        }
    }

    private addLinuxServerCard(linuxServer: LinuxServer, index: number): void {
        if (this.cards.findIndex((item) => {
            return item.cardType === SupportCardType.LINUX_SERVER && item.value.id === linuxServer.id;
        }) === -1) {
            this.cards.splice(index, 0, {cardType: SupportCardType.LINUX_SERVER, value: linuxServer});
        }
    }

    private addSubProjectCard(subProject: SubProject, index: number): void {
        if (this.cards.findIndex((item) => {
            return item.cardType === SupportCardType.SUB_PROJECT && item.value.id === subProject.id;
        }) === -1) {
            this.cards.splice(index, 0, {cardType: SupportCardType.SUB_PROJECT, value: subProject});
        }
    }

    public onClose(supportCard: SupportCard): void {
        const index = this.cards.findIndex((item) => {
            return item.cardType === supportCard.cardType && item.value.id === supportCard.value.id;
        });
        if (index > -1) {
            this.cards.splice(index, 1);
        }
    }

    public onDesktopServerConnect(desktopServer: BaseDTOIdLong): void {
        this.stomp.send('/stomp/open-local-admin', {id: desktopServer.id});
    }

    public onLinuxServerConnect(linuxServer: BaseDTOIdLong): void {
        this.stomp.send('/stomp/open-linux-local-admin', {id: linuxServer.id});
    }

    public onDesktopServerSelect(desktopServer: DesktopServer, supportCard: SupportCard): void {
        const index = this.cards.findIndex((item) => {
            return item.cardType === supportCard.cardType && item.value.id === supportCard.value.id;
        });
        this.addDesktopServerCard(desktopServer, index + 1);
    }

    public onLinuxServerSelect(linuxServer: LinuxServer, supportCard: SupportCard): void {
        const index = this.cards.findIndex((item) => {
            return item.cardType === supportCard.cardType && item.value.id === supportCard.value.id;
        });
        this.addLinuxServerCard(linuxServer, index + 1);
    }

    public onSubProjectSelect(subProject: SubProject, supportCard: SupportCard): void {
        const index = this.cards.findIndex((item) => {
            return item.cardType === supportCard.cardType && item.value.id === supportCard.value.id;
        });
        this.addSubProjectCard(subProject, index + 1);
    }

    private restoreSearchFromUrl(): void {
        const queryParamMap = this.route.snapshot.queryParamMap;
        if (queryParamMap.has(this.searchParamId)) {
            const search: string = queryParamMap.get(this.searchParamId);
            const searchType: string = queryParamMap.get(this.searchTypeParamId);
            this.search.setValue(search);
            if (searchType){
                this.searchType.setValue(searchType);
            }
            this.handleSearch(searchType, search);
        }
    }

    private setSearchUrl(searchType: string, search: string): void {
        if (searchType && searchType !== 'Todos'){
            this.queryParamsService.setUrlQueryParam({id: 'searchType', value: searchType});
        }
        this.queryParamsService.setUrlQueryParam({id: 'search', value: search});
    }

    private restoreDesktopServerFromUrl(): void {
        const queryParamMap = this.route.snapshot.queryParamMap;
        if (queryParamMap.has(this.desktopServerParamId)) {
            const desktopServerId: number = Number(queryParamMap.get(this.desktopServerParamId));
            this.desktopServerDao.getOne(desktopServerId).subscribe((desktopServer) => {
                this.addDesktopServerCard(desktopServer, 0);
            });
        }
    }

    private restoreSubProjectFromUrl(): void {
        const queryParamMap = this.route.snapshot.queryParamMap;
        if (queryParamMap.has(this.subProjectParamId)) {
            const subProjectId: number = Number(queryParamMap.get(this.subProjectParamId));
            this.subProjectDao.getOne(subProjectId).subscribe((subProject) => {
                this.addSubProjectCard(subProject, 0);
            });
        }
    }

    public onClearCards(): void {
        this.cards = [];
    }

    public onSearchSubmit(): void {

    }


}
