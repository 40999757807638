  <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Instâncias do Google Cloud Platform</span>
    </mat-toolbar-row>
  </mat-toolbar>


  <div class="mat-white-bg mat-elevation-z2" fxLayout="column"
       fxLayoutAlign="space-between stretch">
    <div fxLayout="column" class="p-16" fxLayoutAlign="none stretch">
      <div *ngFor="let instance of instancesFromGoogle" style="margin-bottom: 25px;">
        <div style="margin-bottom: 5px;">
          {{instance.googleInstanceName}}
        </div>
        <div *ngIf="onCheckIfExists(instance); else new">
          <button type="button" (click)="onUpdateInstance(instance)" mat-raised-button>Atualizar</button>
        </div>
        <ng-template #new>
          <div>
            <button type="button" (click)="onCreateInstance(instance)" mat-raised-button>Criar no R2</button>
          </div>
        </ng-template>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top">
      <button type="button" mat-raised-button color="primary" (click)="onClose()">Fechar</button>
    </div>
  </div>
