import {Component, Input} from '@angular/core';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {Customer, CustomerGroup} from '../../../models';
import {StatusService} from '../status-service';

@Component({
    selector: 'app-manager-user-changing-or-deleting',
    templateUrl: './manager-user-changing-or-deleting.component.html',
    styleUrls: ['./manager-user-changing-or-deleting.component.scss', '../status.component.scss']
})
export class ManagerUserChangingOrDeletingComponent {

    managerUsersSnapshots: JaversSnapshot<any>[];

    @Input()
    customers: Customer[] = [];

    @Input()
    customerGroups: CustomerGroup[] = [];

    constructor(private managerUserDao: ManagerUserDaoService, private statusServer: StatusService) {
        this.managerUserDao.snapshotsLimit(5).subscribe((managerUsersSnapshots) => {
            this.managerUsersSnapshots = managerUsersSnapshots;
        });
    }
}
