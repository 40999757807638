import {Component} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {SubProject} from '../../../models';
import {SubProjectDaoService} from '../../r2-cloud-admin/r2-cloud-admin-sub-project/sub-project-dao.service';

@Component({
    selector: 'app-project-changing-or-deleting',
    templateUrl: './project-changing-or-deleting.component.html',
    styleUrls: ['./project-changing-or-deleting.component.scss', '../status.component.scss']
})
export class ProjectChangingOrDeletingComponent {

    public subProjectsSnapshots: JaversSnapshot<SubProject>[];

    constructor(private subProjectDaoService: SubProjectDaoService) {
        this.subProjectDaoService.snapshotsLimit(5).subscribe((subProjectsSnapshots) => {
            this.subProjectsSnapshots = subProjectsSnapshots;

        });
    }
}
