import {Component, Inject, OnInit} from '@angular/core';
import {ManagerUserDaoService} from '../manager-user-dao.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ManagerUser, ManagerUserAccessPeriodProfile} from '../../../models';
import {convertToFormGroup, CrudOperation, CrudOperationWrapper, markAsTouched, Province, validateEmail} from '../../../helpers/kluh';
import {Observable, of} from 'rxjs';
import {ImageFileService} from '../../image-file/image-file-service';
import {R2CloudAdminService} from '../../r2-cloud-admin/r2-cloud-admin.service';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {CustomerGroupService} from '../../customer-group/customer-group.service';
import {ComponentCleaner} from '../../../component-cleaner';
import {
    RoleCustomerGroupManagerUserEditComponent
} from '../../iam/role-customer-group-manager-user/role-customer-group-manager-user-edit/role-customer-group-manager-user-edit.component';
import {CustomerDaoService} from '../../customer/customer-dao.service';
import {CustomerGroupDaoService} from '../../customer-group/customer-group-dao.service';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {ProvinceService} from '../../../helpers/province.service';
import {ManagerUserService} from '../manager-user-service';
import {ManagerUserAccessPeriodProfileDaoService} from '../../manager-user-access-period/manager-user-access-period-profile-dao.service';
import {CustomerCreateEditComponent} from '../../customer/customer-create-edit/customer-create-edit.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

@Component({
    selector: 'app-manager-user-create',
    templateUrl: './manager-user-create.component.html',
    styleUrls: ['./manager-user-create.component.scss']
})
export class ManagerUserCreateComponent extends ComponentCleaner implements OnInit {
    imageFileElement: any = null;
    managerUser: ManagerUser;
    externalManagerUser: ManagerUser;
    myForm: FormGroup;
    roleIdForm: FormGroup;
    emailAlReadyExists = false;
    provinceList: Province[];
    managerUserAccessPeriodProfiles: ManagerUserAccessPeriodProfile[];

    constructor(public dialogRef: MatDialogRef<ManagerUserCreateComponent>,
                private managerUserDaoService: ManagerUserDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialog: MatDialog,
                private customerDaoService: CustomerDaoService,
                private customerGroupDaoService: CustomerGroupDaoService,
                public adminService: R2CloudAdminService,
                private managerUserService: ManagerUserService,
                private managerUserAccessPeriodProfileDaoService: ManagerUserAccessPeriodProfileDaoService,
                private customerGroupService: CustomerGroupService,
                private fb: FormBuilder,
                private provinceService: ProvinceService,
                public imageFileService: ImageFileService) {
        super();
    }

    ngOnInit(): void {


        this.customerGroupService.get().subscribe(customerGroup => {
            if (customerGroup && customerGroup.id) {
                this.managerUserAccessPeriodProfileDaoService.filter(
                    {customerGroupId: customerGroup.id}
                ).subscribe(results => {
                    this.managerUserAccessPeriodProfiles = results;
                });
            }
        });


        let email = '';
        this.provinceList = this.provinceService.provinceList;
        this.managerUser = this.data.managerUser;
        if (!this.managerUser) {
            this.managerUser = this.managerUserService.initManagerUser();
        } else {
            email = this.managerUser.email;
        }
        const emailForm = this.fb.control(email, [Validators.email]);
        const managerUserForm = this.fb.group(convertToFormGroup(this.managerUser));
        if (!this.managerUser.id) {
            managerUserForm.disable();
        } else {
            managerUserForm.enable();
        }
        this.myForm = this.fb.group({
            managerUserForm: managerUserForm,
            emailForm: emailForm
        });

        const emailForm$ = emailForm.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe((value: string) => {
            this.emailAlReadyExists = false;
            this.externalManagerUser = null;
            if (value && validateEmail(value)) {
                this.managerUserDaoService.findByEmail(value).subscribe(managerUserFromDao => {
                    if (this.data.managerUser) {
                        if (this.data.managerUser.email !== value) {
                            if (managerUserFromDao) {
                                managerUserForm.setValue(managerUserFromDao);
                                managerUserForm.disable();
                                this.emailAlReadyExists = true;
                                this.externalManagerUser = managerUserFromDao;
                            }
                        }
                    } else {
                        if (managerUserFromDao) {
                            managerUserForm.setValue(managerUserFromDao);
                            managerUserForm.disable();
                            this.emailAlReadyExists = true;
                            this.externalManagerUser = managerUserFromDao;
                        } else {
                            managerUserForm.setValue(this.managerUserService.initManagerUser());
                            managerUserForm.enable();
                        }
                    }
                });
            } else {
                if (managerUserForm.value.id) {
                    managerUserForm.setValue(this.managerUserService.initManagerUser());
                    managerUserForm.disable();
                }
            }
        });
        this.addSubscription(emailForm$);


    }

    onCreateCustomer(): void {
        const dialogRef = this.dialog.open(CustomerCreateEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {}
        });
        const subscription = dialogRef.afterClosed().subscribe((result: CrudOperationWrapper) => {
            this.dialogRefCallback(result);
        });
        this.addSubscription(subscription);
    }

    private dialogRefCallback(result: CrudOperationWrapper): void {
        if (result.operation === 'CREATE') {
            this.adminService.addCustomer(result.data);
        }
    }

    onAddRoleToTheManagerUser(): void {
        const dialogRef = this.dialog.open(RoleCustomerGroupManagerUserEditComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                managerUser: this.externalManagerUser
            }
        });
        const subscription = dialogRef.afterClosed().subscribe((operationWrapper: CrudOperationWrapper) => {
            if (operationWrapper && operationWrapper.operation === 'CREATE') {
                this.adminService.addOrReplaceManagerUser(this.externalManagerUser);
                this.customerDaoService.getOne(this.externalManagerUser.customerId).subscribe(externalCustomerFromDao => {
                    if (externalCustomerFromDao) {
                        this.adminService.addOrReplaceExternalCustomer(externalCustomerFromDao);
                        this.customerGroupDaoService.getOne(externalCustomerFromDao.customerGroupId).subscribe(externalCustomerGroupFromDao => {
                            if (externalCustomerGroupFromDao) {
                                this.adminService.addOrReplaceExternalCustomerGroup(externalCustomerGroupFromDao);
                            }
                        });
                    }
                });
            }

            this.dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    onImageFileChange(object: Event): void {
        const currentTarget = <HTMLInputElement>object.currentTarget;
        if (currentTarget.files.length === 1) {
            const file = currentTarget.files.item(0);
            const reader = new FileReader();
            reader.onload = (event: Event) => {
                const target = <FileReader>event.target;
                const binaryString = <string>target.result;
                const base64 = window.btoa(binaryString);
                this.imageFileElement = base64;
            };
            reader.readAsBinaryString(file);
            markAsTouched(this.myForm);
        }
    }

    onSubmit(): void {
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        const email: string = this.myForm.get('emailForm').value?.trim();

        managerUser.email = email;
        let customer$: Observable<ManagerUser>;
        let operation: CrudOperation;
        if (managerUser.id) {
            customer$ = this.managerUserDaoService.save(managerUser);
            operation = 'SAVE';
        } else {
            customer$ = this.managerUserDaoService.create(managerUser);
            operation = 'CREATE';
        }
        customer$.pipe(switchMap((managerUserFromDao) => {
            const image = this.imageFileElement;
            let imageFile$;
            if (image) {
                imageFile$ = this.imageFileService.saveManagerUserPicture(managerUserFromDao.id, image);
            } else {
                imageFile$ = of(null);
            }
            return forkJoin([imageFile$, of(managerUserFromDao)]);
        })).subscribe((result) => {
            const managerUserFromDao = result[1];
            this.imageFileElement = null;
            const crudOperation: CrudOperationWrapper = {
                data: managerUserFromDao,
                operation: operation
            };
            this.dialogRef.close(crudOperation);
        });
    }

    onRemove(): void {

        const email = this.myForm.get('managerUserForm').value.email;
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: '<h2 class="warn-800-fg">Esse procedimento não tem volta.</h2> ' +
                    '<p class="warn-800-fg">Qualquer conteúdo do marketplace desse usuário também será excluído.</p>' +
                    '<br><b>Confirme</b> a exclusão digitando o e-mail: <b>' + email + '</b> no campo abaixo <br>',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline',
                confirmFieldValue: email
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
                this.managerUserDaoService.reallyDelete(managerUser.id).subscribe(v => {

                    const crudOperation: CrudOperationWrapper = {
                        operation: 'DELETE',
                        data: this.managerUser
                    };
                    this.dialogRef.close(crudOperation);
                });
            }
        });
        this.addSubscription(subscription);

    }

    onCancel(): void {
        const managerUser: ManagerUser = null;
        const crudOperation: CrudOperationWrapper = {
            operation: 'CANCEL',
            data: managerUser
        };
        this.dialogRef.close(crudOperation);
    }


    changeTwoFacture(): void {
        const managerUser: ManagerUser = this.myForm.get('managerUserForm').value;
        if (managerUser.twoFactorAuth) {
            this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                data: {
                    message: 'Se você não tiver acesso ao e-mail ( <b>' + this.data.managerUser.email + '</b> ),<br>você não conseguirá fazer o login. ',
                    disableCancel: false,
                    confirmButtonValue: 'Ativar',
                    icon: 'error_outline'
                }
            }).afterClosed().subscribe((result) => {
                managerUser.twoFactorAuth = !!result;
                this.myForm.get('managerUserForm').setValue(managerUser);
            });
        }
    }
}
