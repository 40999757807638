import {Component} from '@angular/core';
import {ManagerUserOnline} from '../../../models';
import {ManagerUserDaoService} from '../../manager-user/manager-user-dao.service';
import {ImageFileService} from '../../image-file/image-file-service';
import {ManagerUserOnlineDetailsComponent} from '../manager-user-online-details/manager-user-online-details.component';
import {filter} from 'rxjs/operators';
import {CrudOperationWrapper} from '../../../helpers/kluh';
import {MatDialog} from '@angular/material/dialog';
import {ComponentCleaner} from '../../../component-cleaner';
import {ConfirmDialogComponent} from '../../../helpers/confirm-dialog/confirm-dialog.component';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-manager-user-online',
    templateUrl: './manager-user-online.component.html',
    styleUrls: ['./manager-user-online.component.scss', '../status.component.scss']
})
export class ManagerUserOnlineComponent extends ComponentCleaner {

    managerUsersOnline: ManagerUserOnline[];
    managerUsersOnlineFilter: ManagerUserOnline[] = [];
    searchFieldControl: FormControl = new FormControl();

    constructor(private managerUserDao: ManagerUserDaoService,
                public imageFileService: ImageFileService,
                private dialog: MatDialog) {
        super();
        this.getManagerUsersOnline();

        const subscription2 = this.searchFieldControl.valueChanges.subscribe(() => {
            const searchField = this.searchFieldControl.value;
            if (searchField && searchField.length > 0) {
                this.managerUsersOnlineFilter = this.managerUsersOnline.filter(
                    managerUsersOnline => {
                        const search = searchField.toString().toLowerCase();
                        if (managerUsersOnline.email.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                        if (managerUsersOnline.displayName.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                        if (managerUsersOnline.managerUserId.toString().toLowerCase().indexOf(search) > -1) {
                            return managerUsersOnline;
                        }
                    });
            } else {
                this.managerUsersOnlineFilter = this.managerUsersOnline;
            }
        });
        this.addSubscription(subscription2);


    }

    getManagerUsersOnline(): void {
        this.managerUserDao.getManagerUsersOnline().subscribe((managerUsersOnline) => {
            this.managerUsersOnline = managerUsersOnline;
            this.managerUsersOnlineFilter = this.managerUsersOnline;
        });
    }

    managerUserOnlineDetails(managerUserOnline: ManagerUserOnline): void {
        const dialogRef = this.dialog.open(ManagerUserOnlineDetailsComponent, {
            disableClose: true,
            panelClass: 'generic-edit-dialog-large',
            data: {
                managerUserOnline: managerUserOnline,

            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((result: CrudOperationWrapper[]) => {
            dialogRef.close();
        });
        this.addSubscription(subscription);
    }


    disconnectSession(managerUserId: number, sessionId: string): void {
        const subscription = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
                message: 'Você tem certeza que deseja encerrar a sessão do usuário?',
                disableCancel: false,
                confirmButtonValue: 'OK',
                icon: 'error_outline'
            }
        }).afterClosed().subscribe((result) => {
            if (result) {
                this.managerUserDao.disconnectSession({managerUserId, sessionId}).subscribe(() => {
                    this.getManagerUsersOnline();
                });
            }
        });
        this.addSubscription(subscription);
    }
}
