import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../../base-dao';
import {KluhManagerValidator} from '../../../../../validators/kluh-manager-validator';
import {InstanceSchedule} from '../../../../../models';
import {R2CloudHttpApiService} from '../../../../../r2-cloud-http-api.service';

@Injectable({
  providedIn: 'root'
})
export class InstanceScheduleDaoService  extends BaseDao<InstanceSchedule> {

  constructor(protected http: R2CloudHttpApiService, protected validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'instance-schedule');
  }
}
