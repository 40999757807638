<div *ngIf="!router?.id" class="py-32" fxLayout="column" fxLayoutAlign="center center">
  <!--suppress JSBitwiseOperatorUsage -->
  <div class="mat-error">
    Sem configuração de Roteador
  </div>
  <div *appIsAdmin>
    <button type="button" mat-raised-button color="primary" (click)="onCreate()" [disabled]="!editing && !vpnConfig">
      Criar Configuração
    </button>
  </div>
</div>
<div *ngIf="!editing && router?.id" class="py-32" fxLayout="row" fxLayoutWrap fxLayoutAlign="space-around center">
  <div fxLayout="column" fxLayoutAlign="start center">
    <div>
      <span class="mat-caption">Tipo: </span><span class="mat-body-2">{{router.routerType}}</span>
    </div>
    <div>
      <span class="mat-caption">Dyndns: </span><span class="mat-body-2">{{router.dyndns}}</span>
    </div>
    <div>
      <span class="mat-caption">Endereço Local: </span><span
      class="mat-body-2">{{router.address}}/{{router.networkBits}}</span>
    </div>
    <div>
      <span class="mat-caption">Servidor DHCP: </span><span class="mat-body-2">{{router.lanDHCP ? 'Sim' : 'Não'}}</span>
    </div>
    <div>
      <span class="mat-caption">Pool DHCP: </span><span class="mat-body-2">{{router.lanDHCPRange}}</span>
    </div>
    <div>
      <span class="mat-caption">Servidor DNS: </span><span class="mat-body-2">{{router.lanDNS ? 'Sim' : 'Não'}}</span>
    </div>
    <div *ngIf="router.lanDNS && router.remoteDNSList" fxLayout="column" fxLayoutAlign="center center">
      <span class="mat-caption">Servidores DNS Remoto: </span>
      <div *ngFor="let dns of router.remoteDNSList.split(',')">
        <span class="mat-body-2">{{dns}}</span>
      </div>
    </div>
    <div>
      <span class="mat-caption">VPN: </span><span class="mat-body-2">{{router.hasVPN ? 'Sim' : 'Não'}}</span>
    </div>
    <div>
      <span class="mat-caption">Quantidade de Wans: </span><span
      class="mat-body-2">{{wans?.length}}</span>
    </div>
  </div>
  <div *ngIf="wans && wans.length > 0">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let wan of wans;" class="wan-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{wan.interfaceName}}
          </mat-panel-title>
          <mat-panel-description>
            Prioridade: {{wan.priority}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="grow">
          <div>
            <span class="mat-caption">Dyndns: </span><span class="mat-body-2">{{wan.dyndns}}</span>
          </div>
          <div>
            <span class="mat-caption">VPN: </span><span class="mat-body-2">{{wan.hasVPN ? 'Sim' : 'Não'}}</span>
          </div>
          <div>
            <span class="mat-caption">Interface Física: </span><span
            class="mat-body-2">{{wan.originalInterfaceName}}</span>
          </div>
          <div>
            <span class="mat-caption">PPPoE: </span><span class="mat-body-2">{{wan.pppoE ? 'Sim' : 'Não'}}</span>
          </div>
          <ng-container *ngIf="wan.pppoE">
            <div>
              <span class="mat-caption">Usuário PPPoE: </span><span class="mat-body-2">{{wan.pppoEUserName}}</span>
            </div>
            <div>
              <span class="mat-caption">Senha PPPoE: </span><span class="mat-body-2">{{wan.pppoEPassword}}</span>
            </div>
          </ng-container>
          <div>
            <span class="mat-caption">DHCP: </span><span class="mat-body-2">{{wan.dhcp ? 'Sim' : 'Não'}}</span>
          </div>
          <ng-container *ngIf="!wan.dhcp; else useDNS">
            <div>
              <span class="mat-caption">IP: </span><span class="mat-body-2">{{wan.address}}/{{wan.network}}</span>
            </div>
            <div>
              <span class="mat-caption">Gateway: </span><span class="mat-body-2">{{wan.gateway}}</span>
            </div>
          </ng-container>
          <ng-template #useDNS>
            <div>
              <span class="mat-caption">Usar DNS do DHCP: </span><span
              class="mat-body-2">{{wan.useDNS ? 'Sim' : 'Não'}}</span>
            </div>
          </ng-template>
          <div>
            <span class="mat-caption">Provedor: </span><span class="mat-body-2">{{findWanProvider(wan)?.name}}</span>
          </div>
          <div>
            <span class="mat-caption">Tecnologia: </span><span
            class="mat-body-2">{{findWanTechnology(wan)?.name}}</span>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div *ngIf="editing && myForm" ngClass.gt-xs="pt-32" fxLayout.gt-xs="column" fxLayout.xs="column-reverse"
     fxLayoutAlign="space-around stretch">
  <form [formGroup]="myForm" (submit)="myForm.dirty && myForm.valid && onSubmit()">
    <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutAlign.gt-xs="space-around start" ngClass.xs="mt-12"
         fxLayoutAlign.xs="start center">
      <!--<button type="button" mat-raised-button (click)="onFinishEditing()">Fechar</button>-->
      <mat-card ngClass.gt-xs="ml-12" class="router-form mb-12 p-24" fxLayout="column" fxLayoutAlign="center stretch"
                *ngIf="router?.id && myForm"
                [class.error-bg]="myForm.invalid"
                [formGroup]="myForm">
        <mat-form-field class="input-big">
          <mat-select placeholder="Tipo de Roteador" formControlName="routerType">
            <mat-option *ngFor="let routerType of routerTypeList" [value]="routerType">
              {{routerType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input-big">
          <input matInput placeholder="Dyndns" formControlName="dyndns" name="dyndns">
          <mat-error
            *ngIf="myForm.get('dyndns').hasError('required') || myForm.get('dyndns').hasError('invalidDyndns')">
            Dyndns Inválido
          </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big">
          <mat-form-field class="input-small">
            <input matInput placeholder="IP" formControlName="address" name="address">
            <mat-error>
              {{myForm.get('address').errors | error}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-small">
            <input matInput placeholder="Network Bits" formControlName="networkBits" name="networkBits" type="number">
            <mat-error>
              {{myForm.get('networkBits').errors | error}}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-slide-toggle formControlName="lanDHCP" name="lanDHCP" class="mt-12">
          Servidor DHCP
        </mat-slide-toggle>
        <mat-form-field class="input-big" *ngIf="myForm.get('lanDHCP').value">
          <input matInput placeholder="Range DHCP" formControlName="lanDHCPRange" name="lanDHCPRange">
          <mat-error>
            {{myForm.get('lanDHCPRange').errors | error}}
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle formControlName="lanDNS" name="lanDNS" class="mt-12">
          Servidor DNS
        </mat-slide-toggle>
        <mat-form-field class="input-big" *ngIf="myForm.get('lanDNS').value">
          <input matInput placeholder="DNS Remoto (Separado por ,)" formControlName="remoteDNSList"
                 name="remoteDNSList">
          <mat-error>
            {{myForm.get('remoteDNSList').errors | error}}
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle formControlName="hasVPN" name="hasVPN" class="mt-12">
          VPN
        </mat-slide-toggle>
      </mat-card>
      <ng-container *ngIf="myWanForm">
        <div fxLayout.gt-xs="row" fxLayout.xs="column" fxLayoutWrap.gt-xs fxLayoutAlign.gt-xs="space-around start"
             fxLayoutAlign.xs="start center" fxLayoutGap.gt-xs="12px">
          <mat-card class="mb-12" *ngFor="let wanGroup of myWanForm.controls; let i = index;" fxLayout="column"
                    fxLayoutAlign="center stretch"
                    [formGroup]="wanGroup" [class.error-bg]="wanGroup.invalid">
            <div class="p-24" fxLayout="column" fxLayoutAlign="center stretch">
              <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big">
                <mat-form-field class="input-small">
                  <input matInput placeholder="Nome da Interface" formControlName="interfaceName" name="interfaceName">
                  <mat-error *ngIf="wanGroup.get('interfaceName').hasError('pattern');">
                    Interface Inválida
                  </mat-error>
                  <mat-error
                    *ngIf="!wanGroup.get('interfaceName').hasError('pattern') && wanGroup.get('interfaceName').hasError('duplicated');">
                    Interface Duplicada
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="input-small">
                  <input matInput placeholder="Interface Original" formControlName="originalInterfaceName"
                         name="originalInterfaceName">
                  <mat-error *ngIf="wanGroup.get('originalInterfaceName').hasError('pattern');">
                    Interface Inválida
                  </mat-error>
                  <mat-error
                    *ngIf="!wanGroup.get('originalInterfaceName').hasError('pattern') && wanGroup.get('originalInterfaceName').hasError('duplicated');">
                    Interface Duplicada
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="input-big">
                <input matInput placeholder="Dyndns" formControlName="dyndns" name="dyndns">
                <mat-error
                  *ngIf="wanGroup.get('dyndns').hasError('required') || wanGroup.get('dyndns').hasError('invalidDyndns')">
                  Dyndns Inválido
                </mat-error>
                <mat-error
                  *ngIf="!(wanGroup.get('dyndns').hasError('required') || wanGroup.get('dyndns').hasError('invalidDyndns')) && wanGroup.get('dyndns').hasError('duplicated');">
                  Dyndns Duplicado
                </mat-error>
              </mat-form-field>
              <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big">
                <mat-form-field class="input-small">
                  <input matInput placeholder="Prioridade" formControlName="priority" type="number"
                         name="priority">
                  <mat-error *ngIf="wanGroup.get('priority').hasError('required');">
                    Prioridade Inválida
                  </mat-error>
                  <mat-error
                    *ngIf="!wanGroup.get('priority').hasError('required') && wanGroup.get('priority').hasError('duplicated');">
                    Prioridade Duplicada
                  </mat-error>
                </mat-form-field>
                <mat-slide-toggle formControlName="pppoE" name="pppoE" class="mt-12">
                  Usar PPPoE
                </mat-slide-toggle>
              </div>

              <ng-container *ngIf="!wanGroup.get('pppoE').value">
                <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big mt-12">
                  <mat-slide-toggle formControlName="dhcp" name="dhcp">
                    Usar DHCP
                  </mat-slide-toggle>
                  <mat-slide-toggle *ngIf="wanGroup.get('dhcp').value" formControlName="useDNS" name="useDNS">
                    Usar DNS
                  </mat-slide-toggle>
                </div>
                <ng-container *ngIf="!wanGroup.get('dhcp').value">
                  <mat-form-field class="input-big">
                    <input matInput placeholder="IP" formControlName="address" name="address">
                    <mat-error
                      *ngIf="wanGroup.get('address').hasError('required') || wanGroup.get('address').hasError('invalidIp');">
                      IP Inválido
                    </mat-error>
                  </mat-form-field>
                  <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big">
                    <mat-form-field class="input-small">
                      <input matInput placeholder="Gateway" formControlName="gateway" name="gateway">
                      <mat-error
                        *ngIf="wanGroup.get('gateway').hasError('required') || wanGroup.get('gateway').hasError('invalidIp');">
                        Gateway Inválido
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-small">
                      <input matInput placeholder="Network Bits" formControlName="networkBits" name="networkBits"
                             type="number">
                      <mat-error *ngIf="wanGroup.get('networkBits').errors;">
                        Bits de Rede Inválido (>0 e <32)
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="wanGroup.get('pppoE').value">
                <mat-form-field class="input-big">
                  <input matInput placeholder="Usuário PPPoE" formControlName="pppoEUserName" name="pppoEUserName">
                  <mat-error
                    *ngIf="wanGroup.get('pppoEUserName').hasError('required')">
                    Preencha o usuário PPPoE
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="input-big">
                  <input matInput placeholder="Senha PPPoE" formControlName="pppoEPassword" name="pppoEPassword">
                  <mat-error
                    *ngIf="wanGroup.get('pppoEPassword').hasError('required')">
                    Preencha a senha PPPoE
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <div fxLayout="row" fxLayoutAlign="space-between none" class="input-big" class="mt-12">
                <mat-slide-toggle formControlName="hasVPN" name="hasVPN" class="mt-12">
                  Usar VPN
                </mat-slide-toggle>
                <mat-form-field class="input-small" *ngIf="wanGroup.get('hasVPN').value">
                  <input matInput placeholder="Prioridade VPN" formControlName="vpnPriority" type="number"
                         name="vpnPriority">
                  <mat-error *ngIf="wanGroup.get('vpnPriority').hasError('required');">
                    Prioridade Inválida
                  </mat-error>
                  <mat-error
                    *ngIf="!wanGroup.get('vpnPriority').hasError('required') && wanGroup.get('vpnPriority').hasError('duplicated');">
                    Prioridade Duplicada
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field>
                <mat-select placeholder="Provedor" formControlName="wanProvider">
                  <mat-option *ngFor="let wanProvider of wanProviderList" [value]="wanProvider.id">
                    {{wanProvider.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="wanGroup.get('wanProvider').hasError('required')">
                  Escolha um Provedor
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-select placeholder="Tecnologia" formControlName="wanTechnology">
                  <mat-option *ngFor="let wanTechnology of wanTechnologyList" [value]="wanTechnology.id">
                    {{wanTechnology.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="wanGroup.get('wanTechnology').hasError('required')">
                  Escolha uma Tecnologia
                </mat-error>
              </mat-form-field>
            </div>
            <div class="mt-12 p-16 grey-100-bg border-top" fxLayout="row" fxLayoutAlign="space-evenly end">
              <button type="button" (click)="onDeleteWan(i)" mat-raised-button color="warn">
                Remover
              </button>
            </div>
          </mat-card>
        </div>
      </ng-container>
    </div>
    <div class="input-big p-16 grey-100-bg" ngClass.gt-xs="border-top" ngClass.xs="border-bottom" fxLayout="row"
         fxLayoutAlign="space-evenly end">
      <button type="submit" [disabled]="myForm.pristine || !myForm.valid"
              mat-raised-button color="primary">
        Salvar
      </button>
      <button type="button" [disabled]="(myForm.pristine)" (click)="onCancel()" mat-raised-button color="warn">
        Cancelar
      </button>
      <button type="button" (click)="onAddWan()" mat-raised-button color="primary">
        Adicionar Wan
      </button>
    </div>
  </form>¶
</div>
