import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FuseWidgetModule} from '../../../../@fuse/components';
import {KluhSharedModule} from '../../kluh-shared.module';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {R2CloudSupportModule} from './r2-cloud-support/r2-cloud-support.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ServerCreateDialogComponent} from './server-create-dialog/server-create-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';

// const routes = [
//     {
//         path: 'admin-old',
//         component: R2CloudDashboardComponent,
//         canActivate: [AuthService],
//         data: {roles: ['ROLE_ADMIN', 'ROLE_DESKTOP_ADMIN', 'ROLE_DESKTOP_SUPPORT']}
//     }
// ];

@NgModule({
    imports: [
        CommonModule,
        // RouterModule.forChild(routes),
        FuseWidgetModule,
        KluhSharedModule,
        FuseSharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCardModule,
        MatDialogModule,
        MatIconModule,
        MatToolbarModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        R2CloudSupportModule,
        FontAwesomeModule,
        MatSlideToggleModule
    ],
    declarations: [ServerCreateDialogComponent],
    entryComponents: [ServerCreateDialogComponent]
})
export class R2CloudModule {
}
