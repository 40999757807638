import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {CloudConfigDaoService} from './cloud-config-dao.service';
import {InstanceDaoService} from '../instance/instance-dao.service';
import {jsonValidator} from '../../../../validators/json-validator';
import {ComponentCleaner} from '../../../../component-cleaner';
import {filter, map, mergeMap} from 'rxjs/operators';
import {CloudConfig, DataCenter, Instance} from '../../../../models';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-cloud-config',
    templateUrl: './cloud-config.component.html',
    styleUrls: ['./cloud-config.component.scss']
})
export class CloudConfigComponent extends ComponentCleaner {
    @Input()
    dataCenter: DataCenter;
    cloudConfig: CloudConfig;
    @Output() editingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() modelInstancesChange: EventEmitter<Instance[]> = new EventEmitter<Instance[]>();
    @Input()
    editing: boolean;
    myForm: FormGroup;
    googleZones: string[];
    instances: Instance[];

    constructor(private cloudConfigDao: CloudConfigDaoService, private instanceDao: InstanceDaoService, private fb: FormBuilder, private dialog: MatDialog) {
        super();
    }

    @Input()
    set modelInstances(instances: any[]) {
        this.instances = instances;
    }

    onSubmit(): void {

    }

    @Input()
    set modelCloudConfig(cloudConfig: CloudConfig) {
        this.setCloudConfig(cloudConfig);
    }

    setCloudConfig(cloudConfig: CloudConfig): void {
        this.cloudConfig = cloudConfig;
        if (this.cloudConfig) {
            this.cloudConfigDao.getAllGoogleZones().subscribe((googleZones) => {
                this.googleZones = googleZones;
                const myZone = this.googleZones.find((zone) => zone === this.cloudConfig.zone);
                this.myForm = this.fb.group({zone: myZone});
                this.myForm.get('zone').setValidators([Validators.required]);
                const subscription = this.myForm.get('zone').valueChanges.subscribe((zone) => {
                    if (zone !== myZone) {
                        this.cloudConfigDao.setCloudConfigZone(this.cloudConfig.id, zone).subscribe((newCloudConfig) => {
                            this.setCloudConfig(newCloudConfig);
                        });
                    }
                });
                this.addSubscription(subscription);
            });
        }
    }

    onGetInstances(): void {
        this.instanceDao.getFromGoogle(this.dataCenter.id).subscribe((instances) => {
            const dialogRef = this.dialog.open(GoogleInstanceDialogComponent, {
                disableClose: true,
                panelClass: 'google-instance-dialog',
                data: {
                    instancesFromGoogle: instances,
                    instances: this.instances
                }
            });
            const subscription = dialogRef
                .afterClosed().pipe(
                    filter(x => !!x),
                    map((data) => data.instances))
                .subscribe((newInstances) => {
                    this.instances = newInstances;
                    this.modelInstancesChange.emit(newInstances);
                });
            this.addSubscription(subscription);
        });
    }

    onCreateCloudConfig(): void {
        if (this.dataCenter && this.dataCenter.id) {
            const newCloudConfig: CloudConfig = {
                id: null,
                active: true,
                comment: null,
                googleCloudJson: null,
                dataCenterId: this.dataCenter.id,
                zone: 'southamerica-east1-a',
                modified: null,
                optlock: null
                // googleCloudJson: null,
            };
            this.cloudConfigDao.create(newCloudConfig).subscribe((cloudConfig) => this.setCloudConfig(cloudConfig));
        }
    }

    onInsertToken(): void {
        const dialogRef = this.dialog.open(CloudConfigInsertTokenDialogComponent, {
            disableClose: true,
            panelClass: 'insert-token-dialog'
        });
        const subscription = dialogRef
            .afterClosed().pipe(
                filter(x => !!x),
                mergeMap((data) => {
                    this.cloudConfig.googleCloudJson = JSON.parse(data.token);
                    return this.cloudConfigDao.save(this.cloudConfig);
                }))
            .subscribe((cloudConfig) => this.setCloudConfig(cloudConfig));
        this.addSubscription(subscription);
    }

}

@Component({
    selector: 'app-cloud-config-insert-token',
    styleUrls: ['./cloud-config-insert-token.dialog.scss'],
    templateUrl: 'cloud-config-insert-token.dialog.html'
})
export class CloudConfigInsertTokenDialogComponent {
    private keysToCheck = [
        'type',
        'project_id',
        'private_key_id',
        'private_key',
        'client_email',
        'client_id',
        'auth_uri',
        'token_uri',
        'auth_provider_x509_cert_url',
        'client_x509_cert_url'
    ];
    myForm = new FormGroup({token: new FormControl('', [Validators.required, jsonValidator(this.keysToCheck)])});

    constructor(public dialogRef: MatDialogRef<CloudConfigInsertTokenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    onSubmit(): void {
        this.dialogRef.close(this.myForm.value);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'app-google-instance',
    styleUrls: ['./google-instance.dialog.scss'],
    templateUrl: 'google-instance.dialog.html'
})
export class GoogleInstanceDialogComponent {
    instances: Instance[];
    instancesFromGoogle: Instance[];

    constructor(public dialogRef: MatDialogRef<GoogleInstanceDialogComponent>,
                private instanceDao: InstanceDaoService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.instances = data.instances;
        this.instancesFromGoogle = data.instancesFromGoogle;
        if (!this.instances) {
            this.instances = [];
        }
    }

    onCheckIfExists(instance: Instance): boolean {
        return !!this.findInstance(instance);
    }

    private findInstance(instance: Instance): Instance {
        return this.instances.find((x) => x.googleInstanceName === instance.googleInstanceName);
    }

    onCreateInstance(instance: Instance): void {
        this.instanceDao.create(instance).subscribe((newInstance) => {
            this.instances.push(newInstance);
        });
    }

    onUpdateInstance(instance: Instance): void {
        const existingInstance = this.findInstance(instance);
        instance.id = existingInstance.id;
        this.instanceDao.save(instance).subscribe((newInstance) => {
            const index = this.instances.findIndex((x) => x.googleInstanceName === newInstance.googleInstanceName);
            this.instances.splice(index, 1, newInstance);
        });
    }

    onClose(): void {
        this.dialogRef.close({instances: this.instances});
    }
}
