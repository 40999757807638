import {FormControl} from '@angular/forms';

export function dyndnsValidator(formControl: FormControl): any {
  const dyndns = formControl.value;
  // const regex = /[a-z]+-[a-z]+[0-9]+\.dyndns\.kluh\.com\.br/;
  const regex = /[a-z0-9]+\.[a-z0-9]+\.dyndns\.kluh\.com\.br/;
  if (regex.test(dyndns)){
    return null;
  } else {
    return {'invalidDyndns': {value: dyndns}};
  }
}
