import {Component} from '@angular/core';
import {DataCenterDAOService} from './data-center-dao';
import {ProjectDaoService} from '../project/project-dao.service';
import {DataCenterCreateComponent} from './data-center-create/data-center-create.component';

import {fuseAnimations} from '../../../../@fuse/animations';
import {DataCenter, DataCenterType, Project} from '../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-data-center',
  templateUrl: './data-center.component.html',
  styleUrls: ['./data-center.component.scss'],
  animations: fuseAnimations,
})
export class DataCenterComponent {
  projects: Project[];
  dataCenterTypes: DataCenterType[];
  dataCenters: DataCenter[];

  constructor(private projectDAO: ProjectDaoService, private dataCenterDAO: DataCenterDAOService, private dialog: MatDialog) {
    this.setDataCenters();
    this.setDataCenterTypes();
    this.setProjects();
  }

  private setDataCenters(): void {
    this.dataCenterDAO.get().subscribe((dataCenters) => {
      this.dataCenters = dataCenters;
    });
  }

  private setDataCenterTypes(): void {
    this.dataCenterDAO.getDataCenterTypes().subscribe((dataCenterTypes) => {
      this.dataCenterTypes = dataCenterTypes;
    });
  }

  private setProjects(): void {
    this.projectDAO.getActive().subscribe((projects) => {
      this.projects = projects;
    });
  }

  findProjectById(projectId: number): Project {
    return this.projects.find((x) => x.id === projectId);
  }


  onAdd(): void {
    const dialogRef = this.dialog.open(DataCenterCreateComponent, {
      disableClose: true,
      panelClass: 'create-data-center-dialog',
      data: {
        projects: this.projects,
        dataCenterTypes: this.dataCenterTypes
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.setDataCenters();
      }
    });
  }

}
