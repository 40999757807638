import {Component, Input} from '@angular/core';
import {JaversSnapshot} from '../../../javers/javers-snapshot';
import {CustomerGroup, RemoteApp, SubProject} from '../../../models';
import {RemoteAppDaoService} from '../../remote-app/remote-app-dao.service';
import {StatusService} from '../status-service';

@Component({
    selector: 'app-remote-app-snapshots',
    templateUrl: './remote-app-snapshots.component.html',
    styleUrls: ['./remote-app-snapshots.component.scss', '../status.component.scss']
})
export class RemoteAppSnapshotsComponent {

    public remoteAppsSnapshots: JaversSnapshot<RemoteApp>[];

    @Input()
    customerGroups: CustomerGroup[] = [];

    @Input()
    subProjects: SubProject[] = [];

    remoteApps: RemoteApp[] = [];

    loading = false;
    alreadyRead = false;


    constructor(
        private remoteAppDaoService: RemoteAppDaoService,
        public statusServer: StatusService) {

    }

    getData(): void {
        this.loading = true;
        this.alreadyRead = true;
        this.remoteAppDaoService.get().subscribe(remoteApps => {
            this.remoteApps = remoteApps;
            this.remoteAppDaoService.snapshotsLimit(5).subscribe((remoteAppsSnapshots) => {
                this.loading = false;
                this.remoteAppsSnapshots = remoteAppsSnapshots;
            });
        });

    }

}
