import {Injectable} from '@angular/core';
import {BaseDao} from '../../../../base-dao';
import {KluhManagerValidator} from '../../../../validators/kluh-manager-validator';
import {Observable} from 'rxjs';
import {ValueWrapper} from '../../../../helpers/value-wrapper';
import {Instance, SnapshotPolicy, SnapshotPolicyType} from '../../../../models';
import {shareReplay} from 'rxjs/operators';
import {R2CloudHttpApiService} from '../../../../r2-cloud-http-api.service';

@Injectable()
export class InstanceDaoService extends BaseDao<Instance> {
  private snapshotPolicyTypes$: Observable<SnapshotPolicyType[]>;


  constructor(protected http: R2CloudHttpApiService, protected validatorDAO: KluhManagerValidator) {
    super(http, validatorDAO, 'instances');
  }
/*
  clearCache(): void {
    this.snapshotPolicyTypes$ = null;
  }
*/
  getFromGoogle(dataCenterId: number): Observable<Instance[]> {
    return this.http.get<Instance[]>(this.url + '/from-google', {dataCenterId: dataCenterId});
  }

  getSnapshotPolicyTypes(): Observable<SnapshotPolicyType[]> {
    if (this.snapshotPolicyTypes$ == null) {
      this.snapshotPolicyTypes$ = this.http.get<SnapshotPolicyType[]>(this.url + '/snapshot-policy-types').pipe(shareReplay(1));
    }
    return this.snapshotPolicyTypes$;
  }

  getSnapshotPolicy(instanceId: number): Observable<SnapshotPolicy> {
    return this.http.get<SnapshotPolicy>(this.url + '/' + instanceId + '/snapshot-policy');
  }

  getAllSnapshotPolicy(): Observable<SnapshotPolicy[]> {
    return this.http.get<SnapshotPolicy[]>(this.url + '/snapshot-policy');
  }

  saveSnapshotPolicy(snapshotPolicy: SnapshotPolicy): Observable<SnapshotPolicy> {
    return this.http.post<SnapshotPolicy>(this.url + '/snapshot-policy', snapshotPolicy);
  }

  takeSnapshot(instanceId: number): Observable<string> {
    return this.http.get<string>(this.url + '/' + instanceId + '/test-snapshot');
  }

  saveBatchSnapshotPolicy(snapshotPolicies: SnapshotPolicy[]): Observable<SnapshotPolicy[]> {
    return this.http.post<SnapshotPolicy[]>(this.url + '/snapshot-policy/batch', snapshotPolicies);
  }

  stopInstance(instance: Instance): Observable<string> {
    return this.http.post<string>(this.url + '/stop-instance', instance);
  }

  startInstance(instance: Instance): Observable<string> {
    return this.http.post<string>(this.url + '/start-instance', instance);
  }

  getInstanceStatus(instance: Instance): Observable<ValueWrapper> {
    return this.http.get<ValueWrapper>(this.url + '/' + instance.id + '/status');
  }
}
