import {Component} from '@angular/core';
import {AdGroupDaoService} from '../ad-group-dao.service';
import {AdDomainDaoService} from '../../ad-domain/ad-domain-dao.service';
import {AdGroupCreateEditComponent} from '../ad-group-create-edit/ad-group-create-edit.component';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ComponentCleaner} from '../../../component-cleaner';
import {filter} from 'rxjs/operators';
import {ADDomain, ADGroup} from '../../../models';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-ad-group-list',
    templateUrl: './ad-group-list.component.html',
    styleUrls: ['./ad-group-list.component.scss'],
    animations: fuseAnimations
})
export class AdGroupListComponent extends ComponentCleaner {
    public adGroups: ADGroup[];
    public adDomains: ADDomain[];

    constructor(private dao: AdGroupDaoService, private adDomainDao: AdDomainDaoService, private dialog: MatDialog) {
        super();
        this.dao.get().subscribe((adGroups) => {
            this.adGroups = adGroups;
            if (!this.adGroups) {
                this.adGroups = [];
            }
        });
        this.adDomainDao.get().subscribe((adDomains) => {
            this.adDomains = adDomains;
        });
    }

    onAdGroupCreated(adGroup: ADGroup): void {
        if (adGroup && this.adGroups) {
            this.adGroups.push(adGroup);
        }
    }

    getAdDomain(adDomainId: number): ADDomain {
        if (this.adDomains) {
            return this.adDomains.find((adDomain) => {
                return adDomain.id === adDomainId;
            });
        }
        return null;
    }

    onCreate(): void {
        const adGroup: ADGroup = {
            id: null,
            active: true,
            comment: null,
            adUserIds: [],
            adUserPoolRelationshipIds: [],
            name: null,
            prefix: null,
            adDomainId: null,
            subProjectId: null,
            changing: false,
            deleting: false,
            createdAt: null,
            updatedAt: null,
            modified: null,
            optlock: null
        };
        this.openModal(adGroup);
    }

    onEdit(adGroup: ADGroup): void {
        this.openModal(adGroup);
    }

    private openModal(adGroup: ADGroup): void {
        const dialogRef = this.dialog.open(AdGroupCreateEditComponent, {
            disableClose: true,
            panelClass: 'ad-group-edit-dialog',
            data: {
                adGroup: adGroup,
                adDomains: this.adDomains
            }
        });
        const subscription = dialogRef.afterClosed().pipe(filter((x) => !!(x))).subscribe((filteredAdGroup) => {
            if (adGroup.id) {
                const index = this.adGroups.findIndex((y) => y.id === filteredAdGroup.id);
                this.adGroups[index] = filteredAdGroup;
            } else {
                this.adGroups.push(filteredAdGroup);
            }
        });
        this.addSubscription(subscription);
    }

}
