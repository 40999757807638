<td>
    <fa-icon class="s-16 mr-8 linux-icon" [icon]="faLinux"></fa-icon>
</td>
<td  class="name-server">
    <div class="text-max-200" title="{{linuxServer.hostname}}">
            {{linuxServer.hostname}}
        <div *ngIf="!isAdmin" >{{linuxServer.hostname}}</div>
        <div>{{linuxServer.privateIp}}</div>
    </div>
</td>
<!--<td>{{linuxServer.publicIp}}</td>-->
<!--<td class="font-size-12">{{linuxServer.privateIp}}</td>-->
<td class="font-size-12">{{linuxServer.connectionFQDN}}</td>
<td class=" font-size-12">
    <div class="text-max-210">{{version}}</div>
</td>
<td class="text-align-center">
    <div class="client-connected"  title="Online" *ngIf="online; else offline">
        <fa-icon  [icon]="faCircle"></fa-icon>
    </div>
    <ng-template #offline>
        <div class="client-not-connected"  title="Offline">
            <fa-icon  [icon]="faCircle"></fa-icon>
        </div>
    </ng-template>
</td>
<td class="text-align-right p-0 pr-2 ">
    <button *appIfPermission="'WRITE'; data: linuxServer; type: 'LinuxServer'" type="button"
            mat-raised-button
            class="mat-green-600-bg" (click)="onEditLinuxServer(linuxServer)">
        Edit
    </button>
</td>
<td class="text-align-right p-0 pr-2 ">
    <button mat-raised-button style="width: 150px"
            [routerLink]="['/monitoring-details/processor']"
            [queryParams]="{linuxServerId: linuxServer.id, id: customerGroupService.customerGroup.id }"
            class="mat-green-600-bg">
        Monitoramento
    </button>
</td>
<td class="text-align-right p-0 pr-2">
    <button type="button" mat-raised-button
            class="mat-green-600-bg" (mousedown)="openWebSSH(linuxServer)">
        <span *ngIf="linuxServer.comment != 'LOADING'">Abrir</span>
        <mat-spinner style="margin-left: 17px" *ngIf="linuxServer.comment == 'LOADING'"
                     [diameter]="24"
                     color="primary"></mat-spinner>
    </button>

</td>