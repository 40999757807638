import {BaseDTOIdLong} from '../../../../models';

export interface SupportCard {
    cardType: SupportCardType;
    value: BaseDTOIdLong;
}

export enum SupportCardType {
    PROJECT,
    SUB_PROJECT,
    COMPANY,
    MANAGER_USER,
    DESKTOP_SERVER,
    LINUX_SERVER
}
