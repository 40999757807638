import {Injectable} from '@angular/core';
import {KluhManagerValidator} from '../../../validators/kluh-manager-validator';
import {BaseMonitoringDao} from '../../../base-monitoring-dao';
import {Observable} from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {CustomPortalTemplate} from '../../../models-custom-layout';
import {R2CloudHttpCustomLayoutService} from '../../../r2-cloud-http-custom-layout.service';

@Injectable({
    providedIn: 'root'
})
export class CustomPortalTemplateDaoService extends BaseMonitoringDao<CustomPortalTemplate> {
    constructor(private httpAngular: HttpClient,
                http: R2CloudHttpCustomLayoutService,
                validatorDAO: KluhManagerValidator) {
        super(http, validatorDAO, 'custom-portal-template');
    }

    findByManagerUserId(managerUserId: number): Observable<CustomPortalTemplate> {
        return this.httpAngular.get<CustomPortalTemplate>(this.url + '/find-by-manager-user-id/' + managerUserId);
    }

    duplicate(dto: CustomPortalTemplate): Observable<CustomPortalTemplate> {
        return this.http.post<CustomPortalTemplate>(this.url + '/duplicate/', dto);
    }


    findMyList(): Observable<CustomPortalTemplate[]> {
        return this.http.get<CustomPortalTemplate[]>(this.url + '/find-my-list/');
    }
}

