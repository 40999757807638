<div class="dialog-content-wrapper">
    <form [formGroup]="customerForm" (ngSubmit)="customerForm.valid && onSubmit()"
          id="customer-create-edit-form">
        <mat-toolbar matDialogTitle class="mat-accent m-0">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">Cadastro de Filial</span>
            </div>
        </mat-toolbar>


        <div class="mat-white-bg mat-elevation-z2" fxLayout="column" fxLayoutAlign="space-between stretch"
             style="padding: 10px;">

            <table>
                <tr>
                    <td class="vertical-align-top w-150">
                        <div>
                            <img src="{{imageFileService.getCustomerPictureUrl(customer.imageUUID, 150)}}"
                                 width="150"
                                 height="150"
                                 *ngIf="!imageFileElement">
                            <img [src]="'data:image/jpg;base64,' + imageFileElement" width="150" height="150"
                                 *ngIf="imageFileElement">
                        </div>
                        <button style="width: 150px;margin-top: 5px;" type="button" mat-raised-button
                                class="green-800-bg green-800"
                                onclick="document.getElementById('fileToUpload').click()">
                            Alterar Foto
                        </button>
                        <input (change)="onImageFileChange($event)" name="file"
                               id="fileToUpload" accept="image/*" type="file" style="width: 150px;overflow: hidden;">

                    </td>
                    <td class="pl-20">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Nome da Filial" name="name" required formControlName="name"
                                   id="customer-create-edit-name">
                            <mat-error>
                                {{customerForm.get('name').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="customerGroups">
                            <mat-select placeholder="Grupo" formControlName="customerGroupId"
                                        id="customer-create-edit-customer-group-id"
                                        [disabled]="customerGroups.length === 1">
                                <mat-option *ngFor="let customerGroup of customerGroups; let i = index;"
                                            [value]="customerGroup.id"
                                            id="customer-create-edit-customer-group-id-{{i}}">
                                    {{customerGroup.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="Telefone" mask="(00)-00000000000" [validation]="false"
                                   name="phone" formControlName="phone"
                                   id="customer-create-edit-phone">
                            <mat-error>
                                {{customerForm.get('phone').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="CNPJ" name="cnpj" mask="00.000.000/0000-00"
                                   [validation]="false" formControlName="cnpj"
                                   id="customer-create-edit-cnpj">
                            <mat-error>
                                {{customerForm.get('cnpj').errors | error}}
                            </mat-error>

                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <mat-select placeholder="Estado" formControlName="province"
                                        id="customer-create-edit-province">
                                <mat-option *ngFor="let province of provinceList; let i = index;" [value]="province.key"
                                            id="customer-create-edit-province-id-{{i}}">
                                    {{province.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{customerForm.get('province').errors | error}}
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Cidade" name="city" formControlName="city"
                                   id="customer-create-edit-city">
                            <mat-error>
                                {{customerForm.get('city').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Bairro" name="neighborhood" formControlName="neighborhood"
                                   id="customer-create-edit-neighborhood">
                            <mat-error>
                                {{customerForm.get('neighborhood').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="Endereço" name="address" formControlName="address"
                                   id="customer-create-edit-address">
                            <mat-error>
                                {{customerForm.get('address').errors | error}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="full-width">
                            <input matInput placeholder="Número" name="addressNumber" mask="00000000000"
                                   [validation]="false" formControlName="addressNumber"
                                   id="customer-create-edit-address-number">
                            <mat-error>
                                {{customerForm.get('addressNumber').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="Complemento" name="addressAddOn" formControlName="addressAddOn"
                                   id="customer-create-edit-address-add-on">
                            <mat-error>
                                {{customerForm.get('addressAddOn').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width">
                            <input matInput placeholder="CEP" mask="00000-000" name="zipCode" formControlName="zipCode"
                                   id="customer-create-edit-zip-code">
                            <mat-error>
                                {{customerForm.get('zipCode').errors | error}}
                            </mat-error>
                        </mat-form-field>


                        <mat-form-field class="full-width min-width-230">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Observações</mat-label>
                                <textarea rows="15" matInput formControlName="comment" class="full-width"></textarea>
                            </mat-form-field>
                        </mat-form-field>


                        <mat-slide-toggle formControlName="active" name="active" class="mb-40">
                            Ativo
                        </mat-slide-toggle>

                        <div *ngIf="errorMessage" class="mt-10 font-size-16 warn-A700-fg">
                            {{errorMessage}}
                        </div>
                    </td>
                </tr>
            </table>


        </div>
        <div fxLayout="row" fxLayoutAlign="space-between end" fxFill class="p-16 grey-50-bg border-top"
             matDialogActions>
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!customerForm.valid || customerForm.pristine"
                    id="customer-create-edit-submit"
                    aria-label="Salvar">
                <span *ngIf="this.customerForm.get('id').value;else newUser">Salvar</span>
                <ng-template #newUser>Criar</ng-template>
            </button>
            <div *ngIf="this.customerForm.get('id').value">
                <button type="button" mat-raised-button color="warn"
                        id="customer-create-edit-remove"
                        (click)="onRemove()">Excluir
                </button>
            </div>
            <button type="button" mat-raised-button color="primary"
                    id="customer-create-edit-cancel"
                    (click)="onCancel()">Cancelar
            </button>
        </div>

    </form>
</div>

